import React from "react";
import { Box, Grid } from "@material-ui/core";
import { useStyles } from "./styles";

function TableRow({ user, index }) {
    const classes = useStyles();

    const dividerClass = (index) => {
        return index === 2 ? classes.divider : classes.hidden;
    }
    const emptyRowClass = (index) => {
        return index === 2 ? classes.emptyRow : classes.hidden;
    }
    const rankingClass = (index) => {
        switch (index) {
            case 0:
                return classes.rankingFirst;
            case 1:
                return classes.rankingSecond;
            case 2:
                return classes.rankingThird;
            default:
                return classes.rankingDefault;
        }
    }

    return (
        <>
            <tr className={classes.tableRow}>
                <td>
                    <div className="font-face-bold">
                        <h1 className={rankingClass(index)}>{index + 1}</h1>
                    </div>
                </td>
                <td>
                    <Grid container direaction="row" alignItems="center"
                          className={classes.userCell}>
                        <img alt="Profile img" src={user.user.profile_media_urls[0]}
                             className={classes.avatar}/>
                        <Box display="flex" flexDirection={"column"}
                             alignItems={"start"} flexWrap={'wrap'}>
                            <div className="font-face-regular">
                                <h2 className={classes.tableCell}>{user.user.full_name}</h2>
                            </div>
                            <div className="font-face-regular">
                                <h3 className={classes.subtitle}>{user.user.username}</h3>
                            </div>
                        </Box>
                    </Grid>
                </td>
                <td>
                    <div className="font-face-regular">
                        <h3 className={classes.tableCellCentered}>{user.count}</h3>
                    </div>
                </td>
            </tr>
            <tr className={dividerClass(index)}></tr>
            <tr className={emptyRowClass(index)}></tr>
        </>
    );
}

export default TableRow;