import React from "react";
import { Box, Button, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
// import pics from "@assets";
// import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { Spinner } from "@base";
import MyDrawer from "./subComponents/drawer";
//// functions need to use react with our component
import { useDispatch, useSelector } from "react-redux";
import reducer from "../signupForm/store/reducers";
import withReducer from "store/withReducer";
import * as Actions from "../signupForm/store/actions";
import queryString from 'query-string'
import * as BaseActions from "store/actions";
import { isIOS, isMobile } from "react-device-detect";
const useStyles = makeStyles((theme) => ({
  mainContainer: {
    marginBottom: "50px",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "20px"
    }
  },
  illustration: {
    objectFit: "contain",
    margin: "auto",
    width: "70%",
    height: "100%",
  },
  imageContainer: {
    display: "flex",
    alignItems: "center",
    // height: "400px",
    width: "100%",
    margin: isMobile ? "0 0" : "30px 0",
  },
  button: {
    width: "45%",
  },
  buttonDec: {
    width: "45%",
    backgroundColor: "#080A0A"
  },
  btns: {
    cursor: "pointer"
  },
  bottomDiv: {
    width: "100%",
    display: "flex",
    justifyContent: " space-between",
    height: isMobile ? "10vh" : "auto"
  }
}));
const LegalStuff = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const updateProfileResponse = useSelector(
    ({ LegalStuff }) => LegalStuff.UpdateProfileReducer
  );
  const updateProfileResponseData = updateProfileResponse.data;
  const isLoading = updateProfileResponse.isLoading;
  // React.useEffect(() => {
  //   const user = localStorage.getItem("quarterlyUser");
  //   if (!user) {
  //     alert()
  //     props.history.push("/");
  //   }
  // });
  const { layout } = useSelector(({ BaseReducer }) => ({
    layout: BaseReducer.LayoutReducer,
  }));
  React.useEffect(() => {
    if (layout.bottomNav) dispatch(BaseActions.updateLayout({ bottomNav: false }));
  }, [layout.bottomNav])
  React.useEffect(() => {
    open && dispatch(BaseActions.showMessage(<MyDrawer setOpen={setOpen} />));
  }, [open]);
  // React.useLayoutEffect(() => {
  //   const user = JSON.parse(localStorage.getItem("quarterlyUser"));
  //   if (!user) {
  //     props.history.push("/");
  //     alert("nottt");
  //   } else {
  //     if (user.data && user.data.tc_and_pp) {
  //       if (user.data.tc_and_pp === 1) {
  //         props.history.push("/");
  //         return;
  //       }
  //     } else if (user && user.tc_and_pp && user.tc_and_pp === 1) {
  //       props.history.push("/");
  //       return;
  //     }
  //   }
  // }, []);
  const id = queryString.parse(props.location.search).id
  const code = queryString.parse(props.location.search).code
  React.useEffect(() => {
    if (updateProfileResponseData) {
      if (updateProfileResponseData.status === "Success" && updateProfileResponseData.data.tc_and_pp === 1) {
        if (id) {
          props.history.push(`/personType?id=${id}&code=${code}`);
        } else {
          props.history.push("/personType");
        }
      }
    }
  }, [updateProfileResponseData, id, code, props.history]);
  const handleAcceptPolicy = (val) => {
    dispatch(
      Actions.updateProfile({
        tc_and_pp: 1,
      })
    );
  };
  const handleNavigate = (path, data) => {
    props.history.push(path);
  };
  return (
    <Box
      display="flex"
      flexDirection="column"
      height="90%"
      position="absolute"
      overflowY="scroll"
      justifyContent="space-between"
      // className={classes.mainContainer}
      // border="1px dashed orange"
      paddingBottom="50px"
    >
      <Box padding={isMobile ? "15px 30px 0px 30px" : "32px 30px 0px 30px"} overflowY="scroll" height={isMobile ? "75vh" : "auto"} display="flex" flexDirection="column" justifyContent={isMobile ? "inherit" : "space-between"} margin={isMobile ? "0" : "0 0 20px 0"}>
        <Typography variant="h1">Some Legal Stuff...</Typography>
        <Box className={classes.imageContainer}>
          <img
            alt="Legal Stuff"
            variant="square"
            // src={pics.legalStuff}
            src={"assets/backgrounds/LegalStuff.jpg"}
            className={classes.illustration}
          />
        </Box>
        {/* <Box marginBottom="20px"> */}
        <Box className={classes.mainContainer}>
          <Typography variant="subtitle2">
            To use Quartrly,
          </Typography>
          <div>
            <div style={{ display: "flex" }}>
              <div style={{ width: "10px", height: "10px", paddingTop: "7px" }}>
                <div style={{ backgroundColor: "#FFFFFF", width: "7px", height: "7px", borderRadius: "9999px" }}></div>
              </div>
              <div style={{ marginLeft: "10px" }}>
                <Typography variant="subtitle2">
                  Accept and Agree to our{" "}
                  <Typography onClick={() => handleNavigate(id ? `/privacy?from=legal?id=${id}&code=${code}` : `/privacy?from=legal`)} className={classes.btns} component="span" variant="subtitle1">
                    Privacy policy{" "}
                  </Typography>
                  and{" "}
                  <Typography onClick={() => handleNavigate(id ? `/terms?from=legal?id=${id}&code=${code}` : `/terms?from=legal`)} className={classes.btns} component="span" variant="subtitle1">
                    Terms of Service.
                  </Typography>
                </Typography>
              </div>
            </div>
            <div style={{ display: "flex" }}>
              <div style={{ width: "10px", height: "10px", paddingTop: "7px" }}>
                <div style={{ backgroundColor: "#FFFFFF", width: "7px", height: "7px", borderRadius: "9999px" }}></div>
              </div>
              <div style={{ marginLeft: "10px" }}>
                <Typography variant="subtitle2">
                  Must be 18 years of age or older.
                </Typography>
              </div>
            </div>
            <div style={{ display: "flex" }}>
              <div style={{ width: "10px", height: "10px", paddingTop: "7px" }}>
                <div style={{ backgroundColor: "#FFFFFF", width: "7px", height: "7px", borderRadius: "9999px" }}></div>
              </div>
              <div style={{ marginLeft: "10px" }}>
                <Typography variant="subtitle2">
                  Reside in the United States.
                </Typography>
              </div>
            </div>
          </div>
        </Box>
      </Box>
      <Box position={isMobile && "fixed"} width={isMobile ? "100%" : "auto"} bottom={isMobile && 15}
        padding="auto 20px 10px 20px"
        padding="30px"
        paddingBottom="50px"
        paddingBottom={isMobile ? "10px" : "32px"}
        display="flex"
        justifyContent=" space-between"
      >
        <Button
          variant="outlined"
          size="large"
          // component={Link}
          // to="/"
          color="primary"
          className={classes.buttonDec}
          onClick={() => {
            setOpen(true);
          }}
        >
          Decline
        </Button>
        <Button
          variant="contained"
          size="large"
          color="primary"
          className={classes.button}
          disabled={isLoading}
          onClick={() => handleAcceptPolicy()}
        >
          {isLoading ? <Spinner /> : "Accept"}
        </Button>
      </Box>
    </Box>
  );
};
export default withReducer("LegalStuff", reducer)(withRouter(LegalStuff));
