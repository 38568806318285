import React, {useEffect, useState} from "react";
import {withRouter} from "react-router";
import clsx from "clsx";
import {makeStyles} from "@material-ui/core/styles";
import {Box, Button, Paper, Typography,} from "@material-ui/core";
import Pics from "../../@assets";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import useDeepCompareEffect from "use-deep-compare-effect";
import * as BaseActions from "store/actions";
import {useDispatch, useSelector} from "react-redux";
import * as Actions from "./store/actions";
import reducer from "./store/reducers";
import withReducer from "store/withReducer";
import Reviews from "components/reviews";
import BlockUser from "components/blockUser/BlockUser";
import ReportUser from "components/blockUser/ReportUser";
import Carousel from "react-multi-carousel";
import {useParams} from "react-router"
import {isIOS} from "react-device-detect";
import Header from "../../@base/header";
import PlaceholderImage from "../home/subComponents/userCard/icons/PlaceholderImage";
import StarRateIcon from "../../@base/icons/StarRateIcon";
import Footer from "../../@base/footer";
import {Image} from "semantic-ui-react";
import PlaceholderErrorImage from "../home/subComponents/userCard/icons/PlaceholderErrorImage";

const responsive = {
    all: {
        breakpoint: {max: 4000, min: 0},
        items: 1,
    },
};

const useStyles = makeStyles((theme) => ({
	container: {
		backgroundSize: "cover",
		position: "relative",
		borderRadius: 6,
		backgroundColor: "black",
	},
	carousel: {
		paddingTop: '79px',
		paddingBottom: '35px',
		backgroundColor: '#141414',
	},
	moreMenu: {
		backgroundColor: "#000000a1",
		top: 119,
		width: "100%",
		[theme.breakpoints.up("sm")]: {
			width: "375px",
		},
	},
	menuContainer: {
		color: "white",
		backgroundColor: "rgba(0,0,0,0.4)",
		padding: "10px 0",
		minWidth: "150px",
	},
	menuItem: {
		padding: "5px 10px",
		display: "flex",
		alignItems: "center",
		cursor: "pointer",
		"&:hover": {
			backgroundColor: "grey",
		},
	},
	imgContainer: {
		display: "block",
		height: "172px",
		width: "172px",
		margin: '0 auto',
		padding: 6,
		backgroundColor: "#FFFFFF33",
		borderRadius: 30,
	},
	profileImage: {
		background: '#1B1B1B',
		height: '100%',
		boxShadow: "0px 12px 24px rgba(68, 68, 68, 0.1)",
		borderRadius: 24,
	},
	dotList: {
		marginTop: 15,
		bottom: 20,
		display: "flex",
		columnGap: 10,
	},
	dot: {
		borderRadius: '50%',
		height: 8,
		width: 8,
		cursor: "pointer",
	},
	video: {
		position: "absolute",
		height: "100%",
		width: "100%",
	},
	dp: {
		height: "20px",
		width: "26px",
	},
	main: {
		display: "flex",
		flexDirection: "column",
		padding: "25px 20px 20px 20px",
		flex: 1,
		gap: '25px',
		overflow: "scroll",
		height: "calc(100vh - 392px)",
		[theme.breakpoints.up("sm")]: {
			height: "calc(100vh - 374px - 92px)",
		},
	},
	title: {
		color: "white",
		textTransform: "capitalize",
		fontFamily: "-apple-system, BlinkMacSystemFont, cerebri-regular",
		wordBreak: 'break-all',
		fontWeight: 600,
		fontSize: 24,
		lineHeight: '28.64px',
		letterSpacing: '0%',
	},
	price: {
		fontFamily: "-apple-system, BlinkMacSystemFont, cerebri-regular",
		fontWeight: 600,
		fontSize: 24,
		alignSelf: "start",
		lineHeight: '28.64px',
		letterSpacing: '0%',
		color: 'rgba(49, 167, 219, 1)'
	},
	ratingPill: {
		fontFamily: "-apple-system, BlinkMacSystemFont, cerebri-regular",
		boxSizing: "border-box",
		display: "flex",
		bottom: '5px',
		right: '5px',
		gap: '8px',
		placeItems: "center",
		padding: '2.5px 8px',
		backgroundColor: '#1B1B1B',
		borderRadius: '24px',
	},
	reviewsCount: {
		fontFamily: "-apple-system, BlinkMacSystemFont, cerebri-regular",
		fontWeight: 400,
		fontSize: 12,
		lineHeight: '14.32px',
		letterSpacing: '0%',
		color: 'rgba(159, 159, 159, 1)',
	},
	seeReviewsLink: {
		fontFamily: "-apple-system, BlinkMacSystemFont, cerebri-regular",
		fontWeight: 600,
		fontSize: 12,
		lineHeight: '14.32px',
		letterSpacing: '0%',
		textAlign: 'right',
		textDecoration: 'underline',
		textDecorationStyle: 'solid',
		textDecorationOffset: 'Auto',
		textDecorationThickness: 'Auto',
		cursor: 'pointer',
	},
	userBio: {
		fontFamily: "-apple-system, BlinkMacSystemFont, cerebri-regular",
		fontWeight: 400,
		fontSize: 14,
		lineHeight: '22px',
		letterSpacing: '0%',
	},
	inline: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
	},
	greyRectangle: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		width: '100%',
		minHeight: '64px',
		padding: '0 15px',
		background: '#1B1B1B',
		boxShadow: '0px 12px 24px rgba(68, 68, 68, 0.1)',
		borderRadius: '16px',
	},
	greyRectangleLabel: {
		fontStyle: 'normal',
		fontWeight: '400',
		fontSize: '14px',
		lineHeight: '22px',
		color: '#FFFFFF',
	},
	greyRectangleValue: {
		fontStyle: 'normal',
		fontWeight: '400',
		fontSize: '14px',
		lineHeight: '17px',
		textAlign: 'right',
		color: '#9F9F9F',
	},
	expertiseContainer: {
		minHeight: 'fit-content !important',
		padding: 0,
	},
	expertiseDiv: {
		padding: 15,
		fontWeight: '700',
		fontSize: '18px',
		lineHeight: '21.48px',
		letterSpacing: '0%',
		'&:not(:last-child)': {
			borderBottom: '1px solid #262626',
		},
	},
	expertise: {
		padding: 15,
		paddingTop: 10,
		paddingBottom: 10,

	},
	expertiseName: {
		fontSize: '14px',
		lineHeight: '22px',
		letterSpacing: '0%',
		fontStyle: 'normal',
		color: '#FFFFFF',
	},
	expertiseYears: {
		fontStyle: 'normal',
		fontWeight: '400',
		fontSize: '14px',
		lineHeight: '17px',
		textAlign: 'right',
		color: '#9F9F9F',
	},
	expertiseHeading: {
		fontStyle: 'normal',
		fontWeight: '700',
		fontSize: '18px',
		lineHeight: '21px',
		alignItems: 'center',
		color: '#31A7DB',
	},
	expertiseHeaderSecondary: {
		fontWeight: '400',
		fontSize: '14px',
		lineHeight: '16.71px',
		letterSpacing: '0%',
		textAlign: 'right',
	},
	tags: {
		fontSize: 12,
		backgroundColor: "#09161C",
		fontFamily: "-apple-system, BlinkMacSystemFont, cerebri-regular",
		borderRadius: 6,
		padding: "5px 10px",
		margin: "0 10px 10px 0",
	},
	slider: {
		position: "absolute",
		left: "50%",
		transform: "translateX(-50%)",
		bottom: 20,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	Bottom: {
		"&:before": {
			display: 'none'
		},
	},
	BottomTop: {
		height: 115,
		backgroundImage: "linear-Bottom(#0A0A0A,#00000000 )",
		opacity: 1,
		zIndex: 9,
		position: "fixed",
		borderRadius: 6,
		width: "100%",
		top: 0,
		[theme.breakpoints.up("sm")]: {
			width: 370,
			bottom: 42,
		},
	},
	gradientBottom: {
		[theme.breakpoints.up("sm")]: {
			display: "none"
		},
		width: "100%",
		height: "60px",
		position: "fixed",
		bottom: 50,
		left: 0,
		zIndex: 20,
		backgroundImage: "linear-gradient(#00000000, #0A0A0A)",
	},
	backdrop: {
		zIndex: 100,
		backgroundColor: 'rgba(0, 0, 0, 0.7)',
		position: 'absolute',
		top: 0,
		left: 0,
		width: '100%',
		height: '91vh',
		[theme.breakpoints.down('xs')]: {
			height: '100vh',
		},
	},
	confirmWrapper: {
		marginBottom: isIOS ? " 60px" : "40px",
		backgroundColor: '#111111',
		padding: '2rem',
		position: 'absolute',
		zIndex: 1000,
		bottom: 0,
		left: 0,
		width: '100%',
	},
	cancelHeading: {
		color: '#31A7DB',
		marginBottom: '1rem',
		fontSize: 18,
		fontFamily: 'Cerebri-SemiBold',
		fontWeight: 400
	},
	cancelConfirmText: {
		color: '#808080',
		marginBottom: '2rem',
		fontWeight: 400,
		fontSize: 16,
	},
	btnContainer: {
		padding: "0 25px 10px 25px",
		position: "fixed",
		[theme.breakpoints.up("sm")]: {
			bottom: 40,
			width: 373,
		},
		marginBottom: "30px",
	},
}));

const CustomDot = ({active, onClick, carouselState, set, profile_media_urls}) => {
    const classes = useStyles();
    if (profile_media_urls) {
        carouselState.totalItems = profile_media_urls.length + 1;
    }
    carouselState.currentSlide === 3 ? set(true) : set(false);

    return (
		<div
			style={{backgroundColor: active ? '#31A7DB' : '#FFFFFF40'}}
			className={classes.dot}
			onClick={() => onClick()}
		></div>
	);
};

function UserProfile({history, match, ...props}) {
	const {id} = useParams();
	const UserProfileID = id;
	const dispatch = useDispatch();
    const classes = useStyles();
	const [imageErrors, setImageErrors] = useState([false, false, false]);

    const {userData, layout} = useSelector(({Profile, BaseReducer}) => ({
        userData: Profile.FetchProfileReducer,
        layout: BaseReducer.LayoutReducer,
    }));

    const ratesResponse = useSelector(({Profile}) => Profile.GetRatesReducer);
    const {
        companyFee,
        referralFee,
        transactionFee
    } = ratesResponse.data ? ratesResponse.data : {}
    const [refresh, setRefresh] = useState(false);
    const [play, setPlay] = useState(0);
    const [user, setUserData] = React.useState({});
    const [open, setOpen] = React.useState(false);
    React.useEffect(() => {
        dispatch(BaseActions.updateLayout({bottomNav: false}));
        dispatch(BaseActions.getUserData());
        return () => dispatch(Actions.resetProfile())
    }, [dispatch]);
    React.useEffect(() => {
        if (layout.bottomNav) dispatch(BaseActions.updateLayout({bottomNav: false}));
    }, [layout.bottomNav])
    useDeepCompareEffect(() => {
        if (userData.data && userData.data.status === "Success") {
            setUserData(userData.data.data);
            let charges = 0
            if (userData.data.data.referrer_is_set === 0) {
                charges = companyFee + transactionFee + userData.data.data.rate
            } else {
                charges = companyFee + referralFee + transactionFee + userData.data.data.rate
            }
            if (refresh) {
                history.push({
                    pathname: "/booking",
                    state: {
                        _id: userData.data.data._id,
                        rate: charges,
                        full_name: userData.data.data.full_name,
                        userImage: userData.data.data.profile_media_urls[0],
                        availability: userData.data.data.availablility,
                        charges: userData.data.data.rate,
                        enabledUsername: userData.data.data.enabledUsername,
                        username: userData.data.data.username
                    },
                });
            }
            return;
        }
    }, [userData]);
    const {
        tags,
        profile_media_urls,
        full_name,
        enabledUsername,
        username,
        rate,
        rating,
        bio,
        reviews_count,
        video_url,
        user_code,
        _id,
    } = user;
    const [shouldUseImage, setShouldUseImage] = useState(false);
    const videoRef = React.useRef(null)
    React.useEffect(() => {
        if (isSafari() && videoRef.current) {
            // obtain reference to the video element
            const player = videoRef.current.children[0];
            // if the reference to video player has been obtained
            if (player) {
                // set the video attributes using javascript as per the
                // webkit Policy
                player.controls = false;
                player.playsinline = true;
                player.autoplay = false;
                // Let's wait for an event loop tick and be async.
                setTimeout(() => {
                    // player.play() might return a promise but it's not guaranteed crossbrowser.
                    const promise = player.play();
                    // let's play safe to ensure that if we do have a promise
                    if (promise.then) {
                        promise
                            .then(() => {
                            })
                            .catch(() => {
                                // if promise fails, hide the video and fallback to <img> tag
                                videoRef.current.style.display = "none";
                                setShouldUseImage(true);
                            });
                    }
                }, 0);
            }
        }
    }, [])
    const handleStopVideo = (currentSlide) => {
        if (videoRef.current?.children[0]) {
            videoRef.current.children[0].pause()
        }
    }
    const isSafari = () => {
        const ua = navigator.userAgent.toLowerCase();
        return ua.indexOf("safari") > -1 && ua.indexOf("chrome") < 0;
    };
    React.useEffect(() => {
        if (window.location.pathname.split('/').pop().length > 10) {
            dispatch(Actions.fetchProfile(window.location.pathname.split('/').pop()));
        } else {
            dispatch(Actions.fetchProfileByRefferal(window.location.pathname.split('/').pop()));
        }
    }, [dispatch, refresh]);
    const handleReviews = (id, count) => {
        dispatch(
            BaseActions.showMessage(<Reviews handleClose={handleClose} count={count} userId={id} currentUserId={loggedInUserId} />)
        );
    };
    const handleClose = () => {
        dispatch(BaseActions.hideMessage());
    };
    const blockUser = (id) => {
        setOpen(false);
        dispatch(
            BaseActions.showMessage(<BlockUser userId={id} userName={full_name}/>)
        );
    };
    const reportUser = (id) => {
        setOpen(false);
        dispatch(
            BaseActions.showMessage(<ReportUser userId={id} userName={full_name}/>)
        );
    };
    const handleGoBack = () => {
        const tag = history.location.state?.tag
        if (window.location.search.includes('from=search')) {
            history.push({
                pathname: `/search/${history.location.state.tab}`,
                state: {searchValue: history.location.state.searchValue}
            })
        } else if (window.location.search.includes('from=expertise')) {
            history.push({pathname: `/profilesintag`, state: tag})
        } else if (window.location.search.includes('from=notifications')) {
            history.push('/notifications')
        } else {
            history.push('/home')
        }
    }
    const isLoggedIn = JSON.parse(localStorage.getItem("quarterlyUser"));
    const loggedInUserId = isLoggedIn?.data?._id;

    const token = localStorage.getItem("JWTtoken");
    const handleClick = () => {
        if (isLoggedIn && token) {
            setRefresh(true)
        } else {
            history.push(`/signup?id=${_id}&code=${user_code}`)
        }
    }

    return (
        <>
            <Header backArrow onBack={handleGoBack} RightComponent={() => (
                (isLoggedIn && token) &&
                <MoreVertIcon
                    style={{padding: 5, height: '30px', width: '30px', color: "white"}}
                    onClick={() => setOpen(!open)}
                />
            )}/>

			{open && (
				<Box
					position="fixed"
					height="100%"
					zIndex="10"
					className={classes.moreMenu}
				>
					<div className={classes.menuContainer}>
						<div className={classes.menuItem} onClick={() => blockUser(_id)}>
							<img
								alt="icon"
								src={Pics.blockIcon}
								style={{marginRight: "10px"}}
								width={20}
							/>
							<Typography style={{fontSize: "16px"}} variant="body1">
								Block
							</Typography>
						</div>
						<div className={classes.menuItem} onClick={() => reportUser(_id)}>
							<img
								alt="icon"
								src={Pics.reportIcon}
								style={{marginRight: "10px"}}
								width={20}
							/>
							<Typography style={{fontSize: "16px"}} variant="body1">
								Report
							</Typography>
						</div>
					</div>
				</Box>
			)}

			<Paper className={classes.container}>
				<Carousel
					afterChange={(previousSlide, {currentSlide, onMove}) => {
						handleStopVideo(currentSlide)
					}}
					swipeable
					draggable
					responsive={responsive}
					keyBoardControl={true}
					showDots={true}
					customDot={<CustomDot set={setPlay} profile_media_urls={profile_media_urls}/>}
					dotListClass={classes.dotList}
					removeArrowOnDeviceType={["all"]}
					containerClass={classes.carousel}
				>
					{profile_media_urls?.map((pic, idx) => (
						<div className={classes.imgContainer}>
							{
								pic && !(pic.match(/\/uploads\/default_pic\.png$/) || pic === "")
									? (
										imageErrors[idx] === true
										? <PlaceholderErrorImage
											className={classes.profileImage}
											title={"Error Loading Media"}
										/>
										: <Image
											key={idx}
											src={pic}
											alt={user.enabledUsername ? user.username : user.full_name}
											className={classes.profileImage}
											onError={() => setImageErrors(prev => {
												return { ...prev, [idx]: true }
											})}
											width="100%"
										/>
									)
									: <PlaceholderImage className={classes.profileImage}/>
							}
						</div>
					))}

					{video_url !== "" ? (
						<div className={classes.video}>
							{
								shouldUseImage ? (
									<img src={video_url} alt="Muted Video"/>
								) : (
									<div
										ref={videoRef}
										dangerouslySetInnerHTML={{
											__html: `
										<video
										  loop
										  controls
										  height="172px"
										  width="100%"
										  playsinline
										  preload="metadata"
										>
											<source src="${video_url}" type="video/mp4" />
										</video>`
										}}
									/>
								)
							}
						</div>
					) : (
						<div
							style={{
								width: "100%",
								height: 172,
								display: "flex",
								backgroundColor: "#E0E0E0",
								justifyContent: "center",
								alignItems: "center",
							}}
						>
							<Typography
								style={{
									color: "#c7c7c7",
									fontSize: 30,
								}}
							>
								No video uploaded
							</Typography>
						</div>
					)}
				</Carousel>


				{/*<Box className={classes.main}>*/}
				<Box className={classes.main}>
					<Box className={classes.inline}>
						<Typography variant="h1" className={classes.title}>
							{enabledUsername ? username : full_name}
						</Typography>

						<Typography variant="body2" className={classes.price}>
							{rate <= 0 ? "Free" : `$${Number(rate)}`}
						</Typography>
					</Box>

					<Box className={classes.inline}>
						<Box
							display="flex"
							flexDirection="row"
							gridColumnGap={9}
							alignItems="center"
						>
							<Box
								display="flex"
								flexDirection="row"
								className={classes.ratingPill}>
								<StarRateIcon fill="#FFB800"/>

								<Typography variant="body1" style={{
									fontSize: '12px',
									fontWeight: 'lighter'
								}}>
									{rating === 0 ? 'No Reviews' : rating?.toFixed(1)}
								</Typography>
							</Box>

							<Typography variant="body1" className={classes.reviewsCount}>
								{reviews_count} reviews
							</Typography>
						</Box>


						<Typography
							variant="body1"
							className={classes.seeReviewsLink}
							onClick={() => handleReviews(_id, reviews_count)}
						>
							See reviews
						</Typography>
					</Box>

					<Typography variant="caption" className={classes.userBio}>
						{bio || "No bio information."}
					</Typography>

					<Box display="flex" flexDirection="column" gridRowGap={15}>
						{tags?.length > 0 && (
							<Box className={clsx(classes.greyRectangle, classes.expertiseContainer)}
								 justifyContent={'space-between'} alignContent={'center'} flexDirection={'column'}>
								<Box className={classes.expertiseDiv} display="flex" flexDirection="row"
									 justifyContent="space-between" alignItems="center" width={'100%'}>
									<Typography className={classes.expertiseHeading}>
										Expertise
									</Typography>
									<Typography className={classes.expertiseHeaderSecondary}>Years</Typography>
								</Box>
								{tags
									?.sort(function (a, b) {
										return b.years - a.years;
									})
									.map((t, i) => (
										<Box className={classes.expertise} key={i} display={'flex'}
											 flexDirection={'row'} width={'100%'} justifyContent={'space-between'}
											 alignItems={'center'}>
											<Typography className={classes.expertiseName}>{t.expertise}</Typography>
											<Typography className={classes.expertiseYears}>{t.years}</Typography>
										</Box>
									))}
							</Box>
						)}
					</Box>
				</Box>
			</Paper>

			{
				UserProfileID !== loggedInUserId &&
				<Footer>
					<Box display="flex" gridColumnGap={12} padding="16px 20px 34px">
						<Button className={classes.buttonBottom} onClick={handleClick} fullWidth>Book a Quartr</Button>
					</Box>
				</Footer>
			}
        </>
    );
}

export default withReducer("Profile", reducer)(withRouter(UserProfile));
