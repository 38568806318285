import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
// import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
// import InboxIcon from "@material-ui/icons/MoveToInbox";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
// import CheckBoxIcon from "@material-ui/icons/CheckBox";
// import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
// import IndeterminateCheckBoxIcon from "@material-ui/icons/IndeterminateCheckBox";
import pics from "@assets";
import moment from "moment";
const useStyles = makeStyles((theme) => ({
  con: {
    backgroundColor: "#111111",
    borderRadius: "6px",
    margin: "10px 0",
  },
  root: {
    padding: "10px",
    color: "white",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  listItemCon: {
    position: "relative",
  },
  overly: {
    position: "absolute",
    height: "100%",
    width: " 100%",
    top: 0,
    left: 0,
    background: " #000000b3",
  },
}));
function addMinutes(date, minutes) {
  // alert(date)
  return new Date(new Date(date).getTime() + minutes * 60000).toISOString();
}
export default function HourQuarters({
  // startTime,
  // endTime,
  // quarters,
  handler,
  selectedSlots,
  quarterStamps,
}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [slots, setSlots] = React.useState([]);
  const [count, setCount] = React.useState([]);
  const [disabled, setDisabled] = React.useState([]);
  const [selectedOnes, setSelectedOnes] = React.useState([]);
  const [duration, setDuration] = React.useState({
    startTime: "",
    endTime: "",
  });
  const handleClick = (val) => {
    //  alert('sdf')
    setOpen(!open);
  };
  React.useEffect(() => {
    setSlots(selectedSlots);
  }, [selectedSlots]);
  React.useEffect(() => {
    // alert(JSON.stringify(slots))
    // alert(JSON.stringify(selectedSlots))
    let arr = [];
    quarterStamps.forEach((stamp) => {
      let matched =
        selectedSlots && selectedSlots.filter((x) => x.timestamp === stamp);
      if (matched && matched.length !== 0) {
        arr = [...arr, ...matched];
        return;
      }
    });
    let disabledArr = quarterStamps.filter(
      (stamp) => stamp < new Date().toISOString()
    );
    setCount(arr);
    setDisabled(disabledArr);
  }, [selectedSlots, slots, quarterStamps]);
  React.useEffect(() => {
    let startTime = moment(new Date(quarterStamps[0])).format("h:mm A");
    let endTime = moment(new Date(addMinutes(quarterStamps[3], 15))).format(
      "h:mm A"
    );
    setDuration({ startTime, endTime });
  }, []);
  // React.useEffect(() => {
  //   if (count.length === 4) {
  //     alert("full");
  //     return;
  //   }
  //   if (count.length < 4 && count.length > 0) {
  //     alert("partial");
  //     return;
  //   }
  //   if (count.length === 0) {
  //     alert("empty");
  //     return;
  //   }
  // }, [count]);
  const handleCount = (stamp) => {
    if (count.filter((stm) => stm === stamp).length !== 0) {
      let data = count.filter((x) => x !== stamp);
      setCount([...data]);
      return;
    } else {
      setCount([...count, stamp]);
      return;
    }
  };
  const handleUpdate = () => {
    if (count.length === 0) {
      handler(quarterStamps);
      return;
    } else if (count.length === 4) {
      handler(quarterStamps);
      return;
    } else {
      handler(
        count.map((x) => x.timestamp),
        true
      );
    }
  };
  return (
    <>
      {[1].map((x, i) => (
        <div className={classes.con} key={i}>
          <ListItem button className={classes.listItemCon}>
            <div
              className={disabled.length === 4 ? classes.overly : ""}
              style={{
                cursor: disabled.length === 4 ? "not-allowed" : "pointer",
              }}
            ></div>
            <ListItemIcon
              style={{
                cursor: disabled.length === 0 ? "pinter" : "not-allowed",
              }}
              onClick={() => disabled.length === 0 && handleUpdate(count)}
            >
              {
                count.length === 4 ? (
                  <img
                    style={{ height: "20px", width: "20px" }}
                    src={pics.checkBox}
                    alt="check"
                  />
                ) : count.length === 0 ? (
                  <img
                    style={{ height: "20px", width: "20px" }}
                    src={pics.blankCheckBox}
                    alt="check"
                  />
                ) : (
                  <img
                    style={{ height: "20px", width: "20px" }}
                    src={pics.partialCheckBox}
                    alt="check"
                  />
                )
                // if (count.length < 4 && count.length > 0) {
                //   alert("partial");
                //   return;
                // }
                // if (count.length === 0) {
                //   alert("empty");
                //   return;
                // }
              }
            </ListItemIcon>
            <div
              onClick={() => handleClick()}
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                alignItems: "center",
              }}
            >
              {/* <ListItemText
              classes={{primary:{fontsize:'14px'}}}
              style={{fontSize:"14px"}}
                primary={duration.startTime + " - " + duration.endTime}
              /> */}
              <Typography variant="subtitle1">
                {duration.startTime + " - " + duration.endTime}
              </Typography>
              {open ? (
                <ExpandLess style={{ fontSize: "36px", color: 'white' }} />
              ) : (
                <ExpandMore style={{ fontSize: "36px", color: 'white' }} />
              )}
            </div>
          </ListItem>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <div className={classes.root}>
              {quarterStamps.map((q, i) => (
                <QuarterPill
                  key={i}
                  stamp={q}
                  selectedSlots={slots}
                  click={handler}
                  handleCount={handleCount}
                />
              ))}
            </div>
          </Collapse>
        </div>
      ))}
    </>
  );
}
const usePillStyle = makeStyles((theme) => ({
  root: {
    border: "1px solid #808080",
    width: "calc(50% - 10px)",
    margin: "7px 5px",
    padding: 5,
    textAlign: "center",
    borderRadius: 6,
    cursor: "pointer",
  },
  sleceted: {
    cursor: "pointer",
    backgroundColor: "#31A7DB",
    border: "1px solid #31A7DB",
    width: "calc(50% - 10px)",
    margin: "7px 5px",
    padding: 5,
    textAlign: "center",
    borderRadius: 6,
  },
}));
const QuarterPill = ({ click, selectedSlots, stamp, handleCount }) => {
  const classes = usePillStyle();
  const [selectedOne, setSelectedOne] = React.useState([]);
  const [duration, setDuration] = React.useState({
    startTime: "",
    endTime: "",
  });
  React.useEffect(() => {
    setSelectedOne(selectedSlots);
  }, [selectedSlots]);
  // setting default 1hour quartr
  React.useEffect(() => {
    let startTime = moment(new Date(stamp)).format("hh:mm A");
    let endTime = moment(new Date(addMinutes(stamp, 15))).format("hh:mm A");
    setDuration({ startTime, endTime });
  }, []);
  let isSelected = () => {
    let matched =
      selectedOne && selectedOne.filter((x) => stamp === x.timestamp);
    if (matched && matched.length !== 0) {
      //  alert(JSON.stringify(matched))
      //  alert('true')
      // handleCount(stamp);
      return true;
    } else {
      // alert('false')
      return false;
    }
  };
  return (
    <div
      className={isSelected() ? classes.sleceted : classes.root}
      onClick={() => {
        stamp > new Date().toISOString() && click([stamp]);
      }}
      style={
        stamp < new Date().toISOString()
          ? {
              cursor: "not-allowed",
              // backgroundColor: "dimGrey",
              border: "1px solid #414040bd",
              color: "#414040bd",
            }
          : {}
      }
    >
      <Typography
        style={{
          fontSize: "12px",
          color: stamp < new Date().toISOString() ? "#414040bd" : "#fff",
        }}
        variant="subtitle1"
      >
        {duration.startTime} - {duration.endTime}
      </Typography>
    </div>
  );
};
