import React from "react";
import { Typography } from "@material-ui/core";
import { useStyles } from './styles';

function DateFilters({ handleStartDateChange, handleEndDateChange }) {
    const classes = useStyles();

    return (
        <div className={classes.dateInputsGrid}>
            <label className={classes.dateInputLabel} htmlFor="startDate">
                <Typography className={classes.dateLabel}>From</Typography>
                <input type="date" id="startDate" name="startDate" className={classes.dateInput}
                       onChange={handleStartDateChange}/>
            </label>
            <label className={classes.dateInputLabel} htmlFor="endDate">
                <Typography className={classes.dateLabel}>To</Typography>
                <input type="date" id="endDate" name="endDate" className={classes.dateInput}
                       onChange={handleEndDateChange}/>
            </label>
        </div>
    );
}

export default DateFilters;