import io from "socket.io-client";
import { history } from "@history";

import { TimerManager } from "@utils";

// socket constructor
class SocketClass {
  // we need to call this function at our entry point so we can initialize our socket connection

  init(token, dispatch) {

    this.startup(token);
    if (dispatch) {
      this.dispatch = dispatch;
    }

  }
  // fake socket object so application can survive without facing undefined error.
  socket = {
    emit: console.log,
    on: console.log,
    connect: console.log,
    disconnect: console.log,
  };

  // calls counter event emit from serverside so it calls reminder 2 minutes calculations
  quartrUpdate = () => {
    this.socket.on("callstartcounter", (data) => {
      let dataParsed = JSON.parse(data);
      console.log(JSON.parse(data), "meeting data");

      // timerManager ==> a method which handles all the logical calculation for reminder 2 minutes, 
      // it dispatches event after every second so we can consume current remaining time.
      TimerManager(this.dispatch, dataParsed, dataParsed.remaining_seconds);
    });

  };

  // getSocket==> function to get socket instance for use in other components 
  getSocket = () => {
    return this.socket;
  };
  logout = () => {
    this.socket.disconnect();
  };
  login = () => {
    this.socket.connect();
  };

  // update availability from socket event
  getOffline = (id) => {
    this.socket.emit("onprofile", {
      profile_id: id,
    });
    this.socket.emit("updatemyprofile", { available: 0 });
  };

  // test function not used in app
  historyTest = (id) => {
    // alert("called");
    history.push("/quartr/" + id);
  };


  // configuration of socket called when we init socket
  startup = (token) => {
    console.log("starting ===================");
    // this.socket.close()
    this.socket = io(`${process.env.REACT_APP_ENV === "prod" ? process.env.REACT_APP_BASE_URL_PROD : process.env.REACT_APP_BASE_URL_TESTING}`, {
      forceNew: true,
      reconnection: true,
      transports: ["websocket"],
      query: `token=${token}`,
      extraHeaders: { Authorization: `Bearer ${token}` },
    });
    this.quartrUpdate();
  };
}

const instance = new SocketClass();

export default instance;
