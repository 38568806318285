import React from 'react'
import { List, Typography } from '@material-ui/core'
import InfiniteScroll from 'react-infinite-scroll-component'
import { makeStyles } from '@material-ui/core'
import QuarterListItem from './QuartersListItem'

const useStyles = makeStyles((theme) => ({
  list: {
    position: 'relative',
    height: '56vh',
    overflowY: 'auto',
    overflowX: 'hidden',
    paddingLeft: '1rem',
    paddingRight: '1rem',
    [theme.breakpoints.down('xs')]: {
      height: '66vh',
    },
  },
  noListWrapper: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))

export default function QuartersList({
  quarters,
  showConfirm,
  setShowConfirm,
  isHosting,
  selectedQuarterRef,
  setShowReceipt,
  quarterReceiptRef,
  getUpcomingQuarters,
}) {
  const classes = useStyles()

  return (
    <List id='upcoming-list' className={classes.list}>
      {
        quarters.appointments.length
          ? <InfiniteScroll
            dataLength={quarters.total}
            next={getUpcomingQuarters.bind(this, isHosting ? 1 : 0)}
            hasMore={quarters.page < quarters.totalPages}
            scrollableTarget='upcoming-list'
          >
            {
              quarters.appointments.map((quarter, index) => (
                <QuarterListItem
                  index={index}
                  key={quarter._id}
                  quarter={quarter}
                  showConfirm={showConfirm}
                  setShowConfirm={setShowConfirm}
                  isHosting={isHosting}
                  selectedQuarterRef={selectedQuarterRef}
                  setShowReceipt={setShowReceipt}
                  quarterReceiptRef={quarterReceiptRef}
                />
              ))
            }
          </InfiniteScroll>
          : <div className={classes.noListWrapper}>
            <Typography variant='subtitle1' style={{ color: '#808080' }}>No Quartrs to show</Typography>
          </div>
      }
    </List>
  )
}
