import "./RoadTo10K.css"
import React, { useState } from "react"
import Popover from '@material-ui/core/Popover'
import LanguageIcon from '@material-ui/icons/Language'
import { makeStyles, Typography } from "@material-ui/core"
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos"
import queryString from 'query-string'
import * as BaseActions from "store/actions";
import { useDispatch } from "react-redux";
import { isMobile } from "react-device-detect"
const useStyles = makeStyles((theme) => ({
  headerWrapper: {
    display: 'flex',
    alignItems: 'center',
    // marginTop: 40,
    padding: 17,
    fontWeight: 'bold',
    [theme.breakpoints.down("sm")]: {
      padding: "15px 17px 0px 17px",
    }
  },
  headerText: {
    color: '#31A7DB',
    fontSize: '1.9rem',
    fontFamily: 'Cerebri-Bold'
  },
  leftSideMenu: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 'auto',
  },
  menuIcon: {
    width: 18,
    cursor: 'pointer',
    marginRight: '1rem',
  },
  icon: {
    width: 50,
    height: 50,
    cursor: 'pointer',
    marginRight: '1rem',
  },
  backgroundIcon: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    width: 300
  },
  inviteHeading: {
    display: 'flex',
    flexDirection: 'column',
    padding: 25,
    color: "#31A7DB",
    fontFamily: "Cerebri-SemiBold",
    fontSize: "1rem",
    [theme.breakpoints.down("xs")]: {
      padding: '15px 25px 25px 25px'
    }
  },
  BottomWrapper: {
    display: 'flex',
    alignItems: 'center',
    padding: 25,
    fontWeight: 'bold',
  },
  BottomCode: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '80%',
    height: 50,
    color: '#fff',
    borderRadius: 6,
    border: '1px dashed #31A7DB',
    fontFamily: "Cerebri-SemiBold",
    marginRight: 20,
    letterSpacing: 8,
    fontSize: '1.5rem'
  },
  backButton: {
    // left: 20,
    // backgroundColor: "rgba(0,0,0,0.4)",
    // borderRadius: 6,
    // paddingLeft: 6,
    color: "white",
    cursor: "pointer",
  },
  languageButton: {
    paddingLeft: 6,
    color: "white",
    cursor: "pointer"
  },
  popover: {
    display: 'flex',
    flexDirection: 'column',
    // padding: '10px 20px'
  }
}))
const RoadTo10KRules = ({ history }) => {
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(BaseActions.updateLayout({ bottomNav: true }));
  }, []);
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null)
  const [language, setLanguage] = useState('english')
  const handleClick = (event) => setAnchorEl(event.currentTarget)
  const handleClose = () => setAnchorEl(null)
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined
  const userId = queryString.parse(history.location.search).id
  const code = queryString.parse(history.location.search).code
  const handleGoBack = () => {
      history.push('/leaderboard')
  }
  let data = [];
  if (history && history.location && history.location.state) {
    const firstname = history.location.state[0]
    const lastname = history.location.state[1]
    const email = history.location.state[2]
    data = [firstname, lastname, email]
  }
  return (
    <div className="body">
      <div style={{ paddingTop: isMobile ? '0px' : '13px' }}></div>
      <div id="english">
        <div className={classes.headerWrapper}>
          <div className={classes.leftSideMenu}>
            <ArrowBackIosIcon
              className={classes.backButton}
              onClick={handleGoBack}
            />
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <div className={classes.popover}>
                <span className="hover" style={{ padding: '10px 20px 5px 20px' }}>
                  <Typography variant='caption' onClick={() => setLanguage('english')}>English</Typography>
                </span>
                <span className="hover" style={{ padding: '4px 20px 4px 20px' }}>
                  <Typography variant='caption' onClick={() => setLanguage('french')}>French</Typography>
                </span>
                <span className="hover" style={{ padding: '5px 20px 10px 20px' }}>
                  <Typography variant='caption' onClick={() => setLanguage('spanish')}>Spanish</Typography>
                </span>
              </div>
            </Popover>
            <Typography variant='h5' className={classes.headerText} >Road To 10K</Typography>
          </div>
        </div>
        <English />
      </div>
    </div >
  )
}
export default RoadTo10KRules
function English() {
  const classes = useStyles();
  return (
    <div>
      <div className={classes.inviteHeading}>
        <Typography variant='span'>
        Quartrly "Road To 10K" Referral Contest – Official Terms and Conditions
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
        NO PURCHASE NECESSARY TO ENTER OR WIN. A PURCHASE OR PAYMENT OF ANY KIND WILL NOT INCREASE YOUR CHANCES OF WINNING.
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
        By participating in the Quartrly "Road To 10K" Referral Contest (the “Contest”), you agree to abide by these Official Terms and Conditions, including all eligibility requirements, and understand that the results of the Contest, as determined by Quartrly, Inc., are final in all respects.
        </Typography>
        <Typography variant='span' style={{ marginTop: '15px' }}>
        1. Sponsor
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
        The Contest is sponsored by Quartrly, Inc. (“Quartrly” or “Company”), located at 8020 S. Rainbow Blvd #100-239, Las Vegas, NV 89139. The Contest is subject to all applicable federal, state, and local laws and regulations.
        </Typography>
        <Typography variant='span' style={{ marginTop: '15px' }}>
        2. Eligibility
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
        The Contest is open only to legal residents of the United States who are 18 years of age or older at the time of entry. Employees, officers, and directors of Quartrly and its affiliates, and their immediate family members, are not eligible to participate.
        </Typography>
        <Typography variant='span' style={{ marginTop: '15px' }}>
        3. Contest Period
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
        The Contest will begin on November 1, 2024 and will end once Quartrly has reached 10,000 registered users (the “Contest Period”). Quartrly reserves the right to extend or shorten the Contest Period at its sole discretion.
        </Typography>
        <Typography variant='span' style={{ marginTop: '15px' }}>
        4. How to Enter
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
        To participate in the Contest, individuals must refer users to Quartrly using their unique 6 character referral code provided by the Company. A referral is considered valid when the referred user successfully signs up for a Quartrly account and meets the following criteria:
          <p>•  The referred user must be a new user (not previously registered on Quartrly).</p>
          <p>•  The referred user must complete their registration and verify their account.</p>
          <p>•  Must input the 6 character Referral Code during registration.</p>
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
        Participants will automatically be entered into the Contest based on the number of valid referrals they generate during the Contest Period. No purchase is required to participate. There is no limit to the number of referrals a participant can submit.
        </Typography>
        <Typography variant='span' style={{ marginTop: '15px' }}>
        5. Prize
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
        The participant who refers the most valid users to Quartrly during the Contest Period will receive a $1,000 cash prize. The second place referrer will receive $500, and the third place referrer will receive $150.
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
        Prizes will be paid within 14 days via check, direct deposit, or another payment method at Quartrly’s discretion, following the conclusion of the Contest.
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
        <strong>Note:</strong> The prize may be considered taxable income, and the winner will be responsible for any federal, state, and local taxes associated with the receipt of the prize. If the prize value exceeds $600, Quartrly will issue a 1099-MISC to the winner, and the winner must provide a valid Social Security Number or Tax Identification Number as required by law.
        </Typography>
        <Typography variant='span' style={{ marginTop: '15px' }}>
        6. Winner Selection
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
        The winner will be the participant with the highest number of valid referrals during the Contest Period. In the event of a tie, the winner will be the participant whose last referral was completed first, as determined by Quartrly’s internal system.
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
        Quartrly will review and verify all referrals before declaring the winners. Any invalid or fraudulent referrals (e.g., fake accounts, duplicate users) will be disqualified at Quartrly’s discretion.
        </Typography>
        <Typography variant='span' style={{ marginTop: '15px' }}>
        7. Notification of Winners
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
        The winner(s) will be notified by email within 14 days of the conclusion of the Contest. The winner(s) must respond to the email notification within 14 days of the notification date. If a winner does not respond to the notification within 14 days, or if the prize notification is returned as undeliverable, that winner may be disqualified, and the prize may be awarded to the next eligible participant.
        </Typography>
        <Typography variant='span' style={{ marginTop: '15px' }}>
        8. Disqualification
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
        Quartrly reserves the right to disqualify any participant who, in its sole discretion, tampers with the referral process, violates these Terms and Conditions, or acts in an unsportsmanlike or disruptive manner.
        </Typography>
        <Typography variant='span' style={{ marginTop: '15px' }}>
        9. Privacy
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
        By participating in the Contest, participants agree to allow Quartrly to collect and use personal information for the purpose of administering the Contest. Quartrly will not share participants' personal information with third parties, except as necessary for the administration of the Contest or as required by law. Quartrly’s Privacy Policy can be found at <a href="https://www.quartrly.com/privacy">www.quartrly.com/privacy</a>
        </Typography>
        <Typography variant='span' style={{ marginTop: '15px' }}>
        10. Limitation of Liability
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
        By participating in the Contest, participants agree to release and hold harmless Quartrly and its officers, directors, employees, and agents from any and all liability, loss, or damage arising from or in connection with the Contest, including, but not limited to, participation in the Contest or the acceptance, use, or misuse of any prize.
        </Typography>
        <Typography variant='span' style={{ marginTop: '15px' }}>
        11. Publicity
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
        By accepting the prize, the winner agrees to allow Quartrly to use their name, likeness, and/or biographical information for promotional purposes without further compensation, unless prohibited by law.
        </Typography>
        <Typography variant='span' style={{ marginTop: '15px' }}>
        12. General Conditions
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
        Quartrly reserves the right to cancel, suspend, or modify the Contest if fraud, technical failures, or any other factor beyond Quartrly’s reasonable control impairs the integrity of the Contest, as determined by Quartrly in its sole discretion.
        </Typography>
        <Typography variant='span' style={{ marginTop: '15px' }}>
        13. Governing Law
        </Typography>
        <Typography variant='caption' style={{ marginTop: '15px' }}>
        The Contest and these Terms and Conditions are governed by the laws of the State of Nevada. Any disputes arising out of or relating to the Contest will be resolved exclusively in the courts located in Nevada.
        </Typography>
      </div>
    </div>
  )
}