import firebase from "firebase";

const firebaseConfig = {
  // apiKey: "AIzaSyCIhUFs0FtcaHVSqS4CLzOamT_wm5abyEg",
  // authDomain: "quartrly-256129.firebaseapp.com",
  // databaseURL: "https://quartrly-256129.firebaseio.com",
  // projectId: "quartrly-256129",
  // storageBucket: "quartrly-256129.appspot.com",
  // messagingSenderId: "1083459831432",
  // appId: "1:1083459831432:web:6ba584fbf9611c8c35c13d",
  apiKey: "AIzaSyC2JGWQ6DxqjDpPCnSn6osRGbW2ylcHBzA",
  authDomain: "smartbin-4c00f.firebaseapp.com",
  databaseURL: "https://smartbin-4c00f.firebaseio.com",
  projectId: "smartbin-4c00f",
  storageBucket: "smartbin-4c00f.appspot.com",
  messagingSenderId: "1042896451254",
  appId: "1:1042896451254:web:ef6d000fa732be58be1817"
};

firebase.initializeApp(firebaseConfig);

export default firebase