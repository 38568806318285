import * as Actions from "../actions";

// initial state
const initialState = {
  isLoading: false,
  errMsg: null,
  data: null,
};

// Reducer for LOGIN handling
export const FetchReviewsReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.FETCH_REVIEWS:
      console.log('fetched reviews')
      // we will mould our payload here accordingly  if required  in case of success
      return { ...state, isLoading: false, errMsg: null, data: action.payload };
    case Actions.REVIEWS_IS_LOADING:
      console.log('fetching reviews')
      // ... in case of loading
      return { ...state, isLoading: true, errMsg: null, data: null };
    case Actions.REVIEWS_FAILED:
      // ... in case of error
      return { ...state, isLoading: false, errMsg: action.payload, data: null };
    case Actions.RESET_REVIEWS:
      //reset reviews state
      console.log('reset')
      return { ...state, isLoading: false, errMsg: null, data: null };
    default:
      return state;
  }
};
