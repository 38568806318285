import React from "react";
import {Box, makeStyles, Typography} from "@material-ui/core";
import useDeepCompareEffect from "use-deep-compare-effect";
import {useDispatch, useSelector} from "react-redux";
import * as Actions from "./store/actions";
import * as BaseActions from 'store/actions'
import reducer from "./store/reducers";
import withReducer from "store/withReducer";
import Header from "../../@base/header";
import StarRateIcon from "../../@base/icons/StarRateIcon";
import {Image} from "semantic-ui-react";
import PlaceholderErrorImage from "../home/subComponents/userCard/icons/PlaceholderErrorImage";

function getLocaleDate(iso) {
    var date = new Date(iso);
    var options = {month: 'short', day: 'numeric', year: 'numeric'};
    return date.toLocaleDateString('en-US', options)
}

const useStyles = makeStyles((theme) => ({
    reviewAll: {
        fontWeight: 600,
        fontSize: 20,
        lineHeight: "23.87px",
        letterSpacing: "0%",
    },
    totalReviews: {
        fontWeight: 400,
        fontSize: 12,
        lineHeight: "14.32px",
        letterSpacing: "0%",
    },
    averageRating: {
        fontWeight: 700,
        fontSize: 22,
        lineHeight: "26.25px",
        letterSpacing: "0%",
        textAlign: "right",
    },
    commentatorImage: {
        borderRadius: "50%",
        height: 30,
        backgroundColor: "#272727",
        width: 30
    },
    rating: {
        display: "inline-block",
    },
    ratingNumber: {
        fontWeight: 500,
        fontSize: 14,
        lineHeight: "16.71px",
        letterSpacing: "0%",

    },
    reviewItem: {
        "&:not(&:first-child)": {
            borderTop: "1px solid #373737",
        },
    },
    placeholderImageDiv: {
        width: "30px",
        height: "30px",
        borderRadius: "50%",
        backgroundColor: "#272727",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "14px",
        fontWeight: "bold",
        color: "#fff",
        textTransform: "uppercase",
        fontFamily: "-apple-system, BlinkMacSystemFont, cerebri-regular",
    },
    name: {
        fontWeight: 500,
        fontSize: 14,
        lineHeight: "16.71px",
        letterSpacing: "0%",
    },
    date: {
        fontWeight: 400,
        fontSize: 10,
        lineHeight: "11.93px",
        letterSpacing: "0%",
    },
    subject: {
        border: "1px solid #373737",
        borderRadius: 15,
        backgroundColor: "#262626",
        padding: "4px 10px",
        fontWeight: 300,
        fontSize: 12,
        lineHeight: "14px",
        letterSpacing: "0%",
    },
    comment: {
        fontWeight: 300,
        fontStyle: "italic",
        fontSize: 12,
        lineHeight: "14px",
        letterSpacing: "0%",
        color: "#9F9F9F",
        marginTop: 8,
    },
    starsCategory: {
        fontWeight: 400,
        fontSize: 14,
        lineHeight: "16.71px",
        letterSpacing: "0%",
        color: "#9F9F9F",
        width: 50,
    },
    starsCategoryTotal: {
        fontWeight: 400,
        fontSize: 12,
        lineHeight: "14.32px",
        letterSpacing: "0%",
        color: "#9F9F9F",
        width: 40,
        textAlign: "right",
    },
    ratingBarBackdrop: {
        width: "calc(100% - 116px)",
        height: 8,
        backgroundColor: "#262626",
        borderRadius: 5,
        overflow: "hidden"
    },
    ratingBarFilled: {
        backgroundColor: "#31A7DB",
        borderRadius: 5,
        transition: "width .3s"
    },
}));

const Reviews = ({handleClose, history, ...props}) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [fetchedReviews, setReviews] = React.useState([]);
    const [fetchedAggregateRatings, setAggregateRatings] = React.useState([
        {stars: 5, total: 0},
        {stars: 4, total: 0},
        {stars: 3, total: 0},
        {stars: 2, total: 0},
        {stars: 1, total: 0}
    ]);
    const [averageRating, setAverageRating] = React.useState(0.0);
    const fetchedData = useSelector(({Reviews}) => Reviews.FetchReviewsReducer);
    const fetchedRatingData = useSelector(({Reviews}) => Reviews.FetchAggregateRatingsReducer);

    useDeepCompareEffect(() => {
        if (fetchedData.data) {
            setReviews(fetchedData.data.data);
        }
    }, [fetchedData]);

    useDeepCompareEffect(() => {
        if (fetchedRatingData.data) {
            const { ratingsArray, avgRating } = fillMissingStars(fetchedRatingData.data.data)
            setAverageRating(avgRating)
            setAggregateRatings(ratingsArray);
        }
    }, [fetchedRatingData]);

    React.useEffect(() => {
        dispatch(BaseActions.updateLayout({bottomNav: false}))
        dispatch(Actions.fetchReviews(props.userId));
        dispatch(Actions.fetchAggregateRatings(props.userId));
        return () => {
            dispatch(BaseActions.updateLayout({bottomNav: true}))
            dispatch(Actions.resetReviews())
        }
    }, []);

    function fillMissingStars(ratings) {
        const allStars = [5, 4, 3, 2, 1];
        const ratingsMap = new Map(ratings.map(r => [r.stars, r.total]));

        let totalRatings = 0, weightedSum = 0;

        const completeRatings = allStars.map(star => {
            const total = ratingsMap.get(star) || 0;
            totalRatings += total;
            weightedSum += star * total;

            return { stars: star, total };
        });

        const avg = totalRatings ? (weightedSum / totalRatings).toFixed(1) : 0.0;

        return { ratingsArray: completeRatings, avgRating: avg };
    }

    return (<div style={{ backgroundColor: 'black', position: 'absolute', top: 0, width: '100%', minHeight: '100%' }}>
        <Header backArrow onBack={handleClose} heading={`Reviews (${props.count || 0})`}/>

        <Box
            padding="100px 15px 0"
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            color={"black"}
            width="100%"
        >
            <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between"
                 gridColumnGap="10px">
                <Typography className={classes.reviewAll}>
                    All Reviews
                </Typography>

                <Typography className={classes.totalReviews}>
                    ({props.count || 0} reviews)
                </Typography>
            </Box>

            <Box
                display="flex"
                flexDirection="row"
                alignItems="center" justifyContent="space-between"
                gridColumnGap="10px"
            >
                <StarRateIcon fill="#FFB800" width={16} height={16}/>

                <Typography variant="body1" className={classes.averageRating}>
                    {averageRating}
                </Typography>
            </Box>
        </Box>

        <Box
            display="flex"
            flexDirection="column"
            alignContent="content"
            padding="31px 15px 25px"
            style={{ borderBottom: "1px solid #373737" }}
            gridRowGap={16}
        >
            {
                fetchedAggregateRatings.map(category => {
                    return (
                        <Box display="flex" flexDirection="row" alignContent="content" justifyContent="space-between"
                             gridColumnGap={8}>
                            <Typography className={classes.starsCategory}>
                                {category.stars} Stars
                            </Typography>

                            <Box className={classes.ratingBarBackdrop} alignSelf="center">
                                {
                                    props.count > 0 &&
                                    <Box height="100%" width={`${category.total * 100 / props.count}%`}
                                         className={classes.ratingBarFilled}>
                                    </Box>
                                }
                            </Box>

                            <Typography className={classes.starsCategoryTotal}>
                                ({category.total})
                            </Typography>
                        </Box>
                    )
                })
            }
        </Box>

        <Box padding="0 15px" position="relative" minHeight="180px">
            {fetchedReviews.length === 0
                ? (
                    <div style={{marginTop: "20px", textAlign: "center"}}>
                        <Typography variant="caption">
                            { props.userId === props.currentUserId
                                ? "You have no reviews!"
                                : "No reviews!"
                            }
                        </Typography>
                    </div>
                ) : (fetchedReviews.map((item) => {
                    const userName = item.attendee
                        ? item.attendee.enabledUsername
                            ? item.attendee.username
                            : item.attendee.full_name
                        : 'Quartrly User'

                    return <ReviewItem
                        name={userName}
                        comment={item.comment}
                        rating={item.averageRating}
                        subject={item.subject}
                        date={item.createdAt}
                        commenter={item.attendee}
                    />
                }))
            }
        </Box>
    </div>);
};

export default withReducer("Reviews", reducer)(Reviews);

const ReviewItem = ({name, subject, rating, comment, date, commenter}) => {
    const classes = useStyles();
    const [imageError, setImageError] = React.useState(false);
    const imageUrl = commenter?.profile_media_urls?.length ? commenter.profile_media_urls[0] : undefined;

    const ratingStars = [];

    for (let i = 1; i <= 5; i++) {
        ratingStars.push(
            <StarRateIcon
                fill={i <= rating ? "#FFB800" : "#262626"}
                width={14}
                height={14}
                className={classes.rating}
            />
        )
    }

    return (
        <Box padding="20px 0" display="flex" flexDirection="row" gridColumnGap="10px" className={classes.reviewItem}>
            {
                imageUrl
                    ? (
                        imageError === true
                        ? <PlaceholderErrorImage
                            className={classes.profileImage}
                            title={"Error Loading Media"}
                        />
                        : <Image
                            src={imageUrl}
                            alt={commenter?.enabledUsername ? commenter?.username : commenter?.full_name}
                            height={30}
                            width={30}
                            className={classes.commentatorImage}
                            onError={() => setImageError(true)}
                        />
                    )
                    : <div className={classes.placeholderImageDiv}>
                        {commenter?.enabledUsername
                            ? commenter?.username?.charAt(0)
                            : commenter?.full_name.split(" ").map(name => name.charAt(0))?.join("") || "QU"
                        }
                    </div>
            }

            <Box display="flex" flexDirection="column" gridRowGap={5}>
                <Typography className={classes.name}>
                    {name}
                </Typography>

                <Typography className={classes.date}>
                    {getLocaleDate(date)} at {new Date(date).toLocaleTimeString(
                        navigator.language, { hour: '2-digit', minute: '2-digit' }
                    )}
                </Typography>

                <Box
                    display="flex"
                    flexDirection="row"
                    gridColumnGap={13}
                    marginTop="3px"
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Box display="flex" flexDirection="row" gridColumnGap={10}>
                        <Box display="flex" flexDirection="row" alignContent="center" gridColumnGap={5}>
                            {ratingStars.map((star, index) => (
                                <React.Fragment key={index}>{star}</React.Fragment>
                            ))}
                        </Box>

                        <Typography className={classes.ratingNumber}>
                            {rating.toFixed(1)}
                        </Typography>
                    </Box>

                    {
                        subject &&
                        <Typography className={classes.subject}>
                            {subject}
                        </Typography>
                    }
                </Box>

                {
                    comment &&
                    <Typography className={classes.comment}>
                        &quot;{comment}&quot;
                    </Typography>
                }
            </Box>
        </Box>
    );
};
