import moment from "moment";
import monthlyRecord from "./monthlyRecord";

export default function yearlyRecord(userEarnings) {
    const startDate = moment(userEarnings[0]?.receipt_id?.createdAt).format('DD.MM.YYYY')
    let today = moment();
    let startingDate = moment(startDate, "DD.MM.YYYY");
    const startingMonth = moment(startingDate, 'DD.MM.YYYY').format('MMM YYYY')
    const monthsBetween = today.diff(startingDate, 'months');

    const allMonths = []
    for (let i = 0; i <= monthsBetween; i++) {
        const monthObj = {
            year: moment(startingMonth, 'MMM YYYY').add(i, 'month').format('MMM YYYY'),
            value: 0
        }
        allMonths.push(monthObj)
    }

    let allYears = []
    allMonths.map((item, i) => {
        const index = allYears.findIndex(ind => {
            return ind.year === moment(item.year, 'MMM YYYY').format('YYYY')
        })
        if (index === -1) {
            allYears.push({
                year: moment(item.year, 'MMM YYYY').format('YYYY'),
                value: item.value
            })
        } else {
            allYears[index].value = parseFloat(allYears[index].value) + parseFloat(item.value)
        }
    })

    /*
    for (let i = 0; i <= userEarnings.length; i++) {
        const receipt = userEarnings[i]?.receipt_id
        const index = allYears.findIndex((month, j) => {
            return moment(receipt?.createdAt).format("YYYY") === month.year
        })
        if (index !== -1) {
            const prevValue = parseFloat(allYears[index]?.value) || 0
            const receiptValue = parseFloat(userEarnings[i]?.receipt_id?.hostRate) - parseFloat(userEarnings[i]?.receipt_id?.refundedAmount) || 0
            const newValue = prevValue + receiptValue
            allYears[index].value = newValue

            const prevRef = parseFloat(allYears[index]?.referralFee) || 0
            const receiptRef = parseFloat(userEarnings[i]?.receipt_id?.referralFee) || 0
            const newRef = prevRef + receiptRef
            allYears[index].referralFee = newRef
        }
    }
    */

    
    let monthlyRecords = monthlyRecord(userEarnings)
    for(let currentYear of allYears) {
        let valueTotal = 0.0;
        let referralTotal = 0.0;
        let payoutFeeTotal = 0.0;

        monthlyRecords.filter((mr) => currentYear.year == moment(mr.month, 'MMM YYYY').format('YYYY'))
                        .forEach(mw => {
                            valueTotal += mw.value || 0.0;
                            referralTotal += mw.referralFee || 0.0;
                            payoutFeeTotal += mw.payoutFee || 0.0;
                        });

        currentYear.value = valueTotal;
        currentYear.referralFee = referralTotal;
        currentYear.payoutFee = payoutFeeTotal;
    }
    
    return allYears.reverse()
}
