import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Box, Typography} from "@material-ui/core";
import Header from '@base/header';
import * as BaseActions from "store/actions";
import {useDispatch} from "react-redux";

const useStyles = makeStyles((theme) => ({
    mainWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        fontSize: '1.5rem',
        fontFamily: 'Cerebri-Bold',
        padding: 40,
    },
    spanText: {
        cursor: 'pointer',
        color: '#fff',
        fontSize: '1.2rem',
        fontFamily: 'Cerebri-Bold',
        textTransform: 'uppercase'
    }
}))

function Privacy({history}) {
    const dispatch = useDispatch();
    React.useEffect(() => {
        dispatch(BaseActions.updateLayout({bottomNav: true}));
    }, []);
    const classes = useStyles()
    const handlePrivacy = () => history.push('/privacy?from=tandc')
    const handleTerms = () => history.push('/terms?from=tandc')
    return (
        <div>
            <Header heading='Legal'/>

            <div className={classes.mainWrapper}>
                <Box marginBottom="20px" marginTop="95px">
                    <Typography variant="subtitle2" style={{marginBottom: '15px'}}>To use this app, you accept and agree
                        to our</Typography>
                    <Typography component="span" onClick={handlePrivacy} className={classes.spanText}
                                variant="subtitle1">Privacy policy</Typography>
                    <Typography variant="subtitle2" style={{marginTop: '5px', marginBottom: '5px'}}>and</Typography>
                    <Typography component="span" onClick={handleTerms} className={classes.spanText} variant="subtitle1">Terms
                        of Service</Typography>
                    <Typography variant="subtitle2" style={{marginTop: '15px'}}>You MUST be 18 years of age or older to
                        use this app</Typography>
                </Box>
            </div>
        </div>
    )
}

export default Privacy
