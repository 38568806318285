import React from "react";
import { withRouter } from "react-router";
import moment from "moment";
import { Box, Typography, Button } from "@material-ui/core";
import pics from "@assets";
import * as BaseActions from "store/actions";
import AddToCalendar from "../addCalendar";
import { makeStyles } from "@material-ui/core/styles";
import { CheckBox } from "@material-ui/icons";
import { useDispatch } from "react-redux";
import { isIOS } from "react-device-detect";
import DummyImage from "@assets/dummyImage.png"

const useStyles = makeStyles((theme) => ({
  calenderContainer: {
    width: "100%",
    // border: "1px dashed orange",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: 'space-between',
    marginTop: "15px"
  },
  pTag: {
    color: "#808080",
    fontSize: "16px",
    marginLeft: "5px"
  }
}));
const BookingSuccess = ({ quarter, ...props }) => {
  // console.log("quarter", quarter)
  const dispatch = useDispatch();

  const name = quarter.usernameEnabled ? quarter.username : quarter.name;
  let event = {
    title: '$' + quarter.quartrCharges + ' Quartr w/ ' + name,
    description: '15 minute Quartrly video call w/ ' + name,
    startTime: new Date(quarter.call_at),
    location: "",
    // endTime: new Date(quarter.call_at).setTime(new Date(quarter.call_at).getTime() + 15)
    endTime: new Date(new Date(quarter.call_at).getTime() + 15 * 60000)
  };
  // console.log(quarter.quartrCharges)
  const [showCal, setShowCal] = React.useState(false)
  const classes = useStyles();
  const handleCheckBoxChange = () => {
    setShowCal(!showCal)
    // console.log({ clicked: "true" })
  }
  // console.log("start time :", event.startTime)
  // console.log("end time :", event.endTime)
  console.log("Quartr image:", quarter.userImage)
  return (
    <>
      <Box padding="30px">
        <Box textAlign="center">
          <div
            style={{
              borderRadius: "6px",
              width: "100%",
              display: "flex",
              justifyContent: "center"
            }}
          >
            <img
              // userImage
              src={quarter.userImage === "https://narsunprojects.com:3001/uploads/default_pic.png" ? DummyImage : quarter.userImage}
              alt={"user"}
              style={{
                width: "56px",
                height: "56px",
                borderRadius: "6px"
              }}
            />
          </div>
        </Box>
        <Box marginTop="30px">
          <Typography variant="body2" style={{ fontSize: "16px" }}>
            Congratulations! Quartr is booked!
          </Typography>
        </Box>
        <Box marginTop="35px">
          <Typography variant="caption" style={{ fontSize: "16px" }}>
            You are scheduling a 15 minute Quartr video call with
            <Typography
              component="span"
              variant="subtitle1"
              style={{ fontSize: "16px", color: "#fff" }}
            >
              {" "}
              {quarter.usernameEnabled ? quarter.username : quarter.name}
            </Typography>{" "}
            on{" "}
            <Typography
              component="span"
              style={{ fontSize: "16px", color: "#fff" }}
            >
              {moment(quarter.call_at).format("dddd, MMMM DD")} @{" "}
              {moment(quarter.call_at).format("h:mm A")}
            </Typography>{" "}
            PDT. Your account has been charged{" "}
            <Typography
              component="span"
              style={{ fontSize: "16px", color: "#fff" }}
            >
              {quarter.rate > 0
                ? `$${Number(quarter.rate).toFixed(2)}`
                : `$0.00`}
            </Typography>
            . Thank you very much!
          </Typography>
        </Box>
        <Box className={classes.calenderContainer} >
          <Box display="flex" alignItems="center">
            {/* <CheckBox
              checked={showCal}
              onChange={handleCheckBoxChange}
              color="primary"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            /> */}
            <p className={classes.pTag}>Add to:</p>
          </Box>
          <AddToCalendar buttonLabel={"Calendar"} event={event}></AddToCalendar>
        </Box>
        <Box marginTop="40px" paddingBottom="10px">
          <Button fullWidth onClick={() => { props.history.push("/home"); dispatch(BaseActions.updateLayout({ bottomNav: true })) }}>
            Ok
          </Button>
        </Box>
      </Box>
    </>
  );
};
export default withRouter(BookingSuccess);
