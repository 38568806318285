import React from "react";
import {Box, InputAdornment, makeStyles, Typography} from "@material-ui/core";
import {ErrorBox, InputField} from "../../../../../../@base/form";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
	transparentInput: {
		fontFamily: "-apple-system, BlinkMacSystemFont, cerebri-regular",
		backgroundColor: "transparent",
		border: "0px",
		outline: "none",
		boxShadow: "none",
		width: "100%",
		fontStyle: "normal",
		fontWeight: 600,
		fontSize: 18,
		lineHeight: "21px",
		display: "flex",
		alignItems: "center",
		color: "#FFFFFF",
		padding: 0,
		"&::placeholder": {
			color: "#4A4A4A",
		},
	},
	yearsInput: {
		color: "#31A7DB",
		textAlign: "center",
	},
	formGroup: {
		boxSizing: "border-box",
		background: "rgba(20, 20, 20, 1)",
		transition: "all 0.25s ease-in-out",
		border: "1px solid #373737",
		borderRadius: 15,
		padding: "5px 5px 5px 15px",
		position: "relative",
		width: "100%",
	},
	formGroupHover: {
		"&:focus-within": {
			background: "#262626",
		},
	},
	formGroupLabel: {
		fontStyle: "normal",
		fontWeight: 400,
		fontSize: 16,
		lineHeight: "19px",
		display: "flex",
		alignItems: "center",
		color: "#9F9F9F",
		marginBottom: 10,
	},
	yearsBtn: {
		cursor: "pointer",
	},
}));


const InputGroup = ({ classes, yearsProps, expertiseProps, errors = [], ...props }) => {
	const cssClasses = useStyles();

	return (
		<Box
			key={props.key}
			display="flex"
			justifyContent="space-between"
			alignItems="end"
			width="100%"
			minHeight="50px"
			className={classes.root}
		>
			<Box width="100%">
				<Box className={clsx(cssClasses.formGroup, cssClasses.formGroupHover)}>
					<Box display="flex" flexDirection="row" width="100%">
						<label htmlFor={props.name} style={{ width:  "calc(100% - 126px"}}>
							<Box display="flex" flexDirection="column">
								<Typography variant="caption" className={cssClasses.formGroupLabel}>
									{props.number}
								</Typography>

								<input
									type="text"
									id={props.name}
									name={expertiseProps.name}
									onBlur={expertiseProps.handleBlur}
									onFocus={expertiseProps.onFocus}
									value={expertiseProps.value}
									maxLength="46"
									placeholder="Write here"
									onChange={(e) =>
										expertiseProps.setFieldValue(expertiseProps.name, e.target.value)}
									disabled={expertiseProps.disabled}
									className={cssClasses.transparentInput}
									{...props}
								/>
							</Box>
						</label>

						<Box
							display="flex"
							flexDirection="column"
							width="126px"
							padding="8px"
							height="100%"
							style={{
								borderRadius: 10,
								borderWidth: 1,
								backgroundColor: "#222222",
								padding: "0 15px",
							}}
						>
							<Box display="flex" flexDirection="column" padding={"3px"} gridRowGap={5}>
								<Typography
									variant="caption"
									className={cssClasses.formGroupLabel}
									style={{ placeSelf: "center", margin: 0 }}
								>
									Years Exp
								</Typography>

								<Box
									display="flex"
									flexDirection="row"
									justifyContent="space-between"
									alignContent="center"
									paddingBottom="7px"
								>
									<Box
										height="21px"
										display="flex"
										alignItems="center"
										className={cssClasses.yearsBtn}
										onClick={() => {
											if (yearsProps.disabled) {
												return;
											}

											if (Number(yearsProps.value) === 1) {
												return;
											}

											let update = Number(yearsProps.value) - 1;
											yearsProps.setFieldValue(yearsProps.name, Number(update));
										}}
									>
										<svg width="16" height="2" viewBox="0 0 16 2" fill="none"
											 xmlns="http://www.w3.org/2000/svg">
											<rect y="2" width="2" height="16" transform="rotate(-90 0 2)" fill="white"/>
										</svg>
									</Box>

									<input
										type="number"
										id={props.name}
										onBlur={yearsProps.handleBlur}
										name={yearsProps.name}
										value={yearsProps.value}
										disabled={yearsProps.disabled}
										onChange={(e) => {
											if (e.target.value === "") {
													yearsProps.setFieldValue(yearsProps.name, e.target.value);
												return
											}
											if (Number(e.target.value) < 1 || Number(e.target.value) > 45) {
												return;
											}

											yearsProps.setFieldValue(yearsProps.name, e.target.value);
										}}
										className={clsx(cssClasses.transparentInput, cssClasses.yearsInput)}
										{...props}
									/>

									<Box
										height="21px"
										display="flex"
										alignItems="center"
										className={cssClasses.yearsBtn}
										onClick={() => {
											if (yearsProps.disabled) {
												return;
											}

											if (Number(yearsProps.value) >= 50) {
												return;
											}

											let update = Number(yearsProps.value) + 1;
											yearsProps.setFieldValue(yearsProps.name, Number(update));
										}}
									>
										<svg width="16" height="16" viewBox="0 0 16 16" fill="none"
											 xmlns="http://www.w3.org/2000/svg">
											<rect y="9" width="2" height="16" transform="rotate(-90 0 9)" fill="white"/>
											<rect x="7" width="2" height="16" fill="white"/>
										</svg>
									</Box>
								</Box>
							</Box>
						</Box>
					</Box>
				</Box>

				{errors.map((error, index) => (
					error && <ErrorBox key={index} error={error}/>
				))}
			</Box>
		</Box>
	);
};
export default React.memo(InputGroup);
