import axios from "axios";
import * as BaseActions from "store/actions";
//SUBMIT LOGIN ACTION NAMES
export const SET_APPOINTMENT = "SET_APPOINTMENT";
export const APPOINTMENT_IS_LOADING = "APPOINTMENT_IS_LOADING";
export const APPOINTMENT_FAILED = "APPOINTMENT_FAILED";
export const APPOINTMENT_RESET = "APPOINTMENT_RESET";
// Helper ACTIONS:
export const appointmentLoading = (ms) => ({
  type: APPOINTMENT_IS_LOADING,
  payload: ms,
});
export const appointmentFailed = (ms) => ({
  type: APPOINTMENT_FAILED,
  payload: ms,
});
export const resetAppointmentReducer = (ms) => ({
  type: APPOINTMENT_RESET,
  payload: ms,
});
export function setAppointment(data) {
  console.log("data :::::AAAAAAAA", data);
  const token = localStorage.getItem("JWTtoken");
  const config = {
    headers: {
      Authorization: `JWT ${token}`,
      // "Content-Type": "application/json",
    },
  };
  const request = axios.post(
    `${process.env.REACT_APP_ENV === "prod" ? process.env.REACT_APP_BASE_URL_PROD : process.env.REACT_APP_BASE_URL_TESTING}appointments/set-appointment`,
    data,
    config
  );
  return (dispatch) => {
    dispatch(appointmentLoading(true));
    dispatch(BaseActions.showLoading(true));
    request
      .then((response) => {
        if (response.data && response.data.status === "Success") {
          console.log({ responce: response.data })
          dispatch({
            type: SET_APPOINTMENT,
            payload: response.data,
          });
          dispatch(BaseActions.hideLoading());
        } else {
          let err = Error();
          err.message = response.data.message;
          throw err;
        }
      })
      .catch((error) => {
        // dispatch(
        //   BaseActions.showAlert({ variant: "error", message: error.message })
        // );
        dispatch(BaseActions.hideLoading());
        if (error.response) {
          if (error.response.status === "404") {
            dispatch(
              appointmentFailed(`${error.response.status} : api not found`)
            );
          } else {
            dispatch(appointmentFailed(error.response.status));
          }
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          dispatch(appointmentFailed(error.request));
          console.log(error.request);
        } else {
          console.log("Error", error.message);
          dispatch(appointmentFailed(error.message));
        }
        console.log(error);
      });
  };
}
