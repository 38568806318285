import axios from "axios";
export default function logoutComplete() {
  handleLogout();
}

const handleLogout = () => {
  const token = localStorage.getItem("JWTtoken");

  const config = {
    headers: {
      Authorization: `JWT ${token}`,
      // "Content-Type": "application/json",
    },
  };

  axios
    .post(`${process.env.REACT_APP_ENV === "prod" ? process.env.REACT_APP_BASE_URL_PROD : process.env.REACT_APP_BASE_URL_TESTING}users/logout`, config)
    .then((res) => {
      localStorage.removeItem("JWTtoken");
      localStorage.removeItem("quarterlyUser");
      window.location.href = "/";
    });
};
