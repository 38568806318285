import { combineReducers } from "redux";
import {
  SubmitSignupReducer,
  UpdateProfileReducer,
  GetUserByReferralCodeReducer,
  SetAvailabilityReducer,
} from "./signup.reducer";
const reducer = combineReducers({
  SubmitSignupReducer,
  UpdateProfileReducer,
  GetUserByReferralCodeReducer,
  SetAvailabilityReducer,
});

export default reducer;
