import { Box, Button, Typography, withStyles, Slide } from "@material-ui/core";
import { Radio as RadioButton } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import clsx from "clsx";
import moment from "moment";
import React, { useState } from "react";
import Carousel from "react-multi-carousel";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router";
import * as BaseActions from "store/actions";
import withReducer from "store/withReducer";
import useDeepCompareEffect from "use-deep-compare-effect";
import * as Actions from "./store/actions";
import * as ProfilActions from './../userProfile/store/actions'
import reducers from "./store/reducers";
import BookingConfirmation from "./subComponents/bookingConfirmation";
import BookingSuccess from "./subComponents/bookingSuccess";
import PaymentDrawer from "./subComponents/paymentDrawer";
import { isAndroid, isIOS, isMobile } from "react-device-detect";
import axios from 'axios'

function addMinutes(date, minutes) {
	// alert(date)
	return new Date(new Date(date).getTime() + minutes * 60000).toISOString();
}
const responsive = {
	all: {
		breakpoint: { max: 4000, min: 0 },
		items: 7.5,
	},
};
const useStyles = makeStyles((theme) => ({
	container: {
		padding: "32px 0 0 0",
		[theme.breakpoints.down("sm")]: {
			padding: "15px 0 0 0",
		}
	},
	header: {
		margin: "0 20px 0 20px",
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
	},
	title: {
		flexGrow: 1,
	},
	backButton: {
		color: "white",
		cursor: "pointer",
	},
	calendarContainer: {
		marginTop: 5,
		[theme.breakpoints.down("sm")]: {
			marginTop: 0,
		}
	},
	dateContainer: {
		cursor: "pointer",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		padding: "10px 0",
	},
	selectedDate: {
		backgroundColor: "#31A7DB",
		borderRadius: 6,
		width: "35px",
	},
	disabledDateContainer: {
		// backgroundColor: "#808080",
		color: "#808080",
		opacity: 0.4,
		cursor: "not-allowed",
	},
	gradient: {
		height: 100,
		backgroundImage: "linear-gradient(#00000000, #0A0A0A)",
		opacity: 1,
		zIndex: 9,
		position: "absolute",
		width: "100%",
		borderRadius: 6,
		bottom: 0,
		// [theme.breakpoints.up("sm")]: {
		//   width: 370,
		//   bottom: 42,
		// },
	},
	buttonBottom: {
		// position: "absolute",
		// [theme.breakpoints.up("sm")]: {
		// 	// position: "fixed",
		// 	bottom: 60,
		// },
		// bottom: 0,
		width: '330px',
		margin: "20px 0",
		// border: "1px dashed orange"
	},
	btnContainer: {
		// border: "1px dashed orange",
		position: "fixed",
		bottom: 10,
		[theme.breakpoints.up("sm")]: {
			width: "372px",
			bottom: 31,
		},
		width: "100%",
		height: "100px",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		background: "linear-gradient(365deg, #0A0A0A 60%, #00000000 100%)",
		opacity: 1,
		margin: 1,
		// marginBottom: 10,
		borderBottomRightRadius: '6px',
		borderBottomLeftRadius: '6px',
	},
	backdrop: {
		backgroundColor: 'rgba(0, 0, 0, 0.7)',
		position: 'absolute',
		top: 0,
		left: 0,
		width: '100%',
		height: '91vh',
		[theme.breakpoints.down('xs')]: {
			height: '100vh',
		},
	},
	confirmWrapper: {
		backgroundColor: '#111111',
		padding: '2rem',
		position: 'absolute',
		zIndex: 1000,
		bottom: 0,
		left: 0,
		width: '100%',
		paddingBottom: '20%'
		// top: "48%"
	},
	cancelHeading: {
		color: '#31A7DB',
		fontWeight: 400,
		marginBottom: '1rem',
		fontSize: 18,
		fontFamily: 'Cerebri-SemiBold'
	},
	cancelConfirmText: {
		color: '#808080',
		marginBottom: '2rem',
		fontWeight: 400,
		fontSize: 16,
	},
}));
const PopupBtn = withStyles({
	root: {
		height: 42,
		'& .MuiButton-label': {
			fontSize: 18,
		},
	},
})(Button)
function Booking({ history, location }) {
	const dispatch = useDispatch();
	const [weekData, setWeek] = useState([]);
	const [host, setHost] = useState({});
	const [filteredQuarters, setFilteredQuarters] = useState([]);
	const [selectedDate, setSelectedDate] = useState(null);
	const [selectedQuarter, setSelectedQuarter] = useState(null);
	const [errorPop, setErrorPop] = useState(false);
	const [quartrCharges, setQuartrCharges] = useState(0);
	const [occupiedSlots, setOccupiedSlots] = useState([]);

	const [username, setUsername] = useState("");
	const [usernameEnabled, setUsernameEnabled] = useState(null);

	const appointmentConfirmation = useSelector(
		({ Booking }) => Booking.SetAppointmentReducer
	);
	const rates = useSelector(
		({ Profile }) => {
			if (!Profile) {
				history.push("/userprofile/" + location.state._id)
			} else {
				return Profile.GetRatesReducer
			}
		}
	);
	const [today, setToday] = useState(-1);
	const classes = useStyles();
	React.useEffect(() => {
		// dispatch(BaseActions.getUserData());
		dispatch(BaseActions.updateLayout({ bottomNav: false }));
	}, [dispatch]);
	React.useEffect(() => {
		dispatch(BaseActions.updateLayout({ bottomNav: false }));
		setHost({
			host_id: location.state._id,
			charges: location.state.rate,
			name: location.state.full_name,
			userImage: location.state.userImage,
		});
		getCalender();
		setUsernameEnabled(location.state.enabledUsername)
		setUsername(location.state.username)
		// setUsernameEnabled(location.state.ena)
	}, []);
	// when we book a quartr it will evaluate response returned from api.
	useDeepCompareEffect(() => {
		if (
			appointmentConfirmation.data &&
			appointmentConfirmation.data.status === "Success"
		) {
			// alert("sucees");
			dispatch(BaseActions.hideMessage());
			setTimeout(() => {
				// it will show pop-up message for booking confirmationo.
				dispatch(
					BaseActions.showMessage(
						<BookingSuccess
							quarter={{
								...host,
								rate: rates.data.data.totalCharges,
								call_at: selectedQuarter.timestamp,
								quartrCharges: quartrCharges,
								username: username,
								usernameEnabled: usernameEnabled
							}}
						/>
					)
				);
				dispatch(Actions.resetAppointmentReducer());
			}, 100);
			return;
		}
		if (appointmentConfirmation.errMsg) {
			handleClose();
			setErrorPop(true)
			dispatch(BaseActions.updateLayout({ bottomNav: isIOS ? false : true }))
			return;
		}
	}, [appointmentConfirmation]);
	// same calender setting as we described in our availability component.
	const getCalender = () => {
		setToday(new Date());
		let curr = new Date();
		let week = [];
		let availableQuarters = location.state.availability;
		for (let i = 0; i < 7; i++) {
			// Causing issue with the current Day of the week
			// The coming if condition has been modified and set with new logic
			let first = curr.getDate() - curr.getDay() + i;
			let day = new Date(curr.setDate(first));
			let date1 = moment(new Date(day).toISOString())
				.utc()
				.format("YYYY-MM-DD");
			if (
				// new Date(day).toLocaleDateString() <
				// new Date().toLocaleDateString()
				moment(new Date().setDate(new Date().getDate())).isBefore(moment().format('YYYY-MM-DD'), 'day')
			) {
				week.push({
					day: day.toString().substr(0, 3),
					date: day,
					isDisabled: true,
				});
			} else {
				let matched = [];
				for (let i = 0; i < availableQuarters.length; i++) {
					let isoString = new Date(day);
					let currentStamp = new Date(availableQuarters[i].timestamp);
					if (isoString.toDateString() === currentStamp.toDateString()) {
						if (
							new Date(currentStamp).toISOString() > new Date().toISOString()
						) {
							matched.push(isoString);
							break;
						}
					}
				}
				if (matched.length !== 0) {
					week.push({
						day: day.toString().substr(0, 3),
						date: day,
						isDisabled: false,
					});
				} else {
					week.push({
						day: day.toString().substr(0, 3),
						date: day,
						isDisabled: true,
					});
				}
			}
		}
		let curr1 = new Date();
		curr1.setDate(curr1.getDate() + 7);
		for (let i = 0; i < 7; i++) {
			let first = curr1.getDate() - curr1.getDay() + i;
			let day = new Date(curr1.setDate(first));
			let date1 = moment(new Date(day).toISOString(), "day")
				.utc()
				.format("YYYY-MM-DD");
			if (
				// new Date(day).toLocaleDateString() < new Date().toLocaleDateString()
				moment(date1).isBefore(moment().format("YYYY-MM-DD"))
			) {
				week.push({
					day: day.toString().substr(0, 3),
					date: day,
					isDisabled: true,
				});
			} else {
				let matched = [];
				for (let i = 0; i < availableQuarters.length - 1; i++) {
					let isoString = new Date(day);
					let currentStamp = new Date(availableQuarters[i].timestamp);
					if (isoString.toDateString() === currentStamp.toDateString()) {
						if (
							new Date(currentStamp).toISOString() > new Date().toISOString()
						) {
							matched.push(isoString);
							break;
						}
					}
				}
				if (matched.length !== 0) {
					week.push({
						day: day.toString().substr(0, 3),
						date: day,
						isDisabled: false,
					});
				} else {
					week.push({
						day: day.toString().substr(0, 3),
						date: day,
						isDisabled: true,
					});
				}
			}
		}
		setWeek(week);
	};
	// as same as in availability.
	const handleDateChange = (i, rawDate) => {
		getUpcomingQuarters(moment(rawDate).format('DD/MM/YYYY'))
		let date = new Date(rawDate).toLocaleDateString("en-US");
		if (date === selectedDate) return;
		setFilteredQuarters([]);
		setSelectedQuarter(null);
		setSelectedDate(date);
	};
	React.useEffect(() => {
		getQuartersStamp(selectedDate).then((stamps) => {
			setFilteredQuarters([...stamps]);
		});
	}, [selectedDate]);
	// this function are used to get all quarters availble from a host.
	// it will display only that quarts which users allowed here.
	async function getQuartersStamp(stamp) {
		let arr = [];
		for (let i = 0; i <= 23; i++) {
			let nestedArray = [];
			let lDate = new Date(stamp).toLocaleDateString("en-US");
			let lTime = new Date(stamp).toLocaleTimeString();
			let params = lDate.split("/").reverse();
			params = [...params, ...lTime.split(":")];
			let q1 = new Date(
				params[0],
				Number(params[2]) - 1,
				params[1],
				i,
				0
			).toISOString();
			if (q1 > new Date().toISOString()) {
				nestedArray.push(q1);
			}
			let q2 = new Date(
				params[0],
				Number(params[2]) - 1,
				params[1],
				i,
				15
			).toISOString();
			if (q2 > new Date().toISOString()) {
				nestedArray.push(q2);
			}
			let q3 = new Date(
				params[0],
				Number(params[2]) - 1,
				params[1],
				i,
				30
			).toISOString();
			if (q3 > new Date().toISOString()) {
				nestedArray.push(q3);
			}
			let q4 = new Date(
				params[0],
				Number(params[2]) - 1,
				params[1],
				i,
				45
			).toISOString();
			if (q4 > new Date().toISOString()) {
				nestedArray.push(q4);
			}
			arr = [...arr, ...nestedArray];
			//   arr.push(nestedArray);
		}
		let composedArray = [];
		let availablitiesOfUser = location.state.availability;
		arr.forEach((stamp) => {
			let matched = [];
			// verifying  if this generated quarter available from host side or not.
			availablitiesOfUser.forEach((availableStamp) => {
				if (availableStamp.timestamp === stamp) {
					matched.push(availableStamp);
					// composedArray.push(availableStamp);
					return;
				}
			});
			if (matched.length !== 0) {
				composedArray.push(matched[0]);
			}
			// else {
			//   composedArray.push({ timestamp: stamp, status: "not_available" });
			// }
		});
		return composedArray;
	}
	const handleSelect = (stamp) => {
		setSelectedQuarter(stamp);
		const data = {
			charges: location.state.charges,
			call_at: stamp.timestamp,
			host_id: location.state._id,
			attendee_id: JSON.parse(localStorage.getItem("quarterlyUser")).data._id,
			name: location.state.full_name,
		}
		setQuartrCharges(data.charges);
		dispatch(ProfilActions.getRates(data))
	};
	const handleClose = () => {
		dispatch(BaseActions.hideMessage())
		dispatch(BaseActions.updateLayout({ bottomNav: false }))
	}
	const handleSetAppointment = (quarter) => {
		dispatch(Actions.setAppointment(quarter));
	};
	//
	const handleSubmit = () => {
		dispatch(BaseActions.updateLayout({ bottomNav: false }))
		dispatch(
			BaseActions.showMessage(
				<BookingConfirmation
					handleConfirmation={handleConfirmation}
					handleClose={handleClose}
					quarter={{
						...host,
						rate: rates.data.data.totalCharges,
						call_at: selectedQuarter.timestamp,
						username: username,
						usernameEnabled: usernameEnabled
					}}
				/>
			)
		);
	};
	const handleConfirmation = (data) => {
		dispatch(
			BaseActions.showMessage(
				<PaymentDrawer
					handleSetAppointment={handleSetAppointment}
					handleClose={handleClose}
					data={data}
				/>
			)
		)
	}


	const getUpcomingQuarters = (date) => {
		return axios.post(`${process.env.REACT_APP_ENV === "prod" ? process.env.REACT_APP_BASE_URL_PROD : process.env.REACT_APP_BASE_URL_TESTING}appointments/get-appointments-by-date?date=${date}`)
			.then((res) => {
				let times = []
				console.log({ res: res.data.data })
				res.data.data.appointments.map((item) => {
					let startTime = moment(new Date(item.call_at)).format("hh:mm A");
					let endTime = moment(new Date(addMinutes(item.call_at, 15))).format("hh:mm A");
					times.push(`${startTime} - ${endTime}`)
				})
				setOccupiedSlots(times)
			}).catch((err) => {
				console.log({ err })
			}).finally(() => {
				dispatch(BaseActions.hideLoading())
			})
	}

	// React.useEffect(() => {
	// 	getUpcomingQuarters()
	// }, [])


	return (
		<div style={{ height: isMobile && errorPop ? "91vh" : "auto", display: "flex", flexDirection: "column", justifyContent: "space-between", position: "relative", width: "100%", overflow: "hidden" }}>
			<Box className={classes.container}>
				<Box className={classes.header}>
					<ArrowBackIosIcon className={classes.backButton} onClick={() => history.goBack()} />
					<Typography variant="h1" className={classes.title}>
						Pick a Day
					</Typography>
				</Box>
				<Box className={classes.calendarContainer}>
					<Carousel responsive={responsive}>
						{weekData.map((d, i) => (
							<Box
								key={i}
								className={`disable-selection ${selectedDate === new Date(d.date).toLocaleDateString("en-US")
									? `${classes.dateContainer} ${classes.selectedDate}`
									: d.isDisabled
										? `${classes.dateContainer} ${classes.disabledDateContainer}`
										: classes.dateContainer
									}`}
								onClick={() => {
									!d.isDisabled && handleDateChange(i, d.date);
								}}
							>
								<Typography variant="subtitle1">{d.day}</Typography>
								<Typography style={{ marginTop: "15px" }} variant="subtitle1">
									{d.date.getDate()}
								</Typography>
							</Box>
						))}
					</Carousel>
				</Box>
			</Box>
			{selectedDate && (
				<Box padding="0px 20px 0px">
				{/* <Box padding="0px 20px 20px 20px"> */}
					{/* <div> */}
					<div style={{ marginTop: isMobile ? "5px" : "20px" }}>
						<Typography variant="body2">
							{moment(selectedDate).format("dddd, MMMM DD")}
						</Typography>
					</div>
					<Box
						// style={{ backgroundColor: "red" }}
						style={{ backgroundColor: "#080A0A", flexGrow: 1, overflow: 'auto' }}
						// style={{ backgroundColor: "#080A0A", flexGrow: 1, overflow: 'auto', padding: '0px 0px 60px 0px' }}
						marginTop={isMobile ? "0" : "20px"}
						// marginBottom="20px"
						maxHeight={isMobile ? "60vh" : "320px"}
						// maxHeight={isMobile ? "365px" : "320px"}
					//   height="100%"
					>
						{/* <div className={classes.gradient}></div> */}
						{filteredQuarters.map((stamp, i) => (
							<QuarterPill
								handleSelect={handleSelect}
								selected={
									selectedQuarter &&
									selectedQuarter.timestamp === stamp.timestamp
								}
								key={i}
								stamp={stamp}
								occupiedSlots={occupiedSlots}
							/>
						))}
					</Box>
				</Box>
			)}
			{!errorPop && <div className={classes.btnContainer}>
				<Button className={classes.buttonBottom} disabled={!selectedQuarter} onClick={() => handleSubmit()} fullWidth>Confirm</Button>
			</div>}
			<Slide direction='up' in={errorPop}>
				<div className={classes.backdrop} style={{ display: errorPop ? 'inherit' : 'none' }}>
					<div className={classes.confirmWrapper}>
						<Typography variant='subtitle1' className={classes.cancelHeading}>Payment Error</Typography>
						<Typography variant='subtitle2' className={classes.cancelConfirmText}>Please recharge your account or try again with a different card.</Typography>
						<div style={{ display: 'flex', justifyContent: 'space-between', paddingBottom: isIOS || isAndroid ? "120px" : "30px" }}>
							<PopupBtn
								onClick={() => setErrorPop(false)}
								fullWidth
								style={{ height: '50px' }}
							>
								Ok
							</PopupBtn>
						</div>
					</div>
				</div>
			</Slide>
		</div>
	);
}
export default withReducer("Booking", reducers)(withRouter(Booking));
const useStyless = makeStyles((theme) => ({
	root: {
		color: (props) => (props.isAvailable ? "#fff" : "#3b3b3b"),
		"&$checked": {
			color: "#31A7DB",
		},
	},
	disabled: {
		color: "#3b3b3b",
	},
	checked: {},
	bookedPill: {
		background: "#b73e3e",
		color: "#fff",
		fontSize: "16px",
		fontFamily: "-apple-system, BlinkMacSystemFont, cerebri-regular",
		marginLeft: "auto",
		marginRight: "5px",
		borderRadius: "20px",
		padding: "1px 15px",
	},
	// icon: {
	//   borderRadius: "50%",
	// },
	checkedIcon: {
		borderRadius: "100%",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		padding: "3px",
		border: "2px solid #FFFFFF",
	},
	blueDot: {
		borderRadius: "100%",
		display: "block",
		width: 14,
		height: 14,
		backgroundColor: "#31A7DB",
		content: '""',
	},
}));
// this one is quartr pill component.
const QuarterPill = ({ selected, stamp, handleSelect, occupiedSlots, ...props }) => {
	const [quarterStatus, setQuarterStatus] = React.useState({
		isBooked: false,
		isAvailable: false,
	});
	const [duration, setDuration] = React.useState({
		startTime: "",
		endTime: "",
	});
	const classes = useStyless(quarterStatus);
	React.useEffect(() => {
		let startTime = moment(new Date(stamp.timestamp)).format("hh:mm A");
		let endTime = moment(new Date(addMinutes(stamp.timestamp, 15))).format("hh:mm A");
		setDuration({ startTime, endTime });
		setQuarterStatus({
			...quarterStatus,
			isAvailable: stamp.status === "not_available" ? false : true,
			isBooked: stamp.status === true ? true : false,
		});
	}, []);
	return (
		<Box
			marginTop={isMobile ? "10px" : "20px"}
			display="flex"
			alignItems="center"
			style={{
				cursor: (quarterStatus.isBooked ||
					occupiedSlots.includes(`${duration.startTime} - ${duration.endTime}`)) ? "not-allowed" : "pointer",
			}}
			onClick={() => {
				if (new Date(stamp.timestamp).getTime() < new Date().getTime()) {
					alert("This quarter is not available now");
					return;
				}
				if (!(quarterStatus.isBooked || occupiedSlots.includes(`${duration.startTime} - ${duration.endTime}`))) handleSelect(stamp);
			}}
		>
			<Box marginRight="5px">
				<RadioButton
					checkedIcon={
						<div className={clsx(classes.icon, classes.checkedIcon)}>
							<span className={clsx(classes.blueDot)} />
						</div>
					}
					disabled={quarterStatus.isBooked || occupiedSlots.includes(`${duration.startTime} - ${duration.endTime}`)}
					classes={{
						root: classes.root,
						checked: classes.checked,
						disabled: classes.disabled,
					}}
					checked={selected}
					color="secondary"
					size="large"
				/>
			</Box>
			<Typography
				style={{
					fontSize: "16px",
					color: (quarterStatus.isBooked ||
						occupiedSlots.includes(`${duration.startTime} - ${duration.endTime}`)) ? "#3b3b3b" : "#fff",
				}}
				variant="body1"
			>
				{duration.startTime} - {duration.endTime}
			</Typography>
			{(
				quarterStatus.isBooked ||
				occupiedSlots.includes(`${duration.startTime} - ${duration.endTime}`)
			) && (
					<div className={classes.bookedPill}>Booked</div>
				)}
		</Box>
	);
};
// #b73e3e
