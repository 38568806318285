import React from "react";

function EditIcon() {
    return (
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M21.4415 9.85804L13.3743 17.9255C13.0485 18.2512 12.6337 18.4732 12.1819 18.5637L8.74988 19.25L9.43629 15.8179C9.52662 15.3663 9.74865 14.9514 10.0744 14.6257L18.1417 6.55821M21.4415 9.85804L23.0914 8.20812C23.547 7.75251 23.547 7.01381 23.0914 6.55821L21.4415 4.9083C20.9859 4.45268 20.2472 4.45268 19.7916 4.9083L18.1417 6.55821M21.4415 9.85804L18.1417 6.55821"
                stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M5.83337 23.3333H22.1667" stroke="white" strokeWidth="2" strokeLinecap="round"
                  strokeLinejoin="round"/>
        </svg>

    )
}

export default EditIcon;
