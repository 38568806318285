import React, { useRef, useState, useEffect } from "react";
import { withRouter } from "react-router";
import {
    Box, Typography, makeStyles,
    //  Button,  withStyles, 
} from "@material-ui/core";
// import useDeepCompareEffect from "use-deep-compare-effect";
//// functions need to use react with our component
import { useDispatch, useSelector } from "react-redux";
import withReducer from "store/withReducer";
import "react-multi-carousel/lib/styles.css";
// import ManageQuarters from './ManageQuarters'
// import { Link } from 'react-router-dom';
import DrawerMenu from 'components/drawerMenu';
import useDetectZoomLevel from "hooks/useDetectZoomLevel";
import NotificationCard from './notificationCard'
import * as Actions from "./store/actions";
import reducers from "./store/reducers";
import * as BaseActions from "store/actions";
import moment from "moment";
import { isMobile } from "react-device-detect";
// import notification from "@base/notification";
const useStyles = makeStyles((theme) => ({
    headerWrapper: {
        display: 'flex',
        alignItems: 'center',
        marginTop: 8,
        marginLeft: 3,
        padding: 17,
        fontWeight: 'bold',
        [theme.breakpoints.down('sm')]: {
            marginTop: 0,
            padding: "15px 17px 0px 17px",
        }
    },
    caption: {
        margin: "0 0 0 20px"
    },
    headerText: {
        color: '#31A7DB',
        fontSize: '1.9rem',
        fontFamily: 'Cerebri-Bold'
    },
    leftSideMenu: {
        display: 'flex',
        alignItems: 'center',
        marginRight: 'auto',
    },
    menuIcon: {
        height: "20px",
        width: "20px",
        cursor: 'pointer',
        marginRight: '1rem',
    },
    historyIcon: {
        color: '#fff',
        width: 18,
        cursor: 'pointer',
    },
    availabilityBtn: {
        width: '82%',
        left: '2rem',
        [theme.breakpoints.down('xs')]: {
            width: '83%',
            left: '2.7rem',
        },
    }
}))
function Notifications({ history }) {
    const [pageCount, setPageCount] = useState(1)
    const [data, setData] = useState([])
    const [firstAttempt, setFirstAttempt] = useState(true)
    const dispatch = useDispatch();
    const notificationsResponse = useSelector(({ Notifications }) => Notifications.GetNotificationReducer);
    const {
        notifications,
        // page,
        // pageSize,
        // total,
        // totalPages,
        // unreadCount
    } = notificationsResponse.data?.data || {};
    // const [finalNotifications, setFinalNotifications] = useState([]);
    useEffect(() => {

        let notifData = []
        notifications && notifications.map((item) => {
            const today = moment().format("dddd, MMM DD")
            const date = moment(item.createdAt).format("dddd, MMM DD")
            const createdAt = date === today ? "Today" : date
            if (notifData.length) {
                const index = notifData.findIndex(ind => ind.createdAt === createdAt)
                if (index !== -1) {
                    notifData[index].notifData.push(item)
                } else {
                    const notificationData = {
                        createdAt,
                        notifData: [{ ...item }]
                    }
                    notifData.push(notificationData)
                }
            } else {
                const notificationData = {
                    createdAt,
                    notifData: [{ ...item }]
                }
                notifData.push(notificationData)
            }
            return
        })
        const finalData = firstAttempt ? [...notifData] : [...data, ...notifData]
        setData(finalData)
    }, [notificationsResponse])

    const classes = useStyles();
    const zoomLevel = useDetectZoomLevel()
    React.useEffect(() => {
        dispatch(BaseActions.updateLayout({ bottomNav: true }));
        let timeout = setTimeout(() => {
            dispatch(Actions.getNotifications(pageCount))
            setFirstAttempt(false)
        }, 100);
        return () => {
            clearTimeout(timeout)
        }
    }, [pageCount])
    const zoomedStyles = zoomLevel > 100 ? { height: `${zoomLevel}vh`, overflowY: 'hidden' } : {}

    const listInnerRef = useRef();

    const onScroll = () => {
        if (listInnerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
            if (scrollTop + clientHeight > scrollHeight - 20) {
                setPageCount(pageCount + 1)
            }
        }
    };


    return (
        <div style={zoomedStyles}>
            <div className={classes.headerWrapper}>
                <div className={classes.leftSideMenu}>
                    <img src='assets/Tabbar/TabbarInactive/menu.png' className={classes.menuIcon} onClick={() => dispatch(BaseActions.showMessage(<DrawerMenu />))} />
                    <Typography variant='h5' className={classes.headerText}>Notifications</Typography>
                </div>
            </div>
            <div style={{ overflowY: 'scroll', height: isMobile ? '92vh' : '70vh' }} onScroll={() => onScroll()} ref={listInnerRef}>
                <Box paddingBottom="40px">
                    {
                        data.length ?
                            data.map((item, j) => {
                                return <>
                                    < Typography key={j} className={classes.caption} variant="caption">
                                        {item.createdAt}
                                    </Typography>
                                    {
                                        item.notifData &&
                                        item.notifData.map((notification, i) => {
                                            return <NotificationCard day={item.createdAt} item={notification} i={i} />
                                        })
                                    }
                                </>
                            })
                            :
                            <Typography className={classes.caption}>
                                No notifications
                            </Typography>
                    }
                </Box>
            </div>
        </div >
    );
}
export default withReducer("Notifications", reducers)(withRouter(Notifications));
