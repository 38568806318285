import React from "react";

import { Box, Typography, InputAdornment } from "@material-ui/core";
import InputField from "@base/InputField";
const InputGroup = ({ text, value, set }) => {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="end"
      width="100%"
      alignItems="center"
    >
      <Typography variant="caption">{text}</Typography>
      <div style={{ width: "45%", color: "#FFFFFF", display: "flex" }}>
        <InputField
          disabled
          type="number"
          textAlign="center"
          value={value}
          isExperties="Yes"
          showBorder="No"
          removeUnderline
          paddingNone
          startAdornment={
            <span style={{ border: '1px solid white', padding: '11% 0% 11% 5%', borderRadius: '8px 0px 0px 8px', cursor: "pointer", borderTop: 'none', borderBottom: 'none' }}>
              <InputAdornment position="start">
                <div
                  onClick={() => {
                    value > 0 && set(value - 0.25);
                  }}
                  style={{
                    color: "white",
                    cursor: "pointer",
                    // fontSize: 26,
                    // marginTop: 10,
                    // marginLeft: -10,
                  }}
                >
                  -
                </div>
              </InputAdornment>
            </span>
          }
          endAdornment={
            <span style={{ height: "35px", border: '1px solid white', padding: '11% 5% 11% 0%', borderRadius: '0px 8px 8px 0px', cursor: "pointer", borderTop: 'none', borderBottom: 'none' }}>
              <InputAdornment position="end">
                <div
                  onClick={() => {
                    value < 5 && set(value + 0.25);
                  }}
                  style={{
                    color: "white",
                    cursor: "pointer",
                    // fontSize: 26,
                    // paddingTop: 10,
                    // marginRight: -10,
                  }}
                >
                  +
                </div>
              </InputAdornment>
            </span>
          }
        />
      </div>
    </Box>
  );
};

export default React.memo(InputGroup);
