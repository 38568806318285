import React from "react";
import PropTypes from "prop-types";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";

import { makeStyles, withStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import DummyImage from "../../../../@assets/dummyImage.png"

import { useDispatch, useSelector } from "react-redux";
const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
  },
  bottom: {
    color: "#31A7DB ",
    //   position: 'absolute',
    //   left: 0,
  },
  top: {
    color: "#09161C",
    animationDuration: "550ms",
    position: "absolute",
    left: 0,
  },
  circle: {
    strokeLinecap: "round",
    // strokeOpacity:0,
  },
  // ////////////////////////////////
  imageCon: {
    display: "flex",
    // alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "column",
    alignItems: 'center',
    marginTop: "40px",
    marginBottom: "20px",
    "& > img": {
      height: "100px",
      width: "100px",
      borderRadius: "6px",
      margin: "5px 0px",
    },
  },
}));

function CircularProgressWithLabel({ timer, ...props }) {
  const classes = useStyles();
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress
        className={classes.bottom}
        thickness={1.8}
        size={115}
        value={100}
        variant="static"
        classes={{
          circle: classes.circle,
        }}
      />
      <CircularProgress
        classes={{
          circle: classes.circle,
        }}
        className={classes.top}
        thickness={2}
        size={115}
        variant="static"
        // style={{ transform: [{ scaleX: 1 }] }}
        {...props}
      />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="caption" component="div" style={{ color: "#31A7DB" }}>
          {timer}
        </Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};

export default function CountdownTimer({ time }) {
  const [progress, setProgress] = React.useState(100);
  const [timer, setTimer] = React.useState("00:00");
  const timerResponse = useSelector(
    ({ BaseReducer }) => BaseReducer.TimerReducer
  );

  let timerConstructor = (duration) => {
    let timer = duration;
    let minutes = "";
    let seconds = "";
    setProgress(0.833 * Number(duration));
    let interval = setInterval(function () {
      minutes = parseInt(timer / 60, 10);
      seconds = parseInt(timer % 60, 10);

      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;
      let completeTime = minutes + ":" + seconds;
      // console.log(minutes + ":" + seconds);
      setProgress(
        (prevProgress) => Math.ceil(prevProgress) < 100 && prevProgress + 0.833
      );
      setTimer(completeTime);
      if (--timer < 0) {
        clearInterval(interval);
        setTimer("00:00");
      }
    }, 1000);
  };

  React.useEffect(() => {
    // timerConstructor(time);
  }, []);
  const classes = useStyles();
  // console.log({ timerResponse: timerResponse.data })
  console.log("timer: ", timerResponse);
  const profileImage = timerResponse.data.profileImage === "https://narsunprojects.com:3001/uploads/default_pic.png" || timerResponse.data.profileImage === "" ? DummyImage : timerResponse.data.profileImage

  // console.log(timerResponse.data)

  return (
    <>
      <div className={classes.imageCon}>
        <Typography variant="caption">Quartr with</Typography>
        <img
          src={(timerResponse.data && profileImage) || ""}
          alt="img"
        />

        <Typography variant="caption" styke={{ alignItmes: 'center' }}>
          <span style={{ textDecoration: "bold", color: 'white' }}>
            {timerResponse.data.userName}
          </span>
          <br />
          will start in
        </Typography>
      </div>
      <CircularProgressWithLabel
        value={timerResponse.data && timerResponse.data.progress}
        timer={timerResponse.data && timerResponse.data.timer}
      />
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', margin: '0 auto', alignItems: 'center', marginTop: '2%' }}>
        <Typography style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', color: 'while' }}>
          Helpful tips prior to Quartr:
        </Typography>
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', margin: '0 auto', color: 'grey' }}>
          <p style={{ fontFamily: 'Cerebri-SemiBold' }}>
            - Good Internet connection<br />
            - Quiet location<br />
            - Adequate lighting<br />
            - Music and call volume at 100%
          </p>
        </div>
      </div>
    </>
  );
}

CountdownTimer.defaultProps = {
  time: 60 * 2,
};
