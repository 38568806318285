export const HIDE_ALERT = "HIDE_ALERT";
export const SHOW_ALERT = "SHOW_ALERT";

export function hideAlert() {
  return {
    type: HIDE_ALERT,
  };
}

//  {ALERT : "what is the ALERT"}

export function showAlert(data) {
  return {
    type: SHOW_ALERT,
    payload: data,
  };
}
