import React, { useState } from 'react'
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Box, Button, Slide, withStyles } from "@material-ui/core";
// import { InputField } from "@base";
import { isMobile } from 'react-device-detect';
// import { Formik } from "formik";
// import * as Yup from "yup";
import axios from 'axios';
import { useHistory, useLocation } from 'react-router-dom'
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
const useStyles = makeStyles((theme) => ({
    root: {
        fontFamily: "-apple-system, BlinkMacSystemFont, cerebri-regular",
        fontSize: "18px",
        "& > *": { color: "white" },
        "&:before": {
            borderBottom: ".5px solid #808080",
        },
        "&:hover": {
            "&:not(.Mui-disabled)": {
                "&:before": {
                    borderBottom: ".5px solid #808080 !important",
                },
            },
        },
    },
    underline: {
        "&:hover": {
            "&:not(.Mui-disabled)": {
                "&:before": {
                    borderBottom: ".5px solid #808080 !important",
                },
            },
        },
    },
    inputBase: {
        paddingLeft: "8.5px",
        paddingRight: "8.5px",
        paddingTop: "13px",
        height: "16px",
    },
    label: {
        fontSize: "50px",
    },
    bottomBtnContainer: {
        // marginBottom: 10,
        width: "100%",
        padding: "20px"
    },
    loginContainer: {
        margin: "15px 20px 0",
        padding: "10px",
        width: '93%',
        [theme.breakpoints.only("sm")]: {
            margin: isMobile ? "15px 20px 260px" : "15px 20px 0"
        },
        [theme.breakpoints.only("xs")]: {
            margin: isMobile ? "15px 20px 260px" : "15px 20px 0"
        },
    },
    passwordInput: {
        width: '100%',
        border: 'none',
        height: '40px',
        borderBottom: '1px solid grey',
        background: 'transparent',
        color: 'white',
        fontSize: '18px',
        margin: '6% 0%',
        fontFamily: "-apple-system, BlinkMacSystemFont, cerebri-regular",
        '&:hover': {
            borderTop: 'none',
            borderRight: 'none',
            borderLeft: 'none',
            outline: 'none',
        },
        '&:focus': {
            borderTop: 'none',
            borderRight: 'none',
            borderLeft: 'none',
            outline: 'none',
        },
        "&:before": {
            borderTop: 'none',
            borderRight: 'none',
            borderLeft: 'none',
            outline: 'none',
        },
    },
    backdrop: {
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '91vh',
        [theme.breakpoints.down('xs')]: {
            height: '100vh',
        },
    },
    confirmWrapper: {
        backgroundColor: '#111111',
        padding: '2rem',
        position: 'absolute',
        zIndex: 1000,
        bottom: 0,
        left: 0,
        width: '100%',
        marginBottom: isMobile ? "30%" : 0
    },
    cancelHeading: {
        color: '#31A7DB',
        fontWeight: 500,
        marginBottom: '1rem',
        fontSize: 16,
        fontFamily: 'Cerebri-SemiBold'
    },
    cancelConfirmText: {
        color: '#808080',
        marginBottom: '2rem',
    },
}));
const PopupBtn = withStyles({
    root: {
        height: 42,
        '& .MuiButton-label': {
            fontSize: 18,
        },
    },
})(Button)
const ResetPassword = () => {
    const classes = useStyles();
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordInvalid, setPasswordInvalid] = useState(true)
    const [passwordResetPopup, setPasswordResetPopup] = useState(false)
    const [showPassword, setShowPassword] = useState(false)
    const [tokenExpired, setTokenExpired] = useState(false);
    // const [error, setError] = useState("");
    const [errPass, setErrPass] = useState("");
    const [errConPass, setErrConPass] = useState("");
    const history = useHistory();
    const search = useLocation();
    const urlParams = new URLSearchParams(search.search);
    const handlePopup = () => {
        setPasswordResetPopup(false)
        history.push('/')
    }
    const token = urlParams.get('token');
    const handleSubmit = (e) => {
        e.preventDefault();
        const data = {
            password: password
        }
        axios.post(`${process.env.REACT_APP_ENV === "prod" ? process.env.REACT_APP_BASE_URL_PROD : process.env.REACT_APP_BASE_URL_TESTING}users/setpassword`,
            data,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `JWT ${token}`,
                },
            })
        // history.push("/login")
        setPasswordResetPopup(true)
    }
    const handlePassword = (e) => {
        setPassword(e.target.value)
    }
    const handleConfirmPassword = (e) => {
        setConfirmPassword(e.target.value)
    }
    // var pattern = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d][!@#\$%\^&\*]?{8,}$/);
    var pattern = new RegExp(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[\w~@#$%^&*+=`|{}:;!.?\"()\[\]-]{8,}$/);
    React.useEffect(() => {
        if (password.length > 7) {
            if (password.length > 15) {
                setErrPass("*Should be or less than 15 characters")
            }
            else if (pattern.test(password)) {
                if (password === confirmPassword) {
                    setPasswordInvalid(false)
                    setErrPass("")
                }
                else {
                    setPasswordInvalid(true)
                    // setErrPass("*Passwords did not match. Please try again.")
                    setErrPass("")
                }
            }
            else {
                setErrPass("*Must contain Uppercase, Lowercase  and Number")
                setPasswordInvalid(true)
            }
        }
        else {
            setErrPass("*Minimum 8 characters.")
            setPasswordInvalid(true)
        }
        if (confirmPassword.length > 7) {
            if (confirmPassword.length > 15) {
                setErrConPass("*Should be or less than 15 characters")
            }
            else if (pattern.test(confirmPassword)) {
                if (confirmPassword === password) {
                    setPasswordInvalid(false)
                    setErrConPass("")
                }
                else {
                    setPasswordInvalid(true)
                    setErrConPass("*Passwords did not match. Please try again.")
                }
            }
            else {
                setErrConPass("*Must contain Uppercase, Lowercase  and Number")
                setPasswordInvalid(true)
            }
        }
        else {
            setErrConPass("*Minimum 8 characters.")
            setPasswordInvalid(true)
        }
    }, [password, confirmPassword])
    function getPayload(jwt) {
        return atob(jwt.split(".")[1])
    }
    const payload = getPayload(token);
    const expiration = new Date(JSON.parse(payload).exp);
    const now = new Date();
    const fiveMinutes = 1000 * 60 * 5;
    const diff = now - expiration
    React.useEffect(() => {
        console.log(expiration)
        // console.log(now.toLocaleTimeString())
        // console.log(now)
        // if (expiration.getTime() - now.getTime() < fiveMinutes) {
        //     console.log("JWT has expired or will expire soon");
        // }
        // else {
        //     console.log("JWT is valid for more than 5 minutes", payload);
        // }
    }, [payload])
    return (
        <>
            <form onSubmit={handleSubmit} style={{ height: "100%" }}>
                <Box
                    display="flex"
                    flexDirection="column"
                    height="100%"
                    width="100%"
                    position="absolute"
                    justifyContent={isMobile ? "" : "space-between"}
                >
                    <Box
                        margin="10px 10px 0px 10px"
                        display="flex"
                        alignItems="center"
                        paddingTop={isMobile ? "1%" : "8%"}
                        flexDirection="column"
                        style = {{ height: '75vh '}}
                    >
                        {!tokenExpired ?
                            <>
                                <div style={{ marginLeft: isMobile ? "-30px" : "10px" }}>
                                    <Typography variant="h1">Create New Password?</Typography>
                                </div>
                                <Typography style={{ fontFamily: "Cerebri-regular", padding: "35px 20px 0px 20px" }} margin="20px 0 0 0" variant="caption" >
                                    (Enter a new password. Password must be different than a previously used password.)
                                </Typography>
                                <Box className={classes.loginContainer} position="relative">
                                    {/* <InputField
                            label="Email"
                            name="email"
                            // onChange={handleChange}
                        /> */}
                                    <label style={{
                                        color: '#808080',
                                        fontSize: '14px',
                                        fontFamily: 'cerebri-light',
                                        fontWeight: '500',
                                        lineHeight: '1.57',
                                        position: 'absolute',
                                        marginTop: '4px',
                                    }}>Password</label>
                                    <input
                                        onChange={handlePassword}
                                        className={classes.passwordInput}
                                        type={showPassword ? "text" : "password"}
                                        name="password"
                                    />
                                    <Visibility
                                        style={{
                                            color: "#fff",
                                            height: "20px",
                                            width: "20px",
                                            position: 'absolute',
                                            top: "13px",
                                            right: '15px',
                                            cursor: 'pointer',
                                            display: showPassword ? "none" : "inherit"
                                        }}
                                        onClick={() => setShowPassword(!showPassword)}
                                    />
                                    <VisibilityOff
                                        style={{
                                            color: "#fff",
                                            height: "20px",
                                            width: "20px",
                                            position: 'absolute',
                                            top: "13px",
                                            right: '15px',
                                            cursor: 'pointer',
                                            display: !showPassword ? "none" : "inherit"
                                        }}
                                        onClick={() => setShowPassword(!showPassword)}
                                    />
                                    {errPass.length > 0 &&
                                        <span
                                            style={{
          on: 'absolute',
                                                fontFamily: "-apple-system, BlinkMacSystemFont, cerebri-regular",
                                                color: 'rgb(203, 58, 58)',
                                                fontSize: '14px',
                                                left: '10px',
                                                top: '75px',
                                                display: password.length === 0 ? "none" : 'inherit',
                                                lineHeight: "1"
                                            }}>
                                            {errPass}
                                        </span>}
                                    <label style={{
                                        color: '#808080',
                                        fontSize: '14px',
                                        fontFamily: 'cerebri-light',
                                        fontWeight: '500',
                                        lineHeight: '1.57',
                                        position: 'absolute',
                                        left: '10px',
                                        whiteSpace: 'nowrap',
                                        top: '107px',
                                    }}>Confirm Password</label>
                                    <input
                                        onChange={handleConfirmPassword}
                                        className={classes.passwordInput}
                                        type={showPassword ? "text" : "password"}
                                        name="confirmpassword"
                                        style={{ marginTop: "30px" }}
                                    />
                                    <Visibility
                                        style={{
                                            color: "#fff",
                                            height: "20px",
                                            width: "20px",
                                            position: 'absolute',
                                            top: "107px",
                                            right: '15px',
                                            cursor: 'pointer',
                                            display: showPassword ? "none" : "inherit"
                                        }}
                                        onClick={() => setShowPassword(!showPassword)}
                                    />
                                    <VisibilityOff
                                        style={{
                                            color: "#fff",
                                            height: "20px",
                                            width: "20px",
                                            position: 'absolute',
                                            top: "107px",
                                            right: '15px',
                                            cursor: 'pointer',
                                            display: !showPassword ? "none" : "inherit"
                                        }}
                                        onClick={() => setShowPassword(!showPassword)}
                                    />
                                    {errConPass.length > 0 &&
                                        <span
                                            style={{
                                                position: 'absolute',
                                                fontFamily: "-apple-system, BlinkMacSystemFont, cerebri-regular",
                                                color: 'rgb(203, 58, 58)',
                                                fontSize: '14px',
                                                left: '10px',
                                                top: '161px',
                                                display: confirmPassword.length === 0 ? "none" : 'inherit',
                                                lineHeight: "1"
                                            }}>
                                            {errConPass}
                                        </span>}
                                </Box>
                            </> :
                            <Box
                                margin="60px 10px 0px 10px"
                                display="flex"
                                alignItems="center"
                                paddingTop={isMobile ? "1%" : "8%"}
                                flexDirection="column"
                            >
                                <div style={{ marginLeft: "10px" }}>
                                    <Typography variant="h1">Token has expired.</Typography>
                                </div>
                            </Box>
                        }
                    </Box>
                    {!tokenExpired ?
                        <Box className={classes.bottomBtnContainer}>
                            <Button
                                fullWidth
                                type="submit"
                                disabled={passwordInvalid || password.length > 15 || confirmPassword.length > 15}
                            >
                                Create
                            </Button>
                        </Box> : null}
                </Box>
            </form >
            <Slide direction='up' in={passwordResetPopup}>
                <div className={classes.backdrop} style={{ display: passwordResetPopup ? 'inherit' : 'none' }}>
                    <div className={classes.confirmWrapper}>
                        <Typography variant='subtitle1' className={classes.cancelHeading}>Password Updated Successfully</Typography>
                        <Typography variant='subtitle2' className={classes.cancelConfirmText}>Your password has been updated successfully.</Typography>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <PopupBtn
                                onClick={handlePopup}
                                fullWidth
                            >
                                Ok
                            </PopupBtn>
                        </div>
                    </div>
                </div>
            </Slide>
        </>
    );
}
export default ResetPassword
