import axios from "axios";
import * as BaseActions from "store/actions";
import firebase from "@firebase";
import { isIOS } from "react-device-detect";
import SocketClass from "@utils/socketService";
import { initFirebase } from "@utils";

export const LOGIN_SUBMIT = "LOGIN_SUBMIT";
export const LOGIN_SUBMIT_IS_LOADING = "LOGIN_SUBMIT_IS_LOADING";
export const LOGIN_SUBMIT_FAILED = "LOGIN_SUBMIT_FAILED";

// Helper ACTIONS:
export const loginSubmitLoading = (ms) => ({
  type: LOGIN_SUBMIT_IS_LOADING,
  payload: ms,
});

export const loginSubmitFailed = (ms) => ({
  type: LOGIN_SUBMIT_FAILED,
  payload: ms,
});

export async function loginSubmit(data) {
  // let dd = {
  //   email: "mubasharrasheed86@gmail.com",
  //   password: "abc123",
  // };
  try {
    if (isIOS) {
      // const messaging = firebase.messaging();
      // alert(messaging)
      const request = axios.post(`${process.env.REACT_APP_ENV === "prod" ? process.env.REACT_APP_BASE_URL_PROD : process.env.REACT_APP_BASE_URL_TESTING}users/login`, { ...data });
      return (dispatch) => {
        dispatch(loginSubmitLoading(true));

        request
          .then((response) => {
            if (response.data && response.data.status === "Success") {
              dispatch({
                type: LOGIN_SUBMIT,
                payload: response.data,
              });
              localStorage.setItem("quarterlyUser", JSON.stringify(response.data));
              localStorage.setItem("JWTtoken", response.data.token);
              initFirebase(dispatch);
              console.log({ firebase: 'Initiallized' })
              SocketClass.init(response.data.token, dispatch);
              dispatch(BaseActions.updateUserData(response.data.data));
            } else {
              let err = Error();
              err.message = response.data.message;
              throw err;
            }
          })
          .catch((error) => {

            if (error.response) {
              if (error.response.status === "404") {
                dispatch(
                  loginSubmitFailed(`${error.response.status} : api not found`)
                );
              } else {
                dispatch(loginSubmitFailed(error.response.status));
              }
              console.log(error.response);
            } else if (error.request) {
              dispatch(loginSubmitFailed(error.request));

              console.log(error.request);
            } else {
              console.log("Error", error.message);
              dispatch(loginSubmitFailed(error.message));
            }
            console.log(error);
          });
      };
    } else {
      const messaging = firebase.messaging();
      const token = await messaging
        .requestPermission()
        .then(() => messaging.getToken())
        .catch((err) => console.log({ err }))
      // }

      // if (token) {
      const request = axios.post(`${process.env.REACT_APP_ENV === "prod" ? process.env.REACT_APP_BASE_URL_PROD : process.env.REACT_APP_BASE_URL_TESTING}users/login`, { ...data, fcm_tokens: token });
      return (dispatch) => {
        dispatch(loginSubmitLoading(true));

        request
          .then((response) => {
            if (response.data && response.data.status === "Success") {
              dispatch({
                type: LOGIN_SUBMIT,
                payload: response.data,
              });
              console.log({ res: response.data })
              localStorage.setItem("quarterlyUser", JSON.stringify(response.data));
              localStorage.setItem("JWTtoken", response.data.token);
              SocketClass.init(response.data.token, dispatch);
              dispatch(BaseActions.updateUserData(response.data.data));
              initFirebase(dispatch);
              console.log({ firebase: 'Initiallized' })
            } else {
              let err = Error();
              err.message = response.data.message;
              throw err;
            }
          })
          .catch((error) => {

            // dispatch(
            //   BaseActions.showAlert({ variant: "error", message: error.message })
            // );
            if (error.response) {
              if (error.response.status === "404") {
                dispatch(
                  loginSubmitFailed(`${error.response.status} : api not found`)
                );
              } else {
                dispatch(loginSubmitFailed(error.response.status));
              }
              console.log(error.response);
            } else if (error.request) {
              dispatch(loginSubmitFailed(error.request));

              console.log(error.request);
            } else {
              console.log("Error", error.message);
              dispatch(loginSubmitFailed(error.message));
            }
            console.log(error);
          });
      };
      // }
    }
  } catch (error) {
    console.log({ error })
  }
}
