import React, { useState } from 'react'
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Box, Button, Slide, withStyles } from "@material-ui/core";
// import { InputField } from "@base";
import { useHistory } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import axios from 'axios';
// import { Formik } from "formik";
// import * as Yup from "yup";
const useStyles = makeStyles((theme) => ({
    root: {
        fontFamily: "-apple-system, BlinkMacSystemFont, cerebri-regular",
        fontSize: "18px",
        "& > *": { color: "white" },
        "&:before": {
            borderBottom: ".5px solid #808080",
        },
        "&:hover": {
            "&:not(.Mui-disabled)": {
                "&:before": {
                    borderBottom: ".5px solid #808080 !important",
                },
            },
        },
    },
    underline: {
        "&:hover": {
            "&:not(.Mui-disabled)": {
                "&:before": {
                    borderBottom: ".5px solid #808080 !important",
                },
            },
        },
    },
    inputBase: {
        paddingLeft: "8.5px",
        paddingRight: "8.5px",
        paddingTop: "13px",
        height: "16px",
    },
    label: {
        fontSize: "50px",
    },
    bottomBtnContainer: {
        // marginBottom: 10,
        width: "100%",
        padding: "20px"
    },
    loginContainer: {
        margin: "80px 20px 0",
        padding: "10px",
        width: '93%',
        [theme.breakpoints.only("sm")]: {
            margin: isMobile ? "76px 20px 200px" : "66px 20px 0"
        },
        [theme.breakpoints.only("xs")]: {
            margin: isMobile ? "76px 20px 200px" : "66px 20px 0"
        },
    },
    backdrop: {
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '91vh',
        [theme.breakpoints.down('xs')]: {
            height: '100vh',
        },
    },
    confirmWrapper: {
        backgroundColor: '#111111',
        padding: '2rem',
        position: 'absolute',
        zIndex: 1000,
        bottom: 0,
        left: 0,
        width: '100%',
    },
    cancelHeading: {
        color: '#31A7DB',
        fontWeight: 400,
        marginBottom: '1rem',
        fontSize: 18,
        fontFamily: 'Cerebri-SemiBold',
    },
    cancelConfirmText: {
        color: '#808080',
        marginBottom: '2rem',
        fontWeight: 400,
        fontSize: '16px'
    },
    emailInput: {
        width: '100%',
        border: 'none',
        height: '40px',
        borderBottom: '1px solid grey',
        background: 'transparent',
        color: 'white',
        fontSize: '18px',
        textTransform: 'lowercase',
        fontFamily: "-apple-system, BlinkMacSystemFont, cerebri-regular",
        borderRadius: "0px",
        borderTop: 'none',
        '&:hover': {
            borderTop: 'none',
            borderRight: 'none',
            borderLeft: 'none',
            outline: 'none',
        },
        '&:focus': {
            borderTop: 'none',
            borderRight: 'none',
            borderLeft: 'none',
            outline: 'none',
        },
        "&:before": {
            borderTop: 'none',
            borderRight: 'none',
            borderLeft: 'none',
            outline: 'none',
        },
    }
}));
const PopupBtn = withStyles({
    root: {
        height: 42,
        '& .MuiButton-label': {
            fontSize: 18,
        },
    },
})(Button)
const ForgotPassword = () => {
    const classes = useStyles();
    const [email, setEmail] = useState('');
    const [passwordResetPopup, setPasswordResetPopup] = useState(false)
    const [invalidEmail, setInvalidEmail] = useState(true)
    const history = useHistory();
    const handleSubmit = (e) => {
        e.preventDefault();
        const data = {
            email: email
        }
        axios.post(`${process.env.REACT_APP_ENV === "prod" ? process.env.REACT_APP_BASE_URL_PROD : process.env.REACT_APP_BASE_URL_TESTING}users/forgotpassword`, data)
        setPasswordResetPopup(true)
    }
    const handleChange = (e) => {
        // var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        var pattern = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
        if (pattern.test(e.target.value)) {
            setInvalidEmail(false)
            setEmail(e.target.value)
        }
        else {
            setInvalidEmail(true)
            return;
        }
    }
    const handlePopup = () => {
        setPasswordResetPopup(false)
        history.push('/login')
    }
    return (
        <>
            <form onSubmit={handleSubmit} style={{ height: "100%" }}>
                <Box
                    display="flex"
                    flexDirection="column"
                    height="100%"
                    width="100%"
                    position="absolute"
                    justifyContent={isMobile ? "" : "space-between"}
                >
                    <Box
                        margin="10px 10px 0px 10px"
                        display="flex"
                        alignItems="center"
                        paddingTop={isMobile ? "1%" : "8%"}
                        flexDirection="column"
                    >
                        <div style={{ marginLeft: isMobile ? "-30px" : "10px" }}>
                            <Typography variant="h1">Forgot Your Password?</Typography>
                        </div>
                        <Typography style={{ fontFamily: "Cerebri-regular", padding: "35px 20px 0px 20px" }} margin="20px 0 0 0" variant="caption" >
                            Enter the email address you used to register and we'll send you password reset instructions
                        </Typography>
                        <Box className={classes.loginContainer}>
                            <label style={{
                                color: '#808080',
                                fontSize: '14px',
                                fontFamily: 'cerebri-light',
                                fontWeight: '500',
                                lineHeight: '1.57',
                            }}>Email</label>
                            <input
                                type="email"
                                onChange={handleChange}
                                className={classes.emailInput}
                            />
                        </Box>
                    </Box>
                    <Box className={classes.bottomBtnContainer}>
                        <Button
                            fullWidth
                            type="submit"
                            disabled={invalidEmail}
                        >
                            Send
                        </Button>
                    </Box>
                </Box>
            </form>
            <Slide direction='up' in={passwordResetPopup}>
                <div className={classes.backdrop} style={{ display: passwordResetPopup ? 'inherit' : 'none' }}>
                    <div className={classes.confirmWrapper}>
                        <Typography variant='subtitle1' className={classes.cancelHeading}>Reset password email sent</Typography>
                        <Typography variant='subtitle2' className={classes.cancelConfirmText}>An email has been sent to you with instructions on how to reset your password.</Typography>
                        <div style={{ display: 'flex', justifyContent: 'space-between', paddingBottom: isMobile ? "120px" : "20px" }}>
                            <PopupBtn
                                onClick={handlePopup}
                                fullWidth
                                style={{ height: '50px' }}
                            >
                                Ok
                            </PopupBtn>
                        </div>
                    </div>
                </div>
            </Slide>
        </>
    );
}
export default ForgotPassword
