import React from "react";
import { Typography, makeStyles } from "@material-ui/core";
//// functions need to use react with our component
import { useDispatch, useSelector } from "react-redux";
// import withReducer from "store/withReducer";
import * as BaseActions from "store/actions";
import * as Actions from './store/actions'
// import reducers from "components/signup/screens/signupForm/store/reducers";
import "react-multi-carousel/lib/styles.css";
import { Link } from 'react-router-dom';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import InviteList from "./inviteList";
const useStyles = makeStyles((theme) => ({
  headerWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 17,
    padding: 17,
    [theme.breakpoints.down("sm")]:{
      marginTop: 0,
    }
  },
  headerText: {
    color: '#31A7DB',
    fontSize: '1.9rem',
    fontFamily: 'Cerebri-Bold'
  },
  leftSideMenu: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginRight: 'auto',
  },
  iconLink: {
    marginTop: '0.5rem',
  }
}))
function History() {
  const dispatch = useDispatch();
  const invites = useSelector(data => data.BaseReducer.InviteReducer.data)
  // console.log(invites, '<<LAHORE')
  const classes = useStyles();
  React.useEffect(() => {
    dispatch(BaseActions.updateLayout({ bottomNav: false }));
  }, [])
  React.useEffect(() => {
    dispatch(Actions.fetchInvites());
  }, [dispatch]);
  return (
    <>
      <div className={classes.headerWrapper}>
        <div className={classes.leftSideMenu}>
          <Link to='/invite' className={classes.iconLink}>
            <ArrowBackIosIcon color='secondary' />
          </Link>
          <Typography variant='h5' className={classes.headerText}>Invites History</Typography>
        </div>
      </div>
      <div>
        <InviteList list={invites?.data ? invites.data : []} />
      </div>
    </>
  );
}
export default History