import axios from "axios";
import * as BaseActions from "store/actions";
import { logout, initFirebase } from "@utils";
import firebase from "@firebase";
import { isIOS } from "react-device-detect";

import SocketClass from "@utils/socketService";
//SUBMIT LOGIN ACTION NAMES
export const SIGNUP_SUBMIT = "SIGNUP_SUBMIT";
export const SIGNUP_SUBMIT_IS_LOADING = "SIGNUP_SUBMIT_IS_LOADING";
export const SIGNUP_SUBMIT_FAILED = "SIGNUP_SUBMIT_FAILED";
//UPDATE PROFILE ACTION NAMES
export const UPDATE_PROFILE = "SIGNUP_SUBMIT";
export const UPDATE_PROFILE_IS_LOADING = "UPDATE_PROFILE_IS_LOADING";
export const UPDATE_PROFILE_FAILED = "UPDATE_PROFILE_FAILED";
export const RESET_PROFILE_REDUCER = "RESET_PROFILE_REDUCER";
//set availability ACTION NAMES
export const SET_AVAILABILITY = "SET_AVAILABILITY";
export const AVAILABILITY_IS_LOADING = "AVAILABILITY_IS_LOADING";
export const AVAILABILITY_FAILED = "AVAILABILITY_FAILED";
export const RESET_AVAILABILITY_REDUCER = "RESET_AVAILABILITY_REDUCER";
//GET USER BY REFERRAL CODE ACTION NAMES
export const GET_USER_BY_REFERRAL_CODE = "GET_USER_BY_REFERRAL_CODE";
export const USER_BY_REFERRAL_CODE_IS_LOADING =
  "USER_BY_REFERRAL_CODE_IS_LOADING";
export const USER_BY_REFERRAL_CODE_FAILED = "USER_BY_REFERRAL_CODE_FAILED";

// Helper ACTIONS:
export const signupSubmitLoading = (ms) => ({
  type: SIGNUP_SUBMIT_IS_LOADING,
  payload: ms,
});

export const userByReferralCodeFailed = (ms) => ({
  type: USER_BY_REFERRAL_CODE_FAILED,
  payload: ms,
});
// Helper ACTIONS:
export const userByReferralCodeLoading = (ms) => ({
  type: USER_BY_REFERRAL_CODE_IS_LOADING,
  payload: ms,
});

export const signupSubmitFailed = (ms) => ({
  type: SIGNUP_SUBMIT_FAILED,
  payload: ms,
});

// Helper ACTIONS:
export const updateProfileLoading = (ms) => ({
  type: UPDATE_PROFILE_IS_LOADING,
  payload: ms,
});

export const updateProfileFailed = (ms) => ({
  type: UPDATE_PROFILE_FAILED,
  payload: ms,
});

export const resetProfileReducer = () => ({
  type: RESET_PROFILE_REDUCER,
});

// Helper ACTIONS:
export const availabilityLoading = (ms) => ({
  type: AVAILABILITY_IS_LOADING,
  payload: ms,
});

export const availabilityFailed = (ms) => ({
  type: AVAILABILITY_FAILED,
  payload: ms,
});

export const resetAvailabilityReducer = () => ({
  type: RESET_AVAILABILITY_REDUCER,
});

export async function signupSubmit(data) {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  if (isIOS) {

    const request = axios.post(`${process.env.REACT_APP_ENV === "prod" ? process.env.REACT_APP_BASE_URL_PROD : process.env.REACT_APP_BASE_URL_TESTING}users/signup`, data);
    return (dispatch) => {
      dispatch(signupSubmitLoading(true));

      request
        .then((response) => {
          if (response.data && response.data.status === "Success") {
            dispatch({
              type: SIGNUP_SUBMIT,
              payload: response.data,
            });
            // logout();
            localStorage.setItem("quarterlyUser", JSON.stringify(response.data));
            localStorage.setItem("JWTtoken", response.data.token);
            dispatch(BaseActions.updateUserData(response.data.data));
            SocketClass.init(response.data.token, dispatch);
            initFirebase(dispatch)
            // dispatch(
            //   BaseActions.showAlert({
            //     message: response.data.message,
            //     variant: "success",
            //   })
            // );
          } else {
            let err = new Error();
            err.message = response.data.message;
            throw err;
          }
        })
        .catch((error) => {
          // dispatch(
          //   BaseActions.showAlert({ variant: "error", message: error.message })
          // );
          if (error.response) {
            if (error.response.status === "404") {
              dispatch(
                signupSubmitFailed(`${error.response.status} : api not found`)
              );
            } else {
              dispatch(signupSubmitFailed(error.response.status));
            }
            console.log(error.response);
          } else if (error.request) {
            dispatch(signupSubmitFailed(error.request));

            console.log(error.request);
          } else {
            console.log("Error", error.message);
            dispatch(signupSubmitFailed(error.message));
          }
          console.log(error);
        });
    };
  } else {
    const messaging = firebase.messaging();
    const token = await messaging
      .requestPermission()
      .then(() => messaging.getToken())
      .catch((err) => console.log({ err }))

    // if (token) {

    const request = axios.post(`${process.env.REACT_APP_ENV === "prod" ? process.env.REACT_APP_BASE_URL_PROD : process.env.REACT_APP_BASE_URL_TESTING}users/signup`, { ...data, fcm_tokens: token });
    return (dispatch) => {
      dispatch(signupSubmitLoading(true));

      request
        .then((response) => {
          if (response.data && response.data.status === "Success") {
            dispatch({
              type: SIGNUP_SUBMIT,
              payload: response.data,
            });
            // logout();
            localStorage.setItem("quarterlyUser", JSON.stringify(response.data));
            localStorage.setItem("JWTtoken", response.data.token);
            dispatch(BaseActions.updateUserData(response.data.data));
            SocketClass.init(response.data.token, dispatch);
            initFirebase(dispatch)
            // dispatch(
            //   BaseActions.showAlert({
            //     message: response.data.message,
            //     variant: "success",
            //   })
            // );
          } else {
            let err = new Error();
            err.message = response.data.message;
            throw err;
          }
        })
        .catch((error) => {
          // dispatch(
          //   BaseActions.showAlert({ variant: "error", message: error.message })
          // );
          if (error.response) {
            if (error.response.status === "404") {
              dispatch(
                signupSubmitFailed(`${error.response.status} : api not found`)
              );
            } else {
              dispatch(signupSubmitFailed(error.response.status));
            }
            console.log(error.response);
          } else if (error.request) {
            dispatch(signupSubmitFailed(error.request));

            console.log(error.request);
          } else {
            console.log("Error", error.message);
            dispatch(signupSubmitFailed(error.message));
          }
          console.log(error);
        });
    };
    // }

  }

}

export function updateProfile(data) {
  const token = localStorage.getItem("JWTtoken");

  console.log({ token })
  const config = {
    headers: {
      Authorization: `JWT ${token}`,
      // "Content-Type": "application/json",
    },
  };

  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const request = axios.post(
    `${process.env.REACT_APP_ENV === "prod" ? process.env.REACT_APP_BASE_URL_PROD : process.env.REACT_APP_BASE_URL_TESTING}users/update-profile`,
    { ...data, timeZone },
    config
  );
  return (dispatch) => {
    dispatch(updateProfileLoading(true));
    dispatch(BaseActions.showLoading(true));

    request
      .then((response) => {
        if (response.data && response.data.status === "Success") {
          dispatch(BaseActions.hideLoading());
          dispatch({
            type: UPDATE_PROFILE,
            payload: response.data,
          });

          localStorage.removeItem("quarterlyUser");
          localStorage.setItem("quarterlyUser", JSON.stringify(response.data));

          dispatch(BaseActions.updateUserData(response.data.data));

        } else {
          let err = Error();
          err.message = response.data.message;
          throw err;
        }
      })
      .catch((error) => {
        dispatch(BaseActions.hideLoading());
        // dispatch(
        //   BaseActions.showAlert({ variant: "error", message: error.message })
        // );

        if (error.response) {
          if (error.response.status === "404") {
            dispatch(
              updateProfileFailed(`${error.response.status} : api not found`)
            );
          } else {
            dispatch(updateProfileFailed(error.response.status));
          }
          console.log(error.response);
        } else if (error.request) {
          dispatch(updateProfileFailed(error.request));

          console.log(error.request);
        } else {
          console.log("Error", error.message);
          dispatch(updateProfileFailed(error.message));
        }
        console.log(error);
      });
  };
}
export function getUserByReferralCode(code) {
  const token = localStorage.getItem("JWTtoken");

  const config = {
    headers: {
      Authorization: `JWT ${token}`,
      "Content-Type": "application/json",
    },
  };

  const request = axios.get(
    `${process.env.REACT_APP_ENV === "prod" ? process.env.REACT_APP_BASE_URL_PROD : process.env.REACT_APP_BASE_URL_TESTING}users/getprofilefromreferralcode?user_code=${code}`,

    config
  );
  return (dispatch) => {
    dispatch(BaseActions.showLoading(true));
    request
      .then((response) => {
        if (response.data && response.data.status === "Success") {
          dispatch(BaseActions.hideLoading(false));
          dispatch({
            type: GET_USER_BY_REFERRAL_CODE,
            payload: response.data,
          });


          // dispatch(
          //   BaseActions.showAlert({
          //     message: response.data.message,
          //     variant: "success",
          //   })
          // );
        } else {
          let err = new Error();
          err.message = response.data.message;
          throw err;
        }
      })
      .catch((error) => {
        dispatch(BaseActions.hideLoading(false));
        // dispatch(
        //   BaseActions.showAlert({ variant: "error", message: error.message })
        // );
        if (error.message) {
          dispatch(userByReferralCodeFailed(error.message));
          return;
        }
        if (error.response) {
          if (error.response.status === "404") {
            dispatch(
              userByReferralCodeFailed(
                `${error.response.status} : api not found`
              )
            );
          } else {
            dispatch(userByReferralCodeFailed(error.response.status));
          }
          console.log(error.response);
        } else if (error.request) {
          dispatch(userByReferralCodeFailed(error.request));

          console.log(error.request);
        } else {
          console.log("Error", error.message);
          dispatch(userByReferralCodeFailed(error.message));
        }
        console.log(error);
      });
  };
}

export function setAvailability(availabilityArray) {
  const token = localStorage.getItem("JWTtoken");
  let data = { availablility: availabilityArray };

  const config = {
    headers: {
      Authorization: `JWT ${token}`,
      // "Content-Type": "application/json",
    },
  };

  const request = axios.post(
    `${process.env.REACT_APP_ENV === "prod" ? process.env.REACT_APP_BASE_URL_PROD : process.env.REACT_APP_BASE_URL_TESTING}users/set-availability`,
    data,
    config
  );

  console.log("REQUEST-->",request);

  return (dispatch) => {
    dispatch(availabilityLoading(true));
    dispatch(BaseActions.showLoading(true));

    request
      .then((response) => {
        if (response.data && response.data.status === "Success") {
          // dispatch(BaseActions.hideLoading());
          dispatch({
            type: SET_AVAILABILITY,
            payload: response.data,
          });

          dispatch(updateProfile({}));
          console.log("Response-->",response);

          // localStorage.removeItem("quarterlyUser");
          // localStorage.setItem("quarterlyUser", JSON.stringify(response.data));

          //dispatch(BaseActions.updateUserData(response.data.data));
          dispatch(BaseActions.updateLayout({ bottomNav: true }));

          // dispatch(
          //   BaseActions.showAlert({
          //     message: response.data.message,
          //     variant: "success",
          //   })
          // );
        } else {
          let err = Error();
          err.message = response.data.message;
          console.log("ERROR ???",err);
          throw err;
        }
      })
      .catch((error) => {
        console.log("ERROR ???",error);
        dispatch(BaseActions.hideLoading());
        // dispatch(
        //   BaseActions.showAlert({ variant: "error", message: error.message })
        // );

        if (error.response) {
          if (error.response.status === "404") {
            dispatch(
              availabilityFailed(`${error.response.status} : api not found`)
            );
          } else {
            dispatch(availabilityFailed(error.response.status));
          }
          console.log(error.response);
        } else if (error.request) {
          dispatch(availabilityFailed(error.request));

          console.log(error.request);
        } else {
          console.log("Error", error.message);
          dispatch(availabilityFailed(error.message));
        }
        console.log(error);
      });
  };
}
