import React from "react";
import { Button, Box, Typography } from "@material-ui/core";
import pics from "@assets";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import { InputField } from "@base";
import useDeepCompareEffect from "use-deep-compare-effect";
import { withRouter } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import * as Actions from "./store/actions";
import * as BaseActions from "store/actions";
import reducer from "./store/reducers";
import withReducer from "store/withReducer";
import { isMobile } from "react-device-detect";
const ReportUser = (props) => {
  const dispatch = useDispatch();
  const [reason, setReason] = React.useState("");
  const [isDisabled, setIsDisabled] = React.useState(true);
  const [compoundReason, setCompoundReason] = React.useState({
    nudity: "",
    criminal: "",
    illegal: "",
    fake: "",
    other: "",
  });
  const response = useSelector(({ Report }) => Report.ReportReducer);
  const data = {
    name: props.userName,
    response: 'user-reported'
  }
  useDeepCompareEffect(() => {
    if (response.data) {
      dispatch(Actions.resetReducers());
      props.history.push("/home", data);
    }
  }, [response]);
  useDeepCompareEffect(() => {
    let ifChecked = Object.values(compoundReason).filter(
      (x) => x.length !== 0 && String(x).toLowerCase() !== "other"
    );
    if (ifChecked.length !== 0 || reason.length !== 0) {
      setIsDisabled(false);
      return;
    } else {
      setIsDisabled(true);
    }
  }, [compoundReason, reason]);
  const handleChange = (name) => (event) => {
    setReason(event.target.value);
  };
  const handleUpdate = (name, value) => {
    if (name === "other" && value === "") {
      setReason("");
    }
    setCompoundReason({ ...compoundReason, [name]: value });
  };
  const handleReport = () => {
    let reasonString = "";
    Object.values(compoundReason).forEach((reason) => {
      if (reason.length === 0) {
        return;
      }
      if (String(reason).toLowerCase() === "other") {
        return;
      }
      if (reasonString.trim().length !== 0) {
        reasonString = `${reasonString}, ${reason}`;
      } else {
        reasonString = reason;
      }
    });
    if (reason.length !== 0) {
      if (reasonString.length === 0) {
        reasonString = `${reason}`;
      } else {
        reasonString = `${reasonString} & ${reason}`;
      }
      // return
    }
    dispatch(Actions.reportUser(props.userId, reasonString));
  };
  const handleClose = (id) => {
    dispatch(BaseActions.hideMessage());
  };
  return (
    <Box padding="30px" paddingBottom="60px">
      <Typography variant="body2">Report User</Typography>
      <Box marginTop={isMobile ? "20px" : "40px"}>
        <ReportItem
          handleUpdate={handleUpdate}
          value={compoundReason.nudity}
          name="nudity"
          title="Nudity"
        />
        <ReportItem
          handleUpdate={handleUpdate}
          value={compoundReason.criminal}
          name="criminal"
          title="Criminal behavior"
        />
        <ReportItem
          handleUpdate={handleUpdate}
          value={compoundReason.illegal}
          name="illegal"
          title="Illegal activity"
        />
        <ReportItem
          handleUpdate={handleUpdate}
          value={compoundReason.fake}
          name="fake"
          title="Fake account"
        />
        <ReportItem
          handleUpdate={handleUpdate}
          value={compoundReason.other}
          name="other"
          title="Other"
        />
      </Box>
      <Box
        style={{
          // visibility: compoundReason.other.length !== 0 ? "visible" : "hidden",
          display: compoundReason.other.length !== 0 ? "inherit" : "none",
          margin: "20px 0px"
        }}
      >
        <InputField
          name="reason"
          placeHolder="Enter Reason Here"
          value={reason}
          onChange={handleChange}
        />
      </Box>
      <Box display="flex" justifyContent="space-between">
        <Button
          style={{ width: "48%", marginRight: "10px" }}
          color="secondary"
          variant="outlined"
          onClick={() => handleClose()}
        >
          Cancel
        </Button>
        <Button
          disabled={isDisabled}
          style={{ width: "45%" }}
          onClick={() => handleReport()}
        >
          Submit
        </Button>
      </Box>
    </Box>
  );
};
export default withReducer("Report", reducer)(withRouter(ReportUser));
const ReportItem = (props) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      margin={isMobile ? "20px 0" : "30px 0"}
      style={{ cursor: "pointer" }}
      onClick={() => {
        if (props.value.length) {
          props.handleUpdate(props.name, "");
        } else {
          props.handleUpdate(props.name, props.title);
        }
      }}
    >
      <Box marginRight="20px" marginTop="5px">
        {props.value.length === 0 ? (
          // <CheckBoxOutlineBlankIcon size="small" style={{ color: "#808080" }} />
          <img
            style={{ height: "20px", width: "20px" }}
            src={pics.blankCheckBox}
            alt="uncheck"
          />
        ) : (
          <img
            style={{ height: "20px", width: "20px" }}
            src={pics.checkBox}
            alt="check"
          />
          // <CheckBoxIcon size="small" color="primary" />
        )}
      </Box>
      <Typography style={{ fontSize: "16px" }} variant="body1">
        {props.title}
      </Typography>
    </Box>
  );
};
