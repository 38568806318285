import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import pics from "@assets";
import { Box, Typography } from "@material-ui/core";
import { withRouter } from "react-router";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { useDispatch, useSelector } from "react-redux";
import * as BaseActions from "store/actions";
import axios from 'axios';
import Spinner from './Spinner';
const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    color: 'white',
    padding: "30px 20px",
    [theme.breakpoints.down("sm")]: {
      padding: "15px 20px",
    }
  },
  headingCon: {
    display: "flex",
    alignItems: "center",
    "& .arrow": {
      marginRight: "10px",
      height: 25,
      width: 11,
      marginBottom: "3px",
    },
  },
  container: {
    padding: '20px 0px'
  },
  question: {
    color: '#a0a0a0',
    padding: '10px',
    fontSize: '16px'
  },
  answer: {
    padding: '10px',
    fontSize: '14px'
  },
}))
const Questions = (props) => {
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(BaseActions.updateLayout({ bottomNav: false }));
  }, []);
  const [Title, setTitle] = React.useState('');
  const [Questions, setQuestions] = React.useState([]);
  const [loading, isLoading] = React.useState(false)
  React.useEffect(() => {
    dispatch(BaseActions.showLoading(true))
    const { location: { search } } = props;
    const urlParams = new URLSearchParams(search);
    const title = urlParams.get('title');
    setTitle(title)
    let results;
    axios.get(`${process.env.REACT_APP_ENV === "prod" ? process.env.REACT_APP_BASE_URL_PROD : process.env.REACT_APP_BASE_URL_TESTING}faqs/list-faq`)
      .then((response) => {
        // console.log(response.data.data)
        results = response.data.data
        setQuestions(results)
      })
  }, []);
  React.useEffect(() => {
    if (Questions.length > 0) {
      dispatch(BaseActions.hideLoading());
    }
  }, [Questions])
  // console.log(Questions)
  // console.log(Title)
  const classes = useStyles();
  return (
    <div>
      {Questions.map((question) => (
        <div>
          {question.type === Title ?
            <Box className={classes.root}>
              <div className={classes.headingCon}>
                <ArrowBackIosIcon className="arrow" alt="img" style={{ cursor: 'pointer', width: '30px' }} onClick={() => props.history.push('/help')} />
                <Typography variant="h1" style={{ marginLeft: "-15px", textTransform: 'capitalize' }}>{question.type} Questions</Typography>
              </div>
              <div>
                <Box className={classes.container}>
                  {question.faqs.map((faq) => (
                    <div>
                      <Typography className={classes.question} variant="subtitle2">{faq.question}</Typography>
                      <Typography className={classes.answer} variant="subtitle2">{faq.answer}</Typography>
                      <br />
                    </div>
                  ))}
                  {/* <Typography className={classes.question} variant="subtitle2">When is my payment method charged for booking a Quartr?</Typography>
              <Typography className={classes.answer} variant="subtitle2">Your payment method is charged immediately; however, the host does not receive your payment until the Quartr has been successfully completed.</Typography> */}
                </Box>
              </div>
            </Box> : null}
        </div>
      ))}
    </div>
  );
};
export default withRouter(Questions)