import { createMuiTheme } from "@material-ui/core/styles";

import { cerebriRegular } from "./fontsConfig/CerebriRegular";
import { cerebriLight } from "./fontsConfig/CerebriLight";
import { cerebriSemiBold } from "./fontsConfig/CerebriSemiBold";
import { cerebriBold } from "./fontsConfig/CerebriBold";

// color config
import { ColorConfig } from "./colorConfig";
// button config
import { MuiButtonConfig } from "./buttonConfig";
// typography config
import { MuiTypographyConfig } from "./typographyConfig";

let { primaryColor, secondaryColor } = ColorConfig;
const theme = createMuiTheme({
  palette: {
    primary: {
      light: primaryColor,
      main: primaryColor,
      dark: primaryColor,
    },
    secondary: {
      main: secondaryColor,
    },
  },
  typography: {
    fontFamily: [
      "Cerabri-Regular",
      "Cerabri-Light",
      "Cerabri-SemiBold",
      "Cerabri-Bold",
    ].join(","),
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "@font-face": [
          cerebriRegular,
          cerebriLight,
          cerebriSemiBold,
          cerebriBold,
        ],
      },
    },
    MuiButton: { ...MuiButtonConfig.styles },
    MuiTypography: { ...MuiTypographyConfig },
  },
  // props
  props: {
    // mui button props
    MuiButtonBase: { ...MuiButtonConfig.props.MuiButtonBase },
    MuiButton: { ...MuiButtonConfig.props.MuiButton },
  },
});
export default theme;
