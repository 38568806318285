import React from "react"
import { Box, Typography, Button } from "@material-ui/core"
import moment from "moment"
// import GoogleCalender from "../googleCalender"
import { isIOS } from "react-device-detect";
const BookingConfirmation = ({
  handleClose,
  handleConfirmation,
  quarter,
  ...props
}) => {
  return (
    <>
      <Box padding="30px">
        <Box>
          <Typography variant="body2">Confirm Booking Quartr</Typography>
        </Box>
        <Box marginTop="25px">
          <Typography variant="caption">
            You are scheduling a Quartr video call with <br />
            <Typography
              component="span"
              variant="h1"
              style={{ fontSize: "16px", color: "white" }}
            >
              {quarter.usernameEnabled ? quarter.username : quarter.name || "Quartrly Calls"} {" "}
            </Typography>
            on{" "}
            <Typography
              component="span"
              variant="h1"
              style={{ fontSize: "16px", color: "white" }}
            >
              {moment(quarter.call_at).format("dddd, MMMM DD")} @{" "}
              {moment(quarter.call_at).format("h:mm A")}{" "}
            </Typography>
            PDT. Your account will be charged{" "}
            <Typography
              component="span"
              variant="h1"
              style={{ fontSize: "16px", color: "white" }}
            >
              {quarter.rate > 0
                ? `$${Number(quarter.rate).toFixed(2)}`
                : `$0.00`}{" "}
            </Typography>
            . Do you wish to proceed?
          </Typography>
        </Box>
        {/* <Box>
          <GoogleCalender />
        </Box> */}
        <Box
          marginTop="40px"
          paddingBottom="10px"
          display="flex"
          justifyContent="space-between"
        >
          <Button
            onClick={() => handleClose()}
            variant="outlined"
            color="secondary"
            style={{ width: "46%" }}
          >
            No
          </Button>
          <Button
            onClick={() => handleConfirmation(quarter)}
            style={{ width: "46%" }}
          >
            Yes
          </Button>
        </Box>
      </Box>
    </>
  );
};
export default BookingConfirmation;
