import * as BaseActions from "store/actions";
export const HIDE_MESSAGE = "[MESSAGE] CLOSE";
export const SHOW_MESSAGE = "[MESSAGE] SHOW";

export function hideMessage() {
  return (dispatch) => {

    dispatch({
      type: HIDE_MESSAGE,
    });

    // dispatch(BaseActions.updateLayout({ bottomNav: true }));
  };
}

//  {message : "what is the message"}

export function showMessage(data, drawer) {
  return (dispatch) => {
    // dispatch(BaseActions.updateLayout({ bottomNav: false }));

    dispatch({
      type: SHOW_MESSAGE,
      data: data,
      isDrawer: drawer ? true : false,
    });
  };
}
