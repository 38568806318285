import * as Actions from "../../actions";

const initialState = {
  user: null,
};
export const UserReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.SET_USER:
      return { ...state, user: action.payload };
    case Actions.ADD_USER_MEDIA:
      let updatedUser = {
        ...state,
        user: { ...state.user, media: [...state.user.media, action.payload] },
      };
      localStorage.setItem("user", JSON.stringify(updatedUser.user));
      return updatedUser;
    default:
      return state;
  }
};
