import * as Actions from "../../actions";
const initialState = {
  state: false,
  data: { message: "hi", variant: "success", initial: true },
};

function getLocaleDate(iso) {
  var date = new Date(iso);

  var options = { month: "short", day: "numeric" };

  return date.toLocaleDateString("en-US", options);
}

export const NotificationReducer = function (state = initialState, action) {
  switch (action.type) {

    case Actions.SHOW_NOTIFICATION: {
      let obj = { state: true, data: {} }
      if (action.payload.type === "appointment") {
        let parsedData = JSON.parse(action.payload.data);
        obj.data.title = `${parsedData.attendee?.enabledUsername ? parsedData.attendee?.username : parsedData.attendee?.full_name
          } booked a $${parsedData.receipt_id?.hostRate > 0 ? `${parsedData.receipt_id.hostRate}` : "FREE"} Quartr for ${getLocaleDate(
            parsedData.call_at
          )} @ ${new Date(parsedData.call_at).toLocaleTimeString(navigator.language, {
            hour: "2-digit",
            minute: "2-digit",
          })}`
        obj.data.body = `${getLocaleDate(
          new Date().toISOString()
        )} at ${new Date().toLocaleTimeString(navigator.language, {
          hour: "2-digit",
          minute: "2-digit",
        })}`
        obj.data.url = `/userprofile/${parsedData.attendee._id}`
        obj.data.icon = action.payload.icon
      }

      if (action.payload.type === "appointmentcancelled") {
        let parsedData = JSON.parse(action.payload.data);
        const attendeeName = parsedData.attendee?.enabledUsername ? parsedData.attendee?.username : parsedData.attendee?.full_name
        const hostName = parsedData.host?.enabledUsername ? parsedData.host?.username : parsedData.host?.full_name
        const loggedInUserId = JSON.parse(localStorage.getItem('quarterlyUser')).data._id
        const finalName = parsedData.attendee._id === loggedInUserId ? hostName : attendeeName
        obj.data.title = `${finalName} cancelled a $${parsedData.receipt_id?.hostRate > 0 ? `${parsedData.receipt_id.hostRate}` : "FREE"} Quartr for ${getLocaleDate(
          parsedData.call_at
        )} @ ${new Date(parsedData.call_at).toLocaleTimeString(navigator.language, {
          hour: "2-digit",
          minute: "2-digit",
        })}`
        obj.data.body = `${getLocaleDate(
          new Date().toISOString()
        )} at ${new Date().toLocaleTimeString(navigator.language, {
          hour: "2-digit",
          minute: "2-digit",
        })}`
        obj.data.url = `/userprofile/${parsedData.attendee._id}`
        obj.data.icon = action.payload.icon
      }

      if (action.payload.type === "reminder" || action.payload.type === "review") {
        // if (action.payload.type === "reminder" || action.payload.type === "review" || action.payload.type === "payout_success" || action.payload.type === "payout_failure") {
        // reminder
        // Get ready! 5 minutes until Quartr with Daniyal Waseem

        obj.data.title = action.payload.body;
        obj.data.body = `${getLocaleDate(
          new Date().toISOString()
        )} at ${new Date().toLocaleTimeString(navigator.language, {
          hour: "2-digit",
          minute: "2-digit",
        })}`;
        obj.data.url = {
          url: `/home`
        }
        obj.data.icon = action.payload.icon
      }
      if (action.payload.type === "referrer") {
        // if (action.payload.type === "reminder" || action.payload.type === "review" || action.payload.type === "payout_success" || action.payload.type === "payout_failure") {
        // reminder
        // Get ready! 5 minutes until Quartr with Daniyal Waseem

        obj.data.title = action.payload.body;
        obj.data.body = `${getLocaleDate(
          new Date().toISOString()
        )} at ${new Date().toLocaleTimeString(navigator.language, {
          hour: "2-digit",
          minute: "2-digit",
        })}`;
        obj.data.url = {
          url: `/home`
        }
        obj.data.icon = action.payload.icon
      }
      //  else {
      //   obj.data.title = action.payload.body;
      //   obj.data.body = `${getLocaleDate(
      //     new Date().toISOString()
      //   )} at ${new Date().toLocaleTimeString(navigator.language, {
      //     hour: "2-digit",
      //     minute: "2-digit",
      //   })}`;
      //   obj.data.url = {
      //     url: `/home`
      //   }
      //   obj.data.icon = action.payload.icon
      // }





      return obj;
    }
    case Actions.HIDE_NOTIFICATION: {
      return {
        state: false,
        data: null,
      };
    }
    default: {
      return state;
    }
  }
};
