import axios from "axios";
import * as BaseActions from "store/actions";
//block ACTION NAMES
export const BLOCK = "BLOCK";
export const BLOCK_IS_LOADING = "BLOCK_IS_LOADING";
export const BLOCK_FAILED = "BLOCK_FAILED";
//repor LOGIN ACTION NAMES
export const REPORT = "REPORT";
export const REPORT_IS_LOADING = "REPORT_IS_LOADING";
export const REPORT_FAILED = "REPORT_FAILED";
export const RESET = "RESET"
// Helper ACTIONS:
export const resetReducers = (ms) => ({
  type: RESET,
  payload: ms,
});
// Helper ACTIONS:
export const blockLoading = (ms) => ({
  type: BLOCK_IS_LOADING,
  payload: ms,
});
export const blockFailed = (ms) => ({
  type: BLOCK_FAILED,
  payload: ms,
});
// Helper ACTIONS:
export const reportLoading = (ms) => ({
  type: REPORT_IS_LOADING,
  payload: ms,
});
export const reportFailed = (ms) => ({
  type: REPORT_FAILED,
  payload: ms,
});
export function blockUser(userId) {
  const token = localStorage.getItem("JWTtoken");
  let data = { user_id: userId };
  const config = {
    headers: {
      Authorization: `JWT ${token}`,
      // "Content-Type": "application/json",
    },
  };
  const request = axios.post(
    `${process.env.REACT_APP_ENV === "prod" ? process.env.REACT_APP_BASE_URL_PROD : process.env.REACT_APP_BASE_URL_TESTING}users/block-user`,
    data,
    config
  );
  return (dispatch) => {
    dispatch(blockLoading(true));
    dispatch(BaseActions.showLoading(true));
    request
      .then((response) => {
        if (response.data && response.data.status === "Success") {
          dispatch({
            type: BLOCK,
            payload: response.data,
          });
          dispatch(resetReducers())
          dispatch(BaseActions.hideLoading());
          // dispatch(
          //   BaseActions.showAlert({
          //     message: response.data.message,
          //     variant: "success",
          //   })
          // );
        } else {
          let err = Error();
          err.message = response.data.message;
          throw err;
        }
      })
      .catch((error) => {
        // dispatch(
        //   BaseActions.showAlert({ variant: "error", message: error.message })
        // );
        if (error.response) {
          if (error.response.status === "404") {
            dispatch(blockFailed(`${error.response.status} : api not found`));
          } else {
            dispatch(blockFailed(error.response.status));
          }
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          dispatch(blockFailed(error.request));
          console.log(error.request);
        } else {
          console.log("Error", error.message);
          dispatch(blockFailed(error.message));
        }
        console.log(error);
      });
  };
}
export function reportUser(userId, reason) {
  const token = localStorage.getItem("JWTtoken");
  let data = {
    user_id: userId,
    reason: reason,
  };
  const config = {
    headers: {
      Authorization: `JWT ${token}`,
      // "Content-Type": "application/json",
    },
  };
  const request = axios.post(
    `${process.env.REACT_APP_ENV === "prod" ? process.env.REACT_APP_BASE_URL_PROD : process.env.REACT_APP_BASE_URL_TESTING}users/report-user`,
    data,
    config
  );
  return (dispatch) => {
    dispatch(reportLoading(true));
    dispatch(BaseActions.showLoading(true));
    request
      .then((response) => {
        if (response.data && response.data.status === "Success") {
          dispatch({
            type: REPORT,
            payload: response.data,
          });
          // dispatch(resetReducers())
          dispatch(BaseActions.hideLoading());
          // dispatch(
          //   BaseActions.showAlert({
          //     message: response.data.message,
          //     variant: "success",
          //   })
          // );
        } else {
          let err = Error();
          err.message = response.data.message;
          throw err;
        }
      })
      .catch((error) => {
        // dispatch(
        //   BaseActions.showAlert({ variant: "error", message: error.message })
        // );
        if (error.response) {
          if (error.response.status === "404") {
            dispatch(reportFailed(`${error.response.status} : api not found`));
          } else {
            dispatch(reportFailed(error.response.status));
          }
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          dispatch(reportFailed(error.request));
          console.log(error.request);
        } else {
          console.log("Error", error.message);
          dispatch(reportFailed(error.message));
        }
        console.log(error);
      });
  };
}
