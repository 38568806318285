export default {
  invite: "/assets/backgrounds/invite.svg",
  img: "/assets/backgrounds/img.svg",
  profileInvite: "/assets/backgrounds/profileInvite.svg",
  splash_gif: "/assets/backgrounds/animate.gif",
  legalStuff: "/assets/backgrounds/legalStuff.svg",
  quartrs: "/assets/backgrounds/quartrs.svg",
  rate: "/assets/backgrounds/rate.svg",
  videoCalling: "/assets/backgrounds/videoCalling.svg",
  whiteLine: "/assets/images/whiteLine.svg",
  blueLine: "/assets/images/blueLine.svg",
  dp: "/assets/images/dp.jpg",
  rating: "/assets/icons/Star@2x.png",
  power: "/assets/icons/power.svg",
  wallet: "/assets/icons/wallet@2x.png",
  help: "/assets/icons/Question@2x.png",
  logout: "/assets/icons/logout-red.png",
  justice: "/assets/icons/Justice@2x.png",
  location: "/assets/icons/current_location@2x.png",
  loading: "/assets/icons/loading.gif",
  imgPlaceholder: "/assets/icons/imgPlaceholder.png",
  videoPlaceholder: "/assets/icons/videoPlaceholder.png",
  videoThumbnail: "/assets/icons/videoThumbnail.png",
  placeHolder: "/assets/images/placeholder.png",
  searchFilter: "/assets/icons/searchFilter.png",
  reportIcon: "/assets/icons/report.png",
  blockIcon: "/assets/icons/block.png",
  checkBox: "/assets/icons/check.png",
  partialCheckBox: "/assets/icons/partial.png",
  blankCheckBox: "/assets/icons/blank.png",
  master: "/assets/icons/master.png",
  visa: "/assets/icons/visa.png",
  cardIll: "/assets/icons/cardIll.png",
  quarter: "/assets/icons/quarter.svg",
  whiteCard: "/assets/icons/whiteCard.png",
  Qlogo: "/assets/icons/Qlogo.png",
  camera: "/assets/video/camera.png",
  camera_fs: "/assets/video/camera_fs.png",
  mic_on: "/assets/video/mic_on.png",
  mic_on_fs: "/assets/video/mic_on_fs.png",
  mic_off: "/assets/video/mic_off.png",
  mic_off_fs: "/assets/video/mic_off_fs.png",
  unionPay: "/assets/icons/unionPay.png",
  amex: "/assets/icons/amex.png",
  discover: "/assets/icons/discover.png",
  JCB: "/assets/icons/JCB.png",
  back_arrow: "/assets/images/backArrow.png",
  // defaultDp: `${process.env.REACT_APP_ENV === "prod" ? process.env.REACT_APP_BASE_URL_PROD :process.env.REACT_APP_BASE_URL_TESTING}uploads/default_pic.png`
  defaultDp: '/assets/dummyImage.png'

  // dp: "assets/images/dp.jpg",
};
