import { makeStyles } from "@material-ui/core/styles";
import { commonStyles } from "../../styles";

export const useStyles = makeStyles((theme) => ({
    buttonsGrid: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr 1fr',
        justifyItems: "space-between",
        gap: '1rem',
    },
    topButtonActive: {
        ...commonStyles.button,
        backgroundColor: '#4CDE5F',
        width: '100%',
        [theme.breakpoints.down('md')]: {
            fontSize: '12px',
            borderRadius: '4px',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '10px',
        }
    },
    topButton: {
        ...commonStyles.button,
        backgroundColor: '#30A8DB',
        width: '100%',
        [theme.breakpoints.down('md')]: {
            fontSize: '12px',
            borderRadius: '4px',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '10px',
        }
    },
}));