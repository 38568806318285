import React from "react";

function SearchIcon() {
    return (
        <svg width="21" height="22" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0.90918 7.44629C0.90918 6.5332 1.08073 5.67822 1.42383 4.88135C1.76693 4.07894 2.24284 3.37337 2.85156 2.76465C3.46029 2.15592 4.16309 1.68001 4.95996 1.33691C5.76237 0.993815 6.62012 0.822266 7.5332 0.822266C8.44629 0.822266 9.30127 0.993815 10.0981 1.33691C10.9006 1.68001 11.6061 2.15592 12.2148 2.76465C12.8236 3.37337 13.2995 4.07894 13.6426 4.88135C13.9857 5.67822 14.1572 6.5332 14.1572 7.44629C14.1572 8.20443 14.0355 8.92383 13.792 9.60449C13.554 10.2852 13.222 10.9022 12.7959 11.4556L16.855 15.5396C16.9435 15.6281 17.0099 15.7305 17.0542 15.8467C17.104 15.9629 17.1289 16.0874 17.1289 16.2202C17.1289 16.4028 17.0874 16.5688 17.0044 16.7183C16.9269 16.8677 16.8162 16.9839 16.6724 17.0669C16.5285 17.1554 16.3625 17.1997 16.1743 17.1997C16.0415 17.1997 15.9142 17.1748 15.7925 17.125C15.6763 17.0807 15.5684 17.0116 15.4688 16.9175L11.3848 12.8252C10.8424 13.2126 10.2448 13.5169 9.5918 13.7383C8.9388 13.9596 8.2526 14.0703 7.5332 14.0703C6.62012 14.0703 5.76237 13.8988 4.95996 13.5557C4.16309 13.2126 3.46029 12.7367 2.85156 12.1279C2.24284 11.5192 1.76693 10.8164 1.42383 10.0195C1.08073 9.21712 0.90918 8.35938 0.90918 7.44629ZM2.32861 7.44629C2.32861 8.16569 2.46143 8.84082 2.72705 9.47168C2.99821 10.097 3.37174 10.6476 3.84766 11.1235C4.3291 11.5994 4.88249 11.973 5.50781 12.2441C6.13867 12.5153 6.8138 12.6509 7.5332 12.6509C8.2526 12.6509 8.92497 12.5153 9.55029 12.2441C10.1812 11.973 10.7345 11.5994 11.2104 11.1235C11.6864 10.6476 12.0599 10.097 12.3311 9.47168C12.6022 8.84082 12.7378 8.16569 12.7378 7.44629C12.7378 6.72689 12.6022 6.05452 12.3311 5.4292C12.0599 4.79834 11.6864 4.24495 11.2104 3.76904C10.7345 3.2876 10.1812 2.91406 9.55029 2.64844C8.92497 2.37728 8.2526 2.2417 7.5332 2.2417C6.8138 2.2417 6.13867 2.37728 5.50781 2.64844C4.88249 2.91406 4.3291 3.2876 3.84766 3.76904C3.37174 4.24495 2.99821 4.79834 2.72705 5.4292C2.46143 6.05452 2.32861 6.72689 2.32861 7.44629Z"
                fill="#838388"/>
        </svg>
    )
}

export default SearchIcon;