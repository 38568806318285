import React from "react";

function MenuIcon() {
    return (
        <svg width="27" height="18" viewBox="0 0 27 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="27" height="2" rx="1" fill="white"/>
            <rect y="8" width="12" height="2" rx="1" fill="white"/>
            <rect y="16" width="19" height="2" rx="1" fill="white"/>
        </svg>
    )
}

export default MenuIcon;