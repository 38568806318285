import React from "react";
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles(() => ({
    container: {
        width: 'calc((100% - 3rem) / 4)',
        minWidth: '70',
    },
    iconDiv: {
        width: '100%',
        aspectRatio: '1 / 1',
        backgroundColor: "#1B1B1B",
        borderRadius: 16,
        display: 'grid',
        placeContent: "center",
    },
    titleDiv: {
        marginTop: 12,
        fontSize: '13px',
        fontWeight: '400',
        lineHeight: '15.51px',
        textAlign: 'center',
        color: '#A8A8A8',
        overflow: 'hidden',
        textWrap: 'nowrap',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    }
}))

function Tile({ title, icon, ...props }) {
    const classes = useStyles();

    return (
        <div className={classes.container} {...props}>
            <div className={classes.iconDiv}>{icon}</div>
            <div className={classes.titleDiv}>{title}</div>
        </div>
    )
}

export default Tile;
