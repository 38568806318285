import React from "react";
import clsx from "clsx";
// import pics from "@assets";
import { makeStyles } from "@material-ui/styles";
import { Box, Typography, Button } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { isIOS } from "react-device-detect";
import useDeepCompareEffect from "use-deep-compare-effect";
import * as BaseActions from "store/actions";
import * as Actions from "components/signup/screens/signupForm/store/actions";
// import { socket } from "@utils";
import SocketClass from "@utils/socketService";
import moment from "moment";
import { useState, useEffect } from "react";

let socket = undefined;
let hours = [
  {
    title: "1 hour",
    value: "1",
    left: false,
    right: false,
    top: false,
    bottom: false,
    align: "flex-start",
  },
  {
    title: "2 hours",
    value: "2",
    left: true,
    right: false,
    top: false,
    bottom: false,
    align: "space-around",
  },
  {
    title: "3 hours",
    value: "3",
    left: true,
    right: false,
    top: false,
    bottom: false,
    align: "flex-end",
  },
  {
    title: "4 hours",
    value: "4",
    left: false,
    right: false,
    top: true,
    bottom: false,
    align: "flex-start",
  },
  {
    title: "5 hours",
    value: "5",
    left: true,
    right: false,
    top: true,
    bottom: false,
    align: "space-around",
  },
  {
    title: "6 hours",
    value: "6",
    left: true,
    right: false,
    top: true,
    bottom: false,
    align: "flex-end",
  },
  {
    title: "7 hours",
    value: "7",
    left: false,
    right: false,
    top: true,
    bottom: true,
    align: "flex-start",
  },
  {
    title: "8 hours",
    value: "8",
    left: true,
    right: false,
    top: true,
    bottom: true,
    align: "space-around",
  },
  {
    title: "9 hours",
    value: "9",
    left: true,
    right: false,
    top: true,
    bottom: true,
    align: "flex-end",
  },
  {
    title: "10 hours",
    value: "10",
    left: false,
    right: false,
    top: false,
    bottom: false,
    align: "flex-start",
  },
  {
    title: "11 hours",
    value: "11",
    left: true,
    right: false,
    top: false,
    bottom: false,
    align: "space-around",
  },
  {
    title: "12 hours",
    value: "12",
    left: true,
    right: false,
    top: false,
    bottom: false,
    align: "flex-end",
  },
];
const AvailabilityDrawer = (props) => {
  const dispatch = useDispatch();
  const [activePill, setActivePill] = React.useState(null);
  const userData = useSelector(({ BaseReducer }) => BaseReducer.UserReducer);
  let userDataFromStorage = JSON.parse(localStorage.getItem("quarterlyUser"));
  React.useEffect(() => {
    socket = SocketClass.getSocket();
  }, []);
  useDeepCompareEffect(() => {
    dispatch(BaseActions.getUserData());
    socket.connect();
    // socket.on("connecting", () => alert("connection"));
    socket.on("connect", () => {
      // alert("connected");
    });
    socket.emit("onprofile", {
      profile_id: userData && userData._id,
    }); // id
    socket.on("userupdated", (data) => {
      // alert("updated");
      dispatch(BaseActions.hideLoading());
      dispatch(BaseActions.hideMessage());
    });
    // socket.on("connection", () => {
    //   alert("connected");
    // });
    // socket.on("error", (e) => {
    //   console.log("sdfds", e);
    // });
    // socket.on("unauthorized", (error) => {
    //   if (
    //     error.data.type === "UnauthorizedError" ||
    //     error.data.code === "invalid_token"
    //   ) {
    //     // redirect user to login page perhaps?
    //     console.log("User token has expired");
    //   }
    // });
    // dispatch(BaseActions.updateLayout({ bottomNav: false }));
    return () => {
      // dispatch(BaseActions.hideMessage());
      // socket.disconnect();
      // dispatch(BaseActions.updateLayout({ bottomNav: true }));
      // dispatch(BaseActions.updateLayout({ bottomNav: false }));
    };
  }, [dispatch, userData]);
  //makes a 4 array
  function getQuartersStamp(current) {
    let arr = [];

    console.log("UTC String", new Date().toUTCString());
    console.log("ISO DATE", new Date().toISOString());
    const hours = new Date().getUTCHours(); // setting UTC + 5 OFFSET , BY DEFUALT ITS GIVING +8
    console.log(hours, "UTC HOURS");
    const calc = hours % 24; //convert to 24hr format if exceeds 24 bcz of utc hours exceed 24
    console.log(hours, "UTC CALC HOURS");

    for (let i = 0; i < Number(activePill); i++) {
      let nestedArray = [];
      let lDate = new Date(current).toLocaleDateString("en-US");
      let lTime = new Date(current).toLocaleTimeString();
      let params = lDate.split("/").reverse();
      params = [...params, ...lTime.split(":")];

      console.log("Times", i);
      console.log("L-Date", lDate);
      console.log("L-Time", lTime);
      console.log("params", params);
      console.log(Number(params[0]), Number(params[1]), Number(params[2]));

      if (i == 0) {
        //if time is less than or equal to 00
        if (Number(params[4]) <= 0)
          nestedArray.push(
            new Date(
              Number(params[0]), //year
              Number(params[2]) - 1, //month
              hours > 19 ? Number(params[1]) - 1 : Number(params[1]), //day
              calc + i + 5, //hours
              0 //minutes
            ).toISOString()
          ); // 00:00

        if (Number(params[4]) <= 15)
          nestedArray.push(
            new Date(
              Number(params[0]),
              Number(params[2]) - 1,
              hours > 19 ? Number(params[1]) - 1 : Number(params[1]), //day
              calc + i + 5,
              15
            ).toISOString()
          ); // 00:15

        if (Number(params[4]) <= 30)
          nestedArray.push(
            new Date(
              Number(params[0]),
              Number(params[2]) - 1,
              hours > 19 ? Number(params[1]) - 1 : Number(params[1]), //day
              calc + i + 5,
              30
            ).toISOString()
          ); // 00:30

        if (Number(params[4]) <= 45)
          nestedArray.push(
            new Date(
              Number(params[0]),
              Number(params[2]) - 1,
              hours > 19 ? Number(params[1]) - 1 : Number(params[1]), //day
              calc + i + 5,
              45
            ).toISOString()
          ); // 00:45
      } else {
        nestedArray.push(
          new Date(
            Number(params[0]),
            Number(params[2]) - 1,
            hours > 19 ? Number(params[1]) - 1 : Number(params[1]), //day
            calc + i + 5,
            0
          ).toISOString()
        ); // 00:00

        nestedArray.push(
          new Date(
            Number(params[0]),
            Number(params[2]) - 1,
            hours > 19 ? Number(params[1]) - 1 : Number(params[1]), //day
            calc + i + 5,
            15
          ).toISOString()
        ); // 00:15

        nestedArray.push(
          new Date(
            Number(params[0]),
            Number(params[2]) - 1,
            hours > 19 ? Number(params[1]) - 1 : Number(params[1]), //day
            calc + i + 5,
            30
          ).toISOString()
        ); // 00:30

        nestedArray.push(
          new Date(
            Number(params[0]),
            Number(params[2]) - 1,
            hours > 19 ? Number(params[1]) - 1 : Number(params[1]), //day
            calc + i + 5,
            45
          ).toISOString()
        ); // 00:45
      }

      arr.push(nestedArray);
    }

    console.log("whole array------>", arr);

    return arr.flat();
  }

  const handleUpdate = (val) => {
    console.log(val);
    socket.emit(val);
  };

  const getDate = () => {
    const current = new Date();
    const reqDates = getQuartersStamp(current);
    return reqDates;
  };

  const handleActivePill = (val) => {
    // socket.emit("updatemyprofile", { hours: Number(val), available: 1 });
    // dispatch(BaseActions.showLoading(true));
    setActivePill(val);
  };

  const handleSave = () => {
    //gets all the dates, according to the selected, e.g: 1hr = 00,15,30,45
    const dates = getDate();
    socket.emit(
      "updatemyprofile",
      { hours: Number(activePill), available: 1 },
      (payload) => {
        console.log("acknowledgment", payload);
      }
    );
    dispatch(BaseActions.showLoading(true));
    dispatch(BaseActions.updateLayout({ bottomNav: true }));
    const payloads = dates.map((date) => {
      let stampObject = {
        appointment: true,
        attendee: false,
        date: date,
        forced: false,
        quartr_now: false,
        status: false,
        timestamp: date,
      };
      return stampObject;
    });
    // enable actions here
    console.log(userData);
    const updation =
      userData.availablility != null
        ? userData.availablility.concat(payloads)
        : payloads;
    dispatch(Actions.setAvailability(updation));
  };
  return (
    <div>
      <Box
        style={{ padding: "28px", background: "#111111" }}
        marginBottom={isIOS ? "60px" : "10px"}
      >
        <Box>
          <Typography variant="body2">I'm available now</Typography>
        </Box>
        <Box marginTop="15px">
          <Typography style={{ fontSize: "14px" }} variant="caption">
            How many hours are you available to take <br /> Quartrly calls?
          </Typography>
        </Box>
        <Box marginTop="25px" display="flex" flexWrap="wrap">
          {hours.map((hours, i) => {
            const currentDate = new Date();
            const hour_s = currentDate.getHours();
            const rounded = Math.ceil(currentDate.getMinutes()) * 15;
            currentDate.setMinutes(rounded);
            currentDate.setHours(hour_s);

            return Number(hours.value) <= 24 - currentDate.getHours() ? (
              <HourPill
                isActive={activePill === hours.value}
                title={hours.title}
                align={hours.align}
                top={hours.top}
                bottom={hours.bottom}
                left={hours.left}
                right={hours.right}
                key={i}
                value={hours.value}
                setActivePill={handleActivePill}
                userData={userData}
              />
            ) : (
              <></>
            );
          })}
        </Box>
        <Box
          marginTop="30px"
          padding="10px 0"
          display="flex"
          justifyContent="space-between"
        >
          <Button
            style={{ width: "45%", margin: "0px 5px" }}
            variant="outlined"
            color="secondary"
            onClick={() => {
              props.handleClose && props.handleClose();
              dispatch(BaseActions.updateLayout({ bottomNav: true }));
              dispatch(BaseActions.hideMessage());
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleSave();
            }}
            disabled={!activePill}
            style={{ width: "45%", margin: "0px 5px" }}
          >
            OK
          </Button>
        </Box>
      </Box>
    </div>
  );
};
export default AvailabilityDrawer;
const usePillStyles = makeStyles((theme) => ({
  root: {
    background: "#111",
    maxWidth: "33.33%",
    width: "100%",
  },
  pillContainer: {
    margin: "10px 0px",
    padding: "2px 13px",
    borderRadius: "15px",
    // minWidth: "94px",
    textAlign: "center",
    cursor: "pointer",
    transition: "all .2s ease-in-out",
    border: "1px solid transparent",
    "&:hover": {
      border: "1px solid #31A7DB",
      "& > *": {
        color: "#31A7DB",
      },
    },
  },
  active: {
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      "& > *": {
        color: "#fff",
      },
    },
  },
}));
const HourPill = ({ title, ...props }) => {
  const classes = usePillStyles();
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent={props.align ? props.align : "space-around"}
      className={classes.root}
      style={{
        borderTop: props.top ? "0.1px solid #8080802b" : "",
        borderLeft: props.left ? "0.1px solid #8080802b" : "",
        borderRight: props.right ? "0.1px solid #8080802b" : "",
        borderBottom: props.bottom ? "0.1px solid #8080802b" : "",
      }}
      //      onClick={console.log("USER-DATA----->", props.userData)}
    >
      <Box
        className={clsx(
          classes.pillContainer,
          props.isActive ? classes.active : ""
        )}
        onClick={() => {
          props.setActivePill(props.value);
        }}
      >
        <Typography variant="body1" style={{ fontSize: "16px" }}>
          {title || ""}
        </Typography>
      </Box>
    </Box>
  );
};
