import axios from "axios";
import * as BaseActions from "store/actions";
//SUBMIT LOGIN ACTION NAMES
export const FETCH_REVIEWS = "FETCH_REVIEWS";
export const REVIEWS_IS_LOADING = "REVIEWS_IS_LOADING";
export const REVIEWS_FAILED = "REVIEWS_FAILED";
export const RESET_REVIEWS = "RESET_REVIEWS";

export const FETCH_AGGREGATE_RATINGS = "FETCH_AGGREGATE_RATINGS";
export const RATING_IS_LOADING = "RATING_IS_LOADING";
export const RATING_FAILED = "RATING_FAILED";
export const RESET_RATING = "RESET_RATING";

// Helper ACTIONS:
export const reviewsLoading = (ms) => ({
  type: REVIEWS_IS_LOADING,
  payload: ms,
});

export const reviewsFailed = (ms) => ({
  type: REVIEWS_FAILED,
  payload: ms,
});

export const resetReviews = () => ({
  type: RESET_REVIEWS
})

export function fetchReviews(id) {
  let data = { user_id: id };
  const token = localStorage.getItem("JWTtoken");


  const config = {
    headers: {
      Authorization: `JWT ${token}`,
      // "Content-Type": "application/json",
    },
  };
  const request = axios.post(`${process.env.REACT_APP_ENV === "prod" ? process.env.REACT_APP_BASE_URL_PROD : process.env.REACT_APP_BASE_URL_TESTING}ratings/get-ratings`, data, config);
  return (dispatch) => {
    dispatch(reviewsLoading(true));
    dispatch(BaseActions.showLoading(true))

    request
      .then((response) => {
        if (response.data && response.data.status === "Success") {
          dispatch({
            type: FETCH_REVIEWS,
            payload: response.data,
          });
          dispatch(BaseActions.hideLoading())
        } else {
          let err = Error();
          err.message = response.data.message;
          throw err;
        }
      })
      .catch((error) => {
        // dispatch(
        //   BaseActions.showAlert({ variant: "error", message: error.message })
        // );
        if (error.response) {
          if (error.response.status === "404") {
            dispatch(reviewsFailed(`${error.response.status} : api not found`));
          } else {
            dispatch(reviewsFailed(error.response.status));
          }
          console.log(error.response);
        } else if (error.request) {
          dispatch(reviewsFailed(error.request));

          console.log(error.request);
        } else {
          console.log("Error", error.message);
          dispatch(reviewsFailed(error.message));
        }
        console.log(error);
      });
  };
}

export function fetchAggregateRatings(id) {
  let data = { user_id: id };
  const token = localStorage.getItem("JWTtoken");


  const config = { headers: { Authorization: `JWT ${token}` } };
  const request = axios.post(
      `${process.env.REACT_APP_ENV === "prod"
          ? process.env.REACT_APP_BASE_URL_PROD
          : process.env.REACT_APP_BASE_URL_TESTING}ratings/get-aggregate-ratings`, data, config
  );

  return (dispatch) => {
    dispatch(reviewsLoading(true));
    dispatch(BaseActions.showLoading(true))

    request
        .then((response) => {
          if (response.data && response.data.status === "Success") {
            dispatch({
              type: FETCH_AGGREGATE_RATINGS,
              payload: response.data,
            });
            dispatch(BaseActions.hideLoading())
          } else {
            let err = Error();
            err.message = response.data.message;
            throw err;
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === "404") {
              dispatch(reviewsFailed(`${error.response.status} : api not found`));
            } else {
              dispatch(reviewsFailed(error.response.status));
            }
            console.log(error.response);
          } else if (error.request) {
            dispatch(reviewsFailed(error.request));

            console.log(error.request);
          } else {
            console.log("Error", error.message);
            dispatch(reviewsFailed(error.message));
          }
          console.log(error);
        });
  };
}
