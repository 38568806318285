export const MOUNT_ROUTE = "MOUNT_ROUTE";
export const UNMOUNT_ROUTE = "UNMOUNT_ROUTE";

export function mountRoute({component, path, key, from}) {
  return {
    type: MOUNT_ROUTE,
    path,
    key,
    component,
    from,
  };
}

export function unmountRoute() {
  return {
    type: UNMOUNT_ROUTE,
  };
}
