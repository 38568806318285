import * as Actions from "../../actions";
const initialState = {
  state: false,
  data: { message: "hi", variant: "success" },
};

export const AlertReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.SHOW_ALERT: {
      return {
        state: true,
        data: action.payload,
      };
    }
    case Actions.HIDE_ALERT: {
      return {
        state: false,
        data: null,
      };
    }
    default: {
      return state;
    }
  }
};
