import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import quartrlyLogo from "../../@assets/test/quartrlyLogo.png"
import { makeStyles } from "@material-ui/styles";
import { isMobile } from "react-device-detect";

//#080A0A

const useStyles = makeStyles((theme) => ({
  spinner: {
    backgroundColor: '#080A0A',
    height: '88vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: isMobile ? "100%" : '24%'
  },
  spinnerImage: {
    position: 'absolute',
    top: '37%',
    left: isMobile ? '25.5%' : "44.5%",
  }
}))

export default function Spinner() {

  const classes = useStyles();
  return (
    <div className={classes.spinner}>
      <img src={quartrlyLogo} className={classes.spinnerImage} />
      <CircularProgress
        style={{ width: "40px", height: "40px", margin: "0px 10px" }}
        color="secondary"
      />
    </div>
  );
}
