import React from "react";
import Header from "@base/header/index";
import {withRouter} from "react-router";
import {useParams} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import {Box, InputBase, Paper, Typography} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import * as BaseActions from "store/actions";
import * as Actions from "components/home/store/actions";
import reducer from "components/home/store/reducers";
import withReducer from "store/withReducer";
import useDeepCompareEffect from "use-deep-compare-effect";
import UserCardHorizontol from "../home/subComponents/userCard/UserCardHorizontol";
import SearchIcon from "../home/subComponents/header/icons/SearchIcon";

const SECTION_TITLES = {
    recently_viewed: "Recently viewed",
    suggested: "Suggestions",
    new_users: "Newly registered profiles",
    available_now: "Available now",
}

const useStyles = makeStyles((theme) => ({
    container: {
        margin: "20px",
    }, cardContainer: {
        display: "flex",
        flex: 1,
        flexDirection: "column",
        flexWrap: "wrap",
        rowGap: 15,
        justifyContent: "space-around",
        "&:after": {
            content: "''", maxWidth: "355px", minWidth: "175px",
        },
        marginBottom: 10,
        [theme.breakpoints.up("sm")]: {
            marginBottom: 70,
        },
    }, pointer: {
        cursor: "pointer",
    },
    total: {
        margin: "20px 0",
        fontWeight: 600,
        fontSize: 14,
        lineHeight: "16.71px",
        letterSpacing: "0%",
        color: "#31A7DB",
    },
    root: {
        marginTop: 15,
        display: "flex",
        flex: 1,
        padding: 10,
        backgroundColor: "#1B1B1B",
        color: "#585858",
        alignItems: "center",
        height: 42,
        gridColumn: 'span 2 / span 2',
        borderRadius: '10px',
        width: '100%',
    },
    inputBase: {
        marginLeft: theme.spacing(1),
        textAlign: "center",
        color: "#1B1B1B",
        width: "100%",
    },
    input: {
        fontFamily: "-apple-system, BlinkMacSystemFont, cerebri-regular",
        width: "100%",
        fontSize: "17px",
        fontWeight: 400,
        lineHeight: '22px',
        letterSpacing: '-0.4300000071525574px',
        textAlign: 'left',
        textUnderlinePosition: 'from-font',
        textDecorationSkipInk: 'none',
        color: '#ADADAD',
        backgroundColor: '#1B1B1B',
    },
}));

function SeeAll({history}) {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [data, setData] = React.useState([]);
    const [page, setPage] = React.useState(1);
    const [total, setTotal] = React.useState(0);
    const [hasMore, setHasMore] = React.useState(false);
    const {param} = useParams()
    const title = SECTION_TITLES[param] || param;
    const fetchedData = useSelector(({SeeAll}) => SeeAll.GetAllReducer);
    const isLoading = fetchedData.isLoading;
    const [search, setSearch] = React.useState("");

    React.useEffect(() => {
        dispatch(BaseActions.updateLayout({bottomNav: false}));
    }, []);

    React.useEffect(() => {
        setPage(1); // Reset page to 1 when search changes
    }, [search]);

    React.useEffect(() => {
        dispatch(Actions.getAll(page, param, search));
    }, [page, dispatch, param, search]);

    useDeepCompareEffect(() => {
        if (fetchedData.data && fetchedData.data.data) {
            setData(page === 1 ? fetchedData.data.data.users : [...new Set([...data, ...fetchedData.data.data.users])]);
            setTotal(fetchedData.data.data.total);
            setHasMore(fetchedData.data.data.totalPages > page);
        }
    }, [fetchedData]);

    const handleNavigate = (path, data) => {
        history.push({ pathname: path, state: data });
    };

    const observer = React.useRef();

    const lastElementRef = React.useCallback((node) => {
        if (isLoading) return;
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting && hasMore) {
                setPage((prevPageNumber) => prevPageNumber + 1);
            }
        });
        if (node) observer.current.observe(node);
    }, [isLoading, hasMore]);

    const onSearch = (event) => {
        setSearch(event.target.value);
    };

    return (
        <>
            <Header backArrow heading={title}/>

            <Box padding="70px 20px 0">
                <Paper className={classes.root}>
                    <SearchIcon/>

                    <InputBase
                        className={classes.inputBase}
                        placeholder="Search"
                        onClick={() => handleNavigate()}
                        inputProps={{
                            className: classes.input,
                            value: search,
                            onChange: onSearch,
                        }}
                    />
                </Paper>

                <Typography variant="body2" className={classes.total}>
                    {total} {total === 1 ? "result" : "results"}
                </Typography>

                <Box className={classes.cardContainer}>
                    {data?.map((d, i) => {
                        if (d.rate > 0) {
                            return data.length === i + 1 ? (
                                <div
                                    ref={lastElementRef}
                                    style={{cursor: "pointer"}}
                                    onClick={() => handleNavigate(`/userprofile/${d.user_code}`, d)}
                                >
                                    <UserCardHorizontol user={d} key={i}/>
                                </div>
                            ) : (
                                <div
                                    style={{cursor: "pointer"}}
                                    onClick={() => handleNavigate(`/userprofile/${d.user_code}`, d)}
                                >
                                    <UserCardHorizontol user={d} key={i}/>
                                </div>
                            )
                        } else {
                            return null;
                        }
                    })}
                </Box>
            </Box>
        </>
    );
}

export default withReducer("SeeAll", reducer)(withRouter(SeeAll));
