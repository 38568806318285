import React from 'react'
import { TextField, MenuItem, withStyles, makeStyles } from '@material-ui/core'
const StyledSelectField = withStyles({
  root: {
    '& *': {
      fontSize: 16
    },
    '& .MuiInput-formControl': {
      borderBottom: '.5px solid',
    },
    '& .MuiSelect-root': {
      color: '#fff',
      fontFamily: 'Cerebri-Regular',
    },
    '& .MuiSelect-icon': {
      color: '#fff',
    },
  },
})(TextField)
const useStyles = makeStyles({
  menu: {
    '& .MuiListItem-root': {
      color: '#fff',
      fontFamily: 'Cerebri-Regular',
      '&:hover': {
        color: '#fff',
      },
    }
  },
  label: {
    color: '#808080',
    fontSize: 14,
    fontFamily: 'cerebri-light',
    fontWeight: 500,
    lineHeight: 1.57,
  },
  secondaryBorderColor: {
    borderBottomColor: '#808080 !important',
  },
  errorText: {
    color: 'rgb(203, 58, 58) !important',
    fontSize: 14,
    fontFamily: 'cerebri-light',
    fontWeight: 500,
    lineHeight: 1.57,
  },
  customMenu: {
    cursor: 'pointer',
    textAlign: 'left',
    fontSize: '1rem',
    color: 'white',
    padding: '6px 10%',
    fontWeight: '400',
    boxSizing: 'border-box',
    lineHeight: '1.5',
    whiteSpace: 'nowrap',
    fontFamily: 'Cerebri-Regular'
  }
})
export default function SelectField({ value, options, error, onChange, selectProps, menuProps, ...restProps }) {
  const classes = useStyles()
  return (
    <StyledSelectField
      select
      color='secondary'
      value={value}
      onChange={onChange}
      error={!!error}
      helperText={!!error && error}
      fullWidth
      InputProps={{
        className: error ? '' : classes.secondaryBorderColor,
      }}
      FormHelperTextProps={{
        className: classes.errorText,
      }}
      SelectProps={{
        MenuProps: {
          className: classes.menu,
        },
        ...selectProps,
      }}
      {...restProps}
    >
      {options.map(option => (
        // <MenuItem key={option.value} value={option.value}>
        //   {option.label}
        // </MenuItem>
        <div key={option.value} value={option.value} className={classes.customMenu}>
          {option.label}
        </div>
      ))}
    </StyledSelectField>
  )
}
