import React from "react";
import { withRouter } from "react-router";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography, Button } from "@material-ui/core";
import { InputField } from "@base";
import pics from "@assets";
import useDeepCompareEffect from "use-deep-compare-effect";
import { useDispatch, useSelector } from "react-redux";
import withReducer from "store/withReducer";
import * as Actions from "./store/actions";
import * as BaseActions from "store/actions";
import reducer from "./store/reducers";
const useStyles = makeStyles((theme) => ({
  root: {
    padding: "27px 22px",
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      padding: "15px 22px",
    }
  },
  headingCon: {
    display: "flex",
    alignItems: "center",
    "& .arrow": {
      marginRight: "20px",
      cursor: "pointer",
      height: 20,
      width: 11,
      marginBottom: "3px",
    },
  },
  flexCon: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    // justifyContent: "space-between",
    paddingTop: "30px",
    // alignItems:"center"
  },
  profileCon: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  profileWithCaption: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& .profilePhoto": {
      height: 94,
      width: 94,
      borderRadius: 6,
      border: "1px solid white",
      marginBottom: "10px",
    },
  },
  ratingCon: {
    marginTop: "20px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  iconEmpty: {
    color: "gray",
  },
  iconFilled: {
    color: "#fcbd3f",
  },
  iconLabel: {
    padding: "0px 8px",
  },
  buttonCon: {
    position: "fixed",
    [theme.breakpoints.up("sm")]: {
      position: "fixed",
      width: 330,
      bottom: 50,
    },
    bottom: 30,
    width: 330,
    margin: "0px 0px",
  },
  // /////////////////////////////
  feedbackCon: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    marginTop: "50px",
  },
  feedbackPill: {
    margin: "5px 0px",
    padding: "6px 14px",
    borderRadius: 6,
    border: "1px solid #808080",
    textAlign: "center",
    cursor: "pointer",
    transition: "all .1s ease-in-out",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      borderColor: "transparent",
    },
  },
  feedbackPillActive: {
    margin: "5px 0px",
    padding: "6px 14px",
    borderRadius: 6,
    border: "1px solid transparent",
    textAlign: "center",
    cursor: "pointer",
    transition: "all .1s ease-in-out",
    backgroundColor: theme.palette.primary.main,
  },
  fieldCon: {
    width: "100%",
    // marginTop: "50px",
  },
  checkboxCon: {
    display: "flex",
    marginTop: "50px",
    marginBottom: "30px",
    // alignItems: "center",
    "& .checkbox": {
      marginRight: "10px",
      height: "24px",
      width: "24px",
      marginTop: "3px",
      cursor: "pointer",
    },
  },
}));
const ContactUs = (props) => {
  const dispatch = useDispatch();
  const [values, setValues] = React.useState({ subject: "", message: "" });
  const [invalid, setInvalid] = React.useState(false);
  const contactReducer = useSelector(
    ({ Contact }) => Contact.SubmitContactReducer
  );
  React.useEffect(() => {
    dispatch(BaseActions.updateLayout({ bottomNav: false }));
  }, []);
  // useDeepCompareEffect(() => {
  //   if (values.subject && values.message) {
  //     if (values.subject.trim() !== "" && values.message.trim() !== "") {
  //       setInvalid(true);
  //     } else {
  //       setInvalid(false);
  //     }
  //   }
  // }, [values]);
  useDeepCompareEffect(() => {
    if (contactReducer.data && contactReducer.data.status === "Success") {
      // dispatch(BaseActions.showMessage(<PopupSuccess />));
      props.history.push('/help')
    }
  }, [contactReducer]);
  useDeepCompareEffect(() => {
    if (values.subject.trim() === "") {
      setInvalid(false)
      return;
    }
    if (values.message.trim() === "") {
      setInvalid(false)
      return;
    }
    setInvalid(true);
  }, [values]);
  const handleChange = (name) => (event) => {
    setValues({ ...values, [name]: event.target.value });
  };
  const handleSubmit = () => {
    dispatch(Actions.submitContact(values));
  };
  const classes = useStyles();
  return (
    <>
      <Box className={classes.root}>
        <div className={classes.headingCon}>
          <img
            onClick={() => props.history.push("/help")}
            className="arrow"
            alt="img"
            src={pics.back_arrow}
            style={{ marginTop: '2px' }}
          />
          <Typography variant="h1">Contact Us</Typography>
        </div>
        <div className={classes.flexCon}>
          <Box className={classes.fieldCon}>
            <InputField
              name="subject"
              label="Subject"
              // error={!commentValid}
              isValid={false}
              value={values.subject}
              placeHolder="Enter subject here"
              onChange={handleChange}
            />
            <InputField
              name="message"
              label="Message"
              // error={!commentValid}
              isValid={false}
              value={values.message}
              placeHolder="Enter your comments/question here"
              onChange={handleChange}
            />
          </Box>
          {/* button */}
          <Box className={classes.buttonCon}>
            <Button
              onClick={() => {
                handleSubmit();
              }}
              disabled={!invalid}
              fullWidth
            >
              Send
            </Button>
          </Box>
        </div>
      </Box>
    </>
  );
};
export default withReducer("Contact", reducer)(ContactUs);
const PopupSuccess = withRouter((props) => {
  return (
    <div style={{ padding: "20px" }}>
      <Typography variant="subtitle1">message sent successfully</Typography>
      <Button
        onClick={() => props.history.push("/home")}
        style={{ marginTop: "20px" }}
        fullWidth
      >
        Done!
      </Button>
    </div>
  );
});
