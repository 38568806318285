import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import logo from '../../@assets/LandingPageAssets/logo.png'
import { useHistory } from 'react-router-dom'

import './App.css'

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    background: '#080A0A',
    padding: '2% 2%',
    paddingLeft: '1.1%',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    }
  },
  logo: {
    // color: 'white',
    // fontSize: '50px',
    width: "50%",
    fontFamily: 'Cerebri-Sans',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      width: "100%",
    }
  },
  logoImage: {
    height: '60px',
    cursor: 'pointer'
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      padding: '5% 0%'
    }
  },
  registerButton: {
    color: '#fff',
    // backgroundColor: '#808080',
    backgroundColor: '#4CDE5F',
    borderRadius: '6px',
    border: 'none',
    width: '154px',
    height: '53px',
    cursor: 'pointer',
    fontSize: '20px',
    [theme.breakpoints.down('xs')]: {
      width: '140px',
      fontSize: '18px'
    }
  },
  loginButton: {
    color: '#fff',
    backgroundColor: '#30A8DB',
    borderRadius: '6px',
    marginLeft: '10%',
    border: 'none',
    width: '154px',
    height: '53px',
    cursor: 'pointer',
    fontSize: '20px',
    [theme.breakpoints.down('xs')]: {
      width: '140px',
      fontSize: '18px'
    }
  },
}))

const Header = () => {
  const history = useHistory();
  const classes = useStyles();
  return (
    <div className="font-face-regular">
      <div className={classes.header}>
        <div className={classes.logo}>
          <img src={logo} className={classes.logoImage} onClick={() => history.push('/')} />
        </div>
        <div className={classes.buttonContainer}>
          <div>
            <button type="button" className={classes.registerButton} onClick={() => { history.push('/intro'); }}>
              Sign Up
            </button>
          </div>
          <div>
            <button type="button" className={classes.loginButton} onClick={() => history.push('/login')}>
              Login
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header
