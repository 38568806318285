import React from "react";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles({
    navigationActionLabel: {
        marginTop: 7,
        fontFamily: "-apple-system, BlinkMacSystemFont, cerebri-regular",
        fontSize: 12,
        fontWeight: 500,
        lineHeight: '14px',
        height: '14px',
    },
});

function BottomNavItemLabel({ label, selected, ...props }) {
    const classes = useStyles();

    return (
        <div className={classes.navigationActionLabel} style={{ color: selected ? '#FFFFFF' : '#5B5B5B' }}>
            {props.children}
        </div>
    );
}

export default BottomNavItemLabel;
