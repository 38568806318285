import React from "react";
import Drawer from "@material-ui/core/Drawer";
import { Box, Button, Typography } from "@material-ui/core";
import { Link, withRouter } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import * as BaseActions from "store/actions";
import { logout } from "@utils";
import { isIOS } from "react-device-detect";
function TemporaryDrawer({ open, set, handleClose, ...props }) {
  console.log(open);
  const dispatch = useDispatch();
  return (
    <React.Fragment>
      {/* <Drawer anchor="bottom" open={open}> */}
      <div
        style={{
          backgroundColor: "black", color: "white", padding: 30, paddingBottom: isIOS ? "20px" : "70px"
        }}
      // onClick={() => set(false)}
      >
        <Typography variant="body2">Confirm Decline</Typography>
        <Typography style={{ marginTop: 36 }} variant="subtitle2">
          Are you sure you wish to decline?
          If you decline, you will not be able to use Quartrly and all your information will be removed.
        </Typography>
        <Box margin="48px 0 0 0" display="flex" justifyContent="space-between">
          <Button
            fullWidth
            style={{ margin: "0px 5px", width: '44%' }}
            variant="outlined"
            color="secondary"
            onClick={() => {
              props.setOpen(false);
              dispatch(BaseActions.hideMessage());
            }}
          >
            No
          </Button>
          <Button
            fullWidth
            // to="/"
            style={{ margin: "0px 5px", width: '44%' }}
            // component={Link}
            onClick={() => {
              logout()

              dispatch(BaseActions.hideMessage());
              props.history.push("/");
              window.location.reload();
            }}
          >
            Yes
          </Button>
        </Box>
      </div>
      {/* </Drawer> */}
    </React.Fragment >
  );
}

export default withRouter(TemporaryDrawer);
