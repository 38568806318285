import { combineReducers } from "redux";
// import auth from 'app/auth/store/reducers'; our auth reducers will be here
import { UserReducer } from "store/reducers/baseReducer/auth.reducer";
import BaseReducer from "./baseReducer";

const createReducer = (asyncReducers) =>
  combineReducers({
    // auth,
    
    BaseReducer, // we have base reducers which can we access anytime from redux.
    UserReducer,
    ...asyncReducers, // these reducers only be accessible when it stays on its component which can be withReducer hook.
  });

export default createReducer;


/**
 * we have base reducer folder in which we have all our required base reducers.
 */