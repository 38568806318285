export const SET_USER = "SET_USER";
export const ADD_USER_MEDIA = "ADD_USER_MEDIA";

// filters

export function setUser(payload) {
  return (dispatch) => {
    dispatch({ type: SET_USER, payload });
  };
}
export function addUserMedia(mediaId) {
  return (dispatch) => {
    dispatch({ type: ADD_USER_MEDIA, payload: mediaId });
  };
}
