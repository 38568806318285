export const UPDATE_LAYOUT = "UPDATE_LAYOUT";
export const FULL_SCREEN = "FULL_SCREEN";
export const EXIT_FULL_SCREEN = "EXIT_FULL_SCREEN";

//  {message : "what is the message"}

export function updateLayout(config) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_LAYOUT,
      payload: config,
    });
  };
}

export function enterFullScreen() {
  return (dispatch) => {
    dispatch({
      type: FULL_SCREEN,
      payload: false,
    });
  };
}

export function exitFullScreen() {
  return (dispatch) => {
    dispatch({
      type: EXIT_FULL_SCREEN,
      payload: false,
    });
  };
}
