export const HIDE_NOTIFICATION = "HIDE_NOTIFICATION";
export const SHOW_NOTIFICATION = "SHOW_NOTIFICATION";

export function hideNotification() {
  return {
    type: HIDE_NOTIFICATION,
  };
}

//  {NOTIFICATION : "what is the NOTIFICATION"}

export function showNotification(data) {
  return {
    type: SHOW_NOTIFICATION,
    payload: data,
  };
}
