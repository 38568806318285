import React from 'react';

function UserIcon({ ...props }) {
    return (
        <svg width={props.width ?? '26'} height={props.height ?? '26'} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M15 14.745C18.3986 14.745 21.1539 12.0038 21.1539 8.62251C21.1539 5.24117 18.3986 2.50006 15 2.50006C11.6013 2.50006 8.84613 5.24117 8.84613 8.62251C8.84613 12.0038 11.6013 14.745 15 14.745Z"
                fill={props.fill} />
            <path
                d="M18.2051 17.2961H11.7949C8.07692 17.2961 5 20.3574 5 24.0563C5 24.9491 5.38461 25.7145 6.15385 26.0971C7.30769 26.7349 9.8718 27.5001 15 27.5001C20.1283 27.5001 22.6923 26.7349 23.8461 26.0971C24.4871 25.7145 25 24.9491 25 24.0563C25 20.2298 21.9231 17.2961 18.2051 17.2961Z"
                fill={props.fill} />
        </svg>
    )
}

export default UserIcon;