import {Box, FormControlLabel, Radio as RadioButton, Typography} from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    body: {
        padding: 20,
    },
    formSectionLabel: {
        fontFamily: "-apple-system, BlinkMacSystemFont, cerebri-regular",
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: 18,
        lineHeight: "21px",
        padding: "0 5px",
        color: "#31A7DB",
    },
    formGroup: {
        boxSizing: "border-box",
        background: "rgba(20, 20, 20, 1)",
        transition: "all 0.25s ease-in-out",
        border: "1px solid #373737",
        borderRadius: 15,
        padding: "8px 15px 12px",
        position: "relative",
    },
    formGroupHover: {
        "&:focus-within": {
            background: "#262626",
        },
    },
    formGroupLabel: {
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: 16,
        lineHeight: "19px",
        display: "flex",
        alignItems: "center",
        color: "#9F9F9F",
        marginBottom: 10,
    },
    transparentInput: {
        fontFamily: "-apple-system, BlinkMacSystemFont, cerebri-regular",
        backgroundColor: "transparent",
        border: "0px",
        outline: "none",
        boxShadow: "none",
        width: "100%",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: 18,
        lineHeight: "21px",
        display: "flex",
        alignItems: "center",
        color: "#FFFFFF",
        padding: 0,
    },
    textArea: {
        fontWeight: 400,
        fontSize: 14,
        lineHeight: "16.71px",
        letterSpacing: "0%",
    },
    root: {
        color: (props) => (props.isAvailable ? "#fff" : "#3b3b3b"),
        marginRight: 10,
    },
    radioRoot: {
        margin: 0
    },
    checkedIcon: {
        borderRadius: "100%",
        padding: 4,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        border: "2px solid rgba(55, 55, 55, 1)",
    },
    radioLabel: {
        fontFamily: "-apple-system, BlinkMacSystemFont, cerebri-regular",
        fontWeight: 400,
        fontSize: 14,
        marginRight: 0,
        lineHeight: "16.71px",
        letterSpacing: "0%",
    },
    blueDot: {
        borderRadius: "100%",
        display: "block",
        width: 10,
        height: 10,
        backgroundColor: "#31A7DB",
        content: '""',
    },
}));

export const FormSection = ({label, children, ...props}) => {
    const classes = useStyles();

    return (
        <Box className={classes.body} display="flex" flexDirection="column" gridRowGap={20} {...props}>
            <Typography className={classes.formSectionLabel}>{label}</Typography>

            {children}
        </Box>
    )
}

export const FormGroup = ({label, errors = [], children, groupEffect = true, ...props}) => {
    const classes = useStyles();

    return (
        <Box>
            <Box className={clsx(classes.formGroup, (groupEffect ? classes.formGroupHover : {}))} {...props}>
                {
                    label &&
                    <Typography variant="caption" className={classes.formGroupLabel}>
                        {label}
                    </Typography>
                }

                {children}
            </Box>

            {errors.map((error, index) => (
                error && <ErrorBox key={index} error={error}/>
            ))}
        </Box>
    )
}

export const InputField = ({label, errors = [], ...props}) => {
    const classes = useStyles();

    return (
        <label htmlFor={props.name}>
            <FormGroup label={label} errors={errors}>
                <input
                    type="text"
                    id={props.name}
                    className={classes.transparentInput}
                    {...props}
                />
            </FormGroup>
        </label>
    )
}

export const RadioButtonField = ({label, value}) => {
    const classes = useStyles();

    return (
        <FormControlLabel
            value={value}
            label={label}
            classes={{label: classes.radioLabel, root: classes.radioRoot}}
            control={
                <RadioButton
                    classes={{root: classes.root}}
                    color="secondary"
                    size="large"
                    checkedIcon={
                        <div className={clsx(classes.icon, classes.checkedIcon)}>
                            <span className={clsx(classes.blueDot)}/>
                        </div>
                    }
                />
            }
        />
    )
}

export const TextArea = ({label, errors = [], ...props}) => {
    const classes = useStyles();

    return (
        <FormGroup label={label} errors={errors}>
			<textarea
                className={clsx(classes.transparentInput, classes.textArea)}
                {...props}
            ></textarea>
        </FormGroup>
    )
}

export const ErrorBox = ({error}) => {
    return (
        <p style={{
            fontFamily: "-apple-system, BlinkMacSystemFont, cerebri-regular",
            color: 'rgb(203, 58, 58)',
            margin: "5px 15px",
            fontSize: '12px',
        }}>
            {error}
        </p>
    )
}
