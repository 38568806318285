import React, { useState, useEffect } from "react";

const useDetectZoomLevel = () => {
    const [zoomLevel, setZoomLevel] = useState(null)

    useEffect(() => {
        const resizeListener = () => {
            const calculatedZoomLevel = Math.ceil(((window.outerWidth - 10) / window.innerWidth) * 100)
            setZoomLevel(calculatedZoomLevel)
        }

        if (!zoomLevel) {
            const calculatedZoomLevel = Math.ceil(((window.outerWidth - 10) / window.innerWidth) * 100)
            setZoomLevel(calculatedZoomLevel)
            window.addEventListener('resize', resizeListener)
        }

        return () => {
            window.removeEventListener('resize', resizeListener)
        };
    }, []);

    return zoomLevel
}

export default useDetectZoomLevel
