import axios from "axios";
import firebase from "@firebase";
import { isIOS } from "react-device-detect";

export default async function logout() {
  const myId = JSON.parse(localStorage.getItem("quarterlyUser"))
  localStorage.removeItem("JWTtoken");
  localStorage.removeItem("quarterlyUser");

  if (isIOS) {
    window.location.href = "/";
    return;
  } else {
    const messaging = firebase.messaging();
    const token = await messaging
      .requestPermission()
      .then(() => messaging.getToken())
      .catch((err) => console.log({ err }))
    const request = axios.post(`${process.env.REACT_APP_ENV === "prod" ? process.env.REACT_APP_BASE_URL_PROD : process.env.REACT_APP_BASE_URL_TESTING}users/logout`, { myId: myId.data, fcm_tokens: token });
    request.then((res) => {
      // if (res.data.status === "Success") {
      window.location.href = "/";
      // }
    })
    return;
  }
}
