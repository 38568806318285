import axios from "axios";
import * as BaseActions from "store/actions";
//block ACTION NAMES
export const SUBMIT_CONTACT = "SUBMIT_CONTACT";
export const SUBMIT_CONTACT_IS_LOADING = "SUBMIT_CONTACT_IS_LOADING";
export const SUBMIT_CONTACT_FAILED = "SUBMIT_CONTACT_FAILED";

export const RESET = "RESET";

// Helper ACTIONS:
export const resetReducers = (ms) => ({
  type: RESET,
  payload: ms,
});
// Helper ACTIONS:
export const contactLoading = (ms) => ({
  type: SUBMIT_CONTACT_IS_LOADING,
  payload: ms,
});

export const contactFailed = (ms) => ({
  type: SUBMIT_CONTACT_FAILED,
  payload: ms,
});

export function submitContact(data) {
  const token = localStorage.getItem("JWTtoken");
  let userEmail = JSON.parse(localStorage.getItem("quarterlyUser")).data.email;
  let modifiedData = {
    subject: data.subject,
    comment: data.message,
    email: userEmail,
  };
  console.log(modifiedData)
  const config = {
    headers: {
      Authorization: `JWT ${token}`,
      // "Content-Type": "application/json",
    },
  };
  const request = axios.post(
    `${process.env.REACT_APP_ENV === "prod" ? process.env.REACT_APP_BASE_URL_PROD : process.env.REACT_APP_BASE_URL_TESTING}contact/contact-us`,
    modifiedData,
    config
  );
  return (dispatch) => {
    dispatch(contactLoading(true));
    dispatch(BaseActions.showLoading(true));

    request
      .then((response) => {
        if (response.data && response.data.status === "Success") {
          dispatch({
            type: SUBMIT_CONTACT,
            payload: response.data,
          });
          dispatch(resetReducers());
          dispatch(BaseActions.hideLoading());
          // dispatch(
          //   BaseActions.showAlert({
          //     message: response.data.message,
          //     variant: "success",
          //   })
          // );
        } else {
          let err = Error();
          err.message = response.data.message;
          throw err;
        }
      })
      .catch((error) => {
        // dispatch(
        //   BaseActions.showAlert({ variant: "error", message: error.message })
        // );
        dispatch(
          BaseActions.hideLoading()
        );
        if (error.response) {
          if (error.response.status === "404") {
            dispatch(contactFailed(`${error.response.status} : api not found`));
          } else {
            dispatch(contactFailed(error.response.status));
          }
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          dispatch(contactFailed(error.request));

          console.log(error.request);
        } else {
          console.log("Error", error.message);
          dispatch(contactFailed(error.message));
        }
        console.log(error);
      });
  };
}
