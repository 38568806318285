import {makeStyles, Switch} from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
    root: {
        width: 51,
        height: 31,
        padding: 0,
        marginRight: 0,
    },
    switchBase: {
        padding: 2,
        color: "#78788040",
        "&$checked": {
            transform: "translateX(19px)",
            color: "#FFF",
            "& + $track": {
                backgroundColor: "#31A7DB",
                opacity: 1,
                border: "none",
            },
        },
    },
    switchBaseBlue: {
        padding: 2,
        color: "Black",
        "&$checked": {
            transform: "translateX(19px)",
            color: "#4CDE5F",
            "& + $track": {
                backgroundColor: "#193010",
                opacity: 1,
                border: "none",
            },
        },
    },
    switchBaseDark: {
        padding: 2,
        "&$checked": {
            transform: "translateX(19px)",
            color: "#FFF",
            "& + $trackDark": {
                backgroundColor: "#31A7DB",
                opacity: 1,
                border: "none",
            },
            "& > .thumbDark": {
                color: "#4CDE5F !important",
            },
        },
        "&$focusVisible $thumbDark": {
            color: "#4CDE5F",
        },
    },
    switchBaseDarkBlue: {
        padding: 5,
        "&$checked": {
            transform: "translateX(22px)",
            color: "#Yellow",
            "& + $trackDark": {
                backgroundColor: "#193010",
                opacity: 1,
                border: "none",
            },
            "& > .thumbDark": {
                color: "#Yellow !important",
            },
        },
        "&$focusVisible $thumbDark": {
            color: "#Yellow",
        },
    },
    thumb: {
        width: 27,
        height: 27,
        marginLeft: 1,
    },
    thumbDark: {
        width: 27,
        height: 27,
        marginLeft: 1,
    },
    track: {
        borderRadius: 31 / 2,
        backgroundColor: "#301010",
        opacity: 1,
        transition: theme.transitions.create(["background-color", "border"]),
    },
    trackDark: {
        borderRadius: 31 / 2,
        backgroundColor: "#78788040",
        color: "#4CDE5F",
        opacity: 1,
        transition: theme.transitions.create(["background-color", "border"]),
    },
    checked: {},
    focusVisible: {},
}));

function MySwitch({isDark, checked, handleChange, ...props}) {
    const classes = useStyles();

    return (
        <Switch
            disabled={props.isLoading}
            focusVisibleClassName={classes.focusVisible}
            disableRipple
            checked={checked}
            onChange={() => handleChange()}
            classes={{
                root: classes.root,
                switchBase: isDark ? classes.switchBaseDark : classes.switchBase,
                thumb: isDark ? classes.thumbDark : classes.thumb,
                track: isDark ? classes.trackDark : classes.track,
                checked: classes.checked,
            }}
            {...props}
        />
    );
}

export default React.memo(MySwitch);
