import React from "react";
import Box from "@material-ui/core/Box";
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    container: {
        backgroundColor: "#272727",
        display: 'flex',
        height: '100%',
        width: '100%',
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        rowGap: '17px',
    },

    title: {
        fontFamily: "-apple-system, BlinkMacSystemFont, cerebri-regular",
        fontWeight: '400',
        fontSize: 12,
        lineHeight: '14.32px',
        letterSpacing: '0%',
        textAlign: 'center',
        color: "white",
    }
}))

function PlaceholderVideo({title = null, ...props}) {
    const classes = useStyles();

    return (
        <Box {...props} style={{overflow: "hidden"}}>
            <Box className={classes.container}>
                <svg width="40" height="28" viewBox="0 0 40 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M28.3333 18.1667L33.7017 21.3877C35.354 22.3791 36.1802 22.8748 36.86 22.8114C37.4527 22.7562 37.9935 22.45 38.3458 21.9701C38.75 21.4198 38.75 20.4564 38.75 18.5294V9.47063C38.75 7.54375 38.75 6.58021 38.3458 6.02979C37.9935 5.55 37.4527 5.24375 36.86 5.18854C36.1802 5.12521 35.354 5.62083 33.7017 6.61229L28.3333 9.83333M7.91667 1.5H21.6667C24.0002 1.5 25.1671 1.5 26.0583 1.95417C26.8423 2.35354 27.4798 2.99104 27.8792 3.775C28.3333 4.66625 28.3333 5.83313 28.3333 8.16667V19.8333C28.3333 22.1669 28.3333 23.3337 27.8792 24.225C27.4798 25.009 26.8423 25.6464 26.0583 26.0459C25.1671 26.5 24.0002 26.5 21.6667 26.5H7.91667C5.58312 26.5 4.41633 26.5 3.52504 26.0459C2.74102 25.6464 2.1036 25.009 1.70415 24.225C1.25 23.3337 1.25 22.1669 1.25 19.8333V8.16667C1.25 5.83313 1.25 4.66625 1.70415 3.775C2.1036 2.99104 2.74102 2.35354 3.52504 1.95417C4.41633 1.5 5.5831 1.5 7.91667 1.5Z"
                        stroke="#4C4C4C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>


                {title && <div className={classes.title}>{title}</div>}
            </Box>
        </Box>
    )
}

export default PlaceholderVideo;
