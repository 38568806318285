import React, {useEffect} from "react";
import {Box, InputBase, makeStyles, Paper} from "@material-ui/core";
import SearchIcon from "./icons/SearchIcon";
import DrawerMenu from "components/drawerMenu";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import {withRouter} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import * as BaseActions from "store/actions";
import SocketClass from "@utils/socketService";
import DummyImage from "../../../../@assets/dummyImage.png"
import MenuIcon from "./icons/MenuIcon";

// eslint-disable-next-line no-unused-vars
let socket = undefined;

const useStyles = makeStyles((theme) => ({
    container: {
        flexDirection: "row",
        alignItems: "center",
        height: "152px",
    },
    positionFixed: {
        borderRadius: "10px 0px 0px 0px",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: "#080A0A",
        display: "flex",
        rowGap: "15px",
        flexDirection: "column",
        padding: "20px 20px 10px 20px",
        position: "fixed",
        zIndex: "100",
        width: "100%",
        [theme.breakpoints.up("sm")]: {
            width: 373,
        },
    },
    dp: {
        height: "18px",
        width: "27px",
    },
    root: {
        display: "flex",
        flex: 1,
        padding: "0 10px",
        backgroundColor: "#1B1B1B",
        color: "#585858",
        alignItems: "center",
        height: 42,
        gridColumn: 'span 2 / span 2',
        borderRadius: '10px',
        width: '100%',
    },
    userProfileContainer: {
        display: 'flex',
        gap: '15px',
        justifySelf: 'end',
        alignSelf: 'center',
        placeItems: 'center'
    },
    userName: {
        fontFamily: "-apple-system, BlinkMacSystemFont, cerebri-regular",
        fontSize: '16px',
        fontWeight: 600,
        lineHeight: '19.09px',
        textAlign: 'left',
        textUnderlinePosition: 'from-font',
        textDecorationSkipInk: 'none',
        color: '#FFFFFF',
    },
    userProfileImage: {
        height: '50px',
        aspectRatio: '1 / 1',
        borderRadius: '50%',
        cursor: 'pointer',
    },
    inputBase: {
        marginLeft: theme.spacing(1),
        textAlign: "center",
        color: "#1B1B1B",
        width: "100%",
    },
    input: {
        fontFamily: "-apple-system, BlinkMacSystemFont, cerebri-regular",
        width: "100%",
        fontSize: "17px",
        fontWeight: 400,
        lineHeight: '22px',
        letterSpacing: '-0.4300000071525574px',
        textAlign: 'left',
        textUnderlinePosition: 'from-font',
        textDecorationSkipInk: 'none',
        color: '#ADADAD',
        backgroundColor: '#1B1B1B',
    },
    backArrow: {
        color: "#FFFFFF",
        cursor: "pointer",
    },
}));

function Header({seeAll, history, searchBar, onChange, value}) {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [, setIsOn] = React.useState(false);
    const [defaultValue, setDefaultValue] = React.useState("");
    const userData = useSelector(({BaseReducer}) => BaseReducer.UserReducer);

    React.useEffect(() => {
        socket = SocketClass.getSocket();
    }, []);
    React.useEffect(() => {
        dispatch(BaseActions.getUserData());
    }, [dispatch]);

    useEffect(() => {
        if (userData.available === 1) {
            let check = false;

            if (userData === null) {
                console.log("inital falsified");
                setIsOn(false);
            } else if (
                userData.availablility != null ||
                userData.availablility !== undefined
            ) {
                // eslint-disable-next-line no-unused-expressions
                userData?.availablility?.map((avail) => {
                    const date = new Date();
                    date.setDate(date.getDate());

                    if (
                        new Date(avail.date).getUTCDate() === date.getUTCDate()
                    ) {
                        check = true;
                    }
                });
            }

            if (check === false) {
                setIsOn(false);
            } else {
                setIsOn(true);
            }
        } else if (userData.available === 0) {
            let check = false;

            if (userData === null) {
                console.log("inital falsified");
                setIsOn(false);
            } else if (
                userData.availablility != null ||
                userData.availablility !== undefined
            ) {
                // eslint-disable-next-line no-unused-expressions
                userData?.availablility?.map((avail) => {
                    const date = new Date();
                    date.setDate(date.getDate());
                    console.log("current->", date.getDate());
                    if (
                        new Date(avail.date).getUTCDate() ===
                        date.getUTCDate()
                    ) {
                        check = true;
                        console.log("truthified");
                    }
                });
            }

            if (check === false) {
                console.log("final falsifiedeee");
                setIsOn(false);
            } else {
                console.log("final truthified");
                setIsOn(true);
            }
        }
    }, [userData.available]);

    const handleNavigate = () => {
        !searchBar && history.push("/search");
    };

    const defaultOnChange = (event) => {
        setDefaultValue(event.target.value);
    };

    const handleDrawer = () => {
        dispatch(BaseActions.hideMessage());
        dispatch(BaseActions.showMessage(<DrawerMenu/>));
    };

    const userImage = userData.profile_media_urls[0] === "https://api.quartrly.com/uploads/default_pic.png" ||
    userData.profile_media_urls[0] === "" ? DummyImage : userData.profile_media_urls[0];

    return (
        <Box className={classes.container}>
            <Box className={classes.positionFixed}>
                <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between"
                    width="100%"
                >
                    {
                        seeAll
                        ?
                            <ArrowBackIosIcon className={classes.backArrow} onClick={() => history.push("/home")}/>
                        :
                            <span className={classes.dp} onClick={() => handleDrawer()}>
                                <MenuIcon/>
                            </span>
                    }

                    <div className={classes.userProfileContainer}>
                        <div className={classes.userName}>
                            {userData.full_name}
                        </div>
                        <img
                            src={userImage}
                            className={classes.userProfileImage}
                            alt="User Profile Image"
                            onClick={() => history.push("/profile")}
                        ></img>
                    </div>
                </Box>

                <Paper className={classes.root}>
                    <SearchIcon/>

                    <InputBase
                        className={classes.inputBase}
                        placeholder="Search"
                        onClick={() => handleNavigate()}
                        inputProps={{
                            className: classes.input,
                            value: value ? value : defaultValue,
                            onChange: onChange ? onChange : defaultOnChange,
                        }}
                    />
                </Paper>
            </Box>
        </Box>
    );
}

export default withRouter(Header);
