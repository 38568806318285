import * as Actions from "../actions";

// initial state
const initialState = {
  isLoading: false,
  errMsg: null,
  data: null,
};

// Reducer for SIGNUP handling
export const SubmitSignupReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.SIGNUP_SUBMIT:
      // we will mould our payload here accordingly  if required  in case of success
      return { ...state, isLoading: false, errMsg: null, data: action.payload };
    case Actions.SIGNUP_SUBMIT_IS_LOADING:
      // ... in case of loading
      return { ...state, isLoading: true, errMsg: null, data: null };
    case Actions.SIGNUP_SUBMIT_FAILED:
      // ... in case of error
      return { ...state, isLoading: false, errMsg: action.payload, data: null };
    default:
      return state;
  }
};

// Reducer for SIGNUP handling
export const UpdateProfileReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.UPDATE_PROFILE:
      // we will mould our payload here accordingly  if required  in case of success
      return { ...state, isLoading: false, errMsg: null, data: action.payload };
    case Actions.UPDATE_PROFILE_IS_LOADING:
      // ... in case of loading
      return { ...state, isLoading: true, errMsg: null, data: null };
    case Actions.UPDATE_PROFILE_FAILED:
      // ... in case of error
      return { ...state, isLoading: false, errMsg: action.payload, data: null };
    case Actions.RESET_PROFILE_REDUCER:
      // ... in case of error
      return { isLoading: false, errMsg: null, data: null };
    default:
      return state;
  }
};
// Reducer for get user by referral code
export const GetUserByReferralCodeReducer = function (
  state = initialState,
  action
) {
  switch (action.type) {
    case Actions.GET_USER_BY_REFERRAL_CODE:
      // we will mould our payload here accordingly  if required  in case of success
      return { ...state, isLoading: false, errMsg: null, data: action.payload };
    case Actions.USER_BY_REFERRAL_CODE_IS_LOADING:
      // ... in case of loading
      return { ...state, isLoading: true, errMsg: null, data: null };
    case Actions.USER_BY_REFERRAL_CODE_FAILED:
      // ... in case of error
      return { ...state, isLoading: false, errMsg: action.payload, data: null };
    default:
      return state;
  }
};

// Reducer for set availability handling
export const SetAvailabilityReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.SET_AVAILABILITY:
      // we will mould our payload here accordingly  if required  in case of success
      return { ...state, isLoading: false, errMsg: null, data: action.payload };
    case Actions.AVAILABILITY_IS_LOADING:
      // ... in case of loading
      return { ...state, isLoading: true, errMsg: null, data: null };
    case Actions.AVAILABILITY_FAILED:
      // ... in case of error
      return { ...state, isLoading: false, errMsg: action.payload, data: null };
    case Actions.RESET_AVAILABILITY_REDUCER:
      // ... in case of error
      return { isLoading: false, errMsg: null, data: null };
    default:
      return state;
  }
};
