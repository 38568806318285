import React from "react";
import { withRouter } from "react-router";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
//// functions need to use react with our component
import { useDispatch, useSelector } from "react-redux";
import useDeepCompareEffect from "use-deep-compare-effect";
// import reducer from "./store/reducers";
// import withReducer from "store/withReducer";
// import * as Actions from "./store/actions";
import * as BaseActions from "store/actions";
import DummyImage from "../../../../@assets/dummyImage.png"
import PlaceholderImage from "../userCard/icons/PlaceholderImage";

const useStyles = makeStyles((theme) => ({
	timer: {
		backgroundColor: "#09161C",
		borderRadius: "16px",
		margin: "0 20px 20px 20px",
		padding: "10px 10px",
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",

		"& > .image": {
			height: "68px",
			width: "68px",
			borderRadius: "16px",
			overflow: "hidden",
		},
		"& > .timer": {
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			marginLeft: "17px",
			marginTop: "5px",
		},
		"& > .button": {
			backgroundColor: "#4CDE5F",
			borderRadius: "6px",
			height: "28px",
			display: "flex",
			alignItems: "center",
			justifyContent: "space-around",
			width: "100%",
			maxWidth: "65px",
			cursor: "pointer",
		},
	},
}));

function QuartrTimer({ history }) {
	const dispatch = useDispatch();
	const [timer, setTimer] = React.useState({ timer: "", stamp: new Date() });
	const [userProfile, setUserProfile] = React.useState("");
	const timerResponse = useSelector(
		({ BaseReducer }) => BaseReducer.TimerReducer
	);

	let goToQuartr = () => history.push(`/quartr/${timerResponse.data._id}`);
	let timerConstructor = (duration) => {
		var timer = duration;
		let minutes = "";
		let seconds = "";
		let interval = setInterval(function () {
			minutes = parseInt(timer / 60, 10);
			seconds = parseInt(timer % 60, 10);

			minutes = minutes < 10 ? "0" + minutes : minutes;
			seconds = seconds < 10 ? "0" + seconds : seconds;
			let completeTime = minutes + ":" + seconds;
			setTimer({ timer: completeTime, stamp: new Date() });

			if (--timer < 0) {
				clearInterval(interval);
				setTimer({ timer: "00:00", stamp: new Date() });
				goToQuartr();
			}
		}, 1000);
	};
	useDeepCompareEffect(() => {
		if (timerResponse.state) {
			if (userProfile) {
				if (userProfile === timerResponse.profileImage) {
					return;
				}
			}
			setUserProfile(timerResponse.data.profileImage);
		}
	}, [timerResponse]);

	// React.useEffect(() => {
	//   setTimeout(() => {

	//     dispatch(BaseActions.hideTimer())
	//   }, 1000);
	// }, []);

	const classes = useStyles();
	return (
		<>
			{timerResponse.state && (
				<Box className={classes.timer}>
					{/* img */}
					<Box className="image">
						{userProfile === "https://narsunprojects.com:3001/uploads/default_pic.png" || userProfile === ""
							? <PlaceholderImage height="100%" width="100%" />
							: <img
								style={{ height: "100%", width: "100%" }}
								src={userProfile}
								alt="sdf"
							/>
						}
					</Box>
					<Box className="timer">
						<Typography variant="subtitle1" style={{ fontSize: "12px" }}>
							Quartr starts in:
						</Typography>
						<Typography variant="subtitle1" style={{ fontSize: "24px" }}>
							{timerResponse.data.timer}
						</Typography>
					</Box>
					<Box className="button" onClick={() => goToQuartr()}>
						<Typography variant="subtitle1" style={{ fontSize: "16px" }}>
							Join
						</Typography>
					</Box>
				</Box>
			)}
		</>
	);
}

export default withRouter(React.memo(QuartrTimer));
