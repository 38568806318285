import React, { useCallback } from "react";
import { Box, Button, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import queryString from 'query-string'
import Referal from "components/signup/screens/referal";
import Slider from '@material-ui/core/Slider';
import { useDispatch, useSelector } from "react-redux";
import reducer from "../signupForm/store/reducers";
import withReducer from "store/withReducer";
import * as Actions from "../signupForm/store/actions";
import * as BaseActions from "store/actions";
import Cropper from 'react-easy-crop'
import { RotateLeft, RotateRight } from "@material-ui/icons";
import getCroppedImg from './../../../editProfile/cropImage'
import DummyImage from '../../../../@assets/dummyImage.png'
import imageCompression from 'browser-image-compression';
import { isMobile, isIOS } from "react-device-detect";
import {uploadWithPresignedURL} from "../../../../@utils/uploadWithPresignedURL";
const useStyles = makeStyles((theme) => ({
    continer: {
        marginTop: isMobile ? "20px" : "10px",
        width: "100%",
        height: isMobile ? "60%" : "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: isMobile ? "inherit" : "center",
        alignItems: "center",
        overflowY: "scroll",
        marginBottom: "80px"
    },
    imgContiner: {
        width: "100%",
        height: "320px",
        display: "flex",
        borderRadius: "6px",
        flexDirection: "column",
        justifyContent: "center",
        position: "relative",
    },
    img: {
        width: "100%",
        height: "100%",
        objectFit: "cover"
    },
    imgButton: {
        backgroundColor: "#353535",
        borderRadius: "6px",
        height: "36px",
        width: "100%",
        color: "#FFFFFF",
        outline: "none",
        fontSize: "16px"
    },
    btnContainer: {
        width: "100%",
        padding: "0 10px 10px 10px",
        position: "absolute",
        bottom: 0,
        display: "flex",
        justifyContent: "center",
    },
    btnBottomContainer: {
        width: "370px",
        padding: "0 10px 10px 10px",
        position: "fixed",
        marginBottom: "30px",
        [theme.breakpoints.up("sm")]: {
            bottom: 40,
        },
    },
    btnChangeBottomContainer: {
        width: "375px",
        // position: "fixed",
        marginBottom: "30px",
        bottom: 0,
        justifyContent: "space-between",
        position: "absolute",
        display: "flex",
        padding: "0 30px 0 30px",
        // width: isMobile ? "331px" : "100%",
        [theme.breakpoints.up("sm")]: {
            bottom: 40,
            marginBottom: "8px",
            padding: "0 30px 0 30px",
        },
        [theme.breakpoints.down("xs")]: {
            bottom: 0,
            position: "unset",
            marginBottom: "0px",
            marginTop: isMobile && isIOS ? '185px' : '136px'
        }
    },
    bottomBtn: {
        backgroundColor: "#080A0A",
        marginTop: "10px",
        marginBottom: 0,
        [theme.breakpoints.up("sm")]: {
            marginBottom: 40,
        },
        // [theme.breakpoints.between('md', 'xl')]: {
        //     marginBottom: 25,
        // },
        [theme.breakpoints.down("sm")]: {
            marginTop: "18px",
        },
    },
    changePicButton: {
        cursor: "pointer",
        position: "absolute",
        bottom: 5,
        left: 5,
        backgroundColor: "rgba(0,0,0,0.7)",
        borderRadius: 6,
        width: "90%",
        textAlign: "center",
        fontSize: 11,
        padding: 5,
    },
    otherChangePicButton: {
        marginLeft: "10px",
        marginRight: "10px",
        cursor: "pointer",
        backgroundColor: "#353535",
        borderRadius: 6,
        width: "280px",
        textAlign: "center",
        fontSize: 18,
        padding: 5,
    },
    imgOuterContainer: {
        [theme.breakpoints.up("sm")]: {
            width: "370px",
            height: `${height}px`,
            marginLeft: "-28px",
            marginTop: "42px"
        },
        marginLeft: "-30px",
        top: 0,
        position: "fixed",
        width: "100%",
        height: `100vh`,
        zIndex: "100",
        backgroundColor: "#080A0A",
    },
    cropContainer: {
        position: "absolute",
        top: 20,
        zIndex: "100",
        width: "320px",
        height: "300px",
    },
    rotateBtn: {
        border: "1px solid silver",
        borderRadius: 6,
        padding: "5px 5px 0 5px",
        cursor: "pointer",
        color: "silver"
    },
    cancelButton: {
        backgroundColor: "#080A09",
        border: '1px solid silver',
    }
}));
function viewportToPixels(value) {
    var parts = value.match(/([0-9\.]+)(vh|vw)/)
    var q = Number(parts[1])
    var side = window[['innerHeight', 'innerWidth'][['vh', 'vw'].indexOf(parts[2])]]
    return side * (q / 100)
}
const height = viewportToPixels("100vh") - 80
// const PrettoSlider = withStyles({
//     root: {
//         color: '#52af77',
//         height: 8,
//     },
//     thumb: {
//         height: 24,
//         width: 24,
//         backgroundColor: '#fff',
//         border: '2px solid currentColor',
//         marginTop: -8,
//         marginLeft: -12,
//         '&:focus, &:hover, &$active': {
//             boxShadow: 'inherit',
//         },
//     },
//     active: {},
//     valueLabel: {
//         left: 'calc(-50% + 4px)',
//     },
//     track: {
//         height: 8,
//         borderRadius: 4,
//     },
//     rail: {
//         height: 8,
//         borderRadius: 4,
//     },
// })(Slider);
const ProfileImage = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    // const [username, setUsername] = React.useState("");
    // const [showUsername, setShowUsername] = React.useState('fullname');
    // const [enabledUsername, setEnabledUsername] = React.useState(false);
    // const [isAvailable, setIsAvailable] = React.useState(false);
    // const [loading, setLoading] = React.useState(false);
    // const [isFirst, setIsFirst] = React.useState(true);
    // const [isDisable, setIsDisable] = React.useState(true);
    const [openReferal, setOpenReferal] = React.useState(false);
    const [imgIndex, setImgIndex] = React.useState(0)
    const [showImage, setShowImage] = React.useState(null)
    const [showCropedImage, setShowCropedImage] = React.useState(false)
    const [compressImage, setCompressImage] = React.useState(false)
    const [crop, setCrop] = React.useState({ x: 0, y: 0 })
    const [zoom, setZoom] = React.useState(3)
    const [rotationValue, setRotationValue] = React.useState(0);
    const [croppedAreaPixels, setCroppedAreaPixels] = React.useState(null)
    const [croppedImage, setCroppedImage] = React.useState(null)
    const [imageFile, setImageFile] = React.useState(null)
    const [showFinalImage, setShowFinalImage] = React.useState(null)
    const { layout } = useSelector(({ BaseReducer }) => ({
        layout: BaseReducer.LayoutReducer,
    }));
    const userData = useSelector(({ BaseReducer }) => BaseReducer.UserReducer);
    if (layout.bottomNav) dispatch(BaseActions.updateLayout({ bottomNav: false }));
    const id = queryString.parse(props.location.search).id
    const handleSetProfilePhoto = async () => {
        setShowCropedImage(false)
        const croppedImage = await getCroppedImg(
            showImage,
            croppedAreaPixels,
            rotationValue
        )
        if (compressImage) {
            const options = {
                maxSizeMB: 0.4,
                maxWidthOrHeight: 1920,
                useWebWorker: true
            }
            const compressedFile = await imageCompression(imageFile, options);
            setCroppedImage(croppedImage)
            setShowFinalImage(URL.createObjectURL(compressedFile))
            const url = await uploadingFile(compressedFile);
            setShowImage(null)
            dispatch(
                Actions.updateProfile({
                    profile_media_urls: [url]
                })
            );
            if (id) {
                props.history.push(`/expertise?id=${id}`);
            } else {
                props.history.push('/expertise');
            }
        } else {
            setCroppedImage(croppedImage)
            setShowFinalImage(URL.createObjectURL(imageFile))
            const url = await uploadingFile(imageFile);
            setShowImage(null)
            dispatch(
                Actions.updateProfile({
                    profile_media_urls: [url]
                })
            );
            if (id) {
                props.history.push(`/expertise?id=${id}`);
            } else {
                props.history.push('/expertise');
            }
        }
    };
    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
        setCroppedImage(null)
        setShowFinalImage(null)
    }, [])
    const fileHandler = async (e, i) => {
        setImgIndex(i)
        const file = e.target.files[0];
        e.target.value = ''
        var img = file.size;
        var imgsize = img / 1024;
        if (imgsize > 400) {
            setCompressImage(true)
            const options = {
                maxSizeMB: 0.4,
                maxWidthOrHeight: 1920,
                useWebWorker: true
            }
            const compressedFile = await imageCompression(file, options);
            setShowImage(URL.createObjectURL(compressedFile))
            setShowCropedImage(true)
        } else {
            setShowImage(URL.createObjectURL(file))
            setShowCropedImage(true)
        }
    };
    const showCroppedImage = useCallback(async () => {
        try {
            setShowCropedImage(false)
            const croppedImage = await getCroppedImg(
                showImage,
                croppedAreaPixels,
                rotationValue
            )
            var blob = croppedImage.slice(0, croppedImage.size, "image/*");
            // const link = `abc/pic`;
            const link = `${userData._id}/pic${0}`;
            const newFile = new File([blob], link, { type: "image/*" });
            setImageFile(newFile)
            setCroppedImage(croppedImage)
            setShowFinalImage(URL.createObjectURL(newFile))
        } catch (e) {
            console.error(e)
        }
    }, [croppedAreaPixels, rotationValue])
    const uploadingFile = async (file) => {
        dispatch(BaseActions.showLoading(true));
        try {
            const objectUrl = await uploadWithPresignedURL(file);
            dispatch(BaseActions.hideLoading());
            return objectUrl;
        } catch (error) {
            dispatch(BaseActions.hideLoading());
        }
    };
    return (
        <>
            {!openReferal ? (
                <Box
                    display="flex"
                    flexDirection="column"
                    height="100%"
                    position="absolute"

                    width="100%"
                >
                    <Box padding={isMobile ? "15px 30px 0px 30px" : "32px 30px 0px 30px"} margin="0 0 20px 0">
                        <Box
                            display="flex"
                            flexDirection="column"
                        >
                            <Typography variant="h1">Upload Your Profile Photo </Typography>
                        </Box>
                    </Box>
                    <Box width="100%" padding={isMobile ? "15px 30px 0px 30px" : "32px 30px 0px 30px"} overflowY="scroll" className={classes.continer}>
                        <Box className={classes.imgContiner}>
                            {
                                croppedImage ?
                                    <img src={showFinalImage} alt="final" className={classes.img} />
                                    :
                                    // <img src={Pics.img} className={classes.img}
                                    // <img src=`${process.env.REACT_APP_ENV === "prod" ? process.env.REACT_APP_BASE_URL_PROD :process.env.REACT_APP_BASE_URL_TESTING}uploads/default_pic.png?1610711811682` className={classes.img} />>
                                    <img src={DummyImage} alt="dummy" className={classes.img} />
                            }
                            <Box className={classes.btnContainer}>
                                {/* <input type="file" className={classes.imgButton} /> */}
                                <label htmlFor={"button-file"}>
                                    <Typography
                                        className={
                                            // img && img !== `${process.env.REACT_APP_ENV === "prod" ? process.env.REACT_APP_BASE_URL_PROD :process.env.REACT_APP_BASE_URL_TESTING}uploads/default_pic.png`
                                            //     ? classes.changePicButton
                                            //     : classes.otherChangePicButton
                                            classes.otherChangePicButton

                                        }
                                    >
                                        {
                                            showFinalImage ? "change" : "Upload"
                                        }
                                    </Typography>
                                </label>
                                <input
                                    accept="image/*"
                                    onChange={(e) => fileHandler(e, 0)}
                                    style={{ display: "none" }}
                                    id="button-file"
                                    type="file"
                                />
                            </Box>
                            {
                                showCropedImage &&
                                <Box className={classes.imgOuterContainer}>
                                    <Box style={{
                                        width: "100%",
                                        height: "350px",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center"
                                    }}>
                                        <Box className={classes.cropContainer}>
                                            <Cropper
                                                image={showImage}
                                                crop={crop}
                                                zoom={zoom}
                                                rotation={rotationValue}
                                                aspect={4 / 4}
                                                onCropChange={setCrop}
                                                onCropComplete={onCropComplete}
                                                onZoomChange={setZoom}
                                            />
                                        </Box>
                                    </Box>
                                    <Box padding="0 32px 0 32px" width="100%">
                                        <div
                                            style={{
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                                width: "100%",
                                                display: "flex",
                                            }}
                                            className={classes.root}
                                        >
                                            <div className={classes.rotateBtn} onClick={() => setRotationValue(rotationValue - 90)}><RotateLeft /></div>
                                            <Box padding="0 20px 0 20px" width="100%">
                                                <div className={classes.root}>
                                                    <Slider value={zoom} min={1} max={5} step={0.1} onChange={(e, newValue) => setZoom(newValue)} aria-label="pretto slider" />
                                                </div>
                                            </Box>
                                            <div className={classes.rotateBtn} onClick={() => setRotationValue(rotationValue + 90)}><RotateRight /></div>
                                        </div>
                                    </Box>
                                    {/* <Box
                                        className={classes.btnChangeBottomContainer}
                                    > */}
                                    <Box position={isMobile && "fixed"} width={isMobile ? "100%" : "auto"} bottom={isMobile && 15}
                                        padding="auto 20px 10px 20px"
                                        padding="30px"
                                        paddingBottom={isMobile ? "10px" : "32px"}
                                        display="flex"
                                        justifyContent=" space-between"
                                    >
                                        <div className={classes.root}>
                                            <Button variant="outlined" style={{ width: 142 }} className={classes.cancelButton} onClick={() => setShowCropedImage(false)}>Cancel</Button>

                                        </div>
                                        <div className={classes.root}>
                                            <Button style={{ width: 142 }} onClick={showCroppedImage}>Save</Button>
                                        </div>
                                    </Box>
                                </Box>
                            }
                        </Box>
                    </Box>
                    <Box position={isMobile && "fixed"} width={isMobile ? "100%" : "auto"} bottom={isMobile && 15}
                        padding="auto 20px 10px 20px"
                        padding="30px"
                        paddingBottom="50px"
                        paddingBottom={isMobile ? "10px" : "32px"}
                        display="flex"
                        justifyContent=" space-between"
                    >
                        <Button
                            to="/expertise"
                            component={Link}
                            variant="outlined"
                            color="default"
                            style={{ width: "45%", backgroundColor: "#080A0A" }}
                        >
                            {"Skip"}
                        </Button>
                        <Button
                            disabled={!croppedImage}
                            onClick={() => handleSetProfilePhoto()}
                            // color="primary"
                            style={{ width: "45%" }}
                        >
                            {/* {isLoading ? <Spinner /> : "Ok"} */}
                            {"Ok"}
                        </Button>
                    </Box>
                </Box>
            ) : (
                <Referal />
            )
            }
        </>
    );
};
export default withReducer("ProfileImage", reducer)(withRouter(ProfileImage));
