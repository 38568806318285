import {Box, makeStyles, Typography} from "@material-ui/core";
import React from "react";
import Tile from "./tile";
import CelebrityIcon from "./icons/CelebrityIcon";
import BrainIcon from "./icons/BrainIcon";
import InfluencerIcon from "./icons/InfluencerIcon";
import AthleteRunningIcon from "./icons/AthleteRunningIcon";

const useStyles = makeStyles((theme) => ({
    container: {
        padding: "0 20px 0px 20px",
        color: "#31A7DB",
        fontWeight: 500,
        marginBottom: '1rem',
        fontSize: 18,
        fontFamily: 'cerebri-semibold'
    },
    gridContainer: {
        display: 'flex',
        flexDirection: "row",
        justifyContent: "space-between",
        gap: '1rem'
    }
}))

function FindYourPersonSection({ history }) {
    const classes = useStyles();
    const types = [
        {
            title: 'Celebrity',
            icon: <CelebrityIcon />
        },
        {
            title: 'Influencer',
            icon: <InfluencerIcon />
        },
        {
            title: 'Athlete',
            icon: <AthleteRunningIcon />
        },
        {
            title: 'Expert',
            icon: <BrainIcon />
        }
    ];

    return (
        <Box className={classes.container}>
            <Typography variant="body2" style={{marginBottom: 15}}>Find Your Person</Typography>

            <div className={classes.gridContainer}>
                {types.map((t, i) => (
                    <Tile
                        title={t.title}
                        icon={t.icon}
                        key={i}
                        style={{ cursor: "pointer" }}
                        onClick={() => history.push({ pathname: `/seeAll/${t.title}` })}
                    />
                ))}
            </div>
        </Box>
    )
}



export default FindYourPersonSection
