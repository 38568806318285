import axios from "axios";

const baseUrl = process.env.REACT_APP_ENV === "prod"
    ? process.env.REACT_APP_BASE_URL_PROD
    : process.env.REACT_APP_BASE_URL_TESTING;

export function getLeaderboardData(params) {

    try {
        const request = axios.get(
            `${baseUrl}users/get-leaderboard-data?${new URLSearchParams(params)}`
        );

        return request
            .then((response) => {
                return handleSuccess(response);
            })
            .catch((error) => {
                return handleFailure(error);
            });
    } catch (error) {
        console.log({ error })
    }
}

const handleSuccess = (response) => {
    if (response.data && response.data.status === "Success") {
        return response.data;
    } else {
        let err = Error();
        err.message = response.data.message;
        throw err;
    }
}

const handleFailure = (error) => {
    let ms = '';
    if (error.response) {
        ms = error.response.status === "404" ? `${error.response.status} : api not found` : error.response.status;
        return ms;
    } else {
        ms = error.request ? error.request : error.message;
        return ms;
    }
}