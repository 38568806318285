import axios from "axios";
import * as BaseActions from "store/actions";

//SUBMIT LOGIN ACTION NAMES
export const FETCH_QUARTR = "FETCH_QUARTR";
export const QUARTR_IS_LOADING = "QUARTR_IS_LOADING";
export const QUARTR_FAILED = "QUARTR_FAILED";

// CURRENT QUARTR STATE

export const CURRENT_QUARTR_STATE = "FETCH_QUARTR";
export const QUARTR_STATE_IS_LOADING = "QUARTR_STATE_IS_LOADING";
export const QUARTR_STATE_FAILED = "QUARTR_STATE_FAILED";

// Helper ACTIONS:
export const quartrLoading = (ms) => ({
  type: QUARTR_IS_LOADING,
  payload: ms,
});

export const quartrFailed = (ms) => ({
  type: QUARTR_FAILED,
  payload: ms,
});
// Helper ACTIONS:
export const quartrStateLoading = (ms) => ({
  type: QUARTR_STATE_IS_LOADING,
  payload: ms,
});

export const quartrStateFailed = (ms) => ({
  type: QUARTR_STATE_FAILED,
  payload: ms,
});

export function fetchQuartr(id) {
  // alert(id)
  let data = { appointment_id: id };
  const token = localStorage.getItem("JWTtoken");

  // console.log(data, "==>params", token, "<== token");

  const config = {
    headers: {
      Authorization: `JWT ${token}`,
      // "Content-Type": "application/json",
    },
  };
  const request = axios.post(
    `${process.env.REACT_APP_ENV === "prod" ? process.env.REACT_APP_BASE_URL_PROD : process.env.REACT_APP_BASE_URL_TESTING}appointments/get-appointment`,
    data,
    config
  );
  return (dispatch) => {
    dispatch(quartrLoading(true));
    dispatch(BaseActions.showLoading(true));
    request
      .then((response) => {
        if (response.data && response.data.status === "Success") {
          dispatch({
            type: FETCH_QUARTR,
            payload: response.data,
          });
          dispatch(BaseActions.hideLoading());
        } else {
          let err = Error();
          err.message = response.data.message;
          throw err;
        }
      })
      .catch((error) => {
        dispatch(BaseActions.hideLoading());
        // dispatch(
        //   BaseActions.showAlert({ variant: "error", message: error.message })
        // );
        if (error.response) {
          if (error.response.status === "404") {
            dispatch(quartrFailed(`${error.response.status} : api not found`));
          } else {
            dispatch(quartrFailed(error.response.status));
          }
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          dispatch(quartrFailed(error.request));

          console.log(error.request);
        } else {
          console.log("Error", error.message);
          dispatch(quartrFailed(error.message));
        }
        console.log(error);
      });
  };
}
export function checkQuartrState() {
  const token = localStorage.getItem("JWTtoken");

  // console.log(data, "==>params", token, "<== token");

  const config = {
    headers: {
      Authorization: `JWT ${token}`,
      // "Content-Type": "application/json",
    },
  };
  const request = axios.post(
    `${process.env.REACT_APP_ENV === "prod" ? process.env.REACT_APP_BASE_URL_PROD : process.env.REACT_APP_BASE_URL_TESTING}appointments/get-appointment-status`,
    {},
    config
  );
  return (dispatch) => {
    dispatch(quartrStateLoading(true));
    // dispatch(BaseActions.showLoading(true));
    request
      .then((response) => {
        if (response.data && response.data.status === "Success") {
          console.log({ response })
          dispatch({
            type: CURRENT_QUARTR_STATE,
            payload: response.data,
          });
          // dispatch(BaseActions.hideLoading());
        } else {
          let err = Error();
          err.message = response.data.message;
          throw err;
        }
      })
      .catch((error) => {
        // dispatch(
        //   BaseActions.showAlert({ variant: "error", message: error.message })
        // );
        if (error.response) {
          if (error.response.status === "404") {
            dispatch(
              quartrStateFailed(`${error.response.status} : api not found`)
            );
          } else {
            dispatch(quartrStateFailed(error.response.status));
          }
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          dispatch(quartrStateFailed(error.request));

          console.log(error.request);
        } else {
          console.log("Error", error.message);
          dispatch(quartrStateFailed(error.message));
        }
        console.log(error);
      });
  };
}
