import {Box} from "@material-ui/core";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    container: { backgroundColor: "rgba(49, 167, 219, 1)", cursor: "pointer" },
}));

export const EditButton = ({ size, border, ...props }) => {
    const classes = useStyles();

    return (
        <Box height={size} width={size} display="flex" alignItems="center" justifyContent="center"
             position="absolute" borderRadius={"50%"} border={'3px solid white'} {...props}
             className={classes.container}>
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_93_434)">
                    <path
                        d="M13.5641 1.7035L12.2955 0.434749C11.7158 -0.14491 10.7727 -0.144882 10.1931 0.434749L9.69653 0.931338L13.0676 4.30265L13.5641 3.80606C14.1451 3.22504 14.1452 2.28458 13.5641 1.7035Z"
                        fill="white"/>
                    <path
                        d="M0.601887 10.3017L0.00686094 13.5151C-0.017721 13.6479 0.024607 13.7843 0.120119 13.8798C0.215739 13.9755 0.352184 14.0177 0.484829 13.9931L3.69804 13.3981L0.601887 10.3017Z"
                        fill="white"/>
                    <path
                        d="M9.11654 1.5114L1.04443 9.58413L4.41547 12.9554L12.4876 4.88271L9.11654 1.5114Z"
                        fill="white"/>
                </g>
                <defs>
                    <clipPath id="clip0_93_434">
                        <rect width="14" height="14" fill="white"/>
                    </clipPath>
                </defs>
            </svg>
        </Box>
    )
}
