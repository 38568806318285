import * as Actions from "../actions";

// initial state
const initialState = {
  isLoading: false,
  errMsg: null,
  data: null,
};

// Reducer for LOGIN handling
export const SubmitRatingReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.SUBMIT_RATING:
      // we will mould our payload here accordingly  if required  in case of success
      return { ...state, isLoading: false, errMsg: null, data: action.payload };
    case Actions.SUBMIT_RATING_IS_LOADING:
      // ... in case of loading
      return { ...state, isLoading: true, errMsg: null, data: null };
    case Actions.SUBMIT_RATING_FAILED:
      // ... in case of error
      return { ...state, isLoading: false, errMsg: action.payload, data: null };

    case Actions.RESET:
      // ... in case of reset

      return { ...state, isLoading: false, errMsg: null, data: null };
    default:
      return state;
  }
};

export const UpdateProfileReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.UPDATE_PROFILE:
      // we will mould our payload here accordingly  if required  in case of success
      return { ...state, isLoading: false, errMsg: null, data: action.payload };
    case Actions.UPDATE_PROFILE_IS_LOADING:
      // ... in case of loading
      return { ...state, isLoading: true, errMsg: null, data: null };
    case Actions.UPDATE_PROFILE_FAILED:
      // ... in case of error
      return { ...state, isLoading: false, errMsg: action.payload, data: null };
    case Actions.RESET_PROFILE_REDUCER:
      // ... in case of error
      return { isLoading: false, errMsg: null, data: null };
    default:
      return state;
  }
};
