import React, { useEffect, useState } from "react";
import { Typography, makeStyles, Box, Button, ListItem, ListItemAvatar, ListItemText, withStyles, Slide } from "@material-ui/core";
import clsx from 'clsx';
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import * as BaseActions from 'store/actions';
import { useDispatch, useSelector } from 'react-redux';
import Header from "@base/header";
import AccountBalanceOutlinedIcon from '@material-ui/icons/AccountBalanceOutlined';
import ClearIcon from '@material-ui/icons/Clear';
import { pasteAuthHeaders } from '@utils'
import { isAndroid, isIOS } from "react-device-detect";
const StyledListItemText = withStyles({
    primary: {
        fontSize: 16,
        // fontWeight: 600,
        fontFamily: 'Cerebri-Regular',
    },
    secondary: {
        color: '#808080',
        fontSize: 14,
        fontFamily: 'cerebri-light',
    }
})(ListItemText)
const PopupBtn = withStyles({
    root: {
        // height: 42,
        '& .MuiButton-label': {
            fontSize: 18,
        },
    },
})(Button)
const useStyles = makeStyles((theme) => ({
    subTitleContainer: {
        marginTop: '5rem'
    },
    subTitle: {
        color: '#808080',
        fontSize: '16px',
        fontFamily: 'Cerebri-Regular',
        textAlign: 'center'
    },
    balance: {
        fontSize: '65px',
        color: '#4CDE5F',
        fontFamily: 'Cerebri-Bold',
        margin: '0.5rem 0 5rem 0'
    },
    addAccountContainer: {
        width: '100%',
        display: "flex",
        flexDirection: "column",
        // alignItems: "center",
        position: 'absolute',
        bottom: 80,
        // padding: "0% 5%",
    },
    addAccount: {
        width: '90%',
        fontFamily: 'Cerebri-Bold',
    },
    payoutText: {
        fontSize: 14,
    },
    bankIconWrapper: {
        height: 50,
        width: 50,
        marginRight: '1rem',
        backgroundColor: '#08171C',
        borderRadius: 4,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    clearIcon: {
        cursor: 'pointer',
    },
    backdrop: {
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '91vh',
        [theme.breakpoints.down('xs')]: {
            height: '100vh',
        },
    },
    confirmWrapper: {
        backgroundColor: '#111111',
        padding: '2rem',
        position: 'absolute',
        paddingBottom: "60px",
        zIndex: 1000,
        bottom: 0,
        left: 0,
        width: '100%',
    },
    cancelHeading: {
        color: '#31A7DB',
        fontWeight: 400,
        marginBottom: '1rem',
        fontSize: 18,
        fontFamily: 'Cerebri-SemiBold'
    },
    cancelConfirmText: {
        color: '#808080',
        marginBottom: '2rem',
        fontWeight: '400',
        fontSize: '16px'
    },
    noBtn: {
        marginRight: '1rem',
    },
    buttons: {
        // width: "46%"
    }
}))
function Balance() {
    const classes = useStyles();
    const dispatch = useDispatch()
    const [removeAccount, setRemoveAccount] = useState(false)
    const [userBank, setUserBank] = useState(null)
    const [userBankStatus, setUserBankStatus] = useState("")
    // By Shahroze \/\/\/\/\/\/\/\/\/\/
    const [accountAdded, setAccountAdded] = useState(false)
    const [color, setColor] = useState("");
    const [err, setErr] = useState(true)
    // By Shahroze /\/\/\/\/\/\/\/\/\/\
    const { isDrawerOpen, user } = useSelector(({ BaseReducer }) => ({
        isDrawerOpen: BaseReducer.MessageReducer.state,
        user: BaseReducer.UserReducer,
    }))
    const history = useHistory()
    useEffect(() => {
        dispatch(BaseActions.updateLayout({ bottomNav: true }))
        dispatch(BaseActions.showLoading(true))
        axios.get(`${process.env.REACT_APP_ENV === "prod" ? process.env.REACT_APP_BASE_URL_PROD : process.env.REACT_APP_BASE_URL_TESTING}ach/get-ach-details`, pasteAuthHeaders())
            .then(({ data: { status, message, data } }) => {
                if (status?.toLowerCase() === 'fail') throw new Error(message)
                dispatch(BaseActions.setUserBankingData(data[0]))
                setUserBank(data[0])
            })
            .catch((err) => {
                // dispatch(BaseActions.showAlert({
                //     variant: 'error',
                //     message: 'Failed to get banking details',
                // }))
            })
            .finally(() => dispatch(BaseActions.hideLoading()))
    }, [])
    useEffect(() => {
        // By Shahroze \/\/\/\/\/\/\/\/\/\/
        if (history.location.state === "account-added") {
            dispatch(BaseActions.updateLayout({ bottomNav: false }));
            setTimeout(() => {
                dispatch(BaseActions.updateLayout({ bottomNav: true }));
                setAccountAdded(true)
                history.location.state = ""
            }, 2000)
        }
        // By Shahroze /\/\/\/\/\/\/\/\/\/\
        // if (!isDrawerOpen) {
        //     dispatch(BaseActions.updateLayout({ bottomNav: false }))
        // }
    }, [history.location.state])
    const handleRemoveClick = () => {
        dispatch(BaseActions.showLoading(true))
        // console.log(userBank)
        const data = { ach_id: userBank._id }
        // console.log(data.ach_id)
        axios({
            method: 'delete',
            url: `${process.env.REACT_APP_ENV === "prod" ? process.env.REACT_APP_BASE_URL_PROD : process.env.REACT_APP_BASE_URL_TESTING}ach/delete-ach-details`,
            data,
            ...pasteAuthHeaders(),
        }).then(({ data: { status, message, data } }) => {
            if (status?.toLowerCase() === 'fail') throw new Error(message)
            dispatch(BaseActions.removeUserBankAccount())
            setUserBank(null)
            setErr(true)
        })
            .catch((err) => {
                // dispatch(BaseActions.showAlert({
                //     variant: 'error',
                //     message: 'Failed to get banking details',
                // }))
            })
            .finally(() => {
                dispatch(BaseActions.hideLoading())
                dispatch(BaseActions.updateLayout({ bottomNav: true }))
                setRemoveAccount(false)
            }
            )
    }
    // By Shahroze \/\/\/\/\/\/\/\/\/\/
    window.history.replaceState(null, '')
    const handleRemoveAccont = () => {
        dispatch(BaseActions.updateLayout({ bottomNav: false }))
        setRemoveAccount(true)
        // setUserBank(null)
    }
    React.useEffect(() => {
        // console.log("Bank account", userBank)
        console.log(err)
        if (userBank) {
            setErr(false)
        }
        handleUserStatus();
    }, [userBank])
    // React.useEffect(() => {
    //     console.log(isDrawerOpen)
    // }, [isDrawerOpen])
    const handlePopup = () => {
        setRemoveAccount(false)
        dispatch(BaseActions.updateLayout({ bottomNav: true }))
    }
    const handleUserStatus = () => {
        if (userBank && userBank.status) {
            if (userBank.status === "waiting") {
                setUserBankStatus("Processing")
                setColor("yellow")
            }
            else if (userBank.status === "processing") {
                setUserBankStatus("Processing")
                setColor("yellow")
            }
            else if (userBank.status === "approved") {
                setUserBankStatus("Approved")
                setColor("#4CDE5F")
            }
            else if (userBank.status === "rejected") {
                setUserBankStatus("Rejected")
                setColor("red")
            }
        }
    }
    return (
        <>
            <Header heading={'Balance'} />
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
                px='2rem'
                style={{ height: '27vh' }}
            >
                <div className={classes.subTitleContainer}>
                    <Typography variant="h6" className={classes.subTitle}>
                        Available Balance
                    </Typography>
                </div>
                <div>
                    <Typography variant="h1" className={classes.balance}>
                        ${user && user.available_amount ? Number(user && user.available_amount).toFixed(2) : Number(0).toFixed(2)}
                    </Typography>
                </div>
                <div>
                    <Typography variant="h6" className={clsx(classes.subTitle, classes.payoutText)}>
                        {err && <span>Please add a bank account. Once you add a bank account it will take few days to verify.</span>}
                        {/* {userBank && userBank && userBank.status === "waiting" && <span>Account added successfully. Please wait while we start your  account verification process.</span>} */}
                        {userBank && userBank && userBank.status === "waiting" && <span>Bank account verification process started. Please wait while your bank account is being verified.</span>}
                        {userBank && userBank && userBank.status === "processing" && <span>Bank account verification process started. Please wait while your bank account is being verified.</span>}
                        {userBank && userBank && userBank.status === "approved" && <span>Your bank account has been verified. You can now receive payouts from hosted Quartrs.</span>}
                        {userBank && userBank && userBank.status === "rejected" && !userBank.error && <span>Your account has not been verified. Please try again with a different account.</span>}
                        {userBank && userBank && userBank.status === "rejected" && userBank.error === "R01" && <span>Insufficient funds. Available balance is not sufficient to cover the amount of the debit entry.</span>}
                        {userBank && userBank && userBank.status === "rejected" && userBank.error === "R02" && <span>Bank account closed. Previously active amount has been closed by the customer of RDFI.</span>}
                        {userBank && userBank && userBank.status === "rejected" && userBank.error === "R03" && <span>No bank account/unable to locate account. Account number does not correspond to the individual identified in the entry, or the account number designated is not an open account.</span>}
                        {userBank && userBank && userBank.status === "rejected" && userBank.error === "R04" && <span>Invalid bank account number. Account number structure is not valid</span>}
                        {userBank && userBank && userBank.status === "rejected" && userBank.error === "R06" && <span>Returned per ODFI request. ODFI requested the RDFI to return the entry.</span>}
                        {userBank && userBank && userBank.status === "rejected" && userBank.error === "R07" && <span>Authorization revoked by customer. Receiver has revoked authorization.</span>}
                        {userBank && userBank && userBank.status === "rejected" && userBank.error === "R08" && <span>Payment stopped. Receiver of a recurring debit has stopped payment of an entry.</span>}
                        {userBank && userBank && userBank.status === "rejected" && userBank.error === "R09" && <span>Uncollected funds. Collected funds are not sufficient for payment of the debit entry.</span>}
                        {userBank && userBank && userBank.status === "rejected" && userBank.error === "R10" && <span>Customer advises not authorized. Receiver has advised RDFI that originator is not authorized to debit his bank account</span>}
                        {userBank && userBank && userBank.status === "rejected" && userBank.error === "R11" && <span>Check truncation entry return. To be used when returning a check truncation entry.</span>}
                        {userBank && userBank && userBank.status === "rejected" && userBank.error === "R12" && <span>Branch sold to another RDFI. RDFI unable to post entry destined for a bank account maintained at a branch sold to another financial institution.</span>}
                        {userBank && userBank && userBank.status === "rejected" && userBank.error === "R13" && <span>RDFI not qualified to participate. Financial institution does not receive commercial ACH entries.</span>}
                        {userBank && userBank && userBank.status === "rejected" && userBank.error === "R14" && <span>Representative payee deceased or unable to continue in that capacity. The representative payee authorized to accept entries on behalf of a beneficiary is either deceased or unable to continue in that capacity.</span>}
                        {userBank && userBank && userBank.status === "rejected" && userBank.error === "R15" && <span>Beneficiary or bank account holder. (Other than representative payee) deceased* - (1) the beneficiary entitled to payments is deceased or (2) the bank account holder other than a representative payee is deceased.</span>}
                        {userBank && userBank && userBank.status === "rejected" && userBank.error === "R16" && <span>Bank account frozen. Funds in bank account are unavailable due to action by RDFI or legal order.</span>}
                        {userBank && userBank && userBank.status === "rejected" && userBank.error === "R17" && <span>File record edit criteria. Fields rejected by RDFI processing (identified in return addenda).</span>}
                        {userBank && userBank && userBank.status === "rejected" && userBank.error === "R18" && <span>Improper effective entry date. Entries have been presented prior to the first available processing window for the effective date.</span>}
                        {userBank && userBank && userBank.status === "rejected" && userBank.error === "R19" && <span>Amount field error. Improper formatting of the amount field.</span>}
                        {userBank && userBank && userBank.status === "rejected" && userBank.error === "R20" && <span>Non-payment bank account. Entry destined for non-payment bank account defined by reg.</span>}
                        {userBank && userBank && userBank.status === "rejected" && userBank.error === "R21" && <span>Invalid company ID number. The company ID information not valid (normally CIE entries).</span>}
                        {userBank && userBank && userBank.status === "rejected" && userBank.error === "R22" && <span>Invalid individual ID number. Individual id used by receiver is incorrect (CIE entries)</span>}
                        {userBank && userBank && userBank.status === "rejected" && userBank.error === "R23" && <span>Credit entry refused by receiver. Receiver returned entry because minimum or exact amount not remitted, bank account is subject to litigation, or payment represents an overpayment, originator is not known to receiver or receiver has not authorized this credit entry to this bank account.</span>}
                        {userBank && userBank && userBank.status === "rejected" && userBank.error === "R24" && <span>Duplicate entry. RDFI has received a duplicate entry.</span>}
                        {userBank && userBank && userBank.status === "rejected" && userBank.error === "R25" && <span>Addenda error. Improper formatting of the addenda record information.</span>}
                        {userBank && userBank && userBank.status === "rejected" && userBank.error === "R26" && <span>Mandatory field error. Improper information in one of the mandatory fields.</span>}
                        {userBank && userBank && userBank.status === "rejected" && userBank.error === "R27" && <span>Trace number error. Original entry trace number is not valid for return entry; or addenda trace numbers do not correspond with entry detail record.</span>}
                        {userBank && userBank && userBank.status === "rejected" && userBank.error === "R28" && <span>Transit routing number check digit error. Check digit for the transit routing number is incorrect.</span>}
                        {userBank && userBank && userBank.status === "rejected" && userBank.error === "R29" && <span>Corporate customer advises not authorized. RDFI has bee notified by corporate receiver that debit entry of originator is not authorized.</span>}
                        {userBank && userBank && userBank.status === "rejected" && userBank.error === "R30" && <span>RDFI not participant in check truncation program. Financial institution not participating in automated check safekeeping application.</span>}
                        {userBank && userBank && userBank.status === "rejected" && userBank.error === "R31" && <span>Permissible return entry (CCD and CTX only). RDFI has been notified by the ODFI that it agrees to accept a CCD or CTX return entry.</span>}
                        {userBank && userBank && userBank.status === "rejected" && userBank.error === "R32" && <span>RDFI non-settlement. RDFI is not able to settle the entry.</span>}
                        {userBank && userBank && userBank.status === "rejected" && userBank.error === "R33" && <span>Return of XCK entry. RDFI determines at its sole discretion to return an XCK entry; an XCK return entry may be initiated by midnight of the sixtieth day following the settlement date if the XCK entry.</span>}
                        {userBank && userBank && userBank.status === "rejected" && userBank.error === "R34" && <span>Limited participation RDFI. RDFI participation has been limited by a federal or state supervisor.</span>}
                        {userBank && userBank && userBank.status === "rejected" && userBank.error === "R35" && <span>Return of improper debit entry. ACH debit not permitted for use with the CIE standard entry class code (except for reversals).</span>}
                    </Typography>
                </div>
                <div className={classes.addAccountContainer} style={{ padding: userBank && userBank ? "0 5%" : "0% 0% 0% 9%" }}>
                    <p style={{ color: "#31A7DB", fontFamily: 'Cerebri-Bold', padding: '0% 2%', position: 'absolute', top: '-25px', textTransform: 'capitalize' }}>
                        {userBank && userBank && userBank.status && <span>Bank Account Status: <span style={{ color: color, textTransform: 'capitalize' }}>{userBankStatus}</span></span>}
                    </p>
                    {
                        userBank
                            ? <ListItem disableGutters>
                                <ListItemAvatar>
                                    <div className={classes.bankIconWrapper}>
                                        <AccountBalanceOutlinedIcon color='primary' />
                                    </div>
                                </ListItemAvatar>
                                <StyledListItemText
                                    primary={userBank.account_holder_name}
                                    secondary={(userBank.RDFI_Account_Number.slice(-2)).padStart(userBank.RDFI_Account_Number.length, '*')}
                                />
                                <ClearIcon
                                    color='error'
                                    className={classes.clearIcon}
                                    onClick={handleRemoveAccont}
                                />
                            </ListItem>
                            :
                            <Button
                                className={classes.addAccount}
                                startIcon={<AccountBalanceOutlinedIcon />}
                                onClick={() => history.push('/add-bank-account')}
                                fullWidth
                            >
                                Add Bank Account
                            </Button>
                    }
                </div>
            </Box>
            <Slide direction='up' in={removeAccount}>
                <div className={classes.backdrop} style={{ display: removeAccount ? 'inherit' : 'none' }}>
                    <div className={classes.confirmWrapper}>
                        <Typography variant='subtitle1' className={classes.cancelHeading}>Remove Bank Account</Typography>
                        <Typography variant='subtitle2' className={classes.cancelConfirmText}>Are you sure you want to remove this bank account?</Typography>
                        <div style={{ display: 'flex', justifyContent: 'space-between', paddingBottom: isIOS || isAndroid ? "80px" : "30px" }}>
                            <PopupBtn
                                variant='outlined'
                                className={clsx(classes.noBtn, classes.buttons)}
                                onClick={() => handlePopup()}
                                fullWidth
                                style={{ width: '46%', height: '48px' }}
                            >
                                No
                            </PopupBtn>
                            <PopupBtn
                                fullWidth
                                onClick={handleRemoveClick}
                                className={classes.buttons}
                                style={{ width: '46%', height: '48px' }}
                            >
                                Yes
                            </PopupBtn>
                        </div>
                    </div>
                </div>
            </Slide>
            {/* By Shahroze \/\/\/\/\/\/\/\/\/\/ */}
            <Slide direction='up' in={accountAdded}>
                <div className={classes.backdrop} style={{ display: accountAdded ? 'inherit' : 'none' }}>
                    <div className={classes.confirmWrapper}>
                        <Typography variant='subtitle1' className={classes.cancelHeading}>Bank Account Updated</Typography>
                        <Typography variant='subtitle2' className={classes.cancelConfirmText}>
                            You have updated your bank account successfully. After your bank account has been verified, you can start getting payouts from money earned by hosting Quartrs. Verification typically takes a few days.
                        </Typography>
                        <div style={{ display: 'flex', justifyContent: 'space-between', paddingBottom: isIOS || isAndroid ? "120px" : "40px" }}>
                            <PopupBtn
                                onClick={() => setAccountAdded(false)}
                                fullWidth
                            >
                                Ok
                            </PopupBtn>
                        </div>
                    </div>
                </div>
            </Slide>
            {/* By Shahroze /\/\/\/\/\/\/\/\/\/\ */}
        </>
    )
}
export default Balance;
