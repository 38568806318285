import * as Actions from "../actions";

// initial state
const initialState = {
  isLoading: false,
  errMsg: null,
  data: null,
};

// Reducer for GET HOMEPAGE DATA handling
export const GetHomepageReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.GET_HOME_PAGE:
      // we will mould our payload here accordingly  if required  in case of success
      return { ...state, isLoading: false, errMsg: null, data: action.payload };
    case Actions.HOME_PAGE_IS_LOADING:
      // ... in case of loading
      return { ...state, isLoading: true, errMsg: null };
    case Actions.HOME_PAGE_FAILED:
      // ... in case of error
      return { ...state, isLoading: false, errMsg: action.payload, data: null };
    default:
      return state;
  }
};
// Reducer for GET all DATA handling
export const GetAllReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.GET_ALL:
      // we will mould our payload here accordingly  if required  in case of success
      return { ...state, isLoading: false, errMsg: null, data: action.payload };
    case Actions.ALL_IS_LOADING:
      // ... in case of loading
      return { ...state, isLoading: true, errMsg: null };
    case Actions.ALL_FAILED:
      // ... in case of error
      return { ...state, isLoading: false, errMsg: action.payload, data: null };
    default:
      return state;
  }
};
