import React from "react";
import Collapse from "@material-ui/core/Collapse";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { BackDrop, BottomNav, Alert, Notification } from "@base";
import useDeepCompareEffect from "use-deep-compare-effect";
import { useDispatch, useSelector } from "react-redux";
// import * as Actions from "store/actions";
import { withRouter, useRouteMatch, useLocation } from "react-router";
import { isAndroid, isIOS, isMobile } from "react-device-detect";
// const ifMobileNavigator = () => {
//   if (
//     /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
//       navigator.userAgent
//     )
//   ) {
//     // true for mobile device
//     return true;
//   } else {
//     // false for not mobile device
//     return false;
//   }
// };

const useStyles = makeStyles((theme) => ({
  outerContainer: {
    background: isMobile ? "#080A0A" : `url('${process.env.REACT_APP_ENV === "prod" ? process.env.REACT_APP_BASE_URL_PROD : process.env.REACT_APP_BASE_URL_TESTING}uploads/web-background.png')`,
    height: isMobile && isIOS ? "calc(100vh - 80px)" : "100vh",
    backgroundSize: "cover",
    backgroundAttachment: "fixed",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "100% 100%",
    display: "flex",
    justifyContent: "space-around",
    overflow: "hidden",
    position: "relative",
  },
  container: {
    [theme.breakpoints.up("sm")]: {
      paddingTop: ({ fullScreen }) => (fullScreen ? 0 : "40px"),
      maxWidth: "375px",
    },
    width: "100%",
    position: "relative",
    transition: "all .2s ease-in-out",
  },
  borderCon: {
    height: "100vh",
    padding: "0px 0px",
    overflow: "hidden",
    [theme.breakpoints.up("sm")]: {
      border: ({ fullScreen }) => (fullScreen ? "none" : "1px solid #454545"),
      borderRadius: ({ fullScreen }) => (fullScreen ? 0 : "6px"),
      height: ({ fullScreen }) => (fullScreen ? "100vh" : "calc(100vh - 71px)"),
    },
  },
  historyPageCont: {
    [theme.breakpoints.up('sm')]: {
      height: 'calc(100vh - 74px) !important',
    }
  },
  quartersPageCont: {
    [theme.breakpoints.up('sm')]: {
      height: 'calc(100vh - 71px) !important',
    }
  },
  innerContainer: {
    background: "#080A0A",
    border: "none",
    height: "calc(100vh - 80px)",
    position: "relative",
    [theme.breakpoints.up("sm")]: {
      height: ({ fullScreen }) => (fullScreen ? "100vh" : "calc(100vh - 80px)"),
    },
  },
  quartersPageInnerCont: {
    [theme.breakpoints.up('sm')]: {
      height: 'calc(100vh - 74.5px) !important',
    },
  },
  collapseClass: {
    background: "transparent",
    height: "100% !important",
  },
  collapseIos: {
    position: "fixed",
    bottom: "0px",
    borderBottomRightRadius: "10px",
    borderBottomLeftRadius: "10px",
    width: "100%",
    height: "100vh",
    borderTopRightRadius: "6px",
    borderTopLeftRadius: "6px",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#080a0aeb", //"#000000ad",
    zIndex: 1000,
    borderBottom: "1px solid #454545",
    overflowY: "scroll",
  },
  collapseSimple: {
    overflowY: "scroll",
    position: isMobile ? "fixed" : "absolute",
    bottom: "0",
    borderBottomRightRadius: "10px",
    borderBottomLeftRadius: "10px",
    left: "1px",
    width: "100%",
    height: "100vh",
    borderTopRightRadius: "6px",
    borderTopLeftRadius: "6px",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#080a0aeb", //"#000000ad",
    zIndex: 1000,
    borderBottom: "1px solid #454545",
    [theme.breakpoints.up("sm")]: {
      width: "calc(100% - 2px)",
      bottom: "30px",
      height: ({ fullScreen }) => (fullScreen ? "100vh" : "calc(100vh - 71px)"),
    },
  },
  mobileContainer: {
    overflowY: "auto",
    background: "#080A0A",
    border: "none",
    minHeight: "100vh",
    position: "relative",
    [theme.breakpoints.up("sm")]: {
      border: "2px solid silver",
      height: "calc(100vh - 80px)",
    },
  },
  mobileCollapse: {
    position: "fixed",
    bottom: "0px",
    left: 0,
    width: "100%",
    height: "100% ",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#080a0aeb", //"#000000ad",
    zIndex: 100,
  },
  test: {
    backgroundColor: "#111111 !important",
    overflowY: "auto",
  },
  bottomNav: {
    position: 'absolute',
    left: '1px',
    bottom: 33,
    width: 'calc(100% - 2px)',
    borderBottomLeftRadius: '6px',
    borderBottomRightRadius: '6px',
    transition: 'all .2 ease-in-out',
    borderTop: '1px solid #454545',
    [theme.breakpoints.down('xs')]: {
      position: 'fixed',
      left: 0,
      bottom: 0,
      width: '100%',
      borderRadius: 0,
    },
  }
}));

const MobileLayout = ({ children, ...props }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const [isVisible, setIsVisible] = React.useState(false);
  const [isFirst, setIsFirst] = React.useState(false);
  const [isMobile, setIsMobile] = React.useState(false);
  const isMobileMedia = useMediaQuery(theme.breakpoints.up("sm"));
  const location = useLocation();
  const [page, setPage] = React.useState(location.pathname)
  const [layoutProps, setLayoutProps] = React.useState(false);
  let match = useRouteMatch("/quartr/:appointment_id");
  const baseMessage = useSelector(({ BaseReducer }) => BaseReducer.MessageReducer);
  const layoutConfig = useSelector(({ BaseReducer }) => BaseReducer.LayoutReducer);
  // if we are on video (Full screen)  then device orientation
  // will not affect our layout eg it will remain same when
  // we change our device from portrait mode to landscape mode or vise-versa
  useDeepCompareEffect(() => {
    setLayoutProps(layoutConfig.fullScreen);
    if (!layoutConfig.fullScreen) {
      isMobileMedia ? setIsMobile(true) : setIsMobile(false);
    }
  }, [layoutConfig]);
  // if we are not on video screen then it will change layout accordingly
  React.useEffect(() => {
    isMobileMedia !== isMobile && !match && setIsMobile(isMobileMedia);
  }, [isMobileMedia]);
  React.useEffect(() => {
    setPage(location.pathname)
  }, [location.pathname])
  const isQuartersPage = page === '/quarters'
  const isHistoryPage = page === '/history'
  const isProfilePage = page === '/profile'
  // at here we are handling scroll behaviour
  useDeepCompareEffect(() => {
    if (baseMessage.state === true) {
      setIsFirst(true);
      document.getElementsByTagName("body")[0].style.overflow = "hidden";
      setIsVisible(true);
    }
    if (baseMessage.state === false) {
      setIsVisible(false);
      document.getElementsByTagName("body")[0].style.overflow = "auto";
    }
  }, [baseMessage]);
  const classes = useStyles({ fullScreen: layoutProps ? true : false })
  return (
    <>
      {!layoutConfig.fullScreen && !match ? (
        // from here all the code is stabled only for desktop view same components are using below for mobile view
        <div className={classes.outerContainer}>
          {/* Push Notification */}
          <Notification />
          <div className={classes.container}>
            <BackDrop open={true} />
            {/* alert or any kind of message like error etc. */}
            <Alert />
            <div
              className={clsx(
                  isHistoryPage && classes.historyPageCont, isQuartersPage && classes.quartersPageCont,
                  !window.location.pathname.includes('/quartr/') && classes.borderCon
              )}
            >
              <div
                style={
                  (isQuartersPage || isHistoryPage)
                      ? {} : isProfilePage
                          ? { paddingBottom: layoutConfig.quartersLayout ? '60px' : '10px', overflowY: "hidden" }
                          : { paddingBottom: layoutConfig.quartersLayout ? '60px' : '10px', overflowY: "auto" }
                }
                className={clsx(
                    (isQuartersPage || isHistoryPage) && classes.quartersPageInnerCont, classes.innerContainer
                )}
              >
                {children}
              </div>
            </div>
            <div
              className={clsx(
                isIOS ? classes.collapseIos : classes.collapseSimple,
                isVisible ? "slide-in-bottom" : "slide-out-bottom"
              )}
            >
              <div style={{ flexGrow: 1, background: "transparent" }}></div>

              <Collapse
                unmountOnExit
                component="div"
                style={{
                  background: "transparent",
                  maxHeight: isIOS ? "85vh" : isAndroid ? "93vh" : "91vh"
                }}
                classes={{
                  container: classes.test,
                  wrapperInner: classes.test,
                  wrapper: classes.test,
                }}
                in={baseMessage.state}
              >
                {baseMessage.data}
              </Collapse>
            </div>

            {/* Bottom navigation */}
            {localStorage.getItem("JWTtoken") && (
              layoutConfig.bottomNav &&
              <BottomNav
                className={classes.bottomNav}
                style={{ zIndex: layoutConfig.bottomNav ? 10000000 : -1 }}
                newNotification={props.newNotification}
              />
            )}
          </div>
        </div>
      ) : (
        // mobile view starts from here and same documentation as above.
        <div
          style={{ paddingBottom: layoutConfig.bottomNav ? "60px" : "10px" }}
          className={classes.mobileContainer}
        >
          <Notification onMobile />
          <BackDrop open={true} />
          <Alert />
          {children}
          <div
            className={clsx(
              classes.mobileCollapse,
              isVisible ? "slide-in-bottom" : "slide-out-bottom"
            )}
          >
            <div style={{ flexGrow: 1, background: "transparent" }}></div>
            <Collapse
              component="div"
              style={{
                background: "transparent",
                maxHeight: isIOS ? "86vh" : "91vh"
              }}
              classes={{
                container: classes.test,
                wrapperInner: classes.test,
                wrapper: classes.test,
              }}
              in={baseMessage.state}
              unmountOnExit
            >
              {baseMessage.data}
            </Collapse>
          </div>
          {localStorage.getItem("JWTtoken") && (
            layoutConfig.bottomNav &&
            <BottomNav
              style={{
                position: "fixed",
                left: "0",
                bottom: "0",
                width: "100%",
                zIndex: 1000000000,
                transition: "all .2 ease-in-out",
              }}
              newNotification={props.newNotification}
            />
          )}
        </div>
      )}
    </>
  );
};
export default withRouter(MobileLayout);
