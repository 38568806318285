import * as Actions from "../../actions";
const initialState = {
  bottomNav: false,
  fullScreen: false
};

export const LayoutReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.UPDATE_LAYOUT: {
      // alert('show')
      return {
        ...state,
        bottomNav: action.payload.bottomNav,
        fullScreen: action.payload.fullScreen ? true : false
      };
    }
    case Actions.FULL_SCREEN: {
      // alert('show')
      return {
        ...state,
        fullScreen: true,
        // fullScreen:action.payload.fullScreen?true:false
      };
    }
    case Actions.EXIT_FULL_SCREEN: {

      // alert('show')
      return {
        ...state,
        fullScreen: false,
        // fullScreen:action.payload.fullScreen?true:false
      };
    }

    default: {
      return state;
    }
  }
};
