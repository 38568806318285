import React from 'react'
import { makeStyles } from '@material-ui/core'
import { useHistory } from 'react-router-dom';
import './App.css'
const useStyles = makeStyles((theme) => ({
    footerContainer: {
        padding: '10% 27%',
        // width: '46%',
        [theme.breakpoints.down('sm')]: {
            padding: '10% 5%',
            width: '90%',
            marginTop: '10%'
        }
    },
    footerContent: {
        color: '#808080',
        fontSize: '20px',
        // fontFamily: 'Cerebri-Sans',
        textAlign: 'center'
    },
}))
const Footer = () => {
    const classes = useStyles();
    const history = useHistory();
    return (
        <div className="font-face-regular">
            <div className={classes.footerContainer}>
                <p className={classes.footerContent}>
                    To use this app, you accept and agree to our <span style={{ textTransform: 'uppercase', color: 'white', cursor: 'pointer' }} onClick={() => history.push("/privacy")}>PRIVACY POLICY </span>
                    and <span style={{ textTransform: 'uppercase', color: 'white', cursor: 'pointer' }} onClick={() => history.push("/terms")}>TERMS OF SERVICE.</span>
                </p>
                <p className={classes.footerContent} style={{ marginTop: '-10px' }}>
                    You MUST be 18 years of age or older to use this app.
                </p>
            </div>
        </div>
    )
}
export default Footer
