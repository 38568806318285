import * as Actions from "../../actions";
const initialState = {
  component: null,
  key: null,
  path: null,
  from: null,
};

export const RouteReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.MOUNT_ROUTE: {
     let obj = {
      component: action.component,
      path: action.path,
      key: action.key,
      from: action.from,
    }
    console.log(obj,'===>mounterComponent')
      return obj;
    }
    case Actions.HIDE_MESSAGE: {
    
      return state;
    }
    default: {
      return state;
    }
  }
};
