import React, {
    useEffect,
    // useState
} from "react";
import * as BaseActions from "store/actions";
import {history} from "@history";
import {Typography, makeStyles, Button, Box} from "@material-ui/core";
import DrawerMenu from 'components/drawerMenu';
import {useDispatch} from "react-redux";
import {
    FacebookShareButton,
    EmailShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    WhatsappShareButton
} from "react-share"
import {EmailIcon, FacebookIcon, LinkedinIcon, TwitterIcon, WhatsappIcon} from "react-share";
// import Dialog from '@material-ui/core/Dialog';
// import DialogActions from '@material-ui/core/DialogActions';
// import DialogTitle from '@material-ui/core/DialogTitle';
import './index.css';
// import shareIcon from "@assets/shareIcon.png"
import CopyURL from "@assets/Copy.png"
import {isMobile} from "react-device-detect";
import Header from "../../@base/header";
import {SelectField} from "../../@base";

const useStyles = makeStyles((theme) => ({
    headerWrapper: {
        display: 'flex',
        alignItems: 'center',
        marginTop: 8,
        marginLeft: 3,
        padding: 17,
        fontWeight: 'bold',
        [theme.breakpoints.down('sm')]: {
            marginTop: 0,
            padding: "15px 17px 0px 17px",
        }
    },
    headerText: {
        color: '#31A7DB',
        fontSize: '1.9rem',
        fontFamily: 'Cerebri-Bold'
    },
    leftSideMenu: {
        display: 'flex',
        alignItems: 'center',
        marginRight: 'auto',
    },
    menuIcon: {
        height: "28px",
        width: "28px",
        cursor: 'pointer',
        alignItems: 'center',
    },
    icon: {
        width: 50,
        height: 50,
        cursor: 'pointer',
    },
    backgroundIcon: {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        width: 240
    },
    inviteHeading: {
        display: 'flex',
        flexDirection: 'column',
        padding: 25,
        color: "#31A7DB",
        fontFamily: "Cerebri-SemiBold",
        fontSize: "1rem",
        [theme.breakpoints.down('sm')]: {
            padding: "3px 25px 0px 25px",
        }
    },
    BottomWrapper: {
        display: 'flex',
        alignItems: 'center',
        padding: 25,
		paddingTop: 10,
        fontWeight: 'bold',
        marginBottom: "50px",
        [theme.breakpoints.down('sm')]: {
            padding: "0px 25px 0px 25px",
        }
    },
    BottomCode: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '80%',
        height: 50,
        color: '#fff',
        borderRadius: 6,
        border: '1px dashed #31A7DB',
        fontFamily: "Cerebri-SemiBold",
        marginRight: 20,
        letterSpacing: 8,
        fontSize: '1.5rem'
    }
}))

function Invite() {
    const [open, setOpen] = React.useState(false)
    const classes = useStyles();
    const dispatch = useDispatch();
    const userCode = JSON.parse(localStorage.getItem("quarterlyUser")).data.user_code
    const handleClose = () => {
        setOpen(false)
        dispatch(BaseActions.hideMessage())
        history.push("/invite")
    }
    const handleNavigate = () => {
        history.push("invite/history");
    };

    useEffect(() => {
        dispatch(BaseActions.updateLayout({bottomNav: true}))
    }, [])

    open && dispatch(BaseActions.showMessage(<SocialMediaOptions userCode={userCode} handleClose={handleClose}/>))
    const copyToClipBoard = () => {
        navigator.clipboard.writeText(`Hey! You need to check out Quartrly. You can make money doing 15 minute 1-on-1 video calls. Use my referral code: ${userCode}. To get started, go to ${window.location.origin}/userprofile/${userCode}`)
        var tooltip = document.getElementById("myTooltip");
        tooltip.innerHTML = '<span style={{whiteSpace: "nowrap", fontFamily: "cerebri-regular,serif"}}>Invite link copied</span>';
    }

    return (
        <div>
            <Header heading="Invite" RightComponent={() => {
                return (
                    <img src='assets/Tabbar/TabbarInactive/clock.png' alt="clocl" className={classes.menuIcon}
                         onClick={handleNavigate}/>
                )
            }}/>

            <div style={{overflowY: 'scroll', paddingTop: "79px"}}>
                <div>
                    <img src="assets/images/invite.png" alt="invite" className={classes.backgroundIcon}/>
                </div>

                <div className={classes.inviteHeading}>
                    <Typography variant='span'>
                        Invite your family and friends to use Quartrly!
                    </Typography>
                    <Typography variant='caption' style={{marginTop: isMobile ? '0px' : '15px'}}>
                        You will receive 1% of the revenue that they generate in this app. Give out your 6 character
                        code below, but make sure they use this code when they register. Happy inviting!
                    </Typography>
                </div>

                <div className={classes.BottomWrapper}>
                    <div className={classes.BottomCode}>
                        {userCode}
                    </div>

                    <div className="tooltip" style={{ marginTop: 6 }}>
                        <span className="tooltiptext" id="myTooltip"
                              style={{fontFamily: "-apple-system, BlinkMacSystemFont, cerebri-regular"}}>
                            Copy to clipboard
                        </span>

                        <img src={CopyURL} className={classes.icon} alt="copy-url" onClick={copyToClipBoard}
                             style={{borderRadius: "5px"}}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Invite
const SocialMediaOptions = ({handleClose, userCode}) => {
    const url = 'https://quartrly.netlify.app/'
    const quote = 'Hey! You need to download Quartrly! You can make money doing 15 minute video calls. Use my referral code \n' + userCode + '.\n To download the app, go to https://quartrly.netlify.app/'
    const twitterQuote = 'Hey! You need to download Quartrly! You can make money doing 15 minute video calls. Use my referral code \n' + userCode + '.\n To download the app, go to '
    const whatsAppQuote = 'You need to download Quartrly! You can make money doing 15 minute video calls. Use my referral code \n' + userCode + '.\n To download the app, go to '
    return (
        <Box padding="30px">
            <Typography variant="body2">Share via:</Typography>
            <Box paddingTop="20px" paddingBottom="20px">
                <FacebookShareButton url={url} quote={quote}
                                     style={{height: "40px", marginRight: "22px", width: "40px"}}
                                     hashtag="#quartrly"><FacebookIcon style={{height: "40px", width: "40px"}}/>
                </FacebookShareButton>
                <EmailShareButton url={url} subject="Invitation" body={quote} separator={quote}
                                  style={{height: "40px", marginRight: "22px", width: "40px"}}
                                  hashtag="#quartrly"><EmailIcon
                    style={{height: "40px", width: "40px"}}/></EmailShareButton>
                <LinkedinShareButton url={url} source={url} title="Invitation" summary={quote}
                                     style={{height: "40px", marginRight: "22px", width: "40px"}}><LinkedinIcon
                    style={{height: "40px", width: "40px"}}/></LinkedinShareButton>
                <TwitterShareButton url={url} title={twitterQuote}
                                    style={{height: "40px", marginRight: "22px", width: "40px"}}
                                    hashtag="#quartrly"><TwitterIcon
                    style={{height: "40px", width: "40px"}}/></TwitterShareButton>
                <WhatsappShareButton url={url} title="Hey! " separator={whatsAppQuote}
                                     style={{height: "40px", marginRight: "22px", width: "40px"}}
                                     hashtag="#quartrly"><WhatsappIcon
                    style={{height: "40px", width: "40px"}}/></WhatsappShareButton>
            </Box>
            <Box paddingBottom="20px">
                <Button onClick={() => {
                    handleClose && handleClose();
                    return;
                }} fullWidth>Cancel</Button>
            </Box>
        </Box>
    );
};
