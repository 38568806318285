import * as Actions from "../../actions";
const initialState = {
  state: false,
  data: "",
};

export const LoadingReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.SHOW_LOADING: {
      return {
        state: true,
        data: action.data,
      };
    }
    case Actions.HIDE_LOADING: {
      return {
        state: false,
        data: null,
      };
    }
    default: {
      return state;
    }
  }
};
