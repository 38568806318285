import { combineReducers } from "redux";
import { MessageReducer} from "./message.reducer"; 
import {RouteReducer} from './routes.reducer'
import {LoadingReducer} from './loading.reducer'
import {LayoutReducer} from './layout.reducer'
import {UserReducer} from './user.reducer'
import {AlertReducer} from './alert.reducer'
import {TimerReducer} from './timer.reducer'
import {NotificationReducer} from './notification.reducer'
import { InviteReducer } from "../../../components/invite/store/reducers/invite.reducer";
const BaseReducer = combineReducers({
    MessageReducer,
    RouteReducer,
    LoadingReducer,
    LayoutReducer,
    UserReducer,
    AlertReducer,
    TimerReducer,
    NotificationReducer,
    InviteReducer
});

export default BaseReducer;



/**
 * these reducers are base reducers we can access it from everywhere 
 */