export const uploadWithPresignedURL = async (file) => {
    let presignedResponse = await fetch(`${process.env.REACT_APP_ENV === "prod"
        ? process.env.REACT_APP_BASE_URL_PROD : process.env.REACT_APP_BASE_URL_TESTING}upload/get-pre-signed-url`,
{
        method: 'POST',
        headers: {
            Authorization: `JWT ${localStorage.getItem('JWTtoken')}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            fileName: file.name,
            fileType: file.type,
        })
    });

    const {url, objectUrl, fields} = await presignedResponse.json();
    const formData = new FormData();
    Object.entries(fields).forEach(([key, value]) => {
        formData.append(key, value);
    });
    formData.append('file', file);

    const uploadResponse = await fetch(url, {
        method: 'POST',
        body: formData
    });

    if (uploadResponse.ok) {
        return objectUrl;
    } else {
        throw new Error('Upload failed');
    }
}
