import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router";
import * as BaseActions from "store/actions";
import { SubmitSignupReducer } from "components/signup/screens/signupForm/store/reducers/signup.reducer";
import { SubmitLoginReducer } from "components/login/store/reducers/login.reducer";
import { GetHomepageReducer } from "components/home/store/reducers/home.reducer";
import { combineReducers } from "redux";
import withReducer from "store/withReducer";
import { showMessage } from "store/actions";
import useDeepCompareEffect from "use-deep-compare-effect";

import SocketClass from "@utils/socketService";
import { TimerManager } from '@utils'

let fakeData = {
  waiting_appointment: {
    _id: "5fe06bf73fe9c9d0e9f5cf4f",
    createdAt: "2020-12-21T09:33:43.136Z",
    updatedAt: "2020-12-21T09:43:00.507Z",
    attendee: {
      _id: "5fb76efb6268a2452965f402",
      full_name: "rizwan rizwan",
      profile_media_urls: [
        `${process.env.REACT_APP_ENV === "prod" ? process.env.REACT_APP_BASE_URL_PROD : process.env.REACT_APP_BASE_URL_TESTING}uploads/default_pic.png`,
        `${process.env.REACT_APP_ENV === "prod" ? process.env.REACT_APP_BASE_URL_PROD : process.env.REACT_APP_BASE_URL_TESTING}uploads/default_pic.png`,
        `${process.env.REACT_APP_ENV === "prod" ? process.env.REACT_APP_BASE_URL_PROD : process.env.REACT_APP_BASE_URL_TESTING}uploads/default_pic.png`,
      ],
    },
    host: {
      _id: "5f8fe3099f1b2a2ad078e33c",
      full_name: "hammad ali",
      profile_media_urls: [
        `https://${process.env.REACT_APP_ENV === "prod" ? process.env.REACT_APP_PROD_S_THREE_BUCKET_NAME : process.env.REACT_APP_TESTING_S_THREE_BUCKET_NAME}.s3.amazonaws.com/uploads/5f8fe3099f1b2a2ad078e33c/pic0`,
        "https://firebasestorage.googleapis.com/v0/b/quartrly-23168.appspot.com/o/assets%2F5f8fe3099f1b2a2ad078e33c%2Fpic1?alt=media&token=93b82030-e813-4102-a45b-c6dc6d9844f6",
        `https://${process.env.REACT_APP_ENV === "prod" ? process.env.REACT_APP_PROD_S_THREE_BUCKET_NAME : process.env.REACT_APP_TESTING_S_THREE_BUCKET_NAME}.s3.amazonaws.com/uploads/5f8fe3099f1b2a2ad078e33c/pic2`,
      ],
    },
    call_at: "2020-12-21T09:45:00.000Z",
    receipt_id: "5fe06bf63fe9c9d0e9f5cf4d",
    break_time_span_for_host: [],
    total_break_time_in_sec: 0,
    call_ended_at: null,
    last_break_start_time: null,
    oncall: false,
    host_token:
      "T1==cGFydG5lcl9pZD00Njc3NjMwNCZzaWc9OTA1NzdmMGZmYjc2YTY4ZWIwN2Q1NGU1NjMwNjE2ZmRlMzBlMDU0ZDpzZXNzaW9uX2lkPTJfTVg0ME5qYzNOak13Tkg1LU1UWXdPRFUwTXpjNE1EUTNOWDVvYzJ4b09FdEpkMFpYVUVZMlFYbG5iRkpZTlVSQ1dGTi1VSDQmY3JlYXRlX3RpbWU9MTYwODU0Mzc4MSZub25jZT0wLjQ0NjcwMDgwNDk4NjkxNzImcm9sZT1tb2RlcmF0b3ImZXhwaXJlX3RpbWU9MTYwODU0NDgwMSZjb25uZWN0aW9uX2RhdGE9bmFtZSUzRHJpendhbiUyMHJpendhbiZpbml0aWFsX2xheW91dF9jbGFzc19saXN0PWZvY3Vz",
    attendee_token:
      "T1==cGFydG5lcl9pZD00Njc3NjMwNCZzaWc9YjlmNWVlMjYyMzRhYmM2YzBhYjkyZGY5YWE2ZWVkNDgzZDg0Y2Q5YzpzZXNzaW9uX2lkPTJfTVg0ME5qYzNOak13Tkg1LU1UWXdPRFUwTXpjNE1EUTNOWDVvYzJ4b09FdEpkMFpYVUVZMlFYbG5iRkpZTlVSQ1dGTi1VSDQmY3JlYXRlX3RpbWU9MTYwODU0Mzc4MSZub25jZT0wLjE3MDI2Njc1OTM3ODYyMjkmcm9sZT1tb2RlcmF0b3ImZXhwaXJlX3RpbWU9MTYwODU0NDgwMSZjb25uZWN0aW9uX2RhdGE9bmFtZSUzRHJpendhbiUyMHJpendhbiZpbml0aWFsX2xheW91dF9jbGFzc19saXN0PWZvY3Vz",
    room_id:
      "2_MX40Njc3NjMwNH5-MTYwODU0Mzc4MDQ3NX5oc2xoOEtJd0ZXUEY2QXlnbFJYNURCWFN-UH4",
    rejected_by: {
      at: null,
      user: null,
    },
    second_accepted_by: {
      at: null,
      user: null,
    },
    first_accepted_by: {
      at: null,
      user: null,
    },
    cancelled_by: {
      deduction_percent: 0,
      at: null,
      user: null,
    },
    type: "booking",
    payout_status: "unpaid",
    status: "scheduled",
    host_accepted: false,
    number_of_no_answer_by_host: 0,
    attendee_accepted: false,
    number_of_no_answer_by_attendee: 0,
    __v: 0,
    remaining_seconds: 102,
    is_host: true,
    before_call_started: true,
  },
  current_appointment: {
    _id: "5fe067fa3fe9c9d0e9f5cf2a",
    createdAt: "2020-12-21T09:16:42.457Z",
    updatedAt: "2020-12-21T09:28:00.602Z",
    attendee: {
      _id: "5fb76efb6268a2452965f402",
      full_name: "rizwan rizwan",
      profile_media_urls: [
        `${process.env.REACT_APP_ENV === "prod" ? process.env.REACT_APP_BASE_URL_PROD : process.env.REACT_APP_BASE_URL_TESTING}uploads/default_pic.png`,
        `${process.env.REACT_APP_ENV === "prod" ? process.env.REACT_APP_BASE_URL_PROD : process.env.REACT_APP_BASE_URL_TESTING}uploads/default_pic.png`,
        `${process.env.REACT_APP_ENV === "prod" ? process.env.REACT_APP_BASE_URL_PROD : process.env.REACT_APP_BASE_URL_TESTING}uploads/default_pic.png`,
      ],
    },
    host: {
      _id: "5f8fe3099f1b2a2ad078e33c",
      full_name: "hammad ali",
      profile_media_urls: [
        `https://${process.env.REACT_APP_ENV === "prod" ? process.env.REACT_APP_PROD_S_THREE_BUCKET_NAME : process.env.REACT_APP_TESTING_S_THREE_BUCKET_NAME}.s3.amazonaws.com/uploads/5f8fe3099f1b2a2ad078e33c/pic0`,
        "https://firebasestorage.googleapis.com/v0/b/quartrly-23168.appspot.com/o/assets%2F5f8fe3099f1b2a2ad078e33c%2Fpic1?alt=media&token=93b82030-e813-4102-a45b-c6dc6d9844f6",
        `https://${process.env.REACT_APP_ENV === "prod" ? process.env.REACT_APP_PROD_S_THREE_BUCKET_NAME : process.env.REACT_APP_TESTING_S_THREE_BUCKET_NAME}.s3.amazonaws.com/uploads/5f8fe3099f1b2a2ad078e33c/pic2`,
      ],
    },
    call_at: "2020-12-21T09:30:00.000Z",
    receipt_id: "5fe067f93fe9c9d0e9f5cf28",
    break_time_span_for_host: [],
    total_break_time_in_sec: 0,
    call_ended_at: null,
    last_break_start_time: null,
    oncall: false,
    host_token:
      "T1==cGFydG5lcl9pZD00Njc3NjMwNCZzaWc9MzI3ZDhiMjRjNGRkZmJhYWUwMGJjY2RlOTgzM2JkODM3MDA0NzIxMzpzZXNzaW9uX2lkPTJfTVg0ME5qYzNOak13Tkg1LU1UWXdPRFUwTWpnNE1EVTNNSDVaTkc1dmNWTmFkR0ZLS3l0UVNHVXlTVEJGYjFCSVp6bC1VSDQmY3JlYXRlX3RpbWU9MTYwODU0Mjg4MSZub25jZT0wLjIwNzM5ODM3ODM1ODg4MjQyJnJvbGU9bW9kZXJhdG9yJmV4cGlyZV90aW1lPTE2MDg1NDM5MDEmY29ubmVjdGlvbl9kYXRhPW5hbWUlM0RyaXp3YW4lMjByaXp3YW4maW5pdGlhbF9sYXlvdXRfY2xhc3NfbGlzdD1mb2N1cw==",
    attendee_token:
      "T1==cGFydG5lcl9pZD00Njc3NjMwNCZzaWc9MTJjN2FmNjg4NjNlNmM3MzQ1YmM0YzEzNzc2MGQxMDNiMDYxNmMxYTpzZXNzaW9uX2lkPTJfTVg0ME5qYzNOak13Tkg1LU1UWXdPRFUwTWpnNE1EVTNNSDVaTkc1dmNWTmFkR0ZLS3l0UVNHVXlTVEJGYjFCSVp6bC1VSDQmY3JlYXRlX3RpbWU9MTYwODU0Mjg4MSZub25jZT0wLjk5Mzc3Mzc2NDk2OTgxOTEmcm9sZT1tb2RlcmF0b3ImZXhwaXJlX3RpbWU9MTYwODU0MzkwMSZjb25uZWN0aW9uX2RhdGE9bmFtZSUzRHJpendhbiUyMHJpendhbiZpbml0aWFsX2xheW91dF9jbGFzc19saXN0PWZvY3Vz",
    room_id:
      "2_MX40Njc3NjMwNH5-MTYwODU0Mjg4MDU3MH5ZNG5vcVNadGFKKytQSGUySTBFb1BIZzl-UH4",
    rejected_by: {
      at: null,
      user: null,
    },
    second_accepted_by: {
      at: null,
      user: null,
    },
    first_accepted_by: {
      at: null,
      user: null,
    },
    cancelled_by: {
      deduction_percent: 0,
      at: null,
      user: null,
    },
    type: "booking",
    payout_status: "unpaid",
    status: "scheduled",
    host_accepted: false,
    number_of_no_answer_by_host: 0,
    attendee_accepted: false,
    number_of_no_answer_by_attendee: 0,
    __v: 0,
    remaining_seconds: 102,
    is_host: true,
    before_call_started: false,
  },
};

let reducers = combineReducers({
  SubmitLoginReducer,
  SubmitSignupReducer,
  GetHomepageReducer,
});
let socket = undefined;

const Sockets = (props) => {
  const dispatch = useDispatch();
  const userDataQuick = useSelector(
    ({ BaseReducer }) => BaseReducer.UserReducer
  );

  const loginData = useSelector(
    ({ SocketReducers }) => SocketReducers.SubmitLoginReducer
  );
  const signupData = useSelector(
    ({ SocketReducers }) => SocketReducers.SubmitSignupReducer
  );
  const homeData = useSelector(
    ({ SocketReducers }) => SocketReducers.GetHomepageReducer
  );

  let userData = JSON.parse(localStorage.getItem("quarterlyUser"));
  const showAlert = (message, variant) => { };
  let ddd = {
    loginData,
    signupData,
    homeData,
    socket: socket && socket.connected,
  };

  //  useDeepCompareEffect(() => {
  // }, [ddd]);
  React.useEffect(() => {
    dispatch(BaseActions.getUserData());
  }, []);
  React.useEffect(() => {
    socket = SocketClass.getSocket();
  }, [ddd]);

  useDeepCompareEffect(() => {
    socket.emit("onprofile", {
      profile_id: userDataQuick && userDataQuick._id,
    });

    socket.on("userupdated", (data) => {
      let upComingData = JSON.parse(data);
      let updatedData = { ...userData };
      updatedData.data.available = upComingData.available;
      localStorage.removeItem("quarterlyUser");
      localStorage.setItem("quarterlyUser", JSON.stringify(updatedData));
      dispatch(BaseActions.updateUserData(updatedData.data));
      // props.history.push('/videocall')
      // showAlert("profile updated", "info");
    });

    socket.on("unauthorized", (error) => {
      if (
        error.data.type === "UnauthorizedError" ||
        error.data.code === "invalid_token"
      ) {
        console.log("User token has expired");
        // showAlert("Unauthorized", "error");
      }
    });
    socket.on("disconnect", (error) => {
      showMessage(JSON.stringify(error), "error");
      console.log(error);
      // alert("disconect");
    });
    // socket.on("callstartcounter", (data) => {
    //   // alert("called from socket React component");
    //   let dataParsed = JSON.parse(data);
    //   console.log(dataParsed, "meeting data");
    // });

    // socket.on("callstartcounter", (data) => {
    //   let dataParsed = JSON.parse(data);
    //   // this.historyTest(dataParsed._id);
    //   // dispatch(BaseActions.showTimer(dataParsed));
    //   console.log(JSON.parse(data), "meeting data");
    //   alert(JSON.stringify(dataParsed))
    //   alert('called counter')
    //   TimerManager(dispatch,dataParsed,dataParsed.remaining_seconds)
    // });

    // socket.on("broadcast", (data) => {
    //   // dispatch(BaseActions.showTimer(fakeData.waiting_appointment));
    //   console.log(
    //     "broadcasting with 2 minutes",
    //     new Date().toLocaleString(),
    //     data
    //   );
    // });

    // dispatch(BaseActions.hideTimer());
    // setTimeout(() => {
    //   dispatch(BaseActions.showTimer());
    // }, 1000);
    // });
  }, [userDataQuick]);

  return <>{props.children}</>;
};

// export default Sockets;
export default withReducer("SocketReducers", reducers)(withRouter(Sockets));
