// timer function which responsible for handling 2 minutes reminder before call started

import * as BaseActions from "store/actions";
import * as Actions from "components/splash/store/actions";
import { history } from "@history";
let interval = null
let TimerManager = (dispatch, data, duration) => {
  // if refresh page or emit other function then we need to clear first interval and then start other one.
  if (interval) {
    clearInterval(interval)
  }

  let mineId = JSON.parse(localStorage.getItem("quarterlyUser")).data._id;
  let isHost = data.host._id === mineId;
  let profileSend = !isHost
    ? data.host.profile_media_urls[0]
    : data.attendee.profile_media_urls[0];
  let userName = !isHost
    ? data.host.enabledUsername ? data.host.username : data.host.full_name
    : data.attendee.enabledUsername ? data.attendee.username : data.attendee.full_name;
  var timer = duration;
  let checked = false;
  let minutes = "";
  let seconds = "";

  // 100% progress need to complete in 120 second ======> so in this manner each second will consume 0.833seconds

  let prevProgress = 0.833 * (120 - Number(duration)); // here we are calculating previous progress so can start from there last progress or timer
  interval = setInterval(function () {
    minutes = parseInt(timer / 60, 10);
    seconds = parseInt(timer % 60, 10);

    minutes = minutes < 10 ? "0" + minutes : minutes;
    seconds = seconds < 10 ? "0" + seconds : seconds;
    let completeTime = minutes + ":" + seconds;
    prevProgress = prevProgress + 0.833;

    // here we dispatching redux event at every second so it can avail on every other reminder component.
    dispatch(
      BaseActions.showTimer({
        state: true,
        timer: completeTime,
        profileImage: profileSend,
        _id: data._id,
        progress: prevProgress, //0.833
        userName: userName
      })
    );

    // // // here we setting up a beat ==> at these timings we call request and it will reproduce same procedure again .
    // this beat helps us to reduce abnormality in timer on both host and attendee sides.
    if (timer - 1 === 30) {
      dispatch(Actions.checkQuartrState())
    }
    if (timer - 1 === 60) {
      dispatch(Actions.checkQuartrState())
    }
    if (timer - 1 === 90) {
      dispatch(Actions.checkQuartrState())
    }
    if (timer - 1 === 110) {
      dispatch(Actions.checkQuartrState())
    }
    if (timer - 1 < 50) {
      if (!checked) {
        // dispatch(Actions.checkQuartrState());
        checked = true;
      }
    }

    // if our timer down to 00:00 then it need to redirect automatically on quartr screen.
    if (--timer < 0) {
      clearInterval(interval);
      dispatch(BaseActions.hideTimer());
      history.push(`/quartr/${data._id}`);
    }
  }, 1000);
};

export default TimerManager;
