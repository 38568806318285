import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    header: {
        display: 'flex',
        flexWrap: "wrap",
        flexDirection: 'row',
        justifyContent: 'space-between',
        background: '#080A0A',
        padding: '0 0 4% 0',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        }
    },
    logo: {
        fontFamily: 'Cerebri-Sans',
        [theme.breakpoints.down('md')]: {
            width: "50%",
        },
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
            width: "100%",
        }
    },
    logoImage: {
        height: '60px',
        cursor: 'pointer'
    },
    totalUsersColumn: {
        placeSelf: 'center',
        [theme.breakpoints.down('md')]: {
            order: 3,
            width: '100%',
        },
    },
    totalUsersText: {
        color: '#BAE2f3',
        fontSize: '30px',
        fontWeight: 'bold',
        margin: 0,
        textAlign: 'right',
        [theme.breakpoints.down('md')]: {
            fontSize: '20px',
        },
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
            fontSize: '16px',
        }
    },
    pageHeadingColumn: {
        width: '45%',
        justifySelf: "flex-end",
        alignSelf: 'center',
        textAlign: 'center',
        [theme.breakpoints.down('md')]: {
            width: '50%',
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            margin: '1rem 0',
        },
    },
    pageHeading: {
        color: '#31A7DB',
        fontSize: '50px',
        fontWeight: 'bold',
        margin: 0,
        textAlign: 'center',
        [theme.breakpoints.only('md')]: {
            fontSize: '40px',
            textAlign: 'right',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '34px',
        }
    },
    termsLinkWrapper: {
        width: '100%',
        textAlign: 'center',
        [theme.breakpoints.only('md')]: {
            textAlign: 'right',
        },
    },

    termsLink: {
        color: '#BAE2F3',
        textDecoration: 'none',
        fontSize: '16px',
        whiteSpace: 'nowrap',
        '&:hover': {
            color: '#31A7DB',
            textDecoration: 'underline',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '14px',
        }
    }
}));