import axios from "axios";
import * as BaseActions from "store/actions";
//block ACTION NAMES
export const SUBMIT_RATING = "SUBMIT_RATING";
export const SUBMIT_RATING_IS_LOADING = "SUBMIT_RATING_IS_LOADING";
export const SUBMIT_RATING_FAILED = "SUBMIT_RATING_FAILED";

export const UPDATE_PROFILE = "SIGNUP_SUBMIT";
export const UPDATE_PROFILE_IS_LOADING = "UPDATE_PROFILE_IS_LOADING";
export const UPDATE_PROFILE_FAILED = "UPDATE_PROFILE_FAILED";
export const RESET_PROFILE_REDUCER = "RESET_PROFILE_REDUCER";

export const RESET = "RESET";

// Helper ACTIONS:
export const resetReducers = (ms) => ({
  type: RESET,
  payload: ms,
});
// Helper ACTIONS:
export const submitLoading = (ms) => ({
  type: SUBMIT_RATING_IS_LOADING,
  payload: ms,
});

export const submitFailed = (ms) => ({
  type: SUBMIT_RATING_FAILED,
  payload: ms,
});

export function submitRating(data) {
  const token = localStorage.getItem("JWTtoken");

  const config = {
    headers: {
      Authorization: `JWT ${token}`,
      // "Content-Type": "application/json",
    },
  };
  const request = axios.post(
    `${process.env.REACT_APP_ENV === "prod" ? process.env.REACT_APP_BASE_URL_PROD : process.env.REACT_APP_BASE_URL_TESTING}ratings/submit-rating`,
    data,
    config
  );
  return (dispatch) => {
    dispatch(submitLoading(true));
    dispatch(BaseActions.showLoading(true));

    request
      .then((response) => {
        if (response.data && response.data.status === "Success") {
          dispatch({
            type: SUBMIT_RATING,
            payload: response.data,
          });
          dispatch(resetReducers());
          dispatch(BaseActions.hideLoading());
          // dispatch(
          //   BaseActions.showAlert({
          //     message: response.data.message,
          //     variant: "success",
          //   })
          // );
        } else {
          let err = Error();
          err.message = response.data.message;
          throw err;
        }
      })
      .catch((error) => {
        // dispatch(
        //   BaseActions.showAlert({ variant: "error", message: error.message })
        // );
        dispatch(BaseActions.hideLoading())
        if (error.response) {
          if (error.response.status === "404") {
            dispatch(submitFailed(`${error.response.status} : api not found`));
          } else {
            dispatch(submitFailed(error.response.status));
          }
          console.log(error.response);
        } else if (error.request) {
          dispatch(submitFailed(error.request));

          console.log(error.request);
        } else {
          console.log("Error", error.message);
          dispatch(submitFailed(error.message));
        }
        console.log(error);
      });
  };
}


// Helper ACTIONS:
export const updateProfileLoading = (ms) => ({
  type: UPDATE_PROFILE_IS_LOADING,
  payload: ms,
});

export const updateProfileFailed = (ms) => ({
  type: UPDATE_PROFILE_FAILED,
  payload: ms,
});

export const resetProfileReducer = () => ({
  type: RESET_PROFILE_REDUCER,
});

export function updateProfile(data) {
  const token = localStorage.getItem("JWTtoken");


  const config = {
    headers: {
      Authorization: `JWT ${token}`,
      // "Content-Type": "application/json",
    },
  };

  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const request = axios.post(
    `${process.env.REACT_APP_ENV === "prod" ? process.env.REACT_APP_BASE_URL_PROD : process.env.REACT_APP_BASE_URL_TESTING}users/update-profile`,
    { ...data, timeZone },
    config
  );
  return (dispatch) => {
    dispatch(updateProfileLoading(true));
    dispatch(BaseActions.showLoading(true));

    request
      .then((response) => {
        if (response.data && response.data.status === "Success") {
          dispatch(BaseActions.hideLoading());
          dispatch({
            type: UPDATE_PROFILE,
            payload: response.data,
          });

          localStorage.removeItem("quarterlyUser");
          localStorage.setItem("quarterlyUser", JSON.stringify(response.data));

          dispatch(BaseActions.updateUserData(response.data.data));

          // dispatch(
          //   BaseActions.showAlert({
          //     message: response.data.message,
          //     variant: "success",
          //   })
          // );
        } else {
          let err = Error();
          err.message = response.data.message;
          throw err;
        }
      })
      .catch((error) => {
        dispatch(BaseActions.hideLoading());
        // dispatch(
        //   BaseActions.showAlert({ variant: "error", message: error.message })
        // );

        if (error.response) {
          if (error.response.status === "404") {
            dispatch(
              updateProfileFailed(`${error.response.status} : api not found`)
            );
          } else {
            dispatch(updateProfileFailed(error.response.status));
          }
          console.log(error.response);
        } else if (error.request) {
          dispatch(updateProfileFailed(error.request));

          console.log(error.request);
        } else {
          console.log("Error", error.message);
          dispatch(updateProfileFailed(error.message));
        }
        console.log(error);
      });
  };
}
