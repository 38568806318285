import React, {useState} from "react";

import { Box, Typography, Button } from "@material-ui/core";
import { Link, withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { isMobile } from "react-device-detect";
import reducer from "../signupForm/store/reducers";
import withReducer from "store/withReducer";

import CelebrityIcon from "../../../home/subComponents/findYourPerson/icons/CelebrityIcon";
import InfluencerIcon from "../../../home/subComponents/findYourPerson/icons/InfluencerIcon";
import AthleteRunningIcon from "../../../home/subComponents/findYourPerson/icons/AthleteRunningIcon";
import BrainIcon from "../../../home/subComponents/findYourPerson/icons/BrainIcon";
import TileFullWidth from "../../../home/subComponents/findYourPerson/tileFullWidth";

import * as Actions from "../signupForm/store/actions";
import queryString from 'query-string'
import {Spinner} from "../../../../@base";

const useStyles = makeStyles((theme) => ({
	container: {
		padding: "30px",
		color: "#31A7DB",
		fontWeight: 500,
		marginBottom: '1rem',
		fontSize: 18,
		fontFamily: 'cerebri-semibold'
	},
	gridContainer: {
		display: 'flex',
		flexDirection: "column",
		justifyContent: "space-between",
		gap: '1rem'
	},
	pageHeading: {
		marginTop: 25,
		marginBottom: 40,
		fontSize: '33px',
		fontWeight: 600,
		lineHeight: '40px',
		textAlign: 'left',
		textUnderlinePosition: 'from-font',
		textDecorationSkipInk: 'none',
	},
	overflowY: {
		overflowY: "scroll"
	},
}));

export const allPersonTypes = [
	{
		title: 'Celebrity',
		icon: <CelebrityIcon />,
		description: 'Skills gained through work and career',
		selected: false
	},
	{
		title: 'Influencer',
		icon: <InfluencerIcon />,
		description: 'Skills gained through work and career',
		selected: false
	},
	{
		title: 'Athlete',
		icon: <AthleteRunningIcon />,
		description: 'Skills gained through work and career',
		selected: false
	},
	{
		title: 'Expert',
		icon: <BrainIcon />,
		description: 'Skills gained through work and career',
		selected: false
	}
];

const PersonType = (props) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const updateProfileResponse = useSelector(
		({ PersonType }) => PersonType.UpdateProfileReducer
	);
	const updateProfileResponseData = updateProfileResponse.data;
	const isLoading = updateProfileResponse.isLoading;
	const [personTypes, setPersonTypes] = useState(allPersonTypes);
	const [selected, setSelected] = useState(new Set());

	const id = queryString.parse(props.location.search).id
	const code = queryString.parse(props.location.search).code
	React.useEffect(() => {
		if (!!updateProfileResponseData && updateProfileResponseData.status === "Success") {
			if (id) {
				props.history.push(`/username?id=${id}&code=${code}`);
			} else {
				props.history.push("/username");
			}
		}
	}, [updateProfileResponseData, id, code, props.history]);

	const [initialScreenSize] = React.useState(window.innerHeight);
	const [keyboardIsShown, setKeyboardIsShown] = React.useState(false);
	React.useEffect(() => {
		setInterval(() => {
			if (initialScreenSize > window.innerHeight) {
				!keyboardIsShown && setKeyboardIsShown(true);
			} else {
				keyboardIsShown && setKeyboardIsShown(false)
			}
		}, 1)
	}, [keyboardIsShown, initialScreenSize])

	const handleSetPersonType = (val) => {
		dispatch(
			Actions.updateProfile({
				person_type: Array.from(selected),
			})
		);
	};

	const handlePersonTypeClick = (index) => {
		const updatedPersonTypes = [...personTypes];
		updatedPersonTypes[index].selected = !updatedPersonTypes[index].selected;
		setPersonTypes(updatedPersonTypes);

		const selectedCopy = selected;
		if (updatedPersonTypes[index].selected) {
			selectedCopy.add(updatedPersonTypes[index].title);
		} else {
			selectedCopy.delete(updatedPersonTypes[index].title);
		}

		setSelected(selectedCopy)
	}

	const navigateOnSkip = () => {
		props.history.push('/username' + (!!id ? `?id=${id}&code=${code}` : ''));
	};

	return (
		<Box
			display="flex"
			flexDirection="column"
			height="100%"
			position="absolute"
		>
			<Box padding={isMobile ? "15px 30px 0px 30px" : "32px 30px 0px 30px"} margin="0 0 20px 0">
				<Box
					display="flex"
					flexDirection="column"
					paddingBottom={isMobile ? "4%" : "0%"}
				>
					<Typography variant="h1">Person Type</Typography>
				</Box>
				<Typography margin="20px 0 0 0" variant="caption">
					(Choose the type of person you are - select all that apply.)
				</Typography>
			</Box>

			<Box className={classes.container}>
				<div className={classes.gridContainer}>
					{personTypes.map((type, i) => (
						<TileFullWidth title={type.title} icon={type.icon} key={i} selected={type.selected}
									   onSelect={() => handlePersonTypeClick(i)} />
					))}
				</div>
			</Box>

			<Box hidden={(isMobile && keyboardIsShown)}>
				<Box position={isMobile && "fixed"}
					 width={isMobile ? "100%" : "auto"}
					 bottom={isMobile && 15}
					 padding="30px"
					 paddingBottom={isMobile ? "10px" : "32px"}
					 display="flex"
					 justifyContent="space-between"
				>
					<Button
						onClick={() => navigateOnSkip()}
						component={Link}
						variant="outlined"
						color="default"
						style={{ width: "45%", backgroundColor: "#080A0A" }}
					>
						Skip
					</Button>
					<Button
						disabled={!selected.size}
						onClick={() => handleSetPersonType()}
						color="primary"
						style={{ width: "45%" }}
					>
						 {isLoading ? <Spinner /> : "Next"}
					</Button>
				</Box>
			</Box>
		</Box>
	);
};
export default withReducer("PersonType", reducer)(withRouter(PersonType));
