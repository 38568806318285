import * as Actions from "../actions";

// initial state
const initialState = {
  isLoading: false,
  errMsg: null,
  data: null,
};

// Reducer for LOGIN handling
export const SearchReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.SEARCH:
      // we will mould our payload here accordingly  if required  in case of success
      return { ...state, isLoading: false, errMsg: null, data: action.payload };
    case Actions.SEARCH_IS_LOADING:
      // ... in case of loading
      return { ...state, isLoading: true, errMsg: null, data: null };
    case Actions.SEARCH_FAILED:
      // ... in case of error
      return { ...state, isLoading: false, errMsg: action.payload, data: null };
    default:
      return state;
  }
};
// Reducer for LOGIN handling
export const TagReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.SEARCH_BY_TAG:
      // we will mould our payload here accordingly  if required  in case of success
      return { ...state, isLoading: false, errMsg: null, data: action.payload };
    case Actions.TAG_IS_LOADING:
      // ... in case of loading
      return { ...state, isLoading: true, errMsg: null, data: null };
    case Actions.TAG_FAILED:
      // ... in case of error
      return { ...state, isLoading: false, errMsg: action.payload, data: null };
    default:
      return state;
  }
};
