import * as Actions from "../actions";

// initial state
const initialState = {
  isLoading: false,
  errMsg: null,
  data: null,
};

// Reducer for LOGIN handling
export const FetchQuartrReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.FETCH_QUARTR:
      // we will mould our payload here accordingly  if required  in case of success
      return { ...state, isLoading: false, errMsg: null, data: action.payload };
    case Actions.QUARTR_IS_LOADING:
      // ... in case of loading
      return { ...state, isLoading: true, errMsg: null, data: null };
    case Actions.QUARTR_FAILED:
      // ... in case of error
      return { ...state, isLoading: false, errMsg: action.payload, data: null };
    default:
      return state;
  }
};
export const CurrentQuartrStateReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.CURRENT_QUARTR_STATE:
      // we will mould our payload here accordingly  if required  in case of success
      return { ...state, isLoading: false, errMsg: null, data: action.payload };
    case Actions.QUARTR_STATE_IS_LOADING:
      // ... in case of loading
      return { ...state, isLoading: true, errMsg: null, data: null };
    case Actions.QUARTR_STATE_FAILED:
      // ... in case of error
      return { ...state, isLoading: false, errMsg: action.payload, data: null };
    default:
      return state;
  }
};
