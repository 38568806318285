import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import {
  Box,
  Typography,
  Button,
  Radio,
  RadioGroup,
  // Input,
  // InputAdornment,
  FormControlLabel,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import * as BaseActions from "../../../addPayment/store/actions";
import reducers from "components/payment/store/reducers";
import clsx from "clsx";
// import InputMask from "react-input-mask";
import Pics from "@assets";
import CheckIcon from "@material-ui/icons/Check";
import withReducer from "store/withReducer";
import { withRouter } from "react-router";
import useDeepCompareEffect from "use-deep-compare-effect";
import * as Actions from "../../../payment/store/actions";
import CheckoutForm from "../../../addPayment/subComponents/stripeFields";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { isIOS, isMobile } from "react-device-detect";
const stripePromise = loadStripe("pk_live_MIWdkks8jwJHca27NCqcIrFM");
const useStyles = makeStyles((theme) => ({
  container: { padding: "28px", background: "#111111", position: "relative" },
  button: { width: "45%", margin: "0px 5px" },
  root: {
    color: "white",
  },
  checked: {
    "&$checked": {
      color: "#31A7DB",
    },
  },
  inline: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: "25px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "10px",
    }
  },
  inactiveAvailable: {
    marginRight: 20,
    border: "2px solid #808080",
    borderRadius: 6,
    color: "transparent",
    cursor: "pointer",
  },
  activeAvailable: {
    marginRight: 20,
    backgroundColor: "#31A7DB",
    borderRadius: 6,
    color: "white",
    cursor: "pointer",
  },
  fontSize: {
    fontSize: 16,
  },
  // icon: {
  //   borderRadius: "50%",
  // },
  checkedIcon: {
    "&:before": {
      borderRadius: "50%",
      display: "block",
      width: 22,
      height: 22,
      backgroundImage: "radial-gradient(#31A7DB 48%,transparent 32%)",
      border: "2px solid #FFFFFF",
      content: '""',
    },
    // "input:hover ~ &": {
    //   borderRadius: "50%",
    //   border: "2px solid #FFFFFF",
    // },
  },
}));
const PaymentDrawer = ({ handleClose, handleSetAppointment, data }) => {
  const classes = useStyles();
  const [selectedIdx, setSelectedIdx] = React.useState(0);
  const [selectedCard, setSelectedCard] = React.useState(false);
  const [cards, setCards] = React.useState([]);
  const [saveMethod, setSaveMethod] = React.useState(false);
  const [state, setState] = useState({});
  const [isEnabled, setIsEnabled] = useState(false);
  const [callMethod, setCallMethod] = useState(false);
  const [default_method, setDefault] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const dispatch = useDispatch();
  const cardsData = useSelector(({ Payment }) => Payment.PaymentReducer);
  useDeepCompareEffect(() => {
    if (cardsData?.data && cardsData.data.status === "Success") {
      setCards(cardsData.data.data);
      setSelectedIdx(cardsData.data.data.findIndex((c) => c.default_method));
      setSelectedCard(cardsData.data.data.find((c) => c.default_method));
      return;
    }
  }, [cardsData]);
  const fetchedData = useSelector(({ Payment }) => Payment.AddPaymentReducer);
  useDeepCompareEffect(() => {
    if (fetchedData?.data && fetchedData.data.status === "Success") {
      handleSetAppointment({
        ...data,
        customer_stripe_id: fetchedData.data.data.customer_stripe_id,
      });
      dispatch(BaseActions.paymentReset());
      return;
    } else if (fetchedData?.data && fetchedData.data.status === "Fail") {
      setShowError(true)
      setErrorMessage(fetchedData.data.message)
    }
  }, [fetchedData]);
  React.useEffect(() => {
    dispatch(Actions.fetchPayments());
  }, [dispatch]);
  const handleRadioChange = (event) => {
    setSelectedIdx(event.target.value);
  };
  React.useEffect(() => {
    if (state.token) {
      handleSave();
    }
  }, [state]);
  const handleSave = () => {
    if (selectedIdx == 999) {
      dispatch(
        BaseActions.addPayment({
          ...state,
          default_method,
          saved: saveMethod,
        })
      );
      return;
    }
    handleSetAppointment({
      ...data,
      customer_stripe_id: selectedCard.customer_stripe_id,
    });
  };
  return (
    <div>
      <Box className={classes.container}>
        <Box margin={isMobile ? "0" : "0 0 20px 0"}>
          <Typography variant="body2" className={classes.fontSize}>
            Select Payment Method
          </Typography>
        </Box>
        <RadioGroup value={selectedIdx} onChange={handleRadioChange}>
          {cards?.map((c, idx) => (
            <Box key={idx} className={classes.inline}>
              <FormControlLabel
                value={idx}
                control={
                  <Radio
                    checkedIcon={
                      <span
                        className={clsx(classes.icon, classes.checkedIcon)}
                      />
                    }
                    checked={selectedIdx == idx}
                    onClick={() => setSelectedCard(c)}
                    classes={{ root: classes.root, checked: classes.checked }}
                  />
                }
              />
              <img
                alt="icon"
                src={
                  c.card_type === "Visa"
                    ? Pics.visa
                    : c.card_type === "MasterCard"
                      ? Pics.master
                      : c.card_type === "American Express"
                        ? Pics.amex
                        : c.card_type === "Diners Club"
                          ? Pics.discover
                          : c.card_type === "Discover"
                            ? Pics.discover
                            : c.card_type === "UnionPay"
                              ? Pics.unionPay
                              : c.card_type === "JCB"
                                ? Pics.JCB
                                : Pics.cardIll
                }
                style={{ marginRight: 15 }}
              />
              <Box style={{ flexGrow: 1 }}>
                <Typography variant="body1" className={classes.fontSize}>
                  xxxx xxxx xxxx {c.card}
                </Typography>
                <Typography variant="subtitle2">
                  Expires {c.exp_month}/{c.exp_year.toString().slice(2)}
                </Typography>
              </Box>
            </Box>
          ))}
        </RadioGroup>
        <Box margin="50px 0 20px 0" className={classes.inline}>
          <FormControlLabel
            value={cards.length < 10 && 999}
            onChange={handleRadioChange}
            disabled={cards.length >= 10}
            control={
              <Radio
                checkedIcon={
                  <span className={clsx(classes.icon, classes.checkedIcon)} />
                }
                checked={cards.length < 10 && selectedIdx == 999}
                onClick={() => setSelectedCard(state)}
                classes={{ root: classes.root, checked: classes.checked }}
              />
            }
          />
          <Typography className={classes.fontSize}>
            {cards.length >= 10
              ? "Can't add more than 10 cards"
              : "Add new payment method"}
          </Typography>
        </Box>
        {selectedIdx == 999 && cards.length < 10 && (
          <Box>
            <Elements stripe={stripePromise}>
              <CheckoutForm
                setIsEnabled={setIsEnabled}
                callMethod={callMethod}
                set={setCallMethod}
                setState={(newState) => setState({ ...newState })}
              />
              <Box className={classes.inline}>
                <CheckIcon
                  className={
                    saveMethod
                      ? classes.activeAvailable
                      : classes.inactiveAvailable
                  }
                  onClick={() => {
                    setSaveMethod(!saveMethod);
                    setDefault(false);
                  }}
                />
                <Typography className={classes.fontSize}>
                  Save payment method for future use.
                </Typography>
              </Box>
              {saveMethod && (
                <Box className={classes.inline}>
                  <CheckIcon
                    className={
                      default_method
                        ? classes.activeAvailable
                        : classes.inactiveAvailable
                    }
                    onClick={() => setDefault(!default_method)}
                  />
                  <Typography>Make default.</Typography>
                </Box>
              )}
            </Elements>
          </Box>
        )}
        {
          showError &&
          <div style={{ width: "100%", marginTop: "10px" }}>
            <span style={{ fontFamily: "-apple-system, BlinkMacSystemFont, cerebri-regular", color: 'rgb(203, 58, 58)', fontSize: '14px' }}>*{errorMessage}</span>
          </div>
        }
        <Box
          marginTop="30px"
          padding="10px 0"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Button variant="outlined" color="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            onClick={() => {
              if (selectedCard)
                selectedIdx === "999" ? setCallMethod(true) : handleSave();
            }}
            disabled={selectedIdx === "999" ? !isEnabled : false}
          >
            Ok
          </Button>
        </Box>
      </Box>
    </div>
  );
};
export default withReducer("Payment", reducers)(withRouter(PaymentDrawer));
