import React from "react";
import { withRouter } from "react-router";
import { Typography, makeStyles } from "@material-ui/core";
// import useDeepCompareEffect from "use-deep-compare-effect";
//// functions need to use react with our component
import { useDispatch, 
  // useSelector,
 } from "react-redux";
import withReducer from "store/withReducer";
import * as BaseActions from "store/actions";
// import * as Actions from 'components/signup/screens/signupForm/store/actions'
import reducers from "components/signup/screens/signupForm/store/reducers";
import "react-multi-carousel/lib/styles.css";
import { Link } from 'react-router-dom';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import HistoryTabs from './HistoryTabs';
const useStyles = makeStyles((theme) => ({
  headerWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 15,
    padding: 17,
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
      padding: "17px 17px 0px 17px",
    }
  },
  headerText: {
    color: '#31A7DB',
    fontSize: '1.9rem',
    fontFamily: 'Cerebri-Bold'
  },
  leftSideMenu: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginRight: 'auto',
  },
  iconLink: {
    marginTop: '0.5rem',
  }
}))
function History() {
  const dispatch = useDispatch();
  const classes = useStyles();
  React.useEffect(()=>{
    dispatch(BaseActions.updateLayout({ bottomNav: false }));
  },[])
  return (
    <>
      <div className={classes.headerWrapper}>
        <div className={classes.leftSideMenu}>
            <Link to='/quarters' className={classes.iconLink}>
              <ArrowBackIosIcon color='secondary' />
            </Link>
          <Typography variant='h5' className={classes.headerText}>History</Typography>
        </div>
      </div>
      <HistoryTabs />
    </>
  );
}
export default withReducer("Availability", reducers)(withRouter(History));
