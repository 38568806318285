import React, { useState } from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import pics from "@assets";
import { Link } from "react-router-dom";
import Template from "./template";
import { useDispatch, useSelector } from "react-redux";
import * as BaseActions from "store/actions";
import QuartsImage from '@assets/Pickaday.png'
import Rate from '@assets/rate.png'
import InviteImage from '@assets/invite.png'
import { isMobile } from "react-device-detect";
const useStyles = makeStyles((theme) => ({
  image: {
    marginRight: 10,
    cursor: "pointer",
  },
  container: {
    overflowY: "scroll"
  },
  intro: {
    marginBottom: 0
  }
}));
const LegalStuff = () => {
  const dispatch = useDispatch();
  const [screen, setScreenState] = useState(1);
  const classes = useStyles();
  const { layout } = useSelector(({ BaseReducer }) => ({
    layout: BaseReducer.LayoutReducer,
  }));
  React.useEffect(() => {
    if (layout.bottomNav) dispatch(BaseActions.updateLayout({ bottomNav: false }));
    dispatch(BaseActions.updateLayout({ fullScreen: false }));
  }, [layout.bottomNav])
  React.useEffect(() => {
    setTimeout(() => {
      setScreenState(screen === 4 ? 1 : screen + 1)
    }, 3000)
  }, [screen])
  let setScreen = (indicator, no) => {
    if (indicator === "left") {
      if (screen === 1) {
        return;
      }
      setScreenState((pre) => pre - 1);
      return;
    }
    if (indicator === "right") {
      if (screen === 4) {
        return;
      }
      setScreenState((pre) => pre + 1);
      return;
    }
  };
  let setScreenFromBar = (screen) => {
    setScreenState(screen);
  };
  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100%"
      padding={isMobile ? "15px 30px 0px 30px" : "60px 30px 0px 30px"}
      position="absolute"
      className={classes.container}
    >
      {screen === 1 && (
        <Template
          onTap={setScreen}
          screenNo={2}
          title="Video Calling"
          // image={pics.videoCalling}
          image={'assets/backgrounds/VideoCalling.jpg'}
          body="Make money doing 15 minutes video calls with Celebrities, Influencers, Fitness Trainers, Makeup Artists."
        />
      )}
      {screen === 2 && (
        <Template
          screenNo={3}
          onTap={setScreen}
          title="Rate"
          // image={pics.rate}
          // image={'assets/backgrounds/Rate.jpg'}
          image={Rate}
          body="Set the rate you charge people to talk to you. If you want to talk to someone, you have to pay their rate."
        />
      )}
      {screen === 3 && (
        <Template
          onTap={setScreen}
          screenNo={4}
          title="Quartrs"
          // image={pics.quartrs}
          // image={'assets/backgrounds/Quartrs.jpg'}
          image={QuartsImage}
          body="Looking at the list of available times, you can pick a day and time that's convenient for both of you to talk."
        />
      )}
      {screen === 4 && (
        <Template
          onTap={setScreen}
          screenNo={5}
          title="Invite"
          // image={pics.invite}
          // image={'assets/backgrounds/Invite.jpg'}
          image={InviteImage}
          body="Invite your friends and earn 1% of the revenue they generate. Make sure they use your 6 character code."
        />
      )}
      <Box paddingBottom="50px" margin={isMobile ? "15px 0 0 0" : "auto 0 0 0"} display="flex" justifyContent="space-between">
        <Box>
          <img
            onClick={() => setScreenFromBar(1)}
            alt="Legal Stuff"
            variant="square"
            src={pics.blueLine}
            className={classes.image}
          />
          <img
            onClick={() => setScreenFromBar(2)}
            alt="Legal Stuff"
            variant="square"
            src={
              screen === 2 || screen === 3 || screen === 4
                ? pics.blueLine
                : pics.whiteLine
            }
            className={classes.image}
          />
          <img
            onClick={() => setScreenFromBar(3)}
            alt="Legal Stuff"
            variant="square"
            src={screen === 3 || screen === 4 ? pics.blueLine : pics.whiteLine}
            className={classes.image}
          />
          <img
            onClick={() => setScreenFromBar(4)}
            alt="Legal Stuff"
            variant="square"
            src={screen === 4 ? pics.blueLine : pics.whiteLine}
            className={classes.image}
          />
        </Box>
        <Box>
          <Link to="/signup">
            <Typography variant="body1">
              {/* {screen === 4 ? "Skip" : "Skip"} */}
              Skip
            </Typography>
          </Link>
        </Box>
      </Box>
    </Box>
  );
};
export default LegalStuff;
