import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import {
  Box, Typography, Button,
  //  Modal
} from "@material-ui/core";
// import { useDispatch, useSelector } from "react-redux";
// import * as BaseActions from "store/actions";
import InputGroup from "../inputGroup";
import PriceRange from "../priceRange";
import CloseIcon from "@material-ui/icons/Close";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
// import CheckIcon from "@material-ui/icons/Check";
import { MySwitch } from "@base";
// import Dialog from '@material-ui/core/Dialog';
// import DialogActions from '@material-ui/core/DialogActions';
// import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
// import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { isMobile } from "react-device-detect";
// import { BorderColor } from "@material-ui/icons";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const useStyles = makeStyles((theme) => ({
  container: {
    padding: "28px",
    background: "#111111",
    position: "absolute",
    bottom: 0,
    zIndex: 100,
    [theme.breakpoints.down("sm")]: {
      padding: '28px 28px 0px 28px'
    }
  },
  close: {
    stroke: "#CB3A3A",
    strokeWidth: 2,
    position: "absolute",
    right: 15,
    top: 15,
    color: "#CB3A3A",
    cursor: "pointer",
  },
  button: { width: "45%", margin: "0px 5px" },
  inactive: {
    border: "2px solid #808080",
    borderRadius: 6,
    color: "#808080",
    cursor: "pointer",
  },
  active: {
    backgroundColor: "#31A7DB",
    borderRadius: 6,
    color: "white",
    cursor: "pointer",
  },
  inactiveAvailable: {
    border: "2px solid #808080",
    borderRadius: 6,
    color: "#111111",
    cursor: "pointer",
  },
  modal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  modalContainer: {
    width: "300px",
    padding: "10px",
    // border: "1px solid #808080",
    borderRadius: 10,
    color: "#FFFFFF",
    backgroundColor: "#111111",
    borderColor: "#FFFFFF",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  modalActionContainer: {
    marginTop: "20px",
    marginBottom: "10px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  activeAvailable: {
    backgroundColor: "#31A7DB",
    borderRadius: 6,
    color: "white",
    cursor: "pointer",
  },
}));
const FilterDrawer = ({ handleClose, filterHandler, appliedFilter }) => {
  let { minRate, maxRate, sortRating, sortReviews } = appliedFilter;
  const [open, setOpen] = React.useState(false);
  // const dispatch = useDispatch();
  const classes = useStyles();
  const [state, setState] = useState({
    rating:
      appliedFilter.rating || appliedFilter.rating === 0
        ? appliedFilter.rating
        : 3.5,
    minPrice: minRate ? minRate : 1,
    maxPrice: maxRate ? maxRate : 999,
    descendingReview: sortReviews ? sortReviews : false,
    descendingRating: sortRating ? sortRating : false,
    availableNow: appliedFilter.availableNow ? true : false,
  });
  // const handleClickOpen = () => {
  //   setOpen(true);
  // };
  // const handleModalClose = () => {
  //   setOpen(false);
  // };
  const {
    rating,
    minPrice,
    maxPrice,
    descendingReview,
    descendingRating,
    availableNow,
  } = state;
  // React.useEffect(() => {
  //   dispatch(BaseActions.updateLayout({ bottomNav: false }));
  //   return () => {
  //     dispatch(BaseActions.updateLayout({ bottomNav: true }));
  //   };
  // }, []);
  return (
    <div>
      <Box className={classes.container}>
        <Box>
          <Typography variant="body2">Filtering & Sorting</Typography>
        </Box>
        {/* <CloseIcon className={classes.close} onClick={handleClose} /> */}
        <CloseIcon className={classes.close} onClick={() => handleClose()} />
        <Box marginTop="15px">
          <InputGroup
            text="Rating >"
            value={rating}
            set={(rating) => setState({ ...state, rating: parseFloat(rating) })}
          />
        </Box>
        <Box marginTop="15px">
          <Typography variant="caption">Rate</Typography>
          <PriceRange
            min={minPrice}
            max={maxPrice}
            setMin={(minPrice) => setState({ ...state, minPrice })}
            setMax={(maxPrice) => setState({ ...state, maxPrice })}
          />
        </Box>
        <Box
          marginTop="15px"
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
        >
          <Typography variant="caption">Number of reviews</Typography>
          <Box>
            <KeyboardArrowUpIcon
              className={!descendingReview ? classes.active : classes.inactive}
              style={{ marginRight: 10 }}
              onClick={() =>
                setState({ ...state, descendingReview: !descendingReview })
              }
            />
            <KeyboardArrowDownIcon
              className={descendingReview ? classes.active : classes.inactive}
              onClick={() =>
                setState({ ...state, descendingReview: !descendingReview })
              }
            />
          </Box>
        </Box>
        <Box
          marginTop={isMobile ? "10px" : "20px"}
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="caption">Rating</Typography>
          <Box>
            <KeyboardArrowUpIcon
              className={!descendingRating ? classes.active : classes.inactive}
              style={{ marginRight: 10 }}
              onClick={() =>
                setState({ ...state, descendingRating: !descendingRating })
              }
            />
            <KeyboardArrowDownIcon
              className={descendingRating ? classes.active : classes.inactive}
              onClick={() =>
                setState({ ...state, descendingRating: !descendingRating })
              }
            />
          </Box>
        </Box>
        <Box
          marginTop={"10px"}
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="caption">Available Now</Typography>
          <MySwitch
            checked={availableNow}
            handleChange={() =>
              setState({ ...state, availableNow: !availableNow })
            }
          />
        </Box>
        <Box
          margin="20px 0"
          padding={isMobile ? "0px 0" : "10px 0"}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          paddingBottom={isMobile ? "80px" : "0"}
        >
          <Button
            className={classes.button}
            variant="outlined"
            color="secondary"
            onClick={() => {
              setState({
                ...state,
                rating: 3.5,
                minPrice: 1,
                maxPrice: 999,
              });
            }}
          >
            Clear all
          </Button>
          <Button
            className={classes.button}
            onClick={() => {
              filterHandler(state);
              handleClose();
            }}
          >
            Apply
          </Button>
        </Box>
      </Box>
    </div>
  );
};
export default FilterDrawer;
