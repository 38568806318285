import React from 'react';

function HomeIcon({ ...props }) {
    return (
        <svg width={props.width ?? '26'} height={props.height ?? '26'} viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_2_208)">
                <path
                    d="M25.762 12.017L15.6574 1.91196C14.1919 0.446908 11.8082 0.446908 10.3426 1.91196L0.23807 12.017C-0.0793159 12.3344 -0.0793159 12.8488 0.23807 13.1662C0.554948 13.4836 1.06987 13.4836 1.38675 13.1662L2.03117 12.5218V22.8508C2.03117 24.1409 3.07702 25.1868 4.36713 25.1868H8.42967C8.71014 25.1868 8.93749 24.9594 8.93749 24.6789V17.1632C8.93749 15.761 10.0743 14.6242 11.4766 14.6242H14.5235C15.9258 14.6242 17.0626 15.761 17.0626 17.1632V24.6789C17.0626 24.9594 17.2899 25.1868 17.5704 25.1868H21.6329C22.923 25.1868 23.9689 24.1409 23.9689 22.8508V12.5218L24.6133 13.1662C24.7717 13.3247 24.9799 13.4044 25.1876 13.4044C25.3953 13.4044 25.6035 13.3247 25.762 13.1662C26.0794 12.8488 26.0794 12.3344 25.762 12.017Z"
                    fill={props.fill} />
            </g>
            <defs>
                <clipPath id="clip0_2_208">
                    <rect width="26" height="26" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    )
}

export default HomeIcon;