import React from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  illustration: {
    objectFit: "contain",
    margin: "auto",
    width: "80%",
    height: "100%",
  },
  imageContainer: {
    display: "flex",
    alignItems: "center",
    height: "400px",
    width: "100%",
  },
  TitleText: {
    marginTop: "10vh",
    [theme.breakpoints.down('sm')]: {
      marginTop: "4vh",
    }
  },
  bodyText: {
    margin: "20px 0",
  },
}));
const LegalStuff = ({ title, image, body, onTap, screenNo }) => {
  const classes = useStyles();
  const inviteBody = body.split('1%')
  let handleClick = (e) => {
    const clickTarget = e.target
    const clickTargetWidth = clickTarget.offsetWidth
    const xCoordInClickTarget = e.clientX - clickTarget.getBoundingClientRect().left
    if (clickTargetWidth / 2 > xCoordInClickTarget)
      onTap("left")
    else
      onTap("right")
  }
  return (
    <div
      className="disable-selection"
      // onClick={()=>onTap(screenNo)}
      onClick={(e) => handleClick(e)}
    >
      <Box className={classes.imageContainer}>
        <img
          alt="Legal Stuff"
          variant="square"
          src={image}
          className={classes.illustration}
        />
      </Box>
      <Typography variant="h1" className={classes.TitleText}>
        {title}
      </Typography>
      {
        screenNo === 5
          ?
          <div>
            <Typography variant="caption" className={classes.bodyText}>{inviteBody[0]}</Typography>
            <Typography variant="caption" style={{ color: '#fff' }}>1%</Typography>
            <Typography variant="caption" className={classes.bodyText}>{inviteBody[1]}</Typography>
          </div>
          :
          <Typography variant="caption" className={classes.bodyText}>
            {body}
          </Typography>
      }
    </div>
  );
};
export default LegalStuff;
