import React, { useState, useEffect } from 'react'
import * as BaseActions from "store/actions";
import * as Actions from './store/actions';
import Header from './components/header';
import DateFilters from "./components/dateFilters";
import TopButtons from "./components/topButtons";
import TableHeader from './components/tableHeader';
import TableRow from "./components/tableRow";
import { useDispatch } from "react-redux";
import { useStyles } from './styles';

function Leaderboard() {
    const dispatch = useDispatch();

    const pageHeading = 'Invites Leaderboard';
    const classes = useStyles();
    const pageSizeOptions = [10, 25, 50, 100];

    const [userData, setUserData] = useState([]);
    const [totalUsers, setTotalUsers] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    useEffect(() => {
        const getLeaderboardData = async () => {
            dispatch(BaseActions.showLoading(true))

            let params = { page: 1, pageSize: pageSize };
            if (startDate !== null)
                params.startDate = startDate;
            if (endDate !== null)
                params.endDate = endDate;

            const response = await Actions.getLeaderboardData(params);
            if (response.status === 'Success') {
                setUserData(response.data.users)
                setTotalUsers(response.data.users_count);
            } else {
                console.log(response);
            }

            dispatch(BaseActions.hideLoading(true));
        }

        getLeaderboardData();
    }, [pageSize, startDate, endDate]);

    useEffect(() => {
        dispatch(BaseActions.updateLayout({ fullScreen: true }))
    }, []);

    const handlePageSizeChange = (option) => {
        setPageSize(option);
    }

    const handleStartDateChange = (event) => {
        setStartDate(event.target.value);
    }

    const handleEndDateChange = (event) => {
        setEndDate(event.target.value);
    }

    return (
        <div className={classes.container}>
            <Header totalUsers={totalUsers} pageHeading={pageHeading} />

            <div className={classes.background}>
                <div className={classes.filtersGrid}>
                    <TopButtons options={pageSizeOptions} pageSize={pageSize}
                                handlePageSizeChange={handlePageSizeChange} />
                    <DateFilters handleStartDateChange={handleStartDateChange}
                                 handleEndDateChange={handleEndDateChange} />
                </div>

                <div className={classes.tableContainer}>
                    <div className={classes.tableContainerContent}>
                        <table className={classes.table}>
                            <TableHeader />
                            {userData.map((user, index) => (
                                <TableRow user={user} index={index} />
                            ))}
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Leaderboard;