import React from "react";

function InfluencerIcon() {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.79999 8.8C7.90456 8.8 8.79999 7.90457 8.79999 6.8C8.79999 5.69543 7.90456 4.8 6.79999
								 4.8C5.69542 4.8 4.79999 5.69543 4.79999 6.8C4.79999 7.90457 5.69542 8.8 6.79999 8.8Z"
                  stroke="#31A7DB" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round"
                  strokeLinejoin="round"/>
            <path d="M6.79999 27.2C7.90456 27.2 8.79999 26.3046 8.79999 25.2C8.79999 24.0954 7.90456
								 23.2 6.79999 23.2C5.69542 23.2 4.79999 24.0954 4.79999 25.2C4.79999 26.3046 5.69542 27.2
								 6.79999 27.2Z"
                  stroke="#31A7DB" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round"
                  strokeLinejoin="round"/>
            <path d="M25.2 27.2C26.3046 27.2 27.2 26.3046 27.2 25.2C27.2 24.0954 26.3046 23.2 25.2 23.2C24.0954
								 23.2 23.2 24.0954 23.2 25.2C23.2 26.3046 24.0954 27.2 25.2 27.2Z"
                  stroke="#31A7DB" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round"
                  strokeLinejoin="round"/>
            <path d="M25.2 8.8C26.3046 8.8 27.2 7.90457 27.2 6.8C27.2 5.69543 26.3046 4.8 25.2 4.8C24.0954 4.8
								 23.2 5.69543 23.2 6.8C23.2 7.90457 24.0954 8.8 25.2 8.8Z"
                  stroke="#31A7DB" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round"
                  strokeLinejoin="round"/>
            <path d="M12.5 12.6C11.1 12.6 10 13.8 10 15.2C10 16.6 11.1 17.8 12.5 17.8C14.7 17.8 16.9 18.3 18.9
								 19.3L20.9 20.3V14.5V10L18.9 11C17 12 14.8 12.6 12.5 12.6Z"
                  stroke="#31A7DB" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round"
                  strokeLinejoin="round"/>
            <path d="M11 17.2L13.4 22H15.9V12.6" stroke="#31A7DB" strokeWidth="1.5" strokeMiterlimit="10"
                  strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M5.5 23.7C3.9 21.5 3 18.9 3 16C3 13.1 3.9 10.5 5.5 8.3" stroke="#31A7DB" strokeWidth="1.5"
                  strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M23.7 26.5C21.5 28.1 18.9 29 16 29C13.1 29 10.5 28.1 8.29999 26.5" stroke="#31A7DB"
                  strokeWidth="1.5"
                  strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M26.6 8.39999C28.1 10.5 29 13.2 29 16C29 18.9 28.1 21.5 26.5 23.7" stroke="#31A7DB"
                  strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M8.29999 5.5C10.5 3.9 13.1 3 16 3C18.9 3 21.5 3.9 23.7 5.5" stroke="#31A7DB"
                  strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export default InfluencerIcon;