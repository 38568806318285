import React, {useState} from "react";
import {withRouter} from "react-router";
import {Box, Paper, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import StarRateIcon from "./icons/StarRateIcon";
import PlaceholderImage from "./icons/PlaceholderImage";
import {Image} from "semantic-ui-react";
import PlaceholderErrorImage from "./icons/PlaceholderErrorImage";

const useStyles = makeStyles((theme) => ({
    userCard: {
        display: "flex",
        cursor: 'pointer',
        width: '100%',
        height: '109px',
        backgroundColor: '#1B1B1B',
        alignItems: 'center',
        padding: '8px',
        borderRadius: '16px',
        position: 'relative',
        gap: '12px',
    },
    container: {
        height: "94px",
        width: "94px",
        borderRadius: "16px",
        backgroundSize: '100% 100%',
        backgroundRepeat: 'no-repeat',
        boxShadow: '0px 12px 24px 0px #4444441A',
        backgroundColor: '#C4C4C4',
    },
    tags: {
        marginRight: "10px",
        fontSize: 10,
    },
    tagsContainer: {
        width: "calc(100% - 94px)",
        padding: '7px 0',
        display: "grid",
        alignSelf: "start",
        gap: '8px',
        gridTemplateColumns: '1fr 1fr',
    },
    ratePill: {
        boxSizing: "border-box",
        position: "absolute",
        bottom: '8px',
        right: '12px',
        placeItems: "center",
        backgroundColor: 'transparent',
    },
    ratingPill: {
        boxSizing: "border-box",
        position: "absolute",
        display: "flex",
        bottom: '10px',
        left: '111px',
        gap: '7px',
        placeItems: "center",
        padding: '0 8px',
        backgroundColor: '#2F2F2F',
        borderRadius: '9px',
    },
    userName: {
        fontSize: '16px',
        fontWeight: 500,
        lineHeight: '19.09px',
        letterSpacing: '0.5px',
        textAlign: 'left',
        textUnderlinePosition: 'from-font',
        textDecorationSkipInk: 'none',
    },
    userExpertiseContainer: {
        display: 'grid',
        gridTemplate: '100%',
        gap: '4px',
        marginTop: '7px'
    },
    userExpertise: {
        fontFamily: "-apple-system, BlinkMacSystemFont, cerebri-regular",
        fontSize: '12px',
        fontWeight: '300',
        lineHeight: '14.32px',
        letterSpacing: '0.5px',
        textAlign: 'left',
        textUnderlinePosition: 'from-font',
        textDecorationSkipInk: 'none',
        color: '#A8A8A8',
    },
    userRate: {
        fontFamily: "-apple-system, BlinkMacSystemFont, cerebri-regular",
        fontSize: '26px',
        fontWeight: 600,
        lineHeight: '31.03px',
        textAlign: 'right',
        textUnderlinePosition: 'from-font',
        textDecorationSkipInk: 'none',
        color: '#FFFFFF',
        justifySelf: 'end',
        alignSelf: 'start',
    }
}));

function UserCard({ user, ...props }) {
    const classes = useStyles();
    const [imageError, setImageError] = useState(false);

    let userTags = user.tags.reduce((tags, tag, i) => {
        return i <= 1 ? (
            <>
                {tags}
                <Typography variant="body1" className={classes.userExpertise} noWrap={true}>
                    <span title={`${tag.expertise} - ${tag.years} ${tag.years === 1 ? 'yr' : 'yrs'}`}>
                        {tag.expertise} - {tag.years} {tag.years === 1 ? 'yr' : 'yrs'}
                    </span>
                </Typography>
            </>
        ) : null
    }, '')

    return (
        <Box className={`${classes.userCard} userCard disable-selection`}>
            {user?.profile_media_urls[0] &&
                !(user.profile_media_urls[0] === "" || user.profile_media_urls[0].match(/\/uploads\/default_pic\.png$/))
                ? (imageError
                    ? <PlaceholderErrorImage className={classes.container} title={"Error Loading Media"} />
                    : <Image
                        src={user.profile_media_urls[0]}
                        alt={user.enabledUsername ? user.username : user.full_name}
                        className={classes.container}
                        onError={() => setImageError(true)}
                    />
                )
                : <PlaceholderImage className={classes.container} />
            }

            <Box className={classes.ratingPill}>
                <StarRateIcon fill="#FFB800"/>

                <Typography variant="body1" style={{
                    fontWeight: 600,
                    fontSize: 12,
                    lineHeight: "19.09px",
                    letterSpacing: "0%",
                }}>
                    {user.rating === 0 ? 'No Reviews' : user.rating.toFixed(1)}
                </Typography>
            </Box>

            <Box className={classes.ratePill}>
                <Typography variant="body1" className={classes.userRate}>
                    {user.rate <= 0 ? "Free" : `$${user.rate}`}
                </Typography>
            </Box>

            <Box className={classes.tagsContainer}>
                <Box style={{gridColumn: 'span 2'}}>
                    <Typography variant="body1" className={classes.userName} noWrap={true}>
						<span title={user.enabledUsername ? user.username : user.full_name}>
							{user.enabledUsername ? user.username : user.full_name}
						</span>
                    </Typography>

                    {
                        user.tags.length
                            ? (
                                <div className={classes.userExpertiseContainer}>
                                    {userTags}
                                </div>
                            ) : null
                    }
                </Box>
            </Box>
        </Box>
    );
}

export default React.memo(withRouter(UserCard));
