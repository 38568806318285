import React from "react";
import {injectReducer} from '../store';


// withReducer is a hook which provides us to connect our component with redux store.
/**
 * we just need to export our component with this "WithReducer" hook and rest of the things it will handle itself.
 */
const withReducer = (key, reducer) => WrappedComponent =>
    class extends React.PureComponent {
        constructor(props)
        {
            super(props);
            injectReducer(key, reducer);
        };

        render()
        {
            return <WrappedComponent {...this.props} />;
        };
    };

export default withReducer;
