import { makeStyles } from "@material-ui/core/styles";
import { commonStyles } from "../../styles";

export const useStyles = makeStyles((theme) => ({
    tableRow: {
        height: '7rem',
        [theme.breakpoints.down('md')]: {
            height: '5.5rem',
        },
        [theme.breakpoints.down('sm')]: {
            height: '4rem',
        },
    },
    userCell: {
        margin: "auto",
        gap: '2rem',
        flexWrap: 'nowrap',
        [theme.breakpoints.down('sm')]: {
            gap: '1.5rem',
        },
        [theme.breakpoints.down('xs')]: {
            gap: '1rem',
            paddingLeft: '1rem',
        },
    },
    tableCell: {
        ...commonStyles.mediumText,
        color: '#FFFFFF',
        fontWeight: '400',
        margin: '0',
        [theme.breakpoints.down('sm')]: {
            ...commonStyles.smallText,
        },
        [theme.breakpoints.down('xs')]: {
            ...commonStyles.smallerText,
        },
    },
    tableCellCentered: {
        ...commonStyles.mediumText,
        color: '#FFFFFF',
        fontWeight: '400',
        margin: '0',
        textAlign: 'center',
        overflow: 'hidden',
        [theme.breakpoints.down('sm')]: {
            ...commonStyles.smallText,
        },
        [theme.breakpoints.down('xs')]: {
            ...commonStyles.smallerText,
        },
    },
    subtitle: {
        color: '#808080',
        lineHeight: '70%',
        fontWeight: '400',
        ...commonStyles.smallText,
        margin: '0',
        [theme.breakpoints.down('md')]: {
            ...commonStyles.smallerText,
            textAlign: 'center'
        },
        [theme.breakpoints.down('xs')]: {
            ...commonStyles.extraSmallText,
        },
    },
    rankingFirst: {
        ...commonStyles.heading,
        ...commonStyles.extraLargeText,
        textAlign: 'center',
        [theme.breakpoints.down('md')]: {
            ...commonStyles.largeText,
        },
        [theme.breakpoints.down('sm')]: {
            ...commonStyles.mediumText,
        },
        [theme.breakpoints.down('xs')]: {
            ...commonStyles.smallerText,
        },
    },
    rankingSecond: {
        ...commonStyles.heading,
        ...commonStyles.largeText,
        color: '#79C6E7',
        textAlign: 'center',
        [theme.breakpoints.down('md')]: {
            ...commonStyles.mediumText,
        },
        [theme.breakpoints.down('sm')]: {
            ...commonStyles.mediumText,
        },
        [theme.breakpoints.down('xs')]: {
            ...commonStyles.smallerText,
        },
    },
    rankingThird: {
        ...commonStyles.heading,
        ...commonStyles.mediumText,
        color: '#BAE2f3',
        textAlign: 'center',
        [theme.breakpoints.down('md')]: {
            ...commonStyles.mediumText,
        },
        [theme.breakpoints.down('sm')]: {
            ...commonStyles.smallText,
        },
        [theme.breakpoints.down('xs')]: {
            ...commonStyles.smallerText,
        },
    },
    rankingDefault: {
        color: '#FFFFFF',
        ...commonStyles.mediumText,
        textAlign: 'center',
        [theme.breakpoints.down('md')]: {
            ...commonStyles.smallText,
        },
        [theme.breakpoints.down('xs')]: {
            ...commonStyles.smallerText,
        },
    },
    avatar: {
        width: "75px",
        height: "75px",
        display: "inline-block",
        borderRadius: '50%',
        [theme.breakpoints.down('sm')]: {
            width: "50px",
            height: "50px",
        },
        [theme.breakpoints.down('xs')]: {
            width: "40px",
            height: "40px",
        },
    },
    divider: {
        height: '1.5rem',
        borderBottom: '2px solid #808080',
    },
    hidden: {
        display: 'none',
    },
    emptyRow: {
        height: '1.5rem',
    },
}));