import React from 'react'
import { List } from '@material-ui/core'
import EarningsListItem from './EarningsListItem'

export default function EarningsList(props) {
    return (
    <List>
      <EarningsListItem earningType={props.earningType} userEarnings={props.userEarnings} />
    </List>
  )
}
