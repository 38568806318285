import React, { useEffect, useState, useRef } from 'react'
import { Box, Button, Slide, ListItem, ListItemText, ListItemAvatar, Avatar, Typography, Backdrop } from '@material-ui/core'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import moment from 'moment'
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
import { useSwipeable } from 'react-swipeable'
import CloseIcon from "@material-ui/icons/Close";
import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';
import { isMobile } from 'react-device-detect';
import DummyImage from "@assets/dummyImage.png"
const StyledListItem = withStyles({
  root: {
    paddingLeft: 0,
    paddingRight: 0,
    cursor: 'pointer',
    userSelect: 'none',
  }
})(ListItem)
const StyledListItemText = withStyles({
  primary: {
    fontSize: 16,
    fontWeight: 600,
  },
  secondary: {
    color: '#808080',
    fontSize: 12,
  }
})(ListItemText)
const StyledPaymentText = withStyles({
  root: {
    color: '#31A7DB',
    fontSize: 18,
    fontWeight: 600,
    fontFamily: 'Cerebri-Bold'
  }
})(Typography)
const useStyles = makeStyles((theme) => ({
  collapsedItem: {
    position: 'absolute',
    left: '-5.2rem',
    borderRadius: '7px',
    backgroundColor: '#111111',
    paddingRight: 10,
  },
  cancelled: {
    color: '#CB3A3A',
  },
  cancelBtn: {
    position: 'absolute',
    height: '75px',
    right: '1rem',
    backgroundColor: '#CB3A3A',
    fontSize: 12,
    border: 0,
    color: '#fff',
    padding: '0 11px',
    borderRadius: 7,
    outline: 'none !important',
    '&:hover': {
      backgroundColor: '#CB3A3A',
    },
  },
}));
const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: "#CB3A3A",
  },
  tooltip: {
    backgroundColor: "#CB3A3A",
    fontSize: "14px",
    fontFamily: "Cerebri Sans"
  },
}));
function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();
  return <Tooltip TransitionComponent={Zoom} arrow classes={classes} {...props} />
}
export default function QuartersList({
  quarter: {
    _id,
    host,
    attendee,
    call_at,
    receipt_id,
    appointment_id,
  },
  index,
  showConfirm,
  setShowConfirm,
  isHosting,
  selectedQuarterRef,
  setShowReceipt,
  quarterReceiptRef,
}) {
  const otherUser = isHosting ? attendee : host
  const [showCancel, setShowCancel] = useState(false)
  const classes = useStyles()
  const isSwiped = useRef(false)
  const isCancelled = appointment_id.status === 'cancelled'
  useEffect(() => {
    if (showCancel && !showConfirm) setShowCancel(false)
  }, [showConfirm])
  useEffect(() => {
    if (isSwiped.current) isSwiped.current = false
  }, [showConfirm])
  const handlers = useSwipeable({
    onSwipedLeft: () => {
      isSwiped.current = true
      if (!isCancelled && !showCancel) {
        setShowCancel(true)
      }
    },
    onSwipedRight: () => {
      isSwiped.current = true
      if (showCancel) {
        setShowCancel(false)
      }
    },
    delta: 5,
    preventDefaultTouchmoveEvent: false,
    trackTouch: true,
    trackMouse: true,
  });
  const handleCancelClick = () => {
    selectedQuarterRef.current = {
      appointment_id: appointment_id._id,
      _id,
      list: isHosting ? 'hosting' : 'attending',
      index,
    }
    setShowConfirm(true)
  }
  const handleShowReceipt = () => {
    if (isSwiped.current) {
      isSwiped.current = false
      return
    }
    quarterReceiptRef.current = {
      ...receipt_id,
      isHosting,
      isCancelled,
    }
    setShowReceipt(true)
  }
  // console.log({ receipt_id })
  // console.log(otherUser)
  return (
    <div style={{ display: 'flex', overflow: 'hidden', margin: isMobile ? '0' : '0.5rem 0', height: '69px' }}>
      <StyledListItem
        className={showCancel ? classes.collapsedItem : ''}
      // {...handlers}
      >
        <ListItemAvatar onClick={handleShowReceipt} style={{ marginLeft: "-10px" }}>
          <Avatar
            variant='rounded'
            src={otherUser.profile_media_urls[0] === "https://narsunprojects.com:3001/uploads/default_pic.png" ? DummyImage : otherUser.profile_media_urls[0]}>
          </Avatar>
        </ListItemAvatar>
        <div onClick={handleShowReceipt} style={{ width: "100%" }}>
          <StyledListItemText primary={otherUser.enabledUsername ? otherUser.username : otherUser.full_name} secondary={moment(call_at).format('MMM, DD [at] h:mmA')} />
        </div>
        {
          isCancelled ?
            <StyledPaymentText variant='h6' className={classes.cancelled}>Cancelled</StyledPaymentText>
            :
            <Box display="flex" alignItems="center">
              <div onClick={handleShowReceipt}>
                <StyledPaymentText variant='h6'>{receipt_id.hostRate ? `$${(receipt_id.hostRate + receipt_id.companyFee + receipt_id.transactionFee + receipt_id.referralFee).toFixed(2)}` : 'Free'}</StyledPaymentText>
              </div>
              <BootstrapTooltip title="Cancel" placement="bottom-end" >
                {/* <Button>Bootstrap</Button> */}
                <CloseIcon
                  style={{ marginLeft: "10px", cursor: "pointer", color: '#CB3A3A' }}
                  onClick={handleCancelClick}
                />
              </BootstrapTooltip>
            </Box>
        }
      </StyledListItem>
      {/* {
        showCancel && <Slide direction='left' in={showCancel}>
          <button className={classes.cancelBtn} onClick={handleCancelClick}>
            <div>
              <CancelPresentationIcon />
            </div>
            Cancel
          </button>
        </Slide>
      } */}
    </div>
  )
}
