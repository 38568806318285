import axios from "axios";
import React from "react";
import DummyImage from "../../@assets/dummyImage.png"
import { makeStyles } from "@material-ui/core/styles";
import Rating from "@material-ui/lab/Rating";
import { Box, Typography, Button } from "@material-ui/core";
import { InputField } from "@base";
import pics from "@assets";
import { useDispatch, useSelector } from "react-redux";
import withReducer from "store/withReducer";
import * as Actions from "./store/actions";
import * as BaseActions from "store/actions";
import reducer from "./store/reducers";
import useDeepCompareEffect from "use-deep-compare-effect";
import { ChevronRight } from "@material-ui/icons";
import { isMobile } from "react-device-detect";
const useStyles = makeStyles((theme) => ({
	root: {
		padding: isMobile ? "15px 22px" : "50px 22px",
		height: "100%",
	},
	headingCon: {
		display: "flex",
		alignItems: "center",
		"& .arrow": {
			marginRight: "10px",
			height: 25,
			width: 11,
			marginBottom: "3px",
		},
	},
	flexCon: {
		display: "flex",
		flexDirection: "column",
		paddingTop: "40px",
		paddingBottom: isMobile ? "20px" : "80px",
	},
	profileCon: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
	},
	profileWithCaption: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		"& .profilePhoto": {
			height: 94,
			width: 94,
			borderRadius: 6,
			marginBottom: "10px",
		},
	},
	ratingCon: {
		marginTop: isMobile ? "10px" : "20px",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		height: '60px',
	},
	iconEmpty: {
		color: "gray",
	},
	iconFilled: {
		color: "#fcbd3f",
	},
	iconLabel: {
		padding: "0px 8px",
	},
	buttonCon: {
		marginTop: "auto",
	},
	feedbackCon: {
		display: "flex",
		// justifyContent: "space-between",
		flexWrap: "wrap",
		marginTop: isMobile ? "10px" : "50px",
		// marginRight: '2%'
	},
	feedbackPill: {
		margin: "5px 0px",
		padding: isMobile ? "6px 7px" : "6px 14px",
		borderRadius: 6,
		border: "1px solid #808080",
		textAlign: "center",
		cursor: "pointer",
		transition: "all .1s ease-in-out",
		marginRight: '3%',
		"&:hover": {
			backgroundColor: theme.palette.primary.main,
			borderColor: "transparent",
		},
	},
	feedbackPillActive: {
		margin: "5px 0px",
		padding: isMobile ? "6px 7px" : "6px 14px",
		borderRadius: 6,
		border: "1px solid transparent",
		textAlign: "center",
		cursor: "pointer",
		transition: "all .1s ease-in-out",
		backgroundColor: theme.palette.primary.main,
		marginRight: "3%"
	},
	fieldCon: {
		width: "100%",
		marginTop: isMobile ? "10px" : "30px",
	},
	checkboxCon: {
		display: "flex",
		marginTop: "10px",
		marginBottom: "30px",
		"& .checkbox": {
			marginRight: "10px",
			height: "24px",
			width: "24px",
			marginTop: "3px",
			cursor: "pointer",
		},
	},
}));
const feedbackTitles = [
	// "Good conversation",
	// "Brilliant guy",
	// "Great!",
	// "Brilliant guys",
	// "Great!!",
	// "Best conversation",
	"Great conversation!",
	"Worth every penny!",
	"Learned a lot!",
	"Cute personality!",
	"Useful information!",
	"Bad experience!",
	"Not worth it!",
];
const labels = {
	0: "   ",
	// 0.5: 'Useless',
	// 1: "Very poor",
	1: "Horrible!",
	// 1.5: 'Poor',
	// 2: "Poor",
	2: "Bad",
	// 2.5: 'Ok',
	// 3: "Satisfactory",
	3: "Okay",
	// 3.5: 'Good',
	4: "Good",
	// 4.5: 'Excellent',
	5: "Perfect!",
};
const HostRating = (props) => {
	const dispatch = useDispatch();
	const [rating, setRating] = React.useState(0);
	const [ratingHover, setRatingHover] = React.useState(0);
	const [tag, setTag] = React.useState(null);
	const [comment, setComment] = React.useState("");
	// const [commentValid, setCommentValid] = React.useState(false);
	const [refund, setRefund] = React.useState(false);
	const [valid, setValid] = React.useState(false);
	const [ratingsArray, setRatingsArray] = React.useState([]);
	const [remainingRatings, setRemainingRatings] = React.useState([])
	const { ratingReducer, ratings } = useSelector(({ Rating, BaseReducer }) => ({
		ratingReducer: Rating.SubmitRatingReducer,
		ratings: BaseReducer.UserReducer.rate_your_call,
	}));
	React.useEffect(() => {
		setRatingsArray(ratings)
		setRemainingRatings(ratings)

		if (ratings?.length) {
			let data = { appointment_id: ratings[0].appointment_id };
			const token = localStorage.getItem("JWTtoken");

			const config = {
				headers: {
				Authorization: `JWT ${token}`,
				// "Content-Type": "application/json",
				},
			};
			const request = axios.post(
				`${process.env.REACT_APP_ENV === "prod" ? process.env.REACT_APP_BASE_URL_PROD : process.env.REACT_APP_BASE_URL_TESTING}appointments/get-appointment`,
				data,
				config
			);

			request
				.then((response) => {
					if (response.data && response.data.status === "Success") {
						//appointment data found
						const isMissed = ((response.data.data.status === 'noOnePicked') || (response.data.data.status === 'attendeeDidntPick'));
						if(isMissed) {
							//As meeting was either not picked by anyone or attendee Didn't picked, don't show rating screen
							dispatch(BaseActions.removeRating({ appointment_id: ratings[0].appointment_id }))
							let userData;
							if (ratings.length === 1) {
								userData = {
									rate_your_call: []
								};
								
							} else {
								const remaining = ratings.slice(1)
								userData = {
									rate_your_call: remaining
								};
							}
							dispatch(Actions.updateProfile(userData));
							props.history.push('/home');
							return;
						}
					} else {
					let err = Error();
					err.message = response.data.message;
					throw err;
					}
				})
				.catch((error) => {
					console.log(error.response.data);
					console.log(error.response.status);
					console.log(error.response.headers);
				});
		}

		if (!ratings?.length) {
			props.history.replace("/home");
		}
		dispatch(BaseActions.exitFullScreen())
		dispatch(BaseActions.updateLayout({ fullScreen: false }))
	}, []);
	useDeepCompareEffect(() => {
		if (ratingReducer.errMsg === "Your review against this Quratr is already captured") {
			dispatch(BaseActions.resetRatings())
			props.history.push('/home')
		}
		else if (ratingReducer.data && ratingReducer.data.status === "Success") {
			handleReset();
		}
	}, [ratingReducer]);
	React.useEffect(() => {
		if (rating >= 1 || (refund && rating >= 1)) {
			setValid(true);
		} else {
			setValid(false);
		}
	}, [rating, refund]);
	const handleChange = (name) => (event) => setComment(event.target.value);
	const handleCheck = () => setRefund(!refund);
	const handleSelectTag = (value) => setTag(value);
	const handleReset = () => {
		dispatch(BaseActions.removeRating({ appointment_id: remainingRatings[0].appointment_id }))
		if (ratingsArray.length === 1) {
			props.history.push('/home');
			return;
		}
		setTag(null);
		setRating(0);
		setRatingHover(0);
		setTag(null);
		setComment("");
		setRefund(false);
		setValid(false);
	};
	const handleSubmit = () => {

		let datas = {
			appointment_id: remainingRatings[0].appointment_id || "",
			averageRating: rating,
			refund: refund,
			comment: comment,
			subject: tag,
		};
		dispatch(Actions.submitRating(datas));
		if (remainingRatings.length === 1) {
			let userData = {
				rate_your_call: []
			};
			dispatch(Actions.updateProfile(userData));
			props.history.push('/home');
		}
		const ratingArr = remainingRatings.slice(1)
		setTimeout(
			() => {
				setRemainingRatings(ratingArr)
			}
			, 4000)
	};
	const classes = useStyles();
	// if (!ratings?.length) return null
	return (
		<>
			<Box className={classes.root}>
				<div className={classes.headingCon}>
					<Typography variant="h1">Rate Your Quartr</Typography>
				</div>
				<div style={{ paddingTop: "20px" }}>
					<Typography variant="caption">Thanks for attending a Quartr! Please rate the host on your experience.</Typography>
				</div>
				<div className={classes.flexCon}>
					<Box className={classes.profileCon}>
						<ProfilePhoto
							url={remainingRatings[0]?.profile_url === "https://narsunprojects.com:3001/uploads/default_pic.png" ? DummyImage : remainingRatings[0]?.profile_url}
							caption={remainingRatings[0]?.name_of_host}
						/>
						<Box className={classes.ratingCon}>
							<Rating
								classes={{
									iconEmpty: classes.iconEmpty,
									iconFilled: classes.iconFilled,
									label: classes.iconLabel,
								}}
								name="rating"
								size="large"
								// precision={0.5}
								value={rating}
								onChange={(event, newValue) => {
									setRating(newValue);
								}}
								onChangeActive={(event, newHover) => {
									setRatingHover(newHover);
								}}
							/>
							<div
								style={{
									transition: "all .2s ease-in-out",
									minHeight: "40px",
									paddingTop: "10px",
								}}
							>
								<Typography
									variant="caption"
									style={{ fontSize: "18px", marginTop: "10px" }}
								>
									{labels[Math.ceil(ratingHover) !== -1 ? ratingHover : rating]}
								</Typography>
							</div>
						</Box>
					</Box>
					<Box className={classes.feedbackCon}>
						{feedbackTitles.map((title, i) => (
							<FeedbackPill
								handleSelect={handleSelectTag}
								key={i}
								active={tag === title}
								title={title}
							/>
						))}
					</Box>
					<Box className={classes.fieldCon} style={{ margin: '5% 0%' }}>
						<InputField
							name="comment"
							// error={!commentValid}
							isValid={false}
							value={comment}
							placeHolder="Enter comments here: (optional):"
							onChange={handleChange}
						/>
					</Box>
					<Box className={classes.buttonCon}>
						<Button onClick={() => handleSubmit()} disabled={!valid} fullWidth>
							Submit
						</Button>
					</Box>
				</div>
			</Box>
		</>
	);
};
export default withReducer("Rating", reducer)(HostRating);
const ProfilePhoto = ({ url, caption }) => {
	const classes = useStyles();
	return (
		<Box className={classes.profileWithCaption}>
			<img className="profilePhoto" src={url} alt="sdfdd" />
			<Typography variant="subtitle1" style={{ fontSize: "16px", textTransform: 'capitalize' }}>
				{caption || ""}
			</Typography>
		</Box>
	);
};
const FeedbackPill = ({ title, handleSelect, active }) => {
	const classes = useStyles();
	return (
		<Box
			className={!active ? classes.feedbackPill : classes.feedbackPillActive}
			onClick={() => handleSelect(title)}
		>
			<Typography variant="subtitle1" style={{ fontSize: "12px" }}>
				{title}
			</Typography>
		</Box>
	);
};
FeedbackPill.defaultProps = {
	title: "Great!",
	handleSelect: () => { },
	active: false,
};
