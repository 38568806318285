import axios from "axios";
import * as BaseActions from "store/actions";
//SUBMIT NOTIFICATIONS ACTION NAMES
export const GET_NOTIFICATION = "GET_NOTIFICATION";
export const GET_NOTIFICATION_IS_LOADING = "GET_NOTIFICATION_IS_LOADING";
export const GET_NOTIFICATION_FAILED = "GET_NOTIFICATION_FAILED";

export const IS_READ_NOTIFICATION = "IS_READ_NOTIFICATION";
export const IS_READ_NOTIFICATION_IS_LOADING = "IS_READ_NOTIFICATION_IS_LOADING";
export const IS_READ_NOTIFICATION_FAILED = "IS_READ_NOTIFICATION_FAILED";


// Helper ACTIONS:

export const getNotificationLoading = (ms) => ({
    type: GET_NOTIFICATION_IS_LOADING,
    payload: ms,
});

export const getNotificationFailed = (ms) => ({
    type: GET_NOTIFICATION_FAILED,
    payload: ms,
});

export function getNotifications(page) {
    var cancel = "";
    const token = localStorage.getItem("JWTtoken");

    const headers = {
        Authorization: `JWT ${token}`,
        // "Content-Type": "application/json",
    };
    const request = axios({
        method: "get",
        url: `${process.env.REACT_APP_ENV === "prod" ? process.env.REACT_APP_BASE_URL_PROD : process.env.REACT_APP_BASE_URL_TESTING}notification?page=${page}&unread=false`,
        cancelToken: new axios.CancelToken((c) => {
            cancel = c;
        }),
        headers: headers,
    });
    return (dispatch) => {
        dispatch(getNotificationLoading(true));
        dispatch(BaseActions.showLoading(true));

        request
            .then((response) => {
                if (response.data && response.data.status === "Success") {
                    dispatch({
                        type: GET_NOTIFICATION,
                        payload: response.data,
                    });
                    dispatch(BaseActions.hideLoading());
                } else {
                    let err = Error();
                    err.message = response.data.message;
                    throw err;
                }
            })
            .catch((error) => {
                // dispatch(
                //     BaseActions.showAlert({ variant: "error", message: error.message })
                // );
                if (axios.isCancel(error)) {
                    dispatch(getNotificationFailed(error));
                    return;
                }
                if (error.response) {
                    if (error.response.status === "404") {
                        dispatch(getNotificationFailed(`${error.response.status} : api not found`));
                    } else {
                        dispatch(getNotificationFailed(error.response.status));
                    }
                    console.log(error.response);
                } else if (error.request) {
                    dispatch(getNotificationFailed(error.request));

                    console.log(error.request);
                } else {
                    console.log("Error", error.message);
                    dispatch(getNotificationFailed(error.message));
                }
                console.log(error);
            });
    };
}

export const readNotificationLoading = (ms) => ({
    type: IS_READ_NOTIFICATION_IS_LOADING,
    payload: ms,
});

export const readNotificationFailed = (ms) => ({
    type: IS_READ_NOTIFICATION_FAILED,
    payload: ms,
});

export function readNotifications(id) {
    var cancel = "";
    const token = localStorage.getItem("JWTtoken");

    const headers = {
        Authorization: `JWT ${token}`,
        // "Content-Type": "application/json",
    };
    const request = axios({
        method: "post",
        url: "http://18.188.126.203:5558/notification/mark-as-read/targetId/" + id,
        cancelToken: new axios.CancelToken((c) => {
            cancel = c;
        }),
        headers: headers,
    });
    return (dispatch) => {
        dispatch(readNotificationLoading(true));
        dispatch(BaseActions.showLoading(true));

        request
            .then((response) => {
                if (response.data && response.data.status === "Success") {
                    dispatch({
                        type: IS_READ_NOTIFICATION,
                        payload: response.data,
                    });
                    dispatch(BaseActions.hideLoading());
                } else {
                    let err = Error();
                    err.message = response.data.message;
                    throw err;
                }
            })
            .catch((error) => {
                // dispatch(
                //     BaseActions.showAlert({ variant: "error", message: error.message })
                // );
                if (axios.isCancel(error)) {
                    dispatch(readNotificationFailed(error));
                    return;
                }
                if (error.response) {
                    if (error.response.status === "404") {
                        dispatch(readNotificationFailed(`${error.response.status} : api not found`));
                    } else {
                        dispatch(readNotificationFailed(error.response.status));
                    }
                    console.log(error.response);
                } else if (error.request) {
                    dispatch(readNotificationFailed(error.request));

                    console.log(error.request);
                } else {
                    console.log("Error", error.message);
                    dispatch(readNotificationFailed(error.message));
                }
                console.log(error);
            });
    };
}
