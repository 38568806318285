import axios from "axios";
import * as BaseActions from "store/actions";
//SUBMIT LOGIN ACTION NAMES
export const ADD_PAYMENT = "ADD_PAYMENT";
export const PAYMENT_IS_LOADING = "PAYMENT_IS_LOADING";
export const PAYMENT_FAILED = "PAYMENT_FAILED";
export const PAYMENT_RESET = "PAYMENT_RESET";
// Helper ACTIONS:
export const paymentLoading = (ms) => ({
  type: PAYMENT_IS_LOADING,
  payload: ms,
});
export const paymentReset = (ms) => ({
  type: PAYMENT_RESET,
  payload: ms,
});
export const paymentFailed = (ms) => ({
  type: PAYMENT_FAILED,
  payload: ms,
});
export function addPayment(id) {
  let data = {
    ...id,
    payment_is_set: true,
  };
  const token = localStorage.getItem("JWTtoken");
  const config = {
    headers: {
      Authorization: `JWT ${token}`,
      // "Content-Type": "application/json",
    },
  };
  const request = axios.post(
    `${process.env.REACT_APP_ENV === "prod" ? process.env.REACT_APP_BASE_URL_PROD : process.env.REACT_APP_BASE_URL_TESTING}payments/add-payment`,
    data,
    config
  );
  return (dispatch) => {
    dispatch(paymentLoading(true));
    dispatch(BaseActions.showLoading(true));
    request
      .then((response) => {
        if (response.data && response.data.status === "Success") {
          dispatch({
            type: ADD_PAYMENT,
            payload: response.data,
          });
          // dispatch(
          //   BaseActions.showAlert({
          //     variant: "success",
          //     message: "Payment added successfully",
          //   })
          // );
          dispatch(BaseActions.hideLoading());
        } else {
          dispatch({
            type: ADD_PAYMENT,
            payload: response.data,
          });
          let err = Error();
          err.message = response.data.message;
          throw err;
        }
      })
      .catch((error) => {
        // dispatch(
        //   BaseActions.showAlert({ variant: "error", message: error.message })
        // );
        dispatch(BaseActions.hideLoading());
        if (error.response) {
          if (error.response.status === "404") {
            dispatch(paymentFailed(`${error.response.status} : api not found`));
          } else {
            dispatch(paymentFailed(error.response.status));
          }
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          dispatch(paymentFailed(error.request));
          console.log(error.request);
        } else {
          console.log("Error", error.message);
          dispatch(paymentFailed(error.message.status));
        }
        console.log(error);
      });
  };
}
