import * as Actions from "../actions";
// initial state
const initialState = {
  isLoading: false,
  errMsg: null,
  data: null,
};
// Reducer for LOGIN handling
export const AddPaymentReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.ADD_PAYMENT:
      // we will mould our payload here accordingly  if required  in case of success
      return { ...state, isLoading: false, errMsg: null, data: action.payload };
    case Actions.PAYMENT_IS_LOADING:
      // ... in case of loading
      return { ...state, isLoading: true, errMsg: null, data: null };
    case Actions.PAYMENT_FAILED:
      // ... in case of error
      return { ...state, isLoading: false, errMsg: action.payload, data: null };
    case Actions.PAYMENT_RESET:
      // ... in case of error
      return { ...state, isLoading: false, errMsg: null, data: null };
    default:
      return state;
  }
};
