import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
// import IconButton from "@material-ui/core/IconButton";
// import CloseIcon from "@material-ui/icons/Close";
import MuiAlert from "@material-ui/lab/Alert";
import { useDispatch, useSelector } from "react-redux";
import * as BaseActions from "store/actions";
function MAlert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
export default function Alert() {
  const dispatch = useDispatch();
  const payload = useSelector(({ BaseReducer }) => BaseReducer.AlertReducer);
  const handleClose = () => {
    dispatch(BaseActions.hideAlert());
  };
  return (
    <Snackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      onClose={handleClose}
      open={payload.state}
      autoHideDuration={2000}
    >
      {payload.data && (
        <MAlert
          severity={
            payload.data && payload.data.variant ? payload.data.variant : "info"
          }
        >
          {payload.data ? payload.data.message : ""}
        </MAlert>
      )}
    </Snackbar>
  );
}