import React from "react";
import { Box, Typography, Button } from "@material-ui/core";
import { isIOS } from "react-device-detect";
const NotificationDrawer = ({ closeNotificationDrawer }) => {
  return (
    // <Box padding="30px" marginBottom={} "40px">
    <Box padding="30px" marginBottom={isIOS ? "60px" : "40Px"}>
      <Typography variant="body2" align="left">
        Calendar updated successfully!
      </Typography>
      <Box marginTop="20px" align="left">
        <Typography variant="caption">
          You have updated your calendar successfully. People can now see your availability and book a Quartr accordingly.
        </Typography>
      </Box>
      <Box marginTop="30px">
        <Button onClick={closeNotificationDrawer} fullWidth>
          Ok
        </Button>
      </Box>
    </Box>
  );
};
export default NotificationDrawer;
