import React from "react";
import Box from "@material-ui/core/Box";
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    container: {
        backgroundColor: "#272727",
        display: 'flex',
        height: '100%',
        width: '100%',
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        rowGap: '17px',
    },

    title: {
        fontFamily: "-apple-system, BlinkMacSystemFont, cerebri-regular",
        fontWeight: '400',
        fontSize: 12,
        lineHeight: '14.32px',
        letterSpacing: '0%',
        textAlign: 'center',
        color: "white",
    }
}))

function PlaceholderImage({size = 32, title = null, ...props}) {
    const classes = useStyles();

    return (
        <Box {...props} style={{overflow: "hidden"}}>
            <Box className={classes.container}>
                <svg width={size} height={size} viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="9.5" cy="9.5" r="9" fill="#272727" stroke="#4C4C4C"/>
                    <path
                        d="M8.00548 3.26666H10.9945L10.7423 11.4593H8.24834L8.00548 3.26666ZM8.25768 15.3525C7.95255 15.0929 7.79999 14.7403 7.79999 14.2945C7.79999 13.8488 7.95255 13.4962 8.25768 13.2366C8.56281 12.9771 8.97691 12.8473 9.49999 12.8473C10.0293 12.8473 10.4434 12.9771 10.7423 13.2366C11.0474 13.4905 11.2 13.8432 11.2 14.2945C11.2 14.7403 11.0474 15.0929 10.7423 15.3525C10.4434 15.6064 10.0293 15.7333 9.49999 15.7333C8.97691 15.7333 8.56281 15.6064 8.25768 15.3525Z"
                        fill="#4C4C4C"/>
                </svg>

                {title && <div className={classes.title}>{title}</div>}
            </Box>
        </Box>
    )
}

export default PlaceholderImage;
