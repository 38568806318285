import { combineReducers } from "redux";
import { PaymentReducer, PaymentDeleteReducer } from "./payments.reducer";
import { AddPaymentReducer } from "../../../addPayment/store/reducers/addPayment.reducer";

const reducer = combineReducers({
  PaymentReducer,
  PaymentDeleteReducer,
  AddPaymentReducer,
});

export default reducer;
