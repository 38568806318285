import React from "react";

function BackArrow() {
    return (
        <svg width="13" height="22" viewBox="0 0 13 22" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path d="M13 2.2275L10.813 0L0 11L10.813 22L13 19.7725L4.38073 11L13 2.2275Z"
                  fill="white"/>
        </svg>
    )
}

export default BackArrow;
