import React from "react";
import { Box, Button, makeStyles, Modal, Typography } from "@material-ui/core";

import Rating from "@material-ui/lab/Rating";
// import CloseIcon from "@material-ui/icons/Close";
// import useDeepCompareEffect from "use-deep-compare-effect";
import { useDispatch, useSelector } from "react-redux";
// import * as Actions from "./store/actions";
import * as BaseActions from 'store/actions'
import reducer from "./store/reducers";
import withReducer from "store/withReducer";
import moment from "moment";

function getLocaleDate(iso) {
    var date = new Date(iso);
    var options = { month: 'short', day: 'numeric' };
    return date.toLocaleDateString('en-US', options)
}

const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    modalContainer: {
        width: "300px",
        padding: "10px",
        // border: "1px solid #808080",
        borderRadius: 10,
        color: "#FFFFFF",
        backgroundColor: "#111111",
        borderColor: "#FFFFFF",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    modalActionContainer: {
        marginTop: "20px",
        marginBottom: "10px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    img: {
        width: "100%",
        height: "100%",
        objectFit: "cover",
        borderRadius: "5px",
        // border: "1px dashed orange"
    },
    imgContainer: {
        // margin: "0 0 0 20px",
        width: "20%",
        height: "56px",
    },
    imgBox: {
        // margin: "0 0 0 20px",
        width: "56px",
        height: "56px",
        // border: "1px dashed orange"
    },
    ratingContainer: {
        marginTop: "10px",
        display: "flex",
        alignItems: "center"
    },
}));

const UserReviews = ({ handleClose, ...props }) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [fetchedReviews, setReviews] = React.useState([]);
    // const fetchedData = useSelector(({ Reviews }) => Reviews.FetchReviewsReducer);
    // useDeepCompareEffect(() => {
    //     if (fetchedData.data) {
    //         setReviews(fetchedData.data.data);
    //     }
    // }, [fetchedData]);
    React.useEffect(() => {
        // alert(props.userId);
        dispatch(BaseActions.updateLayout({ bottomNav: false }))
        // dispatch(Actions.fetchReviews(props.userId));
        return () => {
            dispatch(BaseActions.updateLayout({ bottomNav: true }))
            // dispatch(Actions.resetReviews())
        }
    }, []);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleModalClose = () => {
        setOpen(false);
    };
    const {
        available,
        enabledName,
        enabledUsername,
        full_name,
        profile_media_urls,
        rate,
        rating,
        reviews_count,
        tags,
        username,
        _id,
    } = props.targetId
    console.log({ data: props.data })
    return (
        <Box padding="30px" position="relative" minHeight="180px" style = {{ wordBreak: 'break-all'}}>
            <Box display="flex" flexDirection="column">
                <Typography variant="body2">{enabledUsername ? username : full_name} Review</Typography>
            </Box>
            <Box className={classes.ratingContainer}>
                <Box className={classes.imgContainer}>
                    <Box className={classes.imgBox}>
                        <img
                            className={classes.img}
                            src={profile_media_urls[0]}
                        />
                    </Box>
                </Box>
                <Box display="flex" justifyContent="space-between" width="100%" padding="0 10px 0 10px" height="50px">
                    <Box display="flex" flexDirection="column">
                        <Rating
                            name="read-only"
                            precision={0.1}
                            value={props.data.averageRating || 0}
                            readOnly
                            size="small"
                        />
                        <Typography style={{ margin: "5px 0 0 0", fontSize: "14px" }} variant="caption">
                            {props.data.averageRating || 0}
                        </Typography>
                    </Box>
                    <Box>
                        {props.data.subject && (
                            <div
                                style={{
                                    // margin: "5px 0px",
                                    padding: "6px 14px",
                                    borderRadius: 6,
                                    border: "1px solid #808080",
                                    textAlign: "center",
                                }}
                            >
                                <Typography variant="subtitle1" style={{ fontSize: "12px" }}>
                                    {props.data.subject || ""}
                                </Typography>
                            </div>
                        )}
                        <Typography style={{ margin: "10px 0 0 5px", fontSize: "14px" }} variant="caption">
                            {moment(props.data.createdAt).format("DD/MM/YY") || "00/00/00"}
                        </Typography>
                    </Box>
                </Box>
            </Box>
            <Typography style={{ margin: "5px 0 0 0", fontSize: "14px", wordBreak: 'break-word' }} variant="caption">
                {props.data.comment}
            </Typography>
            <Box margin="50px 0 0 0" paddingBottom="50px">
                <Button onClick={() => handleClose()} style={{ width: "100%" }}>Ok</Button>
            </Box>
        </Box>
    );
};

export default withReducer("UserReviews", reducer)(UserReviews);

const ReviewItem = ({ name, subject, rating, comment, date }) => {
    return (
        <Box margin="40px 0px">
            <Box display="flex" alignItems="center" justifyContent="space-between">
                <div>
                    <Typography
                        variant="body2"
                        style={{ color: "#fff", fontSize: "16px" }}
                    >
                        {name}
                    </Typography>
                    <Rating
                        name="read-only"
                        precision={0.1}
                        value={rating || 0}
                        readOnly
                        size="small"
                    />
                </div>
                {subject && (
                    <div
                        style={{
                            margin: "5px 0px",
                            padding: "6px 14px",
                            borderRadius: 6,
                            border: "1px solid #808080",
                            textAlign: "center",
                        }}
                    >
                        <Typography variant="subtitle1" style={{ fontSize: "12px" }}>
                            {subject || ""}
                        </Typography>
                    </div>
                )}
            </Box>
            <Box marginTop="10px">
                <Typography variant="caption">{comment || ""}</Typography>
            </Box>
            <Box marginTop="8px">
                <Typography style={{ fontSize: "12px" }} variant="subtitle2">
                    {date
                        ? `${getLocaleDate(date)} at ${new Date(date).toLocaleTimeString(navigator.language, {
                            hour: '2-digit',
                            minute: '2-digit'
                        })}`
                        : ""}
                    {/* Aug, 8 at 4:25AM */}
                </Typography>
            </Box>
        </Box>
    );
};
