import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

export default function Spinner() {
  return (
    <CircularProgress
      style={{ width: "20px", height: "20px", margin: "0px 10px" }}
      color="secondary"
    />
  );
}
