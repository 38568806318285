import React from "react";
import { Box, InputBase, makeStyles, Paper } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
// import FilterListIcon from "@material-ui/icons/FilterList";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { withRouter } from "react-router";
import * as BaseActions from "store/actions";
import { useDispatch } from "react-redux";
import FilterDrawer from "components/search/subComponents/filters";
import Pics from "@assets";
const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: 40,
    padding: 20,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down('sm')]: {
      padding: "0px 20px !important"
    }
  },
  root: {
    display: "flex",
    flex: 1,
    margin: "0 20px",
    padding: "0 5px",
    backgroundColor: "#161616",
    color: "#808080",
    alignItems: "center",
    height: 35,
  },
  input: {
    marginLeft: theme.spacing(1),
    textAlign: "center",
    color: "#808080",
    flex: 1,
  },
  backArrow: {
    color: "#FFFFFF",
    cursor: "pointer",
  },
  filterIcon: {
    color: "#FFFFFF",
    cursor: "pointer",
    height: 20,
  },
}));
function Header({ history, value, handleChange,  filterHandler,appliedFilter }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const inputRef = React.useRef();
  React.useEffect(() => {
    inputRef.current.focus();
  }, []);
  const handleFilter = () => {
    dispatch(BaseActions.updateLayout({ bottomNav: false }));
    dispatch(
      BaseActions.showMessage(
        <FilterDrawer
          handleClose={handleClose}
          filterHandler={filterHandler}
          appliedFilter = {appliedFilter}
        />
      )
    );
  };
  const handleClose = () => {
    dispatch(BaseActions.hideMessage());
    dispatch(BaseActions.updateLayout({ bottomNav: true }));
  };
  return (
    <Box className={classes.container}>
      <ArrowBackIosIcon
        className={classes.backArrow}
        onClick={()=>history.push("/home")}
      />
      <Paper className={classes.root}>
        <InputBase
          className={classes.input}
          placeholder="Search"
          value={value}
          onChange={handleChange}
          // innerRef={inputRef}
          // ref = {inputRef}
          inputProps={{
            style: {
              fontFamily: "-apple-system, BlinkMacSystemFont, cerebri-regular",
              fontSize: "16px",
            },
            ref: inputRef,
            className: "header-input",
          }}
        />
        <SearchIcon
        //  onClick={() => alert("Searching")}
        />
      </Paper>
      <img
        alt="icon"
        src={Pics.searchFilter}
        className={classes.filterIcon}
        onClick={handleFilter}
      />
    </Box>
  );
}
export default withRouter(Header);
