import React, {memo} from "react";
import {makeStyles} from "@material-ui/styles";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles((theme) => ({
    container: {
        flexDirection: "row",
        alignItems: "center",
    },
    positionFixed: {
        backgroundColor: '#262626',
        position: "fixed",
        zIndex: "100",
        width: "100%",
        bottom: 0,
        [theme.breakpoints.up("sm")]: {
            width: 373,
            bottom: 33,
        },
    },
}))

const Footer = ({ children }) => {
    const classes = useStyles()

    return (
        <Box className={classes.container}>
            <Box className={classes.positionFixed}>
                {children}
            </Box>
        </Box>
    );
};

export default memo(Footer)
