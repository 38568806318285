import React, {useEffect, useState} from "react";
import * as BaseActions from "store/actions";
import {Typography, Tabs, makeStyles, withStyles} from "@material-ui/core";
import DrawerMenu from 'components/drawerMenu';
import {useDispatch} from "react-redux";
import {SelectField} from '@base'
import EarningsList from './EarningsList';
import axios from "axios";
import './Earnings.css'
import useDetectZoomLevel from "hooks/useDetectZoomLevel";
import {isMobile} from "react-device-detect";
import Header from "../../@base/header";

const StyledTabs = withStyles({
    root: {
        '& .MuiTabs-flexContainer': {
            display: 'flex',
            justifyContent: 'space-around',
        }
    },
})(Tabs);

const useStyles = makeStyles((theme) => ({
    headerWrapper: {
        display: 'flex',
        alignItems: 'center',
        marginTop: 8,
        marginLeft: 3,
        padding: 17,
        fontWeight: 'bold',
        [theme.breakpoints.down('sm')]: {
            marginTop: 0,
            padding: "15px 17px 0px 17px",
        }
    },
    headerText: {
        color: '#31A7DB',
        fontSize: '1.9rem',
        fontFamily: 'Cerebri-Bold'
    },
    leftSideMenu: {
        display: 'flex',
        alignItems: 'center',
        marginRight: 'auto',
    },
    menuIcon: {
        height: "20px",
        width: "20px",
        cursor: 'pointer',
        marginRight: '1rem',
    },
    totalRevenueContainer: {
        display: 'flex',
        justifyContent: "space-between",
        padding: 25,
        background: "#09161C",
        color: "#31A7DB",
        fontFamily: "Cerebri-SemiBold",
        fontSize: "2rem",
        position: "absolute",
        bottom: 0,
        width: "100%",
        '& > h5': {
            fontSize: 24,
            fontFamily: 'Cerebri-SemiBold',
        },
    },
}))
const EARNING_TYPES = {
    weekly: {
        label: 'Weekly',
        value: 'weekly',
    },
    monthly: {
        label: 'Monthly',
        value: 'monthly',
    },
    yearly: {
        label: 'Yearly',
        value: 'yearly',
    },
}
const months = [
    {
        value: "January",
        label: "01",
    },
    {
        value: "February",
        label: "02",
    },
    {
        value: "March",
        label: "03",
    },
    {
        value: "April",
        label: "04",
    },
    {
        value: "May",
        label: "05",
    },
    {
        value: "June",
        label: "06",
    },
    {
        value: "July",
        label: "07",
    },
    {
        value: "August",
        label: "08",
    },
    {
        value: "September",
        label: "09",
    },
    {
        value: "October",
        label: "10",
    },
    {
        value: "November",
        label: "11",
    },
    {
        value: "December",
        label: "12",
    },
];

function Earnings() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [activeTab, setActiveTab] = useState(0)
    const [earningType, setEarningType] = useState(EARNING_TYPES.weekly.value)
    const [userEarnings, setUserEarnings] = useState([]);
    const token = localStorage.getItem("JWTtoken");
    const config = {
        headers: {
            Authorization: `JWT ${token}`,
            "Content-Type": "application/json",
        },
    };

    useEffect(() => {
        axios.get(
			`${process.env.REACT_APP_ENV === "prod"
				? process.env.REACT_APP_BASE_URL_PROD
				: process.env.REACT_APP_BASE_URL_TESTING}payout/get`, config
		)
            .then((response) => {
                setUserEarnings(response.data.data)
            })
        dispatch(BaseActions.updateLayout({bottomNav: true}))
    }, [])

    const zoomLevel = useDetectZoomLevel()
    const zoomedStyles = zoomLevel > 100 ? {height: `${zoomLevel}vh`, overflowY: 'hidden'} : {}
    const handleTabChange = (e, newValue) => setActiveTab(newValue)

    return (
        <div style={zoomedStyles}>
            <Header heading="Earnings" RightComponent={() => {
                return <SelectField
                    name='earning-types'
                    value={earningType}
                    onChange={(e) => setEarningType(e.target.value)}
                    options={Object.values(EARNING_TYPES)}
                />
            }} />

            <div style={{overflowY: 'scroll', height: isMobile ? '92vh' : '70vh', paddingTop: "72px"}}>
                <StyledTabs
                    value={activeTab}
                    indicatorColor='primary'
                    textColor='primary'
                    onChange={handleTabChange}
                >
                </StyledTabs>
                <TabPanel>
                    <EarningsList earningType={earningType} userEarnings={userEarnings}/>
                </TabPanel>
            </div>
        </div>
    )
}

export default Earnings;

function TabPanel({children, value, index, ...other}) {
    return (
        <div
            role='tabpanel'
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && (
                <Typography>{children}</Typography>
            )}
        </div>
    )
}
