import { makeStyles } from "@material-ui/core/styles";
import { commonStyles } from "../../styles";

export const useStyles = makeStyles((theme) => ({
    dateInputsGrid: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gap: '1rem',
        [theme.breakpoints.down('xs')]: {
            gridTemplateColumns: '1fr',
        }
    },
    dateInputLabel: {
        display: "flex",
        gap: '1rem',
        alignItems: "center",
    },
    dateInput: {
        ...commonStyles.button,
        width: '90%',
        color: '$30A8DB',
        fontWeight: 400,
    },
    dateLabel: {
        display: 'inline-block',
        width: '10%',
        fontWeight: 'bold',
        marginRight: '1rem',
    },
}));