import { makeStyles } from "@material-ui/core/styles";

export const commonStyles = {
    heading: {
        color: '#31A7DB',
        fontSize: '50px',
        fontWeight: 'bold',
    },
    smallHeading: {
        fontSize: '40px',
    },
    smallerHeading: {
        fontSize: '34px',
    },
    centeredText: {
        textAlign: 'center',
    },
    extraLargeText: {
        fontSize: '40px',
    },
    largeText: {
        fontSize: '36px',
    },
    mediumText: {
        fontSize: '34px',
    },
    smallText: {
        fontSize: '24px',
    },
    smallerText: {
        fontSize: '18px',
    },
    extraSmallText: {
        fontSize: '14px',
    },
    button: {
        color: '#fff',
        borderRadius: '5px',
        border: 'none',
        padding: '0.5rem 1rem',
        cursor: 'pointer',
        fontSize: '14px',
        fontWeight: 'bold',
        width: '100%',
    },
};

export const useStyles = makeStyles((theme) => ({
    container: {
        width: "83%",
        margin: '0 auto',
        padding: '2% 2%',
        [theme.breakpoints.down('md')]: {
            width: "89%",
        },
        [theme.breakpoints.down('sm')]: {
            width: "96%",
        },
    },
    background: {
        backgroundColor: "#080A0A"
    },
    tableContainer: {
        backgroundColor: "#111111",
        paddingBottom: '2%',
        borderRadius: '10px',
    },
    buttonContainer: {
        display: 'flex',
        flexDirection: 'row',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
            padding: '5% 0%'
        }
    },
    tableContainerContent: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingLeft: '2.5%',
        paddingRight: '2.5%',
        overflow: 'hidden',
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            margin: '2% 0',
            padding: '0% 5%',
        },
    },
    table: {
        width: '100%',
        borderCollapse: 'collapse',
    },
    filtersGrid: {
        display: 'grid',
        gap: '2.5rem',
        gridTemplateColumns: '1fr 1fr',
        marginBottom: '2rem',
        [theme.breakpoints.down('md')]: {
            gridTemplateColumns: '1fr',
        },
    },
}));