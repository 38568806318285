import React from "react";
import {useStyles} from "./styles";

function TableHeader() {
    const classes = useStyles();

    return (
        <tr>
            <th style={{textAlign: 'center'}}>
                <div className="font-face-bold">
                    <h1 className={classes.tableTextHeading}>Rank</h1>
                </div>
            </th>
            <th>
                <div className="font-face-bold">
                    <h1 className={classes.tableTextHeadingUser}>User</h1>
                </div>
            </th>
            <th style={{textAlign: 'center'}}>
                <div className="font-face-bold">
                    <h1 className={classes.tableTextHeading}>Invites</h1>
                </div>
            </th>
        </tr>
    );
}

export default TableHeader;