import React, { useState, useEffect, useRef } from 'react'
import { Button, Tabs, Tab, Typography, makeStyles, Slide } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import axios from 'axios'
import QuartersList from './QuartersList'
import moment from 'moment'
import { useDispatch } from 'react-redux'
import * as BaseActions from 'store/actions';
import update from 'immutability-helper'
import { pasteAuthHeaders } from '@utils'
import { isIOS, isMobile } from 'react-device-detect'
const StyledTabs = withStyles({
  root: {
    '& .MuiTabs-flexContainer': {
      display: 'flex',
      justifyContent: 'space-around',
    }
  },
})(Tabs);
const StyledTab = withStyles((theme) => ({
  root: {
    fontSize: 16,
    color: '#fff',
    textTransform: 'none',
    minWidth: 72,
    fontFamily: 'Cerebri-Regular',
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    '&$selected': {
      color: '#31A7DB',
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:focus': {
      color: '#31A7DB',
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);
const PopupBtn = withStyles({
  root: {
    // height: 42,
    '& .MuiButton-label': {
      fontSize: 18,
    },
  },
})(Button)
const useStyles = makeStyles((theme) => ({
  backdrop: {
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: isIOS && isMobile ? '91vh' : "100vh",
    marginTop: "-13px",
    // [theme.breakpoints.down('xs')]: {
    //   height: '100vh',
    // },
  },
  confirmWrapper: {
    backgroundColor: '#111111',
    padding: '2rem',
    position: 'absolute',
    zIndex: 1000,
    bottom: 0,
    left: 0,
    width: '100%',
    paddingBottom: "60px"
  },
  cancelHeading: {
    color: '#31A7DB',
    fontWeight: 400,
    marginBottom: '1rem',
    fontSize: 18,
    fontFamily: 'Cerebri-SemiBold'
  },
  cancelConfirmText: {
    color: '#808080',
    marginBottom: '2rem',
    fontSize: '16px',
    fontWeight: '400'
  },
  noBtn: {
    marginRight: '1rem',
  },
  receiptWrapper: {
    backgroundColor: '#111111',
    padding: '1.5rem 2rem',
    position: 'absolute',
    zIndex: 1000,
    bottom: 0,
    left: 0,
    width: '100%',
  },
  modalTitle: {
    color: theme.palette.primary.light,
    fontWeight: 500,
    marginBottom: '1rem',
    fontSize: 16,
    fontFamily: 'Cerebri-SemiBold'
  },
  receiptItemHeading: {
    color: '#CCCCCC',
    fontSize: 16,
  },
  payment: {
    color: '#808080',
    fontSize: 20,
  },
  earned: {
    fontFamily: 'Cerebri-SemiBold',
    color: theme.palette.primary.light,
    fontSize: 20,
  },
  okBtn: {
    marginRight: 5,
    width: '100%',
  },
  informationGroup: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '1rem',
  },
  divider: {
    borderTop: '1px dashed #808080',
    width: '100%',
    borderBottom: 0
  },
  subtitle: {
    fontSize: 12,
    width: '80%',
  },
  tabsContainer: {
    overflow: "scroll",
    height: `${height}px`,
    [theme.breakpoints.up("sm")]: {
      height: `${smHeight}px`,
    },
  }
}))
function viewportToPixels(value) {
  var parts = value.match(/([0-9\.]+)(vh|vw)/)
  var q = Number(parts[1])
  var side = window[['innerHeight', 'innerWidth'][['vh', 'vw'].indexOf(parts[2])]]
  return side * (q / 100)
}
const height = viewportToPixels("100vh") - 220
const smHeight = viewportToPixels("100vh") - 280
export default function ManageQuarters({ }) {
  const [activeTab, setActiveTab] = useState(0)
  const [quarters, setQuarters] = useState({
    hosting: {
      appointments: [],
      page: 0,
      totalPages: 1,
    },
    attending: {
      appointments: [],
      page: 0,
      totalPages: 1,
    }
  })
  const [showConfirm, setShowConfirm] = useState(false)
  const [showReceipt, setShowReceipt] = useState(false)
  const selectedQuarterRef = useRef(null)
  const quarterReceiptRef = useRef(null)
  const classes = useStyles();
  const dispatch = useDispatch()
  useEffect(() => {
    Promise.allSettled([getUpcomingQuarters(1), getUpcomingQuarters(0)])
      .finally(() => dispatch(BaseActions.hideLoading()))
  }, [])
  const getUpcomingQuarters = (host) => {
    const activeTabName = host ? 'hosting' : 'attending'
    return axios.post(`${process.env.REACT_APP_ENV === "prod" ? process.env.REACT_APP_BASE_URL_PROD : process.env.REACT_APP_BASE_URL_TESTING}appointments/get-appointments?page=${quarters[activeTabName].page + 1}`, {
      history: 0,
      host,
    },
      pasteAuthHeaders()).then(({ data: { data } }) => {
        setQuarters((prevValues) => ({
          ...prevValues, [activeTabName]: {
            ...data,
            appointments: [...quarters[activeTabName].appointments, ...data.appointments],
          }
        }))
      }).catch((err) => {
        console.log({ err })
      })
  }
  const handleTabChange = (e, newValue) => setActiveTab(newValue)
  const handleCancelConfirm = () => {
    dispatch(BaseActions.showLoading(true))
    axios.post(`${process.env.REACT_APP_ENV === "prod" ? process.env.REACT_APP_BASE_URL_PROD : process.env.REACT_APP_BASE_URL_TESTING}appointments/cancel-appointment`, {
      appointment_id: selectedQuarterRef.current.appointment_id,
      now: moment(),
      attendee: activeTab,
    },
      pasteAuthHeaders()).then(({ data: { status, message, data } }) => {
        if (status?.toLowerCase() === 'fail') throw message
        const {
          list,
          index,
        } = selectedQuarterRef.current
        setQuarters({
          ...quarters,
          [list]: {
            ...quarters[list],
            appointments: update(quarters[list].appointments, { [index]: { $set: data } })
          }
        })
        selectedQuarterRef.current = null
        setShowConfirm(false)
        // dispatch(BaseActions.showAlert({ variant: 'success', message: 'Successfully cancelled quartr' }))
      }).catch((err) => {
        // dispatch(BaseActions.showAlert({ variant: 'error', message: 'Failed to cancel quartr' }))
      }).finally(() => dispatch(BaseActions.hideLoading()))
  }
  const handleCancelAbort = () => {
    selectedQuarterRef.current = null;
    setShowConfirm(false)
  }
  const handleReceiptClose = () => {
    quarterReceiptRef.current = null
    setShowReceipt(false)
  }
  const getTotalAmount = () => {
    const { isHosting, hostRate, refundedAmount, companyFee, transactionFee, referralFee } = quarterReceiptRef.current
    if (!isHosting) return hostRate + companyFee + transactionFee + referralFee
    // const amountWithoutCancellation = hostRate - companyFee - transactionFee - referralFee
    const amountWithoutCancellation = hostRate
    if (!refundedAmount) return amountWithoutCancellation
    return amountWithoutCancellation - refundedAmount
    // return quarterReceiptRef.current.hostRate
  }
  return (
    <>
      <StyledTabs
        value={activeTab}
        indicatorColor='primary'
        textColor='primary'
        onChange={handleTabChange}
      >
        <StyledTab label='Hosting' />
        <StyledTab label='Attending' style={{ marginRight: 0 }} />
      </StyledTabs>
      <div className={classes.tabsContainer}>
        <TabPanel value={activeTab} index={0}>
          <QuartersList
            quarters={quarters.hosting}
            showConfirm={showConfirm}
            setShowConfirm={setShowConfirm}
            setShowReceipt={setShowReceipt}
            selectedQuarterRef={selectedQuarterRef}
            quarterReceiptRef={quarterReceiptRef}
            getUpcomingQuarters={getUpcomingQuarters}
            isHosting
          />
        </TabPanel>
        <TabPanel value={activeTab} index={1}>
          <QuartersList
            quarters={quarters.attending}
            showConfirm={showConfirm}
            setShowConfirm={setShowConfirm}
            setShowReceipt={setShowReceipt}
            selectedQuarterRef={selectedQuarterRef}
            quarterReceiptRef={quarterReceiptRef}
            getUpcomingQuarters={getUpcomingQuarters}
          />
        </TabPanel>
      </div>
      <Slide direction='up' in={showConfirm}>
        <div className={classes.backdrop} style={{ display: showConfirm ? 'inherit' : 'none' }}>
          <div className={classes.confirmWrapper}>
            <Typography variant='subtitle1' className={classes.cancelHeading}>Cancel Quartr</Typography>
            <Typography variant='subtitle2' className={classes.cancelConfirmText}>{activeTab === 0 ? 'Are you sure you want to cancel this quartr, 100% would be refunded back to client.' : 'Are you sure you want to cancel this Quartr? Cancellation fees: 10% over 24 hours. 50% under 24 hours.'}
            </Typography>
            <div style={{ display: 'flex', justifyContent: 'space-between', paddingBottom: "40px" }}>
              <PopupBtn
                variant='outlined'
                className={classes.noBtn}
                onClick={handleCancelAbort}
                fullWidth
                style={{ width: "46%" }}
              >
                No
              </PopupBtn>
              <PopupBtn onClick={handleCancelConfirm} fullWidth style={{ width: "46%" }}>Yes</PopupBtn>
            </div>
          </div>
        </div>
      </Slide>
      {showReceipt && <Slide direction='up' in={showReceipt}>
        <div className={classes.backdrop} >
          <div className={classes.receiptWrapper}>
            <Typography variant='subtitle1' className={classes.modalTitle} style={{ marginBottom: '1.5rem' }}>Receipt</Typography>
            <div>
              <div className={classes.informationGroup} style={{ marginBottom: '1.5rem' }}>
                <Typography variant='subtitle1' className={classes.receiptItemHeading}>
                  {quarterReceiptRef.current.isHosting ? 'Paid' : 'Host Fee'}:
                </Typography>
                <Typography variant='subtitle1' className={classes.receiptItemHeading, classes.payment}>
                  ${!quarterReceiptRef.current.isHosting ? (quarterReceiptRef.current.hostRate).toFixed(2) : (quarterReceiptRef.current.hostRate + quarterReceiptRef.current.companyFee + quarterReceiptRef.current.transactionFee + quarterReceiptRef.current.referralFee).toFixed(2)}
                </Typography>
              </div>
              <div className={classes.informationGroup}>
                <div>
                  <Typography variant='subtitle1' className={classes.receiptItemHeading}>Service Fee:</Typography>
                  <Typography variant='subtitle2' className={classes.subtitle}>(This pays for our developers, designers, testers, servers, internet, etc.)</Typography>
                </div>
                <Typography variant='subtitle1' className={classes.receiptItemHeading, classes.payment}>
                  ${(quarterReceiptRef.current.companyFee).toFixed(2)}
                </Typography>
              </div>
              <div className={classes.informationGroup}>
                <div>
                  <Typography variant='subtitle1' className={classes.receiptItemHeading}>Transaction Fee:</Typography>
                  <Typography variant='subtitle2' className={classes.subtitle}>(This covers the cost to facilitate the payment transaction.)</Typography>
                </div>
                <Typography variant='subtitle1' className={classes.receiptItemHeading, classes.payment}>
                  ${(quarterReceiptRef.current.transactionFee).toFixed(2)}
                </Typography>
              </div>
              <div className={classes.informationGroup}>
                <div>
                  <Typography variant='subtitle1' className={classes.receiptItemHeading}>Reference Fee:</Typography>
                  <Typography variant='subtitle2' className={classes.subtitle}>(This 1% goes to the person who referred you to this app.)</Typography>
                </div>
                <Typography variant='subtitle1' className={classes.receiptItemHeading, classes.payment}>
                  ${(quarterReceiptRef.current.referralFee).toFixed(2)}
                </Typography>
              </div>
              {
                quarterReceiptRef.current.isHosting && quarterReceiptRef.current.isCancelled && <div className={classes.informationGroup}>
                  <div>
                    <Typography variant='subtitle1' className={classes.receiptItemHeading}>Cancellation Fee:</Typography>
                    <Typography variant='subtitle2' className={classes.subtitle}>(This fee is for cancelling the Quratr.)</Typography>
                  </div>
                  <Typography variant='subtitle1' className={classes.receiptItemHeading, classes.payment}>
                    ${(quarterReceiptRef.current.refundedAmount).toFixed(2)}
                  </Typography>
                </div>
              }
              {!quarterReceiptRef.current.isHosting &&
                quarterReceiptRef.current.isCancelled &&
                quarterReceiptRef.current.refundedAmount != quarterReceiptRef.current.hostRate &&
                quarterReceiptRef.current.refundedAmount == (quarterReceiptRef.current.hostRate / 100) * 50 &&
                <div className={classes.informationGroup}>
                  <div>
                    <Typography variant='subtitle1' className={classes.receiptItemHeading}>Cancellation Fee:</Typography>
                    <Typography variant='subtitle2' className={classes.subtitle}>(This fee is for cancelling the Quratr. Cancelled after 24 hours)</Typography>
                  </div>
                  <Typography variant='subtitle1' className={classes.receiptItemHeading, classes.payment}>
                    ${(quarterReceiptRef.current.hostRate).toFixed(2) / 100 * 10}
                  </Typography>
                </div>
              }
              {!quarterReceiptRef.current.isHosting &&
                quarterReceiptRef.current.isCancelled &&
                quarterReceiptRef.current.refundedAmount != quarterReceiptRef.current.hostRate &&
                quarterReceiptRef.current.refundedAmount == (quarterReceiptRef.current.hostRate / 100) * 10 &&
                <div className={classes.informationGroup}>
                  <div>
                    <Typography variant='subtitle1' className={classes.receiptItemHeading}>Cancellation Fee:</Typography>
                    <Typography variant='subtitle2' className={classes.subtitle}>(This fee is for cancelling the Quratr. Cancelled within 24 hours)</Typography>
                  </div>
                  <Typography variant='subtitle1' className={classes.receiptItemHeading, classes.payment}>
                    ${(quarterReceiptRef.current.hostRate).toFixed(2) / 100 * 50}
                  </Typography>
                </div>
              }
              <div className={classes.informationGroup}>
                <hr className={classes.divider} />
              </div>
              <div className={classes.informationGroup}>
                <Typography variant='subtitle1' className={classes.modalTitle}>
                  {quarterReceiptRef.current.isHosting ? 'Earned' : 'Paid'}:
                </Typography>
                <Typography variant='subtitle1' className={classes.modalTitle, classes.earned}>
                  ${getTotalAmount().toFixed(2)}
                </Typography>
              </div>
              {quarterReceiptRef.current.refundedAmount > 0 && quarterReceiptRef.current.refundedAmount != null && quarterReceiptRef.current.isCancelled &&
                <div className={classes.informationGroup}>
                  <Typography variant='subtitle1' className={classes.modalTitle}>
                    Refunded
                  </Typography>
                  <Typography variant='subtitle1' className={classes.modalTitle, classes.earned}>
                    ${quarterReceiptRef.current.refundedAmount.toFixed(2)}
                  </Typography>
                </div>}
              <div style={{ paddingBottom: "80px" }} className={classes.informationGroup}>
                <Button
                  color='primary'
                  className={classes.okBtn}
                  onClick={handleReceiptClose}
                >
                  Ok
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Slide>
      }
    </>
  )
}
function TabPanel({ children, value, index, ...other }) {
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Typography>{children}</Typography>
      )}
    </div>
  )
}
