export const GET_USER_DATA = "GET_USER_DATA";
export const UPDATE_USER_DATA = "UPDATE_USER_DATA";
export const SET_USER_BANKING_DATA = 'SET_USER_BANKING_DATA'
export const REMOVE_USER_BANK_ACCOUNT = 'REMOVE_USER_BANK_ACCOUNT'
export const ADD_RATING = 'ADD_RATING'
export const REMOVE_RATING = 'REMOVE_RATING'
export const RESET_RATINGS = 'RESET_RATINGS'

export function updateUserData(data) {
  return {
    type: UPDATE_USER_DATA,
    payload: data,
  };
}


export function getUserData(data) {
  return {
    type: GET_USER_DATA,

  };
}

export const setUserBankingData = (payload) => ({
  type: SET_USER_BANKING_DATA,
  payload,
})

export const removeUserBankAccount = (payload) => ({
  type: REMOVE_USER_BANK_ACCOUNT,
})

export const addRating = (payload) => ({
  type: ADD_RATING,
  payload,
})

export const removeRating = (payload) => ({
  type: REMOVE_RATING,
  payload,
})

export const resetRatings = () => ({ type: RESET_RATINGS })
