import React from "react";
import {Checkbox, FormControlLabel, makeStyles, Typography} from "@material-ui/core";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles(() => ({
    container: {
        width: '100%',
        cursor: 'pointer',
    },
    selected: {
        border: '2px solid #31A7DB',
    },
    unselected: {
        border: '2px solid #1B1B1B',
    },
    iconDiv: {
        width: '100%',
        height: '100px',
        backgroundColor: "#1B1B1B",
        borderRadius: 16,
        display: 'grid',
        alignContent: "center",
        justifyContent: "start",
        padding: '20px',
    },
    titleDiv: {
        fontFamily: "-apple-system, BlinkMacSystemFont, cerebri-regular",
        fontSize: '20px',
        fontWeight: '100',
        lineHeight: '25.51px',
        textAlign: 'center',
        color: '#A8A8A8',
        overflow: 'hidden',
        textWrap: 'nowrap',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    }
}))

function TileFullWidth({ title, icon, description = '', selected, onSelect, field }) {
    const classes = useStyles();

    return (
        <div className={classes.container} onClick={onSelect}>
            <div className={`${classes.iconDiv} ${selected ? classes.selected : classes.unselected}`}>
                <FormControlLabel
                    control={
                        <Checkbox
                            {...field}
                            checked={selected}
                            value={title}
                            icon={icon}
                            checkedIcon={icon}
                        />
                    }
                    label={
                        <Box ml={1}>
                            <Typography variant="h6" className={classes.titleDiv}>
                                {title}
                            </Typography>
                        </Box>
                    }
                />
            </div>
        </div>
    )
}

export default TileFullWidth;
