import * as Actions from "../actions";

// initial state
const initialState = {
  isLoading: false,
  errMsg: null,
  data: null,
};

// Reducer for LOGIN handling
export const FetchProfileReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.FETCH_PROFILE:
      // we will mould our payload here accordingly  if required  in case of success
      return { ...state, isLoading: false, errMsg: null, data: action.payload };
    case Actions.PROFILE_IS_LOADING:
      // ... in case of loading
      return { ...state, isLoading: true, errMsg: null, data: null };
    case Actions.PROFILE_FAILED:
      // ... in case of error
      return { ...state, isLoading: false, errMsg: action.payload, data: null };
    case Actions.RESET_PROFILE:
      return { ...state, isLoading: false, errMsg: null, data: null }
    default:
      return state;
  }
};
// Reducer for LOGIN handling
export const FetchReviewsReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.FETCH_REVIEWS:
      // we will mould our payload here accordingly  if required  in case of success
      return { ...state, isLoading: false, errMsg: null, data: action.payload };
    case Actions.REVIEWS_IS_LOADING:
      // ... in case of loading
      return { ...state, isLoading: true, errMsg: null, data: null };
    case Actions.REVIEWS_FAILED:
      // ... in case of error
      return { ...state, isLoading: false, errMsg: action.payload, data: null };
    default:
      return state;
  }
};

export const GetRatesReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.GET_RATES:
      // we will mould our payload here accordingly  if required  in case of success
      return { ...state, isLoading: false, errMsg: null, data: action.payload };
    case Actions.GET_RATES_IS_LOADING:
      // ... in case of loading
      return { ...state, isLoading: true, errMsg: null, data: null };
    case Actions.GET_RATES_FAILED:
      // ... in case of error
      return { ...state, isLoading: false, errMsg: action.payload, data: null };
    default:
      return state;
  }
};