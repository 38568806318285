import React from 'react';

function RingingBellIcon({ ...props }) {
    return (
        <svg width={props.width ?? '26'} height={props.height ?? '26'} viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_2_235)">
                <path
                    d="M23.8333 11.7889C23.2353 11.7889 22.75 11.3035 22.75 10.7055C22.75 7.66677 21.567 4.81111 19.4188 2.66178C18.9952 2.23819 18.9952 1.55353 19.4188 1.12994C19.8423 0.70636 20.527 0.70636 20.9506 1.12994C23.5083 3.68769 24.9167 7.08827 24.9167 10.7055C24.9167 11.3035 24.4313 11.7889 23.8333 11.7889Z"
                    fill={props.fill}  />
                <path
                    d="M2.16665 11.7889C1.56865 11.7889 1.08331 11.3035 1.08331 10.7055C1.08331 7.08827 2.49165 3.68769 5.0494 1.12994C5.47298 0.70636 6.15765 0.70636 6.58123 1.12994C7.00481 1.55353 7.00481 2.23819 6.58123 2.66178C4.43298 4.81003 3.24998 7.66677 3.24998 10.7055C3.24998 11.3035 2.76465 11.7889 2.16665 11.7889Z"
                    fill={props.fill} />
                <path
                    d="M23.1606 18.3224C21.5226 16.9379 20.5834 14.9142 20.5834 12.7703V9.75C20.5834 5.93775 17.7515 2.782 14.0834 2.25333V1.08333C14.0834 0.48425 13.598 0 13 0C12.402 0 11.9167 0.48425 11.9167 1.08333V2.25333C8.24744 2.782 5.41669 5.93775 5.41669 9.75V12.7703C5.41669 14.9142 4.47744 16.9379 2.82969 18.3311C2.40827 18.6918 2.16669 19.2162 2.16669 19.7708C2.16669 20.8162 3.0171 21.6667 4.06252 21.6667H21.9375C22.9829 21.6667 23.8334 20.8162 23.8334 19.7708C23.8334 19.2162 23.5918 18.6918 23.1606 18.3224Z"
                    fill={props.fill} />
                <path d="M13 26C14.9619 26 16.6032 24.6014 16.9802 22.75H9.01984C9.39684 24.6014 11.0381 26 13 26Z"
                      fill={props.fill} />
            </g>
            <defs>
                <clipPath id="clip0_2_235">
                    <rect width={props.width ?? '26'} height={props.height ?? '26'} fill="white"/>
                </clipPath>
            </defs>
        </svg>
    )
}

export default RingingBellIcon;