import React from 'react'
import { ListItem, ListItemText, ListItemAvatar, Avatar, Typography } from '@material-ui/core'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import moment from 'moment'
import DummyImage from "@assets/dummyImage.png"

const StyledListItem = withStyles({
  root: {
    paddingLeft: 0,
    paddingRight: 0,
    cursor: 'pointer',
  }
})(ListItem)

const StyledListItemText = withStyles({
  primary: {
    fontSize: 16,
    fontWeight: 600,
  },
  secondary: {
    color: '#808080',
    fontSize: 12,
  }
})(ListItemText)

const StyledPaymentText = withStyles({
  root: {
    color: '#31A7DB',
    fontSize: 18,
    fontWeight: 600,
    fontFamily: 'Cerebri-Bold',
  }
})(Typography)

const useStyles = makeStyles({
  cancelled: {
    color: '#CB3A3A',
  },
})

export default function QuartersList({
  quarter: {
    _id,
    host,
    attendee,
    call_at,
    receipt_id,
    appointment_id,
  },
  isHosting,
  setShowReceipt,
  quarterReceiptRef,
}) {
  const otherUser = isHosting ? attendee : host
  const isCancelled = appointment_id.status === 'cancelled'
  const isMissed = (appointment_id.status === 'noOnePicked') || (isHosting && appointment_id.status === 'hostDidntPick') || (!isHosting && appointment_id.status === 'attendeeDidntPick')
  const handleShowReceipt = () => {
    quarterReceiptRef.current = {
      ...receipt_id,
      isHosting,
      isCancelled,
    }
    setShowReceipt(true)
  }
  const classes = useStyles()
  return (
    <div style={{ display: 'flex', overflow: 'hidden', margin: '0.5rem 0', marginLeft: "-10px" }}>
      {otherUser && otherUser.profile_media_urls[0] && <StyledListItem
        onClick={handleShowReceipt}>
        <ListItemAvatar>
          <Avatar
            variant='rounded'
            src={otherUser.profile_media_urls[0] === "https://narsunprojects.com:3001/uploads/default_pic.png" ? DummyImage : otherUser.profile_media_urls[0]}>
          </Avatar>
        </ListItemAvatar>
        <StyledListItemText primary={otherUser.enabledUsername ? otherUser.username : otherUser.full_name} secondary={moment(call_at).format('MMM, DD [at] h:mmA')} />
        {
          isMissed
            ? <StyledPaymentText variant='h6' className={classes.cancelled}>Missed</StyledPaymentText>
            : isCancelled
              ? <StyledPaymentText variant='h6' className={classes.cancelled}>Cancelled</StyledPaymentText>
              : <StyledPaymentText variant='h6'>{receipt_id.hostRate ? `$${(receipt_id.hostRate + receipt_id.companyFee + receipt_id.transactionFee + receipt_id.referralFee).toFixed(2)}` : 'Free'}</StyledPaymentText>
        }
      </StyledListItem>}
    </div>
  )
}
