import React from "react";
import { withRouter } from "react-router";
import { Button, Box, Typography } from "@material-ui/core";
import { isIOS } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import * as Actions from "./store/actions";
import * as BaseActions from "store/actions";
import reducer from "./store/reducers";
import withReducer from "store/withReducer";
import useDeepCompareEffect from "use-deep-compare-effect";
const BlockUser = ({ userName, userId, ...props }) => {
  const dispatch = useDispatch();
  const response = useSelector(({ Block }) => Block.BlockReducer);
  const data = {
    name: userName,
    response: 'user-blocked'
  }
  useDeepCompareEffect(() => {
    response.data && props.history.push("/home", data);
  }, [response]);
  const handleBlock = (id) => {
    dispatch(Actions.blockUser(userId));
  };
  const handleClose = (id) => {
    dispatch(BaseActions.hideMessage());
  };
  return (
    <Box padding="30px" paddingBottom="60px">
      <Typography variant="body2">Block user</Typography>
      <Box marginTop="20px">
        <Typography variant="caption">
          Are you sure you want to block {userName}?
        </Typography>
      </Box>
      <Box display="flex" marginTop="40px" justifyContent="space-between">
        <Button
          style={{ width: "46%", marginRight: "10px" }}
          color="secondary"
          variant="outlined"
          onClick={() => handleClose()}
        >
          No
        </Button>
        <Button onClick={() => handleBlock(userId)} style={{ width: "46%" }}>
          Yes
        </Button>
      </Box>
    </Box>
  );
};
export default withReducer("Block", reducer)(withRouter(BlockUser));
