import React from "react";
import { withRouter, Route } from "react-router";

import * as BaseActions from "store/actions";

import { useDispatch, useSelector } from "react-redux";

const DynamicComponent = (props) => {
  const dispatch = useDispatch();
  const [mount, setMount] = React.useState();

  const routeReducer = useSelector(
    ({ BaseReducer }) => BaseReducer.RouteReducer
  );

  // React.useEffect(() => {
  //   // alert(JSON.stringify(routeReducer));
  //   // if (routeReducer.component !== null) {
  //   //   setMount(true);
  //   // } else {
  //   //   props.history.push("/home");
  //   // }

  //   return () => {
  //     dispatch(BaseActions.unmountRoute());
  //   };
  // }, [routeReducer]);

  return (
    <>
     
        <h1>hello woeld</h1>
     
    </>
  );
};

export default withRouter(DynamicComponent);
