import React, { useEffect, useState } from 'react'
import { List, ListItem, ListItemAvatar, Avatar, Typography, Box, Slide, Button } from '@material-ui/core'
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles, makeStyles } from '@material-ui/core/styles'
import moment from 'moment'
import { isIOS, isMobile } from 'react-device-detect';
import { monthlyRecord, weeksOfMonths, yearlyRecord } from "@utils";

const StyledListItem = withStyles({
  root: {
    paddingLeft: 0,
    paddingRight: 0,
    cursor: 'pointer',
  }
})(ListItem)
const StyledListItemText = withStyles({
  primary: {
    fontSize: 16,
    fontWeight: 600,
  },
  secondary: {
    color: '#808080',
    fontSize: 12,
  }
})(ListItemText)
const StyledPaymentText = withStyles({
  root: {
    color: '#31A7DB',
    fontSize: 18,
    fontWeight: 600,
    fontFamily: 'Cerebri-Bold',
  }
})(Typography)
const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: '18px',
    textAlign: 'center',
    background: '#09161C',
    padding: '1% 0%'
  },
  backdrop: {
    zIndex: 100,
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    position: 'fixed',
    bottom: 31,
    // left: 0,
    width: isMobile ? '100%' : '375px',
    height: '88vh',
    borderRadius: '6px',
    [theme.breakpoints.down('xs')]: {
      height: '100vh',
    },
  },
  confirmWrapper: {
    marginBottom: isIOS ? "60px" : "40px",
    backgroundColor: '#111111',
    padding: '2rem',
    position: 'fixed',
    zIndex: 1000,
    bottom: 0,
    width: isMobile ? '100%' : '375px',
  },
  Heading: {
    color: '#31A7DB',
    fontWeight: 500,
    marginBottom: '1rem',
    fontSize: 16,
    fontFamily: 'Cerebri-SemiBold'
  },
  ConfirmText: {
    color: '#CCCCCC',
    marginBottom: '1rem',
    fontSize: '16px'
  },
  ConfirmText2: {
    color: '#808080',
    marginBottom: '1rem',
    fontSize: '16px'
  },
}));
const PopupBtn = withStyles({
  root: {
    height: 42,
    '& .MuiButton-label': {
      fontSize: 18,
    },
  },
})(Button)
export default function EarningsListItem(props) {
  const months = [
    {
      tag: 'Jan',
      value: "January",
      label: "01",
    },
    {
      tag: 'Feb',
      value: "February",
      label: "02",
    },
    {
      tag: 'Mar',
      value: "March",
      label: "03",
    },
    {
      tag: 'Apr',
      value: "April",
      label: "04",
    },
    {
      tag: 'May',
      value: "May",
      label: "05",
    },
    {
      tag: 'Jun',
      value: "June",
      label: "06",
    },
    {
      tag: 'Jul',
      value: "July",
      label: "07",
    },
    {
      tag: 'Aug',
      value: "August",
      label: "08",
    },
    {
      tag: 'Sep',
      value: "September",
      label: "09",
    },
    {
      tag: 'Oct',
      value: "October",
      label: "10",
    },
    {
      tag: 'Nov',
      value: "November",
      label: "11",
    },
    {
      tag: 'Dec',
      value: "December",
      label: "12",
    },
  ];
  // const classes = useStyles();
  return (
    // <StyledListItem>
    //   <ListItemAvatar style={{ marginLeft: "-8px" }}>
    //     <Avatar
    //       variant='rounded'
    //       src='/assets/images/avatar.png'
    //     />
    //   </ListItemAvatar>
    //   <StyledListItemText primary='Brian Cranston' secondary={moment('2021-04-05T15:17:48.841Z').format('MMM, DD [at] h:mmA')} />
    //   <StyledPaymentText variant='h6'>$10</StyledPaymentText>
    // </StyledListItem>
    <div>
      {props.earningType === "weekly" && <Weekly userEarnings={props.userEarnings} months={months} />}
      {props.earningType === "monthly" && <Monthly userEarnings={props.userEarnings} months={months} />}
      {props.earningType === "yearly" && <Yearly userEarnings={props.userEarnings} months={months} />}
    </div>
  )
}
//////////////////////////////////////////////////////////////////////////////////////WEEKLY//////////////////////////////////////////////////////////
function Weekly(props) {
  const [earnings, setEarnings] = useState([]);
  const [dates, setDates] = useState([]);
  const classes = useStyles();
  const [weeklyRec, setWeeklyRec] = useState(false);
  const [popData, setPopData] = useState({})
  const [groupByMonthYear, setGroupByMonthYear] = useState([])
  const [allWeeks, setAllWeeks] = useState([])
  const [sortedMonths, setSortedMonths] = useState([])
  const { months, userEarnings } = props;
  let result;
  let all = [];

  useEffect(() => {
    const weeksRecord = weeksOfMonths(userEarnings)
    setAllWeeks(weeksRecord)
  }, [userEarnings])

  // useEffect(() => {
  //   const keys = Object.keys(userEarnings);
  //   keys.forEach((key, index) => {
  //     // let newKeys = Object.keys(userEarnings[key]);
  //     result = userEarnings[key].reduce(function (r, a) {
  //       let month = new Date(a.payoutDate).getMonth()
  //       r[month] = r[month] || [];
  //       r[month].push(a);
  //       return r;
  //     }, Object.create(null));
  //     all.push(result)
  //     setGroupByMonthYear(all)
  //     // newKeys.forEach((newKey, index) => {
  //     // });
  //   });
  // }, [userEarnings])
  const payoutMonth = (getDate) => {
    var d = new Date(getDate);
    const month = d.getMonth();
    return months[month].value
  }
  const payoutYear = (getDate) => {
    var d = new Date(getDate);
    return d.getFullYear()
  }
  const payoutDay = (val) => {
    var d = new Date(val);
    // return Number(d.getDate())
    return d.getDate()
  }
  useEffect(() => {
    if (props) {
      let dates = [];
      let earnings = [];
      // for (let key in userEarnings) {
      for (let key in userEarnings) {
        dates.push(key)
        // earnings.push(userEarnings[key])
        earnings.push(userEarnings[key])
      }
      // setEarnings(earnings)
      setDates(dates)
    }
  }, [props])
  const handleWeeklyPop = (amount, referralFee, payoutFee, charges) => {
    setWeeklyRec(true)
    const data = {
      amount: amount,
      charges: charges,
      referralFee: referralFee,
      payoutFee: payoutFee
    }
    setPopData(data)
  }
  const getLastMonth = (payoutDate, month, days) => {
    const tempDate = new Date(payoutDate)
    const subDate = moment(tempDate).subtract(days, 'days')
    return moment(subDate).format('MMM')
    // return months[new Date(new Date(tempDate).setDate(new Date(tempDate).getDate() - days)).getMonth()].value.substring(0, 3)
  }
  const getLastDays = (payoutDate, month, days) => {
    const tempDate = new Date(payoutDate)
    return new Date(tempDate.setDate(tempDate.getDate() - days)).getDate()
  }
  const getPreviousDay = (payoutDate, month, days) => {
    const tempDate = new Date(payoutDate)
    return new Date(tempDate.setDate(tempDate.getDate() - days)).getDate()
  }
  ////////////////////Two sets of data (1) userEarnings [sorted by year] (2) groupByMonthYear [sorted by year & month]////////////////////
  useEffect(() => {
    sortedData();
  }, [groupByMonthYear])
  let sortedMonthsArr = [];
  const sortedData = () => {
    let sortedEarnings = [];
    groupByMonthYear.map(user => {
      const keys = Object.keys(user);
      keys.forEach((key, index) => {
        user[key].map(earn => {
          sortedMonthsArr.push({ month: payoutMonth(earn.payoutDate), year: payoutYear(earn.payoutDate) })
        })
        sortedEarnings.push(user[key])
      })
    })
    setEarnings(sortedEarnings.reverse())
    const newArr = unique(sortedMonthsArr.reverse(), ['month', 'year']);
    setSortedMonths(newArr)
  }
  //////////////////////////////////Finding Unique month and year object///////////////////////////////////////////////////////////////////
  const unique = (arr, props = []) =>
    [...new Map(arr.map(entry =>
      [props.map(k => entry[k]).join('|'), entry]
    )).values()];
  useEffect(() => {
  }, [sortedMonths])
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  const UserEarnings = (earning, index) => {
    // let month = sortedMonths[earning.index].month.substring(0, 3)
    // let year = sortedMonths[earning.index].year
    if (earning.earning.weeks.length) {

      return <div>
        <Typography Typography variant="h1" className={classes.heading} >
          {earning.earning.month}
        </Typography >
        {earning.earning.weeks?.map((earn, i) => {
          return <div>
            <div style={{ padding: '1% 5%' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between', cursor: 'pointer' }} onClick={() => { handleWeeklyPop(earn.value, earn.referralFee, earn.payoutFee, 0) }}>
                <div>
                  <p style={{ fontFamily: "-apple-system, BlinkMacSystemFont, cerebri-regular" }}>
                    {
                      moment(earn.thu, 'DD.MM.YYYY').format('MMM DD')
                    }
                    &nbsp;-&nbsp;
                    {
                      moment(earn.wed, 'DD.MM.YYYY').format('MMM DD')
                    }
                    {/* {getLastMonth(earn.payoutDate, earn.month, 6)}&nbsp;
                    {getLastDays(earn.payoutDate, earn.month, 6)}
                    &nbsp;-&nbsp;
                    {getLastMonth(earn.payoutDate, earn.month, 0)}&nbsp;
                    {getPreviousDay(earn.payoutDate, earn.month, 0)} */}
                  </p>
                </div>
                <div>
                  <p style={{ fontFamily: "-apple-system, BlinkMacSystemFont, cerebri-regular" }}>$ {(((earn.value || 0.0) + (earn.referralFee || 0.0)) - (earn.payoutFee || 0.0)).toFixed(2)}</p>
                </div>
              </div>
            </div>
          </div>
        })
        }
      </div>
    } else {
      return null
    }
  }
  return (
    <Box paddingBottom="20px">
      <>
        {/* {earnings &&
          earnings.map((Earnings, index) => {
            return <UserEarnings
              earning={Earnings}
              index={index}
            />
          })} */}
        {allWeeks &&
          allWeeks.map((Earnings, index) => {
            return <UserEarnings
              earning={Earnings}
              index={index}
            />
          })}
        {allWeeks.length === 0 && <Typography variant='subtitle2' className={classes.ConfirmText} style={{ width: '100%', textAlign: 'center', margin: 'auto' }}>No records found</Typography>}
      </>
      <Slide direction='up' in={weeklyRec}>
        <div className={classes.backdrop} style={{ display: weeklyRec ? 'inherit' : 'none' }}>
          <div className={classes.confirmWrapper}>
            <Typography variant='subtitle1' className={classes.Heading}>Summary</Typography>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '1% 0%' }}>
              <Typography variant='subtitle2' className={classes.ConfirmText}>Call Revenue:</Typography>
              <Typography variant='subtitle2' className={classes.ConfirmText2}>${(popData.amount + popData.charges).toFixed(2)}</Typography>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '1% 0%' }}>
              <Typography variant='subtitle2' className={classes.ConfirmText}>Referral Revenue:</Typography>
              <Typography variant='subtitle2' className={classes.ConfirmText2}>${parseFloat(popData.referralFee).toFixed(2) || '0.00'}</Typography>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '1% 0%' }}>
              <Typography variant='subtitle2' className={classes.ConfirmText}>Pay-out Fee:</Typography>
              <Typography variant='subtitle2' className={classes.ConfirmText2}>{popData.amount === 0 ? '$0.00' : '$0.50'}</Typography>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '1% 0%' }}>
              <Typography variant='subtitle2' className={classes.Heading}>Total Revenue:</Typography>
              <Typography variant='subtitle2' className={classes.Heading}>${(popData.amount + popData.charges + popData.referralFee - 0.5 < 0 ? 0 : popData.amount + popData.charges + popData.referralFee - 0.5).toFixed(2)}</Typography>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', paddingBottom: isMobile ? "80px" : '30px' }}>
              <PopupBtn
                onClick={() => setWeeklyRec(false)}
                fullWidth
              >
                Ok
              </PopupBtn>
            </div>
          </div>
        </div>
      </Slide>
    </Box >
  )
}
/////////////////////////////////////////////////////        MONTHLY          //////////////////////////////////////////////////////////
function Monthly(props) {
  const classes = useStyles();
  const [monthlyRec, setMonthlyRec] = useState(false)
  const [earnings, setEarnings] = useState([]);
  const [dates, setDates] = useState([]);
  const [popData, setPopData] = useState(0)
  const [charges, setCharges] = useState([]);
  const [monthlyCharges, setMonthlyCharges] = useState([]);
  const [groupByMonthYear, setGroupByMonthYear] = useState([])
  const [sortedMonths, setSortedMonths] = useState([])
  const { months, userEarnings } = props;
  let result;
  let all = [];
  // useEffect(() => {
  //   const keys = Object.keys(userEarnings);
  //   keys.forEach((key, index) => {
  //     // let newKeys = Object.keys(userEarnings[key]);
  //     result = userEarnings[key].reduce(function (r, a) {
  //       let month = new Date(a.payoutDate).getMonth()
  //       r[month] = r[month] || [];
  //       r[month].push(a);
  //       return r;
  //     }, Object.create(null));
  //     all.push(result)
  //     setGroupByMonthYear(all)
  //     // newKeys.forEach((newKey, index) => {
  //     // });
  //   });
  // }, [userEarnings])

  useEffect(() => {
    const monthlyReco = monthlyRecord(userEarnings)
    setMonthlyCharges(monthlyReco)
  }, [userEarnings])

  const payoutMonth = (getDate) => {
    var d = new Date(getDate);
    const month = d.getMonth();
    return months[month].value
  }
  const payoutYear = (getDate) => {
    var d = new Date(getDate);
    return d.getFullYear()
  }
  const payoutDay = (val) => {
    var d = new Date(val);
    // return Number(d.getDate())
    return d.getDate()
  }
  useEffect(() => {
    if (props) {
      let dates = [];
      let earnings = [];
      // for (let key in userEarnings) {
      for (let key in userEarnings) {
        dates.push(key)
        // earnings.push(userEarnings[key])
        earnings.push(userEarnings[key])
      }
      // setEarnings(earnings)
      setDates(dates)
    }
  }, [props])
  const handleMonthlyPop = (amount, referralFee, payoutFee, charges) => {
    const data = {
      amount: amount,
      charges: charges,
      referralFee: referralFee,
      payoutFee: payoutFee
    }
    setPopData(data)
    setMonthlyRec(true)
  }
  ////////////////////Two sets of data (1) userEarnings [sorted by year] (2) groupByMonthYear [sorted by year & month]////////////////////
  useEffect(() => {
    sortedData();
  }, [groupByMonthYear])
  let sortedMonthsArr = [];
  const sortedData = () => {
    let sortedEarnings = [];
    groupByMonthYear.map(user => {
      const keys = Object.keys(user);
      keys.forEach((key, index) => {
        user[key].map(earn => {
          sortedMonthsArr.push({ month: payoutMonth(earn.payoutDate), year: payoutYear(earn.payoutDate) })
        })
        sortedEarnings.push(user[key])
      })
    })
    setEarnings(sortedEarnings)
    const newArr = unique(sortedMonthsArr, ['month', 'year']);
    setSortedMonths(newArr)
  }
  const unique = (arr, props = []) => [...new Map(arr.map(entry => [props.map(k => entry[k]).join('|'), entry])).values()];
  useEffect(() => {
  }, [sortedMonths])
  useEffect(() => {
    let totalCharges = [];
    earnings.map(user => {
      let total = 0;
      for (let i = 0; i < user.length; i++) {
        total += user[i].payoutAmount
        total += user[i].payoutCharges
      }
      totalCharges.push(total)
    })
    setCharges(totalCharges)
  }, [earnings])
  useEffect(() => {
  }, [charges])
  return (
    <Box>
      {monthlyCharges?.map((charge, i) => {
        return <>
          <div style={{ padding: '1% 5%' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', cursor: 'pointer' }} onClick={() => handleMonthlyPop(charge.value, charge.referralFee, charge.payoutFee, 0)}>
              <div>
                <p style={{ fontFamily: "-apple-system, BlinkMacSystemFont, cerebri-regular" }}>{charge.month}</p>
              </div>
              <div>
                <p style={{ fontFamily: "-apple-system, BlinkMacSystemFont, cerebri-regular" }}>${(((charge.value || 0.0) + (charge.referralFee || 0.0)) - (charge.payoutFee || 0.0)).toFixed(2)}</p>
              </div>
            </div>
          </div>
        </>
      })
      }
      {monthlyCharges.length === 0 && <Typography variant='subtitle2' className={classes.ConfirmText} style={{ width: '100%', textAlign: 'center', margin: 'auto' }}>No records found</Typography>}
      <Slide direction='up' in={monthlyRec}>
        <div className={classes.backdrop} style={{ display: monthlyRec ? 'inherit' : 'none' }}>
          <div className={classes.confirmWrapper}>
            <Typography variant='subtitle1' className={classes.Heading}>Summary</Typography>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '1% 0%' }}>
              <Typography variant='subtitle2' className={classes.ConfirmText}>Call Revenue:</Typography>
              <Typography variant='subtitle2' className={classes.ConfirmText2}>${popData?.amount?.toFixed(2)}</Typography>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '1% 0%' }}>
              <Typography variant='subtitle2' className={classes.ConfirmText}>Referral Revenue:</Typography>
              <Typography variant='subtitle2' className={classes.ConfirmText2}>${parseFloat(popData.referralFee).toFixed(2) || '0.00'}</Typography>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '1% 0%' }}>
              <Typography variant='subtitle2' className={classes.ConfirmText}>Pay-out Fee:</Typography>
              <Typography variant='subtitle2' className={classes.ConfirmText2}>{popData?.payoutFee?.toFixed(2)}</Typography>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '1% 0%' }}>
              <Typography variant='subtitle2' className={classes.Heading}>Total Revenue:</Typography>
              <Typography variant='subtitle2' className={classes.Heading}>${(popData.amount + popData.referralFee - (popData?.payoutFee || 0) < 0 ? 0 : popData.amount + popData.referralFee - (popData?.payoutFee || 0)).toFixed(2)}</Typography>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', paddingBottom: isMobile ? "80px" : '30px' }}>
              <PopupBtn
                onClick={() => setMonthlyRec(false)}
                fullWidth
              >
                Ok
              </PopupBtn>
            </div>
          </div>
        </div>
      </Slide>
    </Box >
  )
}
//////////////////////////////////////////////////////////////////////////////////////YEARLY//////////////////////////////////////////////////////////
function Yearly(props) {
  const classes = useStyles();
  const [yearlyRec, setYearlyRec] = useState(false)
  const [yearlyCharges, setYearlyCharges] = useState(0)
  const [numberOfYears, setNumberOfYears] = useState([])
  const [popData, setPopData] = useState(0)
  const { userEarnings } = props;
  // useEffect(() => {
  //   const keys = Object.keys(userEarnings);
  //   let totalCharges = []
  //   let years = [];
  //   keys.forEach((key, index) => {
  //     let total = 0;
  //     years.push(Number(key))
  //     userEarnings[key].map(earn => {
  //       total += earn.payoutAmount
  //       total += earn.payoutCharges
  //     })
  //     totalCharges.push(total)
  //     setNumberOfYears(years)
  //   })
  //   setYearlyCharges(totalCharges)
  // }, [props])


  useEffect(() => {
    const monthlyReco = yearlyRecord(userEarnings)
    setYearlyCharges(monthlyReco)
  }, [userEarnings])


  useEffect(() => {
  }, [numberOfYears, yearlyCharges])
  const handleYearlyPop = (amount, referralFee, payoutFee, charges) => {
    const data = {
      amount: amount,
      charges: charges,
      referralFee: referralFee,
      payoutFee: payoutFee
    }
    setPopData(data)
    setYearlyRec(true)
  }
  return (
    <Box>
      <>
        <div style={{ padding: '5% 5%' }}>
          {yearlyCharges.length > 0 && yearlyCharges.map((earnByYear, i) => (
            <div style={{ display: 'flex', justifyContent: 'space-between', cursor: 'pointer' }} onClick={() => handleYearlyPop(earnByYear.value, earnByYear.referralFee, earnByYear.payoutFee, 0)}>
              <div>
                <p style={{ fontFamily: "-apple-system, BlinkMacSystemFont, cerebri-regular" }}>{earnByYear.year}</p>
              </div>
              <div>
                <p style={{ fontFamily: "-apple-system, BlinkMacSystemFont, cerebri-regular" }}>${(((earnByYear.value || 0.0) + (earnByYear.referralFee || 0.0)) - (earnByYear.payoutFee || 0.0)).toFixed(2)}</p>
              </div>
            </div>
          ))}
          {yearlyCharges.length === 0 && <Typography variant='subtitle2' className={classes.ConfirmText} style={{ width: '100%', textAlign: 'center', margin: 'auto', marginTop: '-5.5%' }}>No records found</Typography>}
        </div>
      </>
      <Box>
        <Slide direction='up' in={yearlyRec}>
          <div className={classes.backdrop} style={{ display: yearlyRec ? 'inherit' : 'none' }}>
            <div className={classes.confirmWrapper}>
              <Typography variant='subtitle1' className={classes.Heading}>Summary</Typography>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '1% 0%' }}>
                <Typography variant='subtitle2' className={classes.ConfirmText}>Call Revenue:</Typography>
                <Typography variant='subtitle2' className={classes.ConfirmText2}>${popData?.amount?.toFixed(2)}</Typography>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '1% 0%' }}>
                <Typography variant='subtitle2' className={classes.ConfirmText}>Referral Revenue:</Typography>
                <Typography variant='subtitle2' className={classes.ConfirmText2}>${parseFloat(popData.referralFee).toFixed(2) || '0.00'}</Typography>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '1% 0%' }}>
                <Typography variant='subtitle2' className={classes.ConfirmText}>Pay-out Fee:</Typography>
                <Typography variant='subtitle2' className={classes.ConfirmText2}>{popData?.payoutFee?.toFixed(2)}</Typography>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '1% 0%' }}>
                <Typography variant='subtitle2' className={classes.Heading}>Total Revenue:</Typography>
                <Typography variant='subtitle2' className={classes.Heading}>${(popData.amount + popData.referralFee - (popData?.payoutFee || 0) < 0 ? 0 : popData.amount + popData.referralFee - (popData?.payoutFee || 0)).toFixed(2)}</Typography>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between', paddingBottom: isMobile ? "80px" : '30px' }}>
                <PopupBtn
                  onClick={() => setYearlyRec(false)}
                  fullWidth
                >
                  Ok
                </PopupBtn>
              </div>
            </div>
          </div>
        </Slide>
      </Box>
    </Box>
  )
}
