import React from "react";
import { isMobile } from 'react-device-detect'
import {
	Typography, InputLabel, Input, IconButton, InputAdornment,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { InfoOutlined } from "@material-ui/icons";
const useStyles = makeStyles((theme) => ({
	inputContainer: {
		// margin: theme.spacing(2, 0),
		// marginTop: isMobile ? 0 : 10,
		// border: "1px dashed orange"
	},
	root: {
		paddingLeft: (props) => (props.paddingNone ? 0 : "8.5px"),
		paddingRight: (props) => (props.paddingNone ? 0 : "8.5px"),
		fontFamily: "-apple-system, BlinkMacSystemFont, cerebri-regular",
		fontSize: "18px",
		"& > *": { color: "white" },
		"&:before": {
			borderBottom: ".5px solid #808080",
		},
		"&:hover": {
			"&:not(.Mui-disabled)": {
				"&:before": {
					borderBottom: ".5px solid #808080 !important",
				},
			},
		},
	},
	rootWithOutBorder: {
		paddingLeft: (props) => (props.paddingNone ? 0 : "8.5px"),
		paddingRight: (props) => (props.paddingNone ? 0 : "8.5px"),
		fontFamily: "-apple-system, BlinkMacSystemFont, cerebri-regular",
		fontSize: "18px",
		"& > *": { color: "white" },
		height: "35px"
	},
	underline: {
		"&:hover": {
			"&:not(.Mui-disabled)": {
				"&:before": {
					borderBottom: ".5px solid #808080 !important",
				},
			},
		},
	},
	topBottomBorder: {
		borderTop: "1px solid silver",
		borderBottom: "1px solid silver",
		borderRadius: "8px",
	},
	inputBase: {
		"&::placeholder": {
			fontSize: "18px",
			color: '#353535',
			fontFamily: "-apple-system, BlinkMacSystemFont, cerebri-regular",
			paddingBottom: "13px"
		},
		// padding: 0,
		// paddingBottom: "3px",
		paddingTop: isMobile ? "0px" : "13px",
		paddingBottom: '10px',
		height: "16px",
	},
	expertiesInputBase: {
		"&::placeholder": {
			fontSize: "18px",
			color: '#353535',
			fontFamily: "-apple-system, BlinkMacSystemFont, cerebri-regular",
			// paddingBottom: "5px"
		},
		// padding: 0,
		// paddingBottom: "5px",
		// paddingTop: "5px",
		// paddingBottom: '3px',
		// height: "10px",
		// marginTop: "10px",
		// border: "1px dashed orange"
	},
	infoIcon: {
		// border: "1px dashed orange",
		padding: 5,
		color: "#CB3A3A",
		fontSize: "30px",
	},
	editProfileInfoIcon: {
		padding: 5,
		color: "#CB3A3A",
		fontSize: "30px",
		position: "absolute",
		top: 0,
		right: 0,
		marginTop: "10px"
	}
}));
const InputField = ({ label, name, value, type, placeHolder, validation, ...props }) => {
	const classes = useStyles(props);
	const [values, setValues] = React.useState({
		showPassword: false,
	});
	const handleClickShowPassword = (event) => {
		event.preventDefault();
		setValues({ ...values, showPassword: !values.showPassword });
	};
	const handleMouseDownPassword = (event) => {
		//  console.log('s')
	};
	const isEditProfile = window.location.pathname === "/editProfile" || window.location.pathname === "/expertise" ? true : false
	return (
		<div className={classes.inputContainer} {...props}>
			{/* <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}> */}
			<InputLabel
				htmlFor={name}
				style={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
					position: "relative"
				}}
			>
				<Typography variant="subtitle2">{label}</Typography>
				{type === "password" ?
					props.error && (
						<InfoOutlined style={{ padding: 5, color: "#CB3A3A", fontSize: "30px" }} />
					) : (
						props.isExperties !== "Yes" && (
							<>
								{props.errorIconShow && (
									<div
										style={{
											color: "white",
											fontSize: "10px",
											// marginTop: "4px",
											position: "relative",
											marginRight: "15px",
										}}
									>
										{props.isValid && (
											<img
												src="assets/images/Tick.svg"
												alt="validationTick"
											/>
										)}
									</div>
								)}
								{/* <div> */}
								{props.error && (
									<InfoOutlined className={isEditProfile ? classes.editProfileInfoIcon : classes.infoIcon} />
								)}
								{/* </div> */}
							</>
						)
					)}
			</InputLabel>
			{/* </div> */}
			<Input
				onFocus={props.onFocus || console.log}
				readonly={props.readonly ? "readonly" : ''}
				placeholder={placeHolder || ""}
				id={name}
				onBlur={props.handleBlur}
				error={props.isExperties !== "Yes" && (props.error)}
				disabled={props.disabled}
				type={
					type === "password"
						? values.showPassword
							? "text"
							: "password"
						: type
				}
				maxLength={props.maxLength ? props.maxLength : "255"}
				value={value}
				fullWidth
				classes={{
					root: props.showBorder === "No" ? classes.rootWithOutBorder : classes.root,
					underline: props.showBorder === "No" ? "" : classes.underline,
					underline: props.isExperties === "Yes" ? classes.topBottomBorder : "",
					input: props.isExperties === "Yes" ? classes.expertiesInputBase : classes.inputBase,
				}}
				inputProps={{
					style: {
						textAlign: props.textAlign ? props.textAlign : "left",
						fontSize: props.fontSize ? props.fontSize : "unset",
						height: props.inputHeight ? props.inputHeight : "unset",
						color: props.color ? props.color : "white",
						textTransform: props.textTransform ? props.textTransform : "unset"
					},
					maxlength: props.maxLength ? props.maxLength : "255",
				}}
				onChange={props.onChange(name)}
				color="secondary"
				startAdornment={props.startAdornment ? props.startAdornment : null}
				endAdornment={
					props.endAdornment ? (
						props.endAdornment
					) : type === "password" ? (
						<InputAdornment position="end">
							<IconButton
								size="small"
								color="secondary"
								aria-label="toggle password visibility"
								onClick={handleClickShowPassword}
								onMouseDown={handleMouseDownPassword}
							>
								{values.showPassword ? (
									<Visibility style={{ height: "20px", width: "20px" }} />
								) : (
									<VisibilityOff style={{ height: "20px", width: "20px" }} />
								)}
							</IconButton>
						</InputAdornment>
					) : null
				}
			/>
			<div style={{ minHeight: isMobile ? "10px" : "20px" }}>
				{
					props.isExperties !== "Yes" && (
						props.error && (
							<Typography
								variant="subtitle2"
								style={{ color: "#CB3A3A", fontSize: "14px" }}
							>
								{props.error}
							</Typography>
						)
					)
				}
			</div>
		</div>
	);
};
export default React.memo(InputField);
InputField.defaultProps = {
	label: "",
	name: "",
	value: "",
	type: "text",
	validation: "",
	textAlign: "left",
	fontSize: "",
	height: "",
	error: false,
	isValid: false,
	onChange: console.log,
	errorIconShow: true,
	placeHolder: null,
	textTransform: ""
};
