export const HIDE_TIMER = "HIDE_TIMER";
export const SHOW_TIMER = "SHOW_TIMER";

export function hideTimer() {
  return {
    type: HIDE_TIMER,
  };
}

//  {TIMER : "what is the TIMER"}

export function showTimer(data) {
  return {
    type: SHOW_TIMER,
    payload: data,
  };
}
