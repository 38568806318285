import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";
import { useDispatch } from "react-redux";
import * as BaseActions from "store/actions";
import Pics from "@assets";
import {
  CardNumberElement,
  useElements,
  useStripe,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import { isMobile } from "react-device-detect";
const useStyles = makeStyles((theme) => ({
  text: {
    fontSize: 14,
  },
  fourDigitsText: {
    color: "#353535",
    fontSize: 14,
    position: "absolute",
    right: 10,
    top: 30,
  },
}));
export default function CheckoutForm({
  callMethod,
  set,
  setState,
  setIsEnabled,
}) {
  const classes = useStyles();
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const [brand, setBrand] = useState();
  const [isBrandValid, setIsBrandValid] = useState(false);
  const [isCvcValid, setIsCvcValid] = useState(false);
  const [isExpiryValid, setIsExpiryValid] = useState(false);
  useEffect(() => {
    if (callMethod) {
      handleSubmit();
      set(false);
    }
  }, [callMethod]);
  useEffect(() => {
    if (isBrandValid && isCvcValid && isExpiryValid) {
      setIsEnabled(true)
    } else {
      setIsEnabled(false)
    }
  }, [isBrandValid, isCvcValid, isExpiryValid]);
  const handleSubmit = () => {
    if (!stripe || !elements) {
      return;
    }
    const cardElement = elements.getElement(
      CardNumberElement
      //20241002 - Passing additional elements is causing this error: IntegrationError: Invalid value for elements.getElement: value should be an object. You specified: function ClientElement(_ref) {
      /*,
      CardCvcElement,
      CardExpiryElement
      */
    );
    dispatch(BaseActions.showLoading(true));
    stripe
      .createSource(cardElement)
      .then(({ source }) => {
        if (source) {
          setState({
            card_type: source.card.brand,
            exp_month: source.card.exp_month,
            exp_year: source.card.exp_year,
            last4: source.card.last4,
            token: source.id,
          });
        }
        dispatch(BaseActions.hideLoading());
      })
      .catch((error) => {
        dispatch(BaseActions.hideLoading());
      });
  };
  const inputStyles = {
    style: {
      fontFamily: "Cerebri-Regular",
      base: {
        fontSize: "18px",
        color: "white",
        "::placeholder": {
          color: "#353535",
        },
      },
      invalid: {
        color: "#353535",
      },
    },
  };
  return (
    <div>
      <Box margin={isMobile ? "20px 0 0 0" : "50px 0 0 0"}>
        <Typography variant="caption" className={classes.text}>
          Card number
        </Typography>
        <Box display="flex" justifyContent="space-between" position="relative">
          <Box flexGrow={1}>
            <CardNumberElement
              onChange={(e) => {
                setIsBrandValid((e.complete && e.error === undefined))
                setBrand(e.brand);
              }}
              options={inputStyles}
            />
          </Box>
          <img
            alt="icon"
            src={
              brand === "visa"
                ? Pics.visa
                : brand === "mastercard"
                  ? Pics.master
                  : brand === "amex"
                    ? Pics.amex
                    : brand === "discover"
                      ? Pics.discover
                      : brand === "diners"
                        ? Pics.discover
                        : brand === "unionpay"
                          ? Pics.unionPay
                          : brand === "jcb"
                            ? Pics.JCB
                            : Pics.cardIll
            }
            style={{ position: "absolute", right: 10 }}
            width={35}
            height={25}
          />
        </Box>
      </Box>
      <Box
        margin={isMobile ? "20px 0 0 0" : "30px 0 0 0"}
        display="flex"
        justifyContent="space-between"
        position="relative"
      >
        <Box width="100%" padding="0 20px 0 0">
          <Typography variant="caption" className={classes.text}>
            Expiration Date
          </Typography>
          <CardExpiryElement
            onChange={(e) => setIsExpiryValid((e.complete && e.error === undefined))}
            options={inputStyles} />
        </Box>
        <Box width="100%" padding="0 0 0 20px">
          <Typography variant="caption" className={classes.text}>
            Security code
          </Typography>
          <CardCvcElement
            onChange={(e) => setIsCvcValid((e.complete && e.error === undefined))}
            options={inputStyles}
          />
          {brand === "amex" ? (
            <Typography variant="caption" className={classes.fourDigitsText}>
              4 Digits
            </Typography>
          ) :
            <Typography variant="caption" className={classes.fourDigitsText}>
              3 Digits
            </Typography>
          }
        </Box>
      </Box>
    </div>
  );
}
