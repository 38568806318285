import axios from "axios";
import * as BaseActions from "store/actions";
//SUBMIT LOGIN ACTION NAMES
export const FETCH_INVITES = "FETCH_INVITES";
export const INVITE_IS_LOADING = "INVITE_IS_LOADING";
export const INVITE_FAILED = "INVITE_FAILED";

// Helper ACTIONS:
export const inviteLoading = (ms) => ({
  type: INVITE_IS_LOADING,
  payload: ms,
});

export const inviteFailed = (ms) => ({
  type: INVITE_FAILED,
  payload: ms,
});

export function fetchInvites() {
  const token = localStorage.getItem("JWTtoken")

  const config = {
    headers: {
      Authorization: `JWT ${token}`,
      // "Content-Type": "application/json",
    },
  };
  const request = axios.get(
    `${process.env.REACT_APP_ENV === "prod" ? process.env.REACT_APP_BASE_URL_PROD : process.env.REACT_APP_BASE_URL_TESTING}users/getmysuccessfulinvites?1`,
    config
  );
  return (dispatch) => {
    dispatch(inviteLoading(true));
    dispatch(BaseActions.showLoading(true));

    request
      .then((response) => {
        if (response.data && response.data.status === "Success") {
          console.log(`response`, response)
          dispatch({
            type: FETCH_INVITES,
            payload: response.data,
          });
          dispatch(BaseActions.hideLoading());
        } else {
          let err = Error();
          err.message = response.data.message;
          throw err;
        }
      })
      .catch((error) => {
        // dispatch(
        //   BaseActions.showAlert({ variant: "error", message: error.message })
        // );
        dispatch(BaseActions.hideLoading());
        if (error.response) {
          if (error.response.status === "404") {
            dispatch(inviteFailed(`${error.response.status} : api not found`));
          } else {
            dispatch(inviteFailed(error.response.status));
          }
          console.log(error.response);
        } else if (error.request) {
          dispatch(inviteFailed(error.request));

          console.log(error.request);
        } else {
          console.log("Error", error.message);
          dispatch(inviteFailed(error.message.status));
        }
        console.log(error);
      });
  };
}