import React from "react";
import { withRouter } from "react-router";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
// import StarRateIcon from "@material-ui/icons/StarRate";
// import Pics from "../../../../@assets";
const useStyles = makeStyles((theme) => ({
  container: {
    padding: "10px 30px",
    margin: "10px 0",
    display: 'flex',
    justifyContent: 'space-between',
    "&:hover": {
      background: "#111111",
      cursor: "pointer",
    },
  },
  dp: {
    height: 50,
    width: 50,
    borderRadius: 6,
  },
}));
function TagCard({ history, tag }) {
  const classes = useStyles();
  const handleNavigate = (path, data) => {
    history.push({ pathname: path, state: data });
  };
  return (
    <Box
      className={classes.container}
      onClick={() => handleNavigate("/profilesintag", tag._id)}
    >
      <Typography>{tag && tag._id && tag._id}</Typography>
      <Typography variant="subtitle2">{tag && tag.total} {tag && tag.total > 1 ? "profiles" : "profile"}</Typography>
    </Box>
  );
}
export default withRouter(TagCard);
