import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import logo from '../../@assets/LandingPageAssets/logo.png'
import { useHistory, useLocation } from 'react-router-dom'
import '../LandingPage/App.css'
const useStyles = makeStyles((theme) => ({
    background: {
        backgroundColor: "#080A0A",
        position: "fixed",
        padding: '0',
        margin: '0',
        top: '0',
        left: '0',
        width: "100%",
        height: "100%",
        overflow: "hidden"
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        background: '#080A0A',
        padding: '2% 2%',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        }
    },
    logo: {
        // color: 'white',
        // fontSize: '50px',
        // width: "100%",
        fontFamily: 'Cerebri-Sans',
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
            width: "100%",
        }
    },
    logoImage: {
        height: '60px',
        cursor: 'pointer'
    },
    bannerHeading: {
        color: '#31A7DB',
        // fontFamily: 'Cerebri-Sans',
        fontSize: '40px',
        fontWeight: 'bold',
        textAlign: 'center',
        margin: '0px',
        [theme.breakpoints.down('md')]: {
            fontSize: '30px',
            textAlign: 'center',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '20px',
            textAlign: 'center',
        }
    },
    Button: {
        background: 'transparent',
        border: '1px solid #fff',
        borderRadius: '6px',
        width: '272px',
        height: '53px',
        color: 'white',
        fontSize: '20px',
        cursor: 'pointer',
        '&:hover': {
            color: '#111111',
            background: '#808080'
        }
    },
    justifyCenter: {
        display: 'flex',
        justifyContent: 'center'
    },
}))
const Verify = () => {
    const classes = useStyles();
    const history = useHistory();
    const search = useLocation();
    const urlParams = new URLSearchParams(search.search);
    const token = urlParams.get('token');
    const host = `${process.env.REACT_APP_ENV === "prod" ? process.env.REACT_APP_BASE_URL_PROD : process.env.REACT_APP_BASE_URL_TESTING}`
    const url = `${host}users/verify?token=${token}`
    // const url = ""
    return (
        <div className={classes.background}>
            <div className={classes.header}>
                <div className={classes.logo}>
                    <img src={logo} alt="logo" className={classes.logoImage} onClick={() => history.push('/')} />
                </div>
            </div>
            <div>
                <div className="font-face-bold" style={{ marginTop: '5%' }}>
                    {/* <h1 className={classes.bannerHeading}>Email Verified</h1> */}
                </div>
                <div>
                    <iframe title="verify" className="font-face-bold" src={url} scrolling="no" style={{ width: "100%", border: "none", height: '450px' }}>
                    </iframe>
                </div>
                <div className={classes.justifyCenter}>
                    <div className="font-face-regular">
                        <button className={classes.Button} onClick={() => history.push("/")}>Go to Home Page</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Verify
