import axios from "axios";
import * as BaseActions from "store/actions";
//SUBMIT LOGIN ACTION NAMES
export const FETCH_PROFILE = "FETCH_PROFILE";
export const PROFILE_IS_LOADING = "PROFILE_IS_LOADING";
export const PROFILE_FAILED = "PROFILE_FAILED";
//SUBMIT review ACTION NAMES
export const FETCH_REVIEWS = "FETCH_REVIEWS";
export const REVIEWS_IS_LOADING = "REVIEWS_IS_LOADING";
export const REVIEWS_FAILED = "REVIEWS_FAILED";
export const RESET_PROFILE = 'RESET_PROFILE'

export const GET_RATES = "GET_RATES";
export const GET_RATES_IS_LOADING = "GET_RATES_IS_LOADING";
export const GET_RATES_FAILED = "GET_RATES_FAILED";

// Helper ACTIONS:
export const profileLoading = (ms) => ({
  type: PROFILE_IS_LOADING,
  payload: ms,
});

export const profileFailed = (ms) => ({
  type: PROFILE_FAILED,
  payload: ms,
});

export const resetProfile = () => ({
  type: RESET_PROFILE,
})

export const getRatesLoading = (ms) => ({
  type: GET_RATES_IS_LOADING,
  payload: ms,
});

export const getRatesFailed = (ms) => ({
  type: GET_RATES_FAILED,
  payload: ms,
});

// Helper ACTIONS:
export const reviewsLoading = (ms) => ({
  type: REVIEWS_IS_LOADING,
  payload: ms,
});

export const reviewsFailed = (ms) => ({
  type: REVIEWS_FAILED,
  payload: ms,
});


export function getRates(data) {
  var cancel = "";
  const token = localStorage.getItem("JWTtoken");
  console.log('ge-tr')
  const headers = {
    Authorization: `JWT ${token}`,
    // "Content-Type": "application/json",
  };
  const request = axios({
    method: "post",
    url: `${process.env.REACT_APP_ENV === "prod" ? process.env.REACT_APP_BASE_URL_PROD : process.env.REACT_APP_BASE_URL_TESTING}appointments/get_rates`,
    data,
    cancelToken: new axios.CancelToken((c) => {
      cancel = c;
    }),
    headers: headers,
  });
  return (dispatch) => {
    dispatch(getRatesLoading(true));
    dispatch(BaseActions.showLoading(true));

    request
      .then((response) => {
        if (response.data && response.status === 200) {
          dispatch({
            type: GET_RATES,
            payload: response.data,
          });
          dispatch(BaseActions.hideLoading());
        } else {
          let err = Error();
          err.message = response.data.message;
          throw err;
        }
      })
      .catch((error) => {
        // dispatch(
        //   BaseActions.showAlert({ variant: "error", message: error.message })
        // );
        if (axios.isCancel(error)) {
          dispatch(getRatesFailed(error));
          return;
        }
        if (error.response) {
          if (error.response.status === "404") {
            dispatch(getRatesFailed(`${error.response.status} : api not found`));
          } else {
            dispatch(getRatesFailed(error.response.status));
          }
          console.log(error.response);
        } else if (error.request) {
          dispatch(getRatesFailed(error.request));

          console.log(error.request);
        } else {
          console.log("Error", error.message);
          dispatch(getRatesFailed(error.message));
        }
        console.log(error);
      });
  };
}

export function fetchProfile(id) {
  // let data = { user_id: id };
  const token = localStorage.getItem("JWTtoken");

  // console.log(data, "==>params", token, "<== token");

  const config = {
    headers: {
      Authorization: `JWT ${token}`,
      // "Content-Type": "application/json",
    },
  };
  const request = axios.get(
    `${process.env.REACT_APP_ENV === "prod" ? process.env.REACT_APP_BASE_URL_PROD : process.env.REACT_APP_BASE_URL_TESTING}users/getprofilefromid?uid=${id}`,
    config
  );
  return (dispatch) => {
    dispatch(profileLoading(true));
    dispatch(BaseActions.showLoading(true));

    request
      .then((response) => {
        if (response.data && response.data.status === "Success") {
          dispatch({
            type: FETCH_PROFILE,
            payload: response.data,
          });
          dispatch(BaseActions.hideLoading());
        } else {
          let err = Error();
          err.message = response.data.message;
          throw err;
        }
      })
      .catch((error) => {
        // dispatch(
        //   BaseActions.showAlert({ variant: "error", message: error.message })
        // );
        if (error.response) {
          if (error.response.status === "404") {
            dispatch(profileFailed(`${error.response.status} : api not found`));
          } else {
            dispatch(profileFailed(error.response.status));
          }
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          dispatch(profileFailed(error.request));

          console.log(error.request);
        } else {
          console.log("Error", error.message);
          dispatch(profileFailed(error.message));
        }
        console.log(error);
      });
  };
}

export function fetchProfileByRefferal(id) {
  let data = { referrer: id };
  const token = localStorage.getItem("JWTtoken");

  // console.log(data, "==>params", token, "<== token");

  const config = {
    headers: {
      Authorization: `JWT ${token}`,
      // "Content-Type": "application/json",
    },
  };
  // const request = axios.get(
  //   `${process.env.REACT_APP_ENV === "prod" ? process.env.REACT_APP_BASE_URL_PROD : process.env.REACT_APP_BASE_URL_TESTING}users/getUserByRef`,
  //   config,

  // );
  const request = axios({
    method: "post",
    url: `${process.env.REACT_APP_ENV === "prod" ? process.env.REACT_APP_BASE_URL_PROD : process.env.REACT_APP_BASE_URL_TESTING}users/getUserByRef`,
    data,
    headers: {
      Authorization: `JWT ${token}`,
      // "Content-Type": "application/json",
    },
  });
  return (dispatch) => {
    dispatch(profileLoading(true));
    dispatch(BaseActions.showLoading(true));

    request
      .then((response) => {
        if (response.data && response.data.status === "Success") {
          dispatch({
            type: FETCH_PROFILE,
            payload: response.data,
          });
          dispatch(BaseActions.hideLoading());
        } else {
          let err = Error();
          err.message = response.data.message;
          throw err;
        }
      })
      .catch((error) => {
        // dispatch(
        //   BaseActions.showAlert({ variant: "error", message: error.message })
        // );
        if (error.response) {
          if (error.response.status === "404") {
            dispatch(profileFailed(`${error.response.status} : api not found`));
          } else {
            dispatch(profileFailed(error.response.status));
          }
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          dispatch(profileFailed(error.request));

          console.log(error.request);
        } else {
          console.log("Error", error.message);
          dispatch(profileFailed(error.message));
        }
        console.log(error);
      });
  };
}
export function fetchReviews(id) {
  let data = { user_id: id };
  const token = localStorage.getItem("JWTtoken");

  console.log(data, "==>params", token, "<== token");

  const config = {
    headers: {
      Authorization: `JWT ${token}`,
      // "Content-Type": "application/json",
    },
  };
  const request = axios.post(
    `${process.env.REACT_APP_ENV === "prod" ? process.env.REACT_APP_BASE_URL_PROD : process.env.REACT_APP_BASE_URL_TESTING}ratings/get-ratings`,
    data,
    config
  );
  return (dispatch) => {
    dispatch(reviewsLoading(true));
    dispatch(BaseActions.showLoading(true));

    request
      .then((response) => {
        if (response.data && response.data.status === "Success") {
          dispatch({
            type: FETCH_REVIEWS,
            payload: response.data,
          });
          dispatch(BaseActions.hideLoading());
        } else {
          let err = Error();
          err.message = response.data.message;
          throw err;
        }
      })
      .catch((error) => {
        // dispatch(
        //   BaseActions.showAlert({ variant: "error", message: error.message })
        // );
        if (error.response) {
          if (error.response.status === "404") {
            dispatch(reviewsFailed(`${error.response.status} : api not found`));
          } else {
            dispatch(reviewsFailed(error.response.status));
          }
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          dispatch(reviewsFailed(error.request));

          console.log(error.request);
        } else {
          console.log("Error", error.message);
          dispatch(reviewsFailed(error.message));
        }
        console.log(error);
      });
  };
}
