import React from "react";

import { Box, InputAdornment } from "@material-ui/core";
import InputField from "@base/InputField";
import { isAndroid } from "react-device-detect";
const PriceRange = ({ min, max, setMin, setMax }) => {
  const test = "$";
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="end"
      width="100%"
      alignItems="center"
    >
      <div style={{ width: "45%", color: "#FFFFFF", display: "flex" }}>
        <InputField
          textAlign="center"
          value={"$" + min}
          onChange={(name) => (e) => {
            const test = e.target.value.substring(1);
            test > -1 &&
              test < 1000 &&
              test < max &&
              test !== "" &&
              setMin(parseInt(test));
            test === "" && setMin(0);
          }}
          startAdornment={
            <InputAdornment position="start">
              <div
                style={{
                  color: "#808080",
                  fontSize: 14,
                  marginTop: isAndroid ? -5 : 5,
                  marginLeft: -10,
                }}
              >
                Min
              </div>
            </InputAdornment>
          }
        />
      </div>
      <div style={{ width: "45%", color: "#FFFFFF", display: "flex" }}>
        <InputField
          textAlign="center"
          value={"$" + max}
          onChange={(name) => (e) => {
            const test = e.target.value.substring(1);
            test > -1 &&
              test < 1000 &&
              test > min &&
              test !== "" &&
              setMax(parseInt(test));
            test === "" && setMax(0);
          }}
          startAdornment={
            <InputAdornment position="start">
              <div
                style={{
                  color: "#808080",
                  fontSize: 14,
                  marginTop: isAndroid ? -5 : 5,
                  marginLeft: -10,
                }}
              >
                Max
              </div>
            </InputAdornment>
          }
        />
      </div>
    </Box>
  );
};

export default React.memo(PriceRange);
