import React from 'react'
import { List, Typography } from '@material-ui/core'
import InfiniteScroll from 'react-infinite-scroll-component'
import { makeStyles } from '@material-ui/core'
import QuarterListItem from './QuartersListItem'
import { isAndroid, isIOS, isMobile } from "react-device-detect";
const useStyles = makeStyles((theme) => ({
  list: {
    position: 'relative',
    height: '71vh',
    overflowY: 'auto',
    paddingLeft: '1rem',
    paddingRight: '1rem',
    [theme.breakpoints.down('xs')]: {
      // remove for ios devices issue of padding in history tab
      // height: '80vh',
      height: isMobile && isIOS ? "87vh" : "80vh",
    },
  },
  noListWrapper: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))

export default function QuartersList({ quarters, setShowReceipt, isHosting, quarterReceiptRef, getHistory }) {
  const classes = useStyles()

  return (
    <List id='history-list' className={classes.list}>
      {
        quarters.appointments.length
          ? <InfiniteScroll
            dataLength={quarters.total}
            next={getHistory.bind(this, isHosting ? 1 : 0)}
            hasMore={quarters.page < quarters.totalPages}
            scrollableTarget='history-list'
          >
            {
              quarters.appointments.map((quarter) => (
                <QuarterListItem
                  key={quarter._id}
                  quarter={quarter}
                  isHosting={isHosting}
                  setShowReceipt={setShowReceipt}
                  quarterReceiptRef={quarterReceiptRef}
                />
              ))
            }
          </InfiniteScroll>
          : <div className={classes.noListWrapper}>
            <Typography variant='subtitle1' style={{ color: '#808080' }}>No Quartrs to show</Typography>
          </div>
      }
    </List>
  )
}
