import * as Actions from "../../actions";
const initialState = {};

export const UserReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.UPDATE_USER_DATA: {
      state = { ...state, ...action.payload }
      return state;
    }
    case Actions.GET_USER_DATA: {
      let data = JSON.parse(localStorage.getItem("quarterlyUser"));
      if (data && data.data) {
        state = data.data;
        return state;
      } else {
        return state;
      }
    }

    case Actions.SET_USER_BANKING_DATA: {
      return { ...state, bank: action.payload }
    }

    case Actions.REMOVE_USER_BANK_ACCOUNT: {
      return { ...state, bank: undefined }
    }

    case Actions.ADD_RATING: {
      return { ...state, rate_your_call: [...state.rate_your_call, action.payload] }
    }

    case Actions.REMOVE_RATING: {
      return { ...state, rate_your_call: state.rate_your_call.filter((rating) => rating.appointment_id !== action.payload.appointment_id) }
    }

    case Actions.RESET_RATINGS: {
      return { ...state, rate_your_call: [] }
    }

    default: {
      return state;
    }
  }
};
