import React from "react";
import { Box, Typography } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import pics from "@assets";
//// functions need to use react with our component
import { useDispatch, useSelector } from "react-redux";
import reducer from "../signup/screens/signupForm/store/reducers";
import withReducer from "store/withReducer";
// import * as Actions from "../signup/screens/signupForm/store/actions";
import { ChevronRight } from "@material-ui/icons";
import * as Actions from "./store/actions";
// import * as BaseActions from "store/actions";
import { Button } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
    container: {
        [theme.breakpoints.up("sm")]: {
            paddingBottom: "100px"
        },
        overflowY: "scroll",
        paddingBottom: "0"
    },
    illustration: {
        objectFit: "contain",
        margin: "auto",
        width: "70%",
        height: "100%",
    },
    imageContainer: {
        display: "flex",
        alignItems: "center",
        height: "400px",
        width: "100%",
        margin: "10px 0",
        // border: "1px dashed orange"
    },
    button: {
        width: "45%",
    },
    btns: {
        cursor: "pointer"
    },
    text: {
        fontSize: "16px",
        textAlign: "center"
    },
    profileBtn: {
        cursor: "pointer",
        borderRadius: "6px",
        width: "100%",
        height: "70px",
        background: "#111111 0% 0% no-repeat padding-box",
        padding: "10px",
        display: "flex",
        alignItems: "center",
        // border: "1px dashed orange"
    },
    avatar: {
        width: "50px",
        height: "50px",
    },
    profileText: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        paddingLeft: "10px",
        width: "100%",
    },
    rating: {
        fontSize: "14px"
    },
    name: {
        fontSize: "16px"
    },
    icon: {
        color: "#FFFFFF",
        fontSize: "30px"
    },
    bottomBtn: {
        marginTop: "20px",
        width: "100%"
    }
}));
const ProfileInvite = (props) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    React.useEffect(() => {
        dispatch(Actions.fetchProfile(window.location.pathname.split('/').pop()));
    }, [dispatch]);
    // const userData = useSelector(({ Profile }) => Profile.FetchProfileReducer);
    // console.log({ userData })
    const handleClick = () => {
        const isLoggedIn = JSON.parse(localStorage.getItem("quarterlyUser"));
        const token = localStorage.getItem("JWTtoken");
        if (isLoggedIn && token) {
            // props.history.push({ pathname: `/userprofile/${u._id}` })
            console.log({ fromInvite: "goto profile" })
        } else {
            props.history.push({ pathname: `/login`, state: { user: "user" } })
        }
    }
    const handleClickHome = () => {
        const isLoggedIn = JSON.parse(localStorage.getItem("quarterlyUser"));
        const token = localStorage.getItem("JWTtoken");
        if (isLoggedIn && token) {
            props.history.push("/home");
        } else {
            props.history.push({ pathname: `/login`, state: { user: "user" } })
        }
    }
    return (
        <Box className={classes.container}>
            <Box
                display="flex"
                flexDirection="column"
                width="100%"
                padding="10px 60px 0px 60px"
            // position="absolute"
            >
                <Box className={classes.imageContainer}>
                    <img
                        alt="Profile invite"
                        variant="square"
                        // src={pics.profileInvite}
                        src={"../assets/backgrounds/ProfileInvite.jpg"}
                        className={classes.illustration}
                    />
                </Box>
            </Box>
            <Box margin="0 30px 0 30px">
                <Typography className={classes.text} variant="subtitle2">
                    It seems you haven't booked this Quartr with Taylor Kirst. Go to Taylor Kirst's profile to book a Quartr
                </Typography>
            </Box>
            <Box margin="20px 30px 0 30px">
                <Box onClick={handleClick} className={classes.profileBtn}>
                    <img
                        alt="Profile img"
                        // variant="square"
                        // src={pics.profileInvite}
                        src={"../assets/images/avatar.png"}
                        className={classes.avatar}
                    />
                    <Box className={classes.profileText}>
                        <Box>
                            <Typography className={classes.name} variant="subtitle1">
                                Taylor Kirst
                            </Typography>
                            <Typography className={classes.rating} variant="subtitle2">
                                5.0 (245 reviews)
                            </Typography>
                        </Box>
                        <ChevronRight className={classes.icon} />
                    </Box>
                </Box>
            </Box>
            <Box margin="20px 30px 0 30px">
                <Button onClick={handleClickHome} className={classes.bottomBtn}>Home</Button>
            </Box>
        </Box>
    );
};
export default withReducer("ProfileInvite", reducer)(withRouter(ProfileInvite));
