import React, { useEffect, useState } from "react";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";
import CountdownTimer from "./subComponents/countdownTimer";
import isMongoId from "validator/lib/isMongoId";
import useDeepCompareEffect from "use-deep-compare-effect";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import * as Sentry from '@sentry/react';
import "./index.css";
// import SocketClass from "@utils/socketService";
import * as BaseActions from "store/actions";
import * as Actions from "./store/actions";
import { useDispatch, useSelector } from "react-redux";
import withReducer from "store/withReducer";
import reducer from "./store/reducers";
// import Status from "./subComponents/status";
// import Publisher from "./subComponents/publisher";
// import Subscriber from "./subComponents/subscriber";
import { withRouter } from "react-router";
import pics from "@assets";
import moment from "moment";
import LoadingImage from "@assets/Loading.jpg";
// import { ImageAspectRatioOutlined } from "@material-ui/icons";
let callDuration = 15;
var session = null;
var otPublisher = null;
var globalToken = null;
var previousInterval = null;
var audioInputDevices;
var videoInputDevices;
function addMinutes(date, minutes) {
	// alert(date)
	return new Date(new Date(date).getTime() + minutes * 60000).toISOString();
}
// this is raw call request for beat implement of timer setting.
const callRequest = (success, fails) => {
	const token = localStorage.getItem("JWTtoken");
	// console.log(data, "==>params", token, "<== token");
	const config = {
		headers: {
			Authorization: `JWT ${token}`,
			// "Content-Type": "application/json",
		},
	};
	const request = axios.post(
		`${process.env.REACT_APP_ENV === "prod" ? process.env.REACT_APP_BASE_URL_PROD : process.env.REACT_APP_BASE_URL_TESTING}appointments/get-appointment-status`,
		{},
		config
	);
	request
		.then((response) => {
			if (response.data && response.data.status === "Success") {
				console.log(response.data);
				success(response.data);
			} else {
				let err = Error();
				err.message = response.data.message;
				throw err;
			}
		})
		.catch((err) => fails(err));
};

const VideoCall = (props) => {
	const dispatch = useDispatch();
	// let otPublisher = null;
	let otSubscriber = React.useRef();
	const otSession = React.useRef();
	const isMutedRef = React.useRef(false)
	const timeoutRef = React.useRef(null)
	// const [firstRender, setFirstRender] = React.useState(true);
	const [tryCounts, setTryCounts] = React.useState(0);
	const [audio, setAudio] = React.useState(true);
	const [timer, setTimer] = React.useState({
		timer: "00:00",
		stamp: new Date(),
	});
	// const [isStop, setIsStop] = React.useState(false);
	const [validSession, setValidSession] = React.useState(false);
	const [startPublishing, setStartPublishing] = React.useState(true);
	const [isPortrait, setIsPortrait] = React.useState(true);
	const [connected, setConnected] = React.useState(true);
	const [upcomingId, setUpcomingId] = React.useState(null);
	const [currentState, setCurrentState] = React.useState("connected");
	const [matchedId, setMatchedId] = React.useState("");
	const [invalidId, setInvalidId] = React.useState(false);
	const [startTimer, setStartTimer] = React.useState(false);
	const [onWaiting, setOnWaiting] = React.useState(false);
	const [startingTime, setStartingTime] = React.useState(null);
	const isSmallScreen = useMediaQuery('(max-width:320px)');
	const [audioState, setAudioState] = React.useState("");
	const [subscriberRef, setSubscriberRef] = React.useState(null)
	const [sessionData, setSessionData] = React.useState({
		roomId: "",
		hostToken: "",
		attendeeToken: "",
		isHost: "",
		isMine: "",
		call_at: null,
	});
	const useStyles = makeStyles((theme) => ({
		root: {
			// color: "white",
			position: "absolute",
			top: 0,
			left: 0,
			width: "100%",
			height: "100%",
			// backgroundColor: "white",
			overflow: "hidden",
			[theme.breakpoints.down("xs")]: {
				border: "2px solid silver",
			}
			// borderRadius: 7,
		},
		container: {
			position: "relative",
			top: 0,
			left: 0,
			width: "100%",
			height: "100%",
			// borderRadius:10
		},
		localVideo: {
			position: "absolute",
			zIndex: 1,
			top: (props) => (props.connected ? 20 : 0),
			right: (props) => (props.connected ? 20 : 0),
			height: (props) => (props.connected ? 180 : "100%"),
			width: (props) => (props.connected ? 130 : "100%"),
			[theme.breakpoints.up('md')]: {
				width: (props) => (props.connected ? 180 : '100%'),
				height: (props) => (props.connected ? 130 : '100%'),
			},
			// border: (props) => (props.connected ? "1px solid lightGrey" : "none"),
			borderRadius: (props) => (props.connected ? 10 : 0),
			transition: "all .2s ease-in-out",
			"& * ": {
				borderRadius: (props) => (props.connected ? 10 : 0),
				backgroundSize: "cover !important",
			},
			// "& > .OT_video-poster":{
			//   backgroundSize:"cover"
			// }
		},
		localVideo2: {
			display: (props) => (props.onWaiting ? "none" : "flex"),
			position: "absolute",
			alignItems: "center",
			textAlign: "center",
			zIndex: 1,
			top: (props) => (props.connected ? 20 : 0),
			right: (props) => (props.connected ? 20 : 0),
			height: (props) => (props.connected ? 180 : "100%"),
			width: (props) => (props.connected ? 130 : "100%"),
			border: (props) => (props.connected ? "1px solid lightGrey" : "none"),
			borderRadius: (props) => (props.connected ? 10 : 0),
			transition: "all .25s ease-in-out",
			"& * ": {
				borderRadius: (props) => (props.connected ? 10 : 0),
			},
			[theme.breakpoints.up('md')]: {
				width: (props) => (props.connected ? 180 : '100%'),
				height: (props) => (props.connected ? 130 : '100%'),
			},
		},
		remoteVideo: {
			visibility: ({ currentState }) =>
				currentState === "connected" ? "visible" : "hidden",
			position: "absolute",
			top: 0,
			right: 0,
			height: "100%",
			width: "100%",
			// display:props => props.isPortrait?"none ":'block ',
			"& .OT_fit-mode-cover .OT_video-element": {
				objectFit: (props) =>
					props.isPortrait ? "contain !important" : "cover !important",
			},
			// "& .OT_video-element":{
			//   objectFit :props => props.isPortrait?"contain !important":'cover !important'
			// },
			// "& video":{
			//   display :props => props.isPortrait?"none !important":'block !important'
			// }
		},
		remoteStatus: {
			visibility: ({ currentState }) =>
				currentState !== "connected" ? "visible" : "hidden",
			position: "absolute",
			top: 0,
			right: 0,
			height: "100%",
			width: "100%",
			display: "flex",
			alignItems: "center",
			justifyContent: "space-around",
		},
		remoteAudioStatus: {
			position: "absolute",
			top: "25%",
			right: 0,
			height: "100%",
			width: "100%",
			display: "flex",
			alignItems: "center",
			justifyContent: "space-around",
			color: "#fff"
		},
		audioText: {
			padding: "10px 15px",
			borderRadius: 25,
			background: "#000",
			opacity: 0.6,
			fontSize: '1.2rem',
			fontFamily: 'cerebri-light',
		},
		videoControls: {
			position: "absolute",
			bottom: 20,
			left: isSmallScreen ? "unset" : "calc(50% - 175px)",
			width: "100%",
			maxWidth: "350px",
			zIndex: 9,
			// border: "1px solid black",
			display: "flex",
			alignItems: "center",
			justifyContent: "space-around",
			// backgroundColor: "black",
			padding: 10,
			// "& img": {
			//   cursor: "pointer",
			//   backgroundColor: "black",
			//   padding: "10px 1px",
			//   height: "40px",
			//   width: "40px",
			// },
		},
		controlButton: {
			cursor: "pointer",
			backgroundColor: "#000000b5",
			padding: "10px 1px",
			height: "50px",
			width: "50px",
			display: "flex",
			alignItems: "center",
			justifyContent: "space-around",
			borderRadius: 7,
			"& img": {
				cursor: "pointer",
				// backgroundColor: "black",
				padding: "10px 1px",
				// height: "40px",
				// width: "40px",
			},
		},
		timer: {
			color: "#111111",
			"& div": {
				// backgroundColor: "#FFFFFF",
				color: "#111111",
				height: "50px",
				width: "100px",
				fontSize: "15px",
				margin: "0px 20px",
				borderRadius: 7,
				display: "flex",
				alignItems: "center",
				justifyContent: "space-around",
				fontFamily: "Cerebri-Regular",
			},
		},
		userInBackground: {
			width: "100%",
			height: "50vh",
			position: "fixed",
			backgroundColor: "#080A0A",
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			zIndex: 100,
			bottom: "15vh"
		},
		powerImg: {
			width: "100px",
			height: "100px"
		}
	}));
	const joinedCall = () => {
		const token = localStorage.getItem("JWTtoken");
		const config = {
			headers: {
				Authorization: `JWT ${token}`,
			},
		};
		const request = axios.post(
			`${process.env.REACT_APP_ENV === "prod" ? process.env.REACT_APP_BASE_URL_PROD : process.env.REACT_APP_BASE_URL_TESTING}appointments/joined-appointment`,
			{
				_id: quartrData.data.data._id,
				host: sessionData.isHost ? 1 : 0
			},
			config
		);
		request
			.then((response) => {
				console.log(response.data);
				if (response.data && response.data.status === "Success") {
					console.log(response.data);
				} else {
					let err = Error();
					err.message = response.data.message;
					throw err;
				}
			})
			.catch((err) => console.log(err));
	};
	const classes = useStyles({
		connected,
		currentState,
		isPortrait,
		startPublishing,
		onWaiting,
	});
	let { OT } = window;
	let apiKey = "47056654";
	let quartrData = useSelector(({ Quartr }) => Quartr.FetchQuartrReducer);
	React.useEffect(() => {
		dispatch(BaseActions.updateLayout({ bottomNav: false, fullScreen: true }));
		dispatch(BaseActions.hideMessage());
		const {
			match: { params },
		} = props;
		console.log(params.appointment_id);
		setMatchedId(params.appointment_id);
	}, []);
	// requesting call for fetch quartr from id 'url param'
	React.useEffect(() => {
		// check is this is mongo id then we pass it to our request other wise we will throw error.
		if (matchedId) {
			if (isMongoId(matchedId)) {
				// dispatch(
				//   BaseActions.updateLayout({ bottomNav: false, fullScreen: false })
				// );
				matchedId && dispatch(Actions.fetchQuartr(matchedId));
			} else {
				setInvalidId("Quartr not found!");
			}
		}
	}, [matchedId]);
	// beat implemented here for 15 minutes timer manager
	const [hostUsername, setHostUsername] = React.useState("");
	const [attendeeUsername, setAttendeeUsername] = React.useState("");

	React.useEffect(() => {
		if (tryCounts > 0 && tryCounts < 4) {
			callRequest(handleSuccess, handleFails);
			// alert("api called");
		}
	}, [tryCounts]);
	// call setup goes through here.
	useDeepCompareEffect(() => {
		console.log({ quartrData });
		let uId = localStorage.getItem("upcomingQuartr");
		if (uId) {
			setUpcomingId(uId);
		}
		// checking quartr state whats the actual state and we throwing error
		if (quartrData.data && quartrData.data.status === "Success") {
			if (Object.keys(quartrData.data.data).length !== 0) {
				if (quartrData.data.data.remaining_seconds === -1) {
					setInvalidId("your quartr has been ended! :(");
					props.history.push('/home');
					return;
				}
				if (quartrData.data.data) {
					if (!quartrData.data.data.is_mine && props.match.params.appointment_id !== quartrData.data?.data?.waiting_appointment?._id) {
						setInvalidId("You are not authorized to join this quartr! :(");
						return;
					}
				}
				if (!String(quartrData.data.data.room_id).trim()) {
					setInvalidId("Your quartr not started yet :( ");
					// setOnWaiting(true);
					return;
				}
				// if user has a valid quartr then we need to set session data in our state.
				setSessionData({
					roomId: quartrData.data.data.room_id,
					hostToken: quartrData.data.data.host_token,
					attendeeToken: quartrData.data.data.attendee_token,
					isHost: quartrData.data.data.is_host,
					isMine: quartrData.data.data.is_mine,
					call_at: quartrData.data.data.call_at,
				});
				// setValidSession(true)
			} else {
				setInvalidId("Quartr Expired :( ");
			}
		}

		if (quartrData && quartrData.data && quartrData.data.data && quartrData.data.data.host) {
			if (quartrData.data.data.host.enabledUsername) {
				setHostUsername(quartrData.data.data.host.username)
			}
			else {
				setHostUsername(quartrData.data.data.host.enabledUsername ? quartrData.data.data.host.username : quartrData.data.data.host.full_name)
			}
		}

		if (quartrData && quartrData.data && quartrData.data.data && quartrData.data.data.attendee) {
			if (quartrData.data.data.attendee.enabledUsername) {
				setAttendeeUsername(quartrData.data.data.attendee.username)
			}
			else {
				setAttendeeUsername(quartrData.data.data.attendee.enabledUsername ? quartrData.data.data.attendee.username : quartrData.data.data.attendee.full_name)
			}
		}

	}, [quartrData]);

	// if (quartrData && quartrData.data) {
	// 	console.log(quartrData.data.data)
	// }
	// this will call after stating our session data to state.
	useDeepCompareEffect(() => {
		let api_key = apiKey;
		const {
			roomId,
			attendeeToken,
			hostToken,
			isHost,
			isMine,
			call_at,
		} = sessionData;
		var timer;
		// if call not stated and its on waiting mode then we setting on waiting mode turn on.
		if (call_at > new Date().toISOString()) {
			let diff = new Date(call_at).getTime() - new Date().getTime();
			setInvalidId(
				"Your quartr will start at " + new Date(quartrData.data.data.call_at)
			);
			timeoutRef.current = setTimeout(() => window.location.reload(), diff)
			// waiting mode turn on.
			setOnWaiting(true);
			// timeout method so we can turn off onwaiting mode when remaining time ended
			timer = setTimeout(() => {
				if (roomId && attendeeToken && hostToken) {
					if (isHost) {
						initializeSession(roomId, hostToken, api_key);
					} else {
						initializeSession(roomId, attendeeToken, api_key);
					}
				}
				setInvalidId(false);
			}, diff);
			return () => { console.log('cleartimeout'); clearTimeout(timer) };
		}
		// here is when we go to init our final call quartr, if host then we pass host token and if attendee then we pass attendee token.
		if (roomId && attendeeToken && hostToken) {
			if (isHost) {
				initializeSession(roomId, hostToken, api_key);
			} else {
				initializeSession(roomId, attendeeToken, api_key);
			}
		}
	}, [sessionData]);
	React.useEffect(() => () => timeoutRef.current && clearTimeout(timeoutRef.current), [])
	// this is error handler for consoling logs.
	const handleError = (error) => {
		setCurrentState(error.message);
		OT.getDevices(function (error, devices) {
			audioInputDevices = devices.filter(function (element) {
				return element.kind == 'audioInput' && !!element.deviceId;
			});
			videoInputDevices = devices.filter(function (element) {
				return element.kind == 'videoInput' && !!element.deviceId;
			});
			if (!audioInputDevices.length && !videoInputDevices.length) {
				setCurrentState('Your audio/video source is disabled')
			} else if (!audioInputDevices.length) {
				setCurrentState('Your audio source is disabled')
			} else if (!videoInputDevices.length) {
				setCurrentState('Your video source is disable')
			}
		});
		if (error.title === "RumorSocket") {
			console.log("rumor error socket");
		}
		if (
			error.message ===
			"Timed out while waiting for the Rumor socket to connect."
		) {
			console.log("rumor error error ====> rumor socket");
			// setting our current state for error or others
			setCurrentState("your network is not stable! please refresh your page");
		}
		if (error.code === 1006) {
			setCurrentState(sessionData.isHost
				? (quartrData.data.data.attendee.enabledUsername ? (quartrData.data.data.attendee.username) : (quartrData.data.data.attendee.full_name)) + ' closed Quartrly. Please standby while they reconnect'
				: (quartrData.data.data.host.enabledUsername ? (quartrData.data.data.host.username) : (quartrData.data.data.host.full_name)) + ' closed Quartrly. Please standby while they reconnect');
		}
		console.log(error);
	};
	const handleErrorPublish = (error) => {

		if (error) {
			setCurrentState(error.message);
			if (error.title === "RumorSocket") {
				console.log("rumor socket error", error);
			}
			if (
				error.message ===
				"Timed out while waiting for the Rumor socket to connect."
			) {
				console.log("rumor error socket", error);
				// handleRefresh();
				setCurrentState("your network is not stable! please refresh your page");
			}
			console.log(error.message);
		} else {
			setStartPublishing(true);
		}
		// setCurrentState("connected");
	};
	const handleSuccess = ({ data }) => {
		if (data.current_appointment) {
			setStartingTime(data.current_appointment.remaining_seconds);
		}
		if (data.waiting_appointment) {
			setUpcomingId(data.waiting_appointment._id);
			localStorage.setItem("upcomingQuartr", data.waiting_appointment._id);
		}
	};
	const handleFails = (error) => {
		console.log(error);
		setTimeout(() => {
			callRequest(handleSuccess, handleFails);
		}, 500);
	};

	useEffect(() => {
		if (subscriberRef) {

			//  these are the core event you can find on documentation each of these
			subscriberRef.on("videoDisabled", (e) => {
				setCurrentState("User disable his audio/video source.");
				subscriberRef.setStyle("videoDisabledDisplayMode", "on");
				!sessionData.isHost
					? subscriberRef.setStyle(
						"backgroundImageURI",
						quartrData.data.data.host.profile_media_urls[0]
					)
					: subscriberRef.setStyle(
						"backgroundImageURI",
						quartrData.data.data.attendee.profile_media_urls[0]
					);
				otSubscriber = subscriberRef;
			});
			subscriberRef.on("videoEnabled ", () => {
				setCurrentState("connected");
			});
			subscriberRef.on("VideoEnabledChangedEvent", (event) => {
				console.log("event video chang", event);
			});
			subscriberRef.on("StreamPropertyChangedEvent", (event) => {
				console.log("stream changes", event.stream.hasAudio, event);
				if (event.connection.connectionId !== session.connection.connectionId && !event.stream.hasAudio) {
					setCurrentState("User disable his audio source.");
				}
			});
			subscriberRef.on("audioBlocked", () => {
				setCurrentState("User disable his audio source.");
				console.log('audio disabled')
			});
			subscriberRef.on("audioUnblocked", () => {
				setCurrentState("connected");
			});
			subscriberRef.on("connected", () => {
				Sentry.captureMessage(subscriberRef.getAudioVolume())
				console.log(subscriberRef.getAudioVolume(), 'SUB <<<AUDIO VOLUME')
				subscriberRef.setAudioVolume(80)
				setTimeout(() => {
					subscriberRef.setAudioVolume(100)
				}, 1000)
				console.log(subscriberRef, '<<<SUB REF')
				console.log("Connected ...SUBSCRIBER")
				setCurrentState("connected");
			});
			subscriberRef.on("disconnected", (event) => {
				console.log(event);
				setCurrentState("Disconnected due to some network or any other issue");
			});
			subscriberRef.on("videoDimensionsChanged", (event) => {
				// setCurrentState("user disconnected");
				if (event.newValue.width > event.oldValue.width) {
					//landscape
					setIsPortrait(false);
					document
						.getElementById("subscriber")
						.subscriberRef.setStyle({ fitMode: "cover" });
				} else {
					//portrait
					setIsPortrait(true);
					subscriberRef.setStyle({ fitMode: "contain" });
				}
				// alert('dimensions changed')
				console.log(event);
			});
			subscriberRef.on("videoDisableWarning", (event) => {
				setCurrentState("Weak network connection");
				// alert('dimensions changed')
				console.log(event);
			});
			subscriberRef.on("videoDisableWarning", (event) => {
				setCurrentState("connected");
				// alert('dimensions changed')
				console.log(event);
			});

		}
	}, [subscriberRef])
	// creating suscriber
	const createSubscriber = (event, session) => {
		setSubscriberRef(session.subscribe(
			event.stream,
			"subscriber",
			{
				insertMode: "append",
				width: "100%",
				height: "100%",
				fitMode: isPortrait ? "contain" : "cover", //"cover",
			},
			handleError,
		));
	};
	// creating publisher and publishing stream.
	const publishSteam = (token) => {
		otPublisher = OT.initPublisher(
			"publisher",
			{
				insertMode: "append",
				width: "100%",
				fitMode: "contain", //"cover",
				height: "100%",
				mirror: false
				// showControls: false
			},
			function (error) {
				if (error) {
					handleError(error);
					setStartPublishing(false)
				} else {
					setStartPublishing(true);
					session.connect(token, function (error) {
						// If the connection is successful, publish to the session
						if (error) {
							handleError(error);
						} else {
							/**
							 * these also default events for publishers.
							 */
							session.publish(otPublisher, handleErrorPublish);
							// otPublisher
							setStartPublishing(true);
							otPublisher.on("streamCreated", function (event) {
								console.log(otPublisher, "<<<<<<OT PUB")
								setCurrentState("connected");
								setStartPublishing(true);
							});
							otPublisher.on("streamDestroyed", function (event) {
								setCurrentState("Disconnected due to some network or any other issue");
								console.log(
									"Your stream is stopped / check your internet and refresh your page. Reason: " +
									event.reason
								);
							});
						}
					});
				}
			}
		);
		otPublisher.on("accessDenied", (event) => {
			// alert("event declined");
		});
		// publishers image setting.
		sessionData.isHost
			? otPublisher.setStyle(
				"backgroundImageURI",
				quartrData.data.data.host.profile_media_urls[0]
			)
			: otPublisher.setStyle(
				"backgroundImageURI",
				quartrData.data.data.attendee.profile_media_urls[0]
			);
	};
	// our initialization
	let initializeSession = (sessionId, token, apiId) => {
		globalToken = token;
		console.log('session initializer')
		setOnWaiting(false);
		setValidSession(true);
		if (OT && OT.initSession) {
			session = OT.initSession(apiId, sessionId);
			OT.on("exception", function (event) {
				console.log("loggg from tokboxxxxxx", event.message);
			});
			setStartTimer(true);
			// session on connected.
			/**
			 * session events ==> also can found from documentation.
			 */
			session.on("sessionConnected", function () {
				if ((!sessionData.isHost && !quartrData.data.data.attendee_accepted) || (sessionData.isHost && !quartrData.data.data.host_accepted)) {
					joinedCall()
				}
				handleSignal({ type: 'audio_indicator', data: audio })
				handleSignal({ type: 'is_other_person_muted', data: { test: true } })
				let opt
				if ((sessionData.isHost && quartrData?.data?.data?.attendee_accepted) || (!sessionData.isHost && quartrData?.data?.data?.host_accepted)) {
					let attendieName = (quartrData.data.data.attendee.enabledUsername ? (quartrData.data.data.attendee.username) : (quartrData.data.data.attendee.full_name))
					let hostName = (quartrData.data.data.host.enabledUsername ? (quartrData.data.data.host.username) : (quartrData.data.data.host.full_name))
					opt = sessionData.isHost
						? attendieName + ' closed Quartrly. Please standby while they reconnect'
						: hostName + ' closed Quartrly. Please standby while they reconnect'
				} else {
					opt = `Hi ${sessionData.isHost
						? (quartrData.data.data.host.enabledUsername ? (quartrData.data.data.host.username) : (quartrData.data.data.host.full_name.split(' ')[0]))
						: (quartrData.data.data.attendee.enabledUsername ? (quartrData.data.data.attendee.username) : (quartrData.data.data.attendee.full_name.split(' ')[0]))
						}! ${!sessionData.isHost
							? (quartrData.data.data.host.enabledUsername ? (quartrData.data.data.host.username) : (quartrData.data.data.host.full_name))
							: (quartrData.data.data.attendee.enabledUsername ? (quartrData.data.data.attendee.username) : (quartrData.data.data.attendee.full_name))
						} hasn't joined yet. \n But when  ${!sessionData.isHost
							? (quartrData.data.data.host.enabledUsername ? (quartrData.data.data.host.username) : (quartrData.data.data.host.full_name.split(' ')[0]))
							: (quartrData.data.data.attendee.enabledUsername ? (quartrData.data.data.attendee.username) : (quartrData.data.data.attendee.full_name.split(' ')[0]))
						} joins, we'll get you both connected. \n Thanks for your patience! `;
				}
				setInvalidId(opt);
			});
			session.on("StreamPropertyChangedEvent", (event) => {
				console.log("stream changes", event.stream.hasAudio, event);
				if (event.connection.connectionId !== session.connection.connectionId) {
					console.log('stream updated')
				}
			});
			session.on("signal:audio_indicator", (event) => {
				if (event.from.connectionId !== session.connection.connectionId) {
					if (!event.data) {
						let message = `${sessionData.isHost
							? (quartrData.data.data.attendee.enabledUsername ? (quartrData.data.data.attendee.username) : (quartrData.data.data.attendee.full_name))
							: (quartrData.data.data.host.enabledUsername ? (quartrData.data.data.host.username) : (quartrData.data.data.host.full_name))} muted audio.`
						setAudioState(message);
					}
					else setAudioState("")
				}
			});
			session.on('signal:is_other_person_muted', (event) => {
				if (event.from.connectionId !== session.connection.connectionId) {
					handleSignal({ type: 'audio_indicator', data: isMutedRef.current })
				}
			})
			session.on("connectionCreated", (event) => {
				console.log(
					"am session map",
					session.connections.map((user) => user)
				);
				if (event.connection.connectionId !== session.connection.connectionId) {
					setInvalidId(false);
					console.log("Another client connected. ");
					console.log(event, session, sessionId);
					setCurrentState("connected");
				}
				if (session.connection.data === event.connection.data && event.connection.connectionId !== session.connection.connectionId) {
					let connections = session.connections.map((user) => user)
					console.log(session.connection.id, connections[0].id, session.connection.id, connections[1].id, connections.length)
					if (session.connection.id === connections[0].id || session.connection.id === connections[1].id) {
						if (otPublisher) {
							session.unpublish(otPublisher);
						}
						if (otSubscriber) {
							session.unsubscribe(otSubscriber);
						}
						session.disconnect();
						props.history.push({
							pathname: "/home",
						});
					}
				}
			});
			session.on("connectionDestroyed", (event) => {
				if (event.connection.connectionId !== session.connection.connectionId) {
					console.log("Another client disconnected. ");
				}
			});
			session.on("sessionDisconnected ", (event) => {
				console.log("rumor se disconnect hua");
				session.connect(token, function (error) {
					// If the connection is successful, publish to the session
					if (error) {
						handleError(error, session);
					} else {
						session.publish(otPublisher, handleErrorPublish);
					}
				});
			});
			// Subscribe to a newly created stream
			session.on("streamCreated", function (event) {
				// setConnected(true);
				createSubscriber(event, session);
				setCurrentState("connected");
			});
			// **********************************************
			// user disconnected from other side
			session.on("connectionDestroyed", (msg) => {
				let attendeeName = (quartrData.data.data.attendee.enabledUsername ? (quartrData.data.data.attendee.username) : (quartrData.data.data.attendee.full_name))
				let hostName = (quartrData.data.data.host.enabledUsername ? (quartrData.data.data.host.username) : (quartrData.data.data.host.full_name))
				setCurrentState(sessionData.isHost
					? attendeeName + " closed Quartrly. Please standby while they reconnect"
					: hostName + " closed Quartrly. Please standby while they reconnect");
				setAudioState(null)
			});
			// user reconnecting from other side
			session.on("sessionReconnecting", (msg) => {
				setCurrentState("Trying to reconnect...");
				console.log("****************user reconnecting from other sidee");
			});
			// user re-connected from other side
			session.on("sessionReconnected", (msg) => {
				setCurrentState("connected");
				console.log("****************user re-connected from other sidee");
			});
			/***
			 * when we use "signal:[event name]" it means we are using custom our own creaeted event. we can get them like this
			 */
			session.on("signal:network_error", function (event) {
				if (event.from.id !== session.connection.connectionId) {
					setCurrentState("User is in background");
				}
				// handleRefresh()
			});
			session.on("signal:network_Connected", function (event) {
				if (event.from.id !== session.connection.connectionId) {
					// console.log("*******************************");
					// console.log("Signal sent from connection: " + event.from.id);
					// console.log("Signal data: " + event.data);
					// console.log("*******************************");
					setCurrentState("connected");
				}
			});
			// Create a publisher
			publishSteam(token);
		} else {
			window.location.reload();
		}
	};
	// change front back camers
	let cycleCamera = () => {
		console.log(otPublisher);
		otPublisher
			.cycleVideo()
			.then((suc) => console.log(suc))
			.catch((err) => console.log(err));
	};
	let muteCall = () => {
		setAudio(!audio);
	};
	// end session and kill quartr
	let endSession = () => {
		// const hostName = (quartrData.data?.data?.host?.enabledUsername ? (quartrData.data?.data?.host?.username) : (quartrData.data?.data?.host?.full_name))
		if (session) {
			if (otPublisher) {
				session.unpublish(otPublisher);
			}
			if (otSubscriber) {
				session.unsubscribe(otSubscriber);
			}
			session.disconnect();
			if (!sessionData.isHost) {
				dispatch(BaseActions.addRating({
					appointment_id: quartrData.data.data._id,
					name_of_host: hostUsername,
					// name_of_host: (quartrData.data?.data?.host?.enabledUsername ? (quartrData.data?.data?.host?.username) : (quartrData.data?.data?.host?.full_name)),
					profile_url: quartrData.data.data.host.profile_media_urls[0],
				}))
			}
			if (upcomingId) {
				props.history.push(`/quartr/${upcomingId}`);
				localStorage.removeItem("upcomingQuartr");
				// setTimeout(() => {
				window.location.reload();
				// }, 1000);
				return;
			} else {
			}
			if (sessionData.isHost) {
				props.history.push({
					pathname: "/home",
					data: { endCallPopup: (quartrData.data?.data?.attendee?.enabledUsername ? (quartrData.data?.data?.attendee?.username) : (quartrData.data?.data?.attendee?.full_name)) || "" },
				});
			} else {
				props.history.push('/rating');
			}
		}
	};
	// timer manager for 15 minutes
	let timerConstructor = (duration) => {
		var timer = duration;
		var current = duration;
		let minutes = "";
		let seconds = "";
		let firstly = false;
		previousInterval = setInterval(function () {
			minutes = parseInt(timer / 60, 10);
			seconds = parseInt(timer % 60, 10);
			minutes = minutes < 10 ? "0" + minutes : minutes;
			seconds = seconds < 10 ? "0" + seconds : seconds;
			let completeTime = minutes + ":" + seconds;
			// console.log(minutes + ":" + seconds);
			setTimer({ timer: completeTime, stamp: new Date() });
			// if (!firstly) {
			//   if (--current < 240) {
			//     firstly = true;
			//     callRequest(handleSuccess, handleFails ,interval);
			//     // alert('sdfdsf')
			//   }
			// }
			//
			// beat api calls for managing time same at both sides.
			if (current === 840) {
				setTryCounts(3);
			}
			if (current === 720) {
				setTryCounts(1);
			}
			if (current === 600) {
				setTryCounts(2);
			}
			if (current === 420) {
				setTryCounts(1);
			}
			if (current === 120) {
				setTryCounts(3);
			}
			if (current === 60) {
				setTryCounts(2);
			}
			--current;
			if (--timer < 0) {
				console.log("timer End");
				endSession();
				setTimer({ timer: "00:00", stamp: new Date() });
				localStorage.removeItem("remainingTimer");
				clearInterval(previousInterval);
			}
		}, 1000);
	};
	// time difference between 2 stamps in seconds
	let getDifference = (finalStamp, previousStamp) => {
		let ms = new Date(finalStamp).getTime() - new Date(previousStamp).getTime();
		let sec = ((ms % 60000) / 1000).toFixed(0);
		console.log('diff', new Date(finalStamp).getTime(), new Date(previousStamp).getTime(), sec, ms)
		return sec;
	};
	//  it will handle core functionality of quartr handler when 15 minutes duration are undergoes
	let quartrTimer = () => {
		let upcomingStartTime = 0;
		if (localStorage.getItem("remainingTimer")) {
			console.log('time remaning', localStorage.getItem("remainingTimer"))
			upcomingStartTime = localStorage.getItem("remainingTimer");
		} else if (quartrData.data?.data?.remaining_seconds <= 900) {
			console.log(quartrData.data?.data?.remaining_seconds)
			localStorage.setItem(
				"remainingTimer",
				String(
					quartrData.data.data.remaining_seconds
				)
			);
			upcomingStartTime = quartrData.data.data.remaining_seconds
		}
		else {
			if (
				getDifference(
					new Date().toISOString(),
					new Date(sessionData.call_at).toISOString()
				) < 60
			) {
				console.log('quatr', quartrData);
				upcomingStartTime = 15 * 60;
				console.log('difference true', new Date().toISOString(),
					new Date(sessionData.call_at).toISOString(), upcomingStartTime);
			} else {
				endSession();
				setTimer({ timer: "00:00", stamp: new Date() });
				localStorage.removeItem("remainingTimer");
				clearInterval(previousInterval);
			}
		}
		setStartingTime(upcomingStartTime);
		// timerConstructor(upcomingStartTime);
	};
	const stopQuartr = () => {
		session.destroy();
		endSession();
		setTimer({ timer: "00:00", stamp: new Date() });
		localStorage.removeItem("remainingTimer");
		clearInterval(previousInterval);
	}
	let data = useSelector(({ Quartr }) => Quartr.FetchQuartrReducer);
	console.log({ data })
	React.useEffect(() => {
		const isLoggedIn = JSON.parse(localStorage.getItem("quarterlyUser"));
		const token = localStorage.getItem("JWTtoken");
		if (isLoggedIn && token) {
			// setRefresh(true)
		} else {
			dispatch(Actions.fetchQuartr(matchedId))
			// props.history.push(`/userProfile/${window.location.pathname.split('/').pop()}`)
			props.history.push(`/userProfile/${quartrData.data.data.attendee}`)
		}
		startTimer && quartrTimer();
	}, [startTimer]);
	React.useEffect(() => {
		if (startingTime) {
			console.log({ startingTime });
			clearInterval(previousInterval);
			timerConstructor(startingTime);
		}
	}, [startingTime]);
	React.useEffect(() => {
		if (otPublisher) otPublisher.publishAudio(audio);
		isMutedRef.current = audio
		if (session) handleSignal({ type: "audio_indicator", data: audio });
	}, [audio]);
	//  to send custom events to a connected session
	const handleSignal = ({ type, data }) => {
		if (session) {
			session.signal(
				{
					type,
					// to: recipientConnection; // a Connection object
					data,
				},
				function (error) {
					if (error) {
						console.log("signal error: " + error.message);
					}
				}
			);
		}
	};
	// core event listners for handling user background or not and other requests
	const [diff, setDiff] = useState(0)
	React.useEffect(() => {
		// var a = moment(new Date);
		// var b = moment(`${moment().format("DD-MM-YYYY")} ${timer.timer}`);
		// var b = moment(timer.timer, 'HH:mm');

		// console.log({ a, b })
		const diff = moment(timer.timer, 'mm:ss').diff(moment().startOf('day'), 'seconds')
		setDiff(moment(timer.timer, 'mm:ss').diff(moment().startOf('day'), 'seconds'))
	}, [timer.timer])
	React.useEffect(() => {

		if (window) {
			setInterval(() => {
				if (document.visibilityState === "hidden") {
					handleSignal({ type: "network_error", data: "user Disconnected" });
				}
			}, 1000);
			window.addEventListener("visibilitychange", () => {
				if (
					document.visibilityState === "visible" ||
					document.visibilityState === "focus"
				) {
					handleSignal({ type: "network_Connected", data: "user Connected" });
					return;
				}
				if (document.visibilityState === "hidden") {
					handleSignal({ type: "network_error", data: "user Disconnected" });
					return;
				}
			});
		}
		return () => {
			if (session) {
				session.destroy();
			}
		};
	}, []);
	return (
		<>
			<div className={classes.root}>
				<div className={classes.container}>
					{/* remote video */}
					{!invalidId && (
						<div id="subscriber" className={classes.remoteVideo}></div>
					)}
					{/* local video container */}
					<div
						id="publisher"
						style={{ visibility: startPublishing ? "visible" : "hidden" }}
						className={classes.localVideo}
					></div>
					{/* local video container error message */}
					<div
						style={{
							display: validSession ? "flex" : "none",
							visibility: !startPublishing ? "visible" : "hidden",
							color: "white",
						}}
						className={classes.localVideo2}
					>
						<Typography variant="caption">
							Allow permission to start quartr
						</Typography>
					</div>
					<div id="remote-status" className={classes.remoteStatus}>
						<Typography variant="caption">{currentState}</Typography>
					</div>
					{audioState &&
						<div className={classes.remoteAudioStatus}>
							<Typography variant="h5" className={classes.audioText}>{audioState}</Typography>
						</div>
					}
					{/* objections */}
					{invalidId && currentState === "connected" && (
						<div
							style={{
								position: "absolute",
								top: onWaiting ? "calc(50% - 130px)" : "calc(50% - 25px)",
								left: "calc(50% - 40vw)",
								width: "100%",
								maxWidth: "80vw",
								textAlign: "center",
								marginTop: '-18%'
							}}
						>
							{onWaiting ? (
								<Box textAlign="center">
									<CountdownTimer />
								</Box>
							) : (
								<Typography variant="caption">{invalidId}</Typography>
							)}
						</div>
					)}
					{currentState === "User is in background" && (
						<div
							className={classes.userInBackground}
						>
							<div>
								<div style={{ display: "flex", justifyContent: 'center' }}>
									{/* <img src={pics.power} className={classes.powerImg} /> */}
									{/* <img src={"/assets/icons/Loading.jpg"} className={classes.powerImg} /> */}
									<img src={LoadingImage} className={classes.powerImg} />
								</div>
								<div style={{ width: "182px", textAlign: 'center' }}>
									<Typography variant="caption">{sessionData.isHost
										? (quartrData.data.data.attendee.enabledUsername ? (quartrData.data.data.attendee.username) : (quartrData.data.data.attendee.full_name)) + "  minimized Quartrly. Please standby until they return."
										: (quartrData.data.data.host.enabledUsername ? (quartrData.data.data.host.username) : (quartrData.data.data.host.full_name)) + "  minimized Quartrly. Please standby until they return."}</Typography>
								</div>
							</div>
						</div>
					)}
					{/* objections */}
					{!onWaiting && sessionData.roomId && (
						<div className={classes.videoControls}>
							<div
								className={classes.controlButton}
								onClick={() => {
									cycleCamera();
								}}
							>
								<img src={pics.camera_fs} alt={"flip"} />
							</div>
							<div className={classes.timer} id="timer">
								<div style={{ backgroundColor: diff < 60 && diff > 0.1 ? "red" : "#FFFFFF", color: diff < 60 ? "white" : "black" }}>{timer.timer}</div>
								{/* <div style={{ backgroundColor: "red" }}>{timer.timer}</div> */}
							</div>
							<div
								className={classes.controlButton}
								onClick={() => {
									muteCall();
								}}
							>
								<img
									alt={"mic"}
									src={audio ? pics.mic_on_fs : pics.mic_off_fs}
								/>
							</div>
							{/* <Button className={classes.timer} onClick={() => { stopQuartr() }}>Stop</Button> */}
						</div>
					)}
				</div>
			</div>
		</>
	);
};
export default withReducer("Quartr", reducer)(withRouter(VideoCall));
