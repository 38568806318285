
// this function we are using to prevent user for getting down into application without submitting its all signup process.

export default  function  homeRedirect(data, history) {

 return new Promise((res,rej)=>{

   if (data.tc_and_pp !== 1) {
     history.push("/legalStuff");
     rej()
     return;
   }
   if (data.rate === -1) {
     history.push("/rate-refer");
     rej()
     return;
    }
    
    if (data.tags === null) {
      history.push("/expertise");
      rej()
     return;
   }
   res()
  })
}
