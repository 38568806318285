import React from 'react'
import { Box, Typography, makeStyles } from "@material-ui/core";
import moment from 'moment';
// import { Link } from 'react-router-dom';
import * as BaseActions from "store/actions";
import * as Actions from "./../store/actions";
import UserReviews from 'components/userReviews';
import { useDispatch } from "react-redux";
import { useHistory } from 'react-router-dom'
import DummyImage from "@assets/dummyImage.png"
const useStyles = makeStyles((theme) => ({
    container: {
        padding: "10px 20px 10px 20px",
        "&:hover": {
            background: "#111111"
        },
        display: "flex",
        alignItems: "start",
    },
    img: {
        width: "100%",
        height: "100%",
        objectFit: "cover",
        borderRadius: "5px",
    },
    imgContainer: {
        // margin: "0 0 0 20px",
        width: "20%",
        height: "56px",
        cursor: "pointer"
        // border: "1px dashed orange"
    },
    imgBox: {
        // margin: "0 0 0 20px",
        width: "56px",
        height: "56px",
        // border: "1px dashed orange"
    },
    textContainer: {
        // border: "1px dashed orange",
        width: "80%",
        padding: "0 0 0 8px",
        display: "flex",
        flexDirection: "column",
        cursor: "pointer"
    },
    text: {
        color: "#FFFFFF"
    },
    timeText: {
        color: "#808080",
        fontSize: "14px",
    },
    isSeen: {
        width: "9px",
        height: "9px",
        backgroundColor: "#31A7DB",
        borderRadius: "9999px",
    },
    timeContainer: {
        padding: "0 20px 0 0",
    }
}))
export default function NotificationCard(props) {
    const dispatch = useDispatch();
    const { item, i, day } = props
    const classes = useStyles();
    const getDay = (date) => {
        return day === "Today" ? day : moment(date).format("dddd")
    }
    const setMessage = item.message.split(' ').length === 1 && item.type === "appointment" ? " booked" : "";
    const getMessage = () => {
        if (item.type === "appointment") {
            return item.message + setMessage + " a $" + JSON.parse(item.data)?.receipt_id?.totalCharges + " Quartr for " + moment(JSON.parse(item.data)?.call_at).format("dddd") + " @ " + moment(JSON.parse(item.data)?.call_at).format("hh:mm A")
        } else if (item.type === "appointmentcancelled") {
            return item.message + " a $" + JSON.parse(item.data)?.receipt_id?.totalCharges + " Quartr for " + moment(JSON.parse(item.data)?.call_at).format("dddd") + " @ " + moment(JSON.parse(item.data)?.call_at).format("hh:mm A")
            // } else if (item.type === "referrer") {
            //     let userData = item.targetId;
            //     // return `${userData?.enabledUsername ? userData?.username : userData?.full_name} has joined and used your code.  You will get 1% of their revenue.`
            //     return `${userData?.enabledUsername ? userData?.username : userData?.full_name} has joined and used your code.  You will get 1% of their revenue.`
        } else {
            return item.message
        }
    }
    const handleReviews = (item) => {
        if (item.type === "reminder" || item.type === "appointment") {
            handleNavigate(`/userprofile/${item.targetId.user_code}?from=notifications`, item.targetId)
        } else if (item.type === "review") {
            dispatch(
                BaseActions.showMessage(<UserReviews handleClose={handleClose} targetId={item.targetId} data={JSON.parse(item.data)} />)
            );
        } else {
            dispatch(
                BaseActions.showMessage(<UserReviews handleClose={handleClose} targetId={item.targetId} data={JSON.parse(item.data)} />)
            );
        }
        if (!item.isRead) {
            dispatch(
                Actions.readNotifications(item._id)
            );
        }
    };
    const handleClose = () => {
        dispatch(BaseActions.hideMessage());
    };
    const history = useHistory()
    const handleNavigate = (path, data) => {
        history.push({ pathname: path, state: data });
    };

    const profileImg = item.targetId?.profile_media_urls[0] ? item.targetId?.profile_media_urls[0] : DummyImage
    const finalImg = profileImg === "https://narsunprojects.com:3001/uploads/default_pic.png" ? DummyImage : profileImg
    return (
        <Box key={i} className={classes.container}>
            {/* <Link to={"/userprofile/" + item.targetId._id}> */}
            <Box onClick={() => handleNavigate(`/userprofile/${item.targetId.user_code}?from=notifications`, item.targetId)} className={classes.imgContainer}>
                <Box className={classes.imgBox}>
                    <img
                        className={classes.img}
                        src={finalImg}
                    />
                </Box>
            </Box>
            {/* </Link> */}
            <Box onClick={item.type === "referrer" || item.type === "appointmentcancelled" ? () => handleNavigate(`/userprofile/${item.targetId.user_code}?from=notifications`, item.targetId) : () => handleReviews(item)} className={classes.textContainer}>
                {/* <Box className={classes.textContainer}> */}
                <Typography variant="caption" className={classes.text}>
                    {getMessage()}
                </Typography>
                <Box className={classes.timeContainer} display="flex" alignItems="center" justifyContent="space-between">
                    <Typography variant="caption" className={classes.timeText}>
                        {getDay(item.createdAt) + " @ " + moment(item.createdAt).format("hh:mm A")}
                    </Typography>
                    {
                        !item.isRead &&
                        <Box className={classes.isSeen}></Box>
                    }
                </Box>
            </Box>
        </Box>
    )
}
