import React from "react";
import { Router, Switch, withRouter } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core/styles";
import { history } from "@history";
import CssBaseline from "@material-ui/core/CssBaseline";
import theme from "@theme";
import { MobileLayout } from "@layout";
import { logout } from '@utils'
import Sockets from "@base/sockets";
import Routes from "@config/routes";
import axios from "axios";
import store from "./store";
import Provider from "react-redux/es/components/Provider";
import SplashScreen from "components/splash";
import { SocketContextProvider } from "@context/socketContext.js";
import SocketClass from "@utils/socketService";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

Sentry.init({
  dsn: "https://0adcf00e84a946a396226d4f645687c5@o1052979.ingest.sentry.io/6036975",

  // Alternatively, use `process.env.npm_package_version` for a dynamic release version
  // if your build tool supports it.
  release: "my-project-name@2.3.12",
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

})
// firebase
// import firebase from "@firebase";
const handleLogout = () => {
  logout()
  window.location.href = '/'
}
function App() {
  // this is our interceptor by mean we can log or check all requests
  const [newNotification, setNewNotification] = React.useState(false)
  const authToken = localStorage.getItem('JWTtoken')
  axios.interceptors.request.use(
    function (config) {
      // Do something before request is sent
      if (authToken) {
        config.headers.Authorization = `JWT ${authToken}`;
      }
      return config;
    },
    function (error) {
      // Do something with request error
      return Promise.reject(error);
    }
  );
  // Add a response interceptor
  // we can log and check all the responses
  axios.interceptors.response.use(
    function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      if (response.data && response.data.status === "Fail") {
        if (response.data.message === "Failed to authenticate token.") {
          handleLogout()
          return
        }
      }
      return response;
    },
    function (error) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      return Promise.reject(error);
    }
  );
  let onFocus = (event) => {
    console.log("focus");
  };
  React.useEffect(() => {
    // these handlers are use for core testing,
    /**
     * for example : if we are online or ofline etc.
     */
    if (window) {
      window.addEventListener("focus", onFocus);
      window.addEventListener("blur", () => console.log("blurring"));
      window.addEventListener("visibilitychange", () =>
        console.log(document.visibilityState)
      );
      window.addEventListener("offline", () => {
        console.log("offline");
      });
      window.addEventListener("online", () => {
        console.log("online");
      });
      window.addEventListener("popstate", () => {
      });
    }
    return () => {
      window.removeEventListener("focus", onFocus);
      window.removeEventListener("blur", () => console.log("blurring"));
      window.removeEventListener("visibilitychange", () =>
        console.log("blurring")
      );
      window.removeEventListener("online", onFocus);
      window.removeEventListener("popstate", onFocus);
      window.removeEventListener("offline", () => console.log("blurring"));
    };
  }, []);
  const isSetNewNotification = () => {
    // console.log({ newNotification })
    setNewNotification(!newNotification)
  }

  return (
    <Router onUpdate={() => window.scrollTo(0, 0)} forceRefresh={true} history={history}>
      <Switch>
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <div className="App">
              <SplashScreen>
                <Sockets>
                  <MobileLayout newNotification={newNotification} >
                    <Routes setNotification={isSetNewNotification} />
                  </MobileLayout>
                </Sockets>
              </SplashScreen>
            </div>
          </ThemeProvider>
        </Provider>
      </Switch>
    </Router>
  );
}
export default App;
