import React, { useState } from "react";
// import Header from "components/home/subComponents/header";
import { withRouter } from "react-router";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Carousel from "react-multi-carousel";
import useDeepCompareEffect from "use-deep-compare-effect";
import HourQuarters from "./subComponents/hourQuarters";
import MonthCollapse from "./subComponents/monthCollapse";
import DefaultAvailabilityConfirmation from "components/defaultAvailabilityConfirmation";
import NotificationDrawer from "components/notificationDrawer";
import { useDispatch, useSelector } from "react-redux";
import * as BaseActions from "store/actions";
import * as Actions from "components/signup/screens/signupForm/store/actions";
import reducers from "components/signup/screens/signupForm/store/reducers";
import moment from "moment";
import withReducer from "store/withReducer";
import { Link, useLocation } from "react-router-dom";
import { isMobile } from "react-device-detect";
const responsive = {
  all: {
    breakpoint: { max: 4000, min: 0 },
    items: 7,
  },
};
// styles
const useStyles = makeStyles((theme) => ({
  container: {
    margin: "32px 20px 0 20px",
    [theme.breakpoints.down("sm")]: {
      margin: "15px 20px 0 20px",
    },
  },
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  title: {
    flexGrow: 1,
  },
  backButton: {
    color: "white",
    cursor: "pointer",
  },
  calendarContainer: {
    marginTop: 40,
    [theme.breakpoints.down("sm")]: {
      marginTop: 0,
    },
  },
  dateContainer: {
    cursor: "pointer",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "10px 0",
  },
  selectedDate: {
    backgroundColor: "#31A7DB",
    borderRadius: 6,
    width: "35px",
  },
  disabledDateContainer: {
    // backgroundColor: "#808080",
    color: "#808080",
    opacity: 0.4,
    cursor: "not-allowed",
  },
  gradient: {
    height: 100,
    backgroundImage: "linear-gradient(#00000000, #0A0A0A)",
    opacity: 1,
    zIndex: 9,
    position: "fixed",
    borderRadius: 6,
    width: "100%",
    bottom: 0,
    [theme.breakpoints.up("sm")]: {
      width: 370,
      bottom: 42,
    },
  },
}));
function Availability({ history }) {
  const dispatch = useDispatch();
  const [weekData, setWeek] = useState([]);
  const [active, setActive] = useState(false);
  // const [availablility, setAvailablility] = useState([]);
  const [selected, setSelected] = useState([]);
  // const [test, setTest] = useState(true);
  const [selectedDate, setSelectedDate] = useState([]);
  const [selectedSlots, setSelectedSlots] = useState([]);
  // const [quarters, setQuarters] = useState([]);
  const [statedUserData, setStatedUserData] = useState({});
  const userData = useSelector(({ BaseReducer }) => BaseReducer.UserReducer);
  const updateProfile = useSelector(
    ({ Availability }) => Availability.UpdateProfileReducer
  );
  const [today, setToday] = useState(-1);
  const classes = useStyles();
  React.useEffect(() => {
    dispatch(BaseActions.updateLayout({ bottomNav: false }));
    dispatch(BaseActions.getUserData());
    // userData && setAvailablility(userData.availablility);
    // dispatch(BaseActions.updateLayout({ bottomNav: false }));
    // dispatch(
    //   BaseActions.showMessage(
    //     <SetYourAvailabilityMessage handleClose={handleUpdate} />
    //   )
    // );
  }, []);
  // setting our data to state for further use.
  useDeepCompareEffect(() => {
    setStatedUserData(userData);
  }, [userData]);
  useDeepCompareEffect(() => {
    if (updateProfile.isLoading) {
      dispatch(BaseActions.showLoading(true));
      return;
    }
    // if (updateProfile.data) {
    // 	dispatch(Actions.resetProfileReducer());
    // 	dispatch(
    // 		BaseActions.showMessage(
    // 			<NotificationDrawer handleClose={handleClose} />
    // 		)
    // 	);
    // 	history.push("/quarters");
    // }
  }, [updateProfile]);
  // we are setting our calender weeks here to our state and creating calanders.
  useDeepCompareEffect(() => {
    // selected slots are those which already selected.
    setSelectedSlots(statedUserData.availablility);
    let days = [];
    statedUserData.availablility &&
      statedUserData.availablility.forEach((date) => {
        let dd = new Date(date.timestamp).toLocaleDateString("en-US");
        if (days.filter((day) => day === dd).length !== 0) {
          return;
        }
        days.push(dd);
        // selected days from calender are here.
        setSelectedDate(days);
      });
  }, [statedUserData]);
  React.useEffect(() => {
    setToday(new Date());
    let curr = new Date();
    let week = [];
    // setting first week of calander
    for (let i = 0; i < 7; i++) {
      let first = curr.getDate() - curr.getDay() + i;
      let day = new Date(curr.setDate(first));
      week.push({ day: day.toString().substr(0, 3), date: day });
    }
    let curr1 = new Date();
    curr1.setDate(curr1.getDate() + 7);
    // setting second week of calender
    for (let i = 0; i < 7; i++) {
      let first = curr1.getDate() - curr1.getDay() + i;
      let day = new Date(curr1.setDate(first));
      week.push({ day: day.toString().substr(0, 3), date: day });
    }
    setWeek(week);
    // when user click on day it set quarts
    // setQuarters(data);
  }, []);
  // confirmation popup ===> this is popup usage for confirmation message
  const showDefaultMessage = () => {
    if (JSON.parse(localStorage.getItem(userData._id))) {
      return;
    }
    dispatch(
      BaseActions.showMessage(
        <DefaultAvailabilityConfirmation
          handleClose={handleClose}
          id={userData._id}
        />
      )
    );
  };
  // sorting array of days so we can sort from 0-10
  const handleSortArray = (arr) => {
    let result = arr.sort(function (aa, bb) {
      let a = new Date(aa).toISOString();
      let b = new Date(bb).toISOString();
      return a < b ? -1 : a > b ? 1 : 0;
    });
    return result;
  };
  // when we click on a calender date following method will triggered.
  const handleDateChange = (i, rawDate) => {
    active === false && setActive(true);
    let date = new Date(rawDate).toLocaleDateString("en-US");
    // if date already selected then we will remove that date on selected "it will unselect"
    if (selectedDate.filter((x) => x === date).length !== 0) {
      setSelected(selected.filter((x) => x !== i));
      setSelectedDate(selectedDate.filter((x) => x !== date));
      setSelectedSlots(
        selectedSlots?.filter(
          (slot) =>
            new Date(slot.timestamp).toLocaleDateString("en-US") !== date
        )
      );
      return;
    }
    // default quarts, we are generating our stamps of a date.
    let defaultQuarters = getDefaultQuartersStamp(
      new Date(rawDate).toISOString()
    );
    selectedSlots && selectedSlots.length
      ? setSelectedSlots([...new Set([...selectedSlots, ...defaultQuarters])])
      : setSelectedSlots([...new Set([...[], ...defaultQuarters])]);
    showDefaultMessage();
    setSelected([...selected, i]);
    setSelectedDate([...selectedDate, date]);
  };
  // for close our popup
  const handleClose = () => {
    dispatch(BaseActions.hideMessage());
  };
  // this is clickHandler for selecting and unselecting our checkbox of 1 hour and 4 quartrs
  const clickHandler = (stampArr, clear) => {
    active === false && setActive(true);
    let filteredArray =
      selectedSlots && selectedSlots.length !== 0 ? [...selectedSlots] : [];
    stampArr.forEach((stamp) => {
      let stampObject = {
        appointment: true,
        attendee: false,
        date: stamp,
        forced: false,
        quartr_now: false,
        status: false,
        timestamp: stamp,
      };
      if (
        filteredArray.filter((slot) => slot.timestamp === stampObject.timestamp)
          .length >= 1
      ) {
        filteredArray = filteredArray.filter(
          (slot) => slot.timestamp !== stampObject.timestamp
        );
        if (clear) {
          return;
        }
        // setSelectedSlots(filteredArray);
        // return;
      } else {
        filteredArray = [...filteredArray, stampObject];
        // setSelectedSlots([...selectedSlots, stampObject]);
      }
    });
    setSelectedSlots(filteredArray);
  };
  // getting default quarts of dates
  function getDate() {
    let arr = [];
    for (let i = 0; i < 12; i++) {
      let nestedArr = [];
      if (i < 10) {
        nestedArr.push(`0${i}:00AM - 0${i}:15AM`);
        nestedArr.push(`0${i}:15AM - 0${i}:30AM`);
        nestedArr.push(`0${i}:30AM - 0${i}:45AM`);
        nestedArr.push(`0${i}:45AM - 0${i + 1}:00AM`);
      } else {
        nestedArr.push(`${i}:00AM - ${i}:15AM`);
        nestedArr.push(`${i}:15AM - ${i}:30AM`);
        nestedArr.push(`${i}:30AM - ${i}:45AM`);
        nestedArr.push(`${i}:45AM - ${i + 1}:00AM`);
      }
      arr.push(nestedArr);
    }
    for (let i = 0; i < 12; i++) {
      let nestedArr = [];
      if (i < 10) {
        nestedArr.push(`0${i}:00PM - 0${i}:15PM`);
        nestedArr.push(`0${i}:15PM - 0${i}:30PM`);
        nestedArr.push(`0${i}:30PM - 0${i}:45PM`);
        nestedArr.push(`0${i}:45PM - 0${i + 1}:00PM`);
      } else {
        nestedArr.push(`${i}:00PM - ${i}:15PM`);
        nestedArr.push(`${i}:15PM - ${i}:30PM`);
        nestedArr.push(`${i}:30PM - ${i}:45PM`);
        nestedArr.push(`${i}:45PM - ${i + 1}:00PM`);
      }
      arr.push(nestedArr);
    }
    return arr;
  }
  // this is custom Quarts we can generate from passing any  stamp, and it will set state our generated time stamp.
  function getQuartersStamp(stamp) {
    let arr = [];
    for (let i = 0; i <= 23; i++) {
      let nestedArray = [];
      let lDate = new Date(stamp).toLocaleDateString("en-US");
      let lTime = new Date(stamp).toLocaleTimeString();
      let params = lDate.split("/").reverse();
      params = [...params, ...lTime.split(":")];
      nestedArray.push(
        new Date(
          params[0],
          Number(params[2]) - 1,
          params[1],
          i,
          0
        ).toISOString()
      );
      nestedArray.push(
        new Date(
          params[0],
          Number(params[2]) - 1,
          params[1],
          i,
          15
        ).toISOString()
      );
      nestedArray.push(
        new Date(
          params[0],
          Number(params[2]) - 1,
          params[1],
          i,
          30
        ).toISOString()
      );
      nestedArray.push(
        new Date(
          params[0],
          Number(params[2]) - 1,
          params[1],
          i,
          45
        ).toISOString()
      );
      arr.push(nestedArray);
    }
    return arr;
  }
  // default quart stamps which will generate our required backend object for passing data to our api and selected slots. ===?> 9am - 5pm will selected defaulted.
  function getDefaultQuartersStamp(stamp) {
    let nestedArray = [];
    for (let i = 9; i <= 16; i++) {
      let lDate = new Date(stamp).toLocaleDateString("en-US");
      let lTime = new Date(stamp).toLocaleTimeString();
      let params = lDate.split("/").reverse();
      params = [...params, ...lTime.split(":")];
      let stampObject = {
        appointment: true,
        attendee: false,
        date: stamp,
        forced: false,
        quartr_now: false,
        status: false,
        timestamp: stamp,
      };
      stampObject.date = new Date(
        params[0],
        Number(params[2]) - 1,
        params[1],
        i,
        0
      ).toISOString();
      stampObject.timestamp = stampObject.date;
      stampObject.date > new Date().toISOString() &&
        nestedArray.push(stampObject);
      let stampObject2 = { ...stampObject };
      stampObject2.date = new Date(
        params[0],
        Number(params[2]) - 1,
        params[1],
        i,
        15
      ).toISOString();
      stampObject2.timestamp = stampObject2.date;
      stampObject2.date > new Date().toISOString() &&
        nestedArray.push(stampObject2);
      let stampObject3 = { ...stampObject };
      stampObject3.date = new Date(
        params[0],
        Number(params[2]) - 1,
        params[1],
        i,
        30
      ).toISOString();
      stampObject3.timestamp = stampObject3.date;
      stampObject3.date > new Date().toISOString() &&
        nestedArray.push(stampObject3);
      let stampObject4 = { ...stampObject };
      stampObject4.date = new Date(
        params[0],
        Number(params[2]) - 1,
        params[1],
        i,
        45
      ).toISOString();
      stampObject4.timestamp = stampObject4.date;
      stampObject4.date > new Date().toISOString() &&
        nestedArray.push(stampObject4);
    }
    return nestedArray;
  }
  // not using at this time.
  const handleUpdate = () => {
    handleClose();
    setTimeout(() => {
      dispatch(
        BaseActions.showMessage(
          <DefaultAvailabilityConfirmation handleClose={handleClose} />
        )
      );
    }, 300);
  };
  const closeNotificationDrawer = () => {
    history.push("/quarters");
  };
  // handle save it will call our request to  backend for selected slots to save in db
  const handleSave = () => {
    console.log(selectedSlots);
    dispatch(Actions.setAvailability(selectedSlots));
    // if (updateProfile.isLoading) {
    // 	dispatch(BaseActions.showLoading(true));
    // 	return;
    // }
    // if (updateProfile.data) {
    dispatch(Actions.resetProfileReducer());
    dispatch(
      BaseActions.showMessage(
        <NotificationDrawer closeNotificationDrawer={closeNotificationDrawer} />
      )
    );
    // history.push("/quarters");
    // }
  };
  const sortedDates = selectedDate ? handleSortArray(selectedDate) : [];
  function viewportToPixels(value) {
    var parts = value.match(/([0-9\.]+)(vh|vw)/);
    var q = Number(parts[1]);
    var side =
      window[["innerHeight", "innerWidth"][["vh", "vw"].indexOf(parts[2])]];
    return side * (q / 100);
  }
  // const height = viewportToPixels("100vh") - 320
  const height = viewportToPixels("100vh");
  const location = useLocation();
  // const handleClick = () => {
  // 	history.push('/quarters')
  // }
  return (
    <div style={{ height: isMobile ? "100vh" : "85vh" }}>
      {/* overflowY removed because of scrolling issue for availability */}
      {/* <div style={{ overflowY: 'hidden', height: isMobile ? '100vh' : '85vh' }}> */}
      <div className={classes.gradient}></div>
      <Box className={classes.container}>
        <Box className={classes.header}>
          <Link
            to={{
              pathname: "/quarters",
              prevLocation: {
                prevLocationPath: location.pathname,
              },
            }}
          >
            <ArrowBackIosIcon className={classes.backButton} />
          </Link>
          <Typography variant="h1" className={classes.title}>
            Availability
          </Typography>
          <Typography
            style={{ cursor: "pointer" }}
            onClick={() => {
              active && handleSave();
            }}
          >
            Save
          </Typography>
        </Box>
        <Box className={classes.calendarContainer}>
          {/* carousel for date selected stamps. */}
          <Carousel responsive={responsive}>
            {weekData.map((d, i) => (
              <Box
                key={i}
                className={`disable-selection ${
                  selectedDate.filter(
                    (x) => x === new Date(d.date).toLocaleDateString("en-US")
                  ).length !== 0
                    ? `${classes.dateContainer} ${classes.selectedDate}`
                    : today > d.date
                    ? `${classes.dateContainer} ${classes.disabledDateContainer}`
                    : classes.dateContainer
                }`}
                onClick={() => {
                  today <= d.date && handleDateChange(i, d.date);
                }}
              >
                <Typography variant="subtitle1">{d.day}</Typography>
                <Typography style={{ marginTop: "15px" }} variant="subtitle1">
                  {d.date.getDate()}
                </Typography>
              </Box>
            ))}
          </Carousel>
        </Box>
      </Box>
      <Box
        style={{
          height: `${height}px`,
          overflowY: "scroll",
        }}
        padding="0px 20px 100px 20px"
      >
        {selectedDate && sortedDates.length
          ? sortedDates.map((date, index) => {
              let result = (
                // these month collapse are for displaying further nested quartr stamps.
                <>
                  <MonthCollapse
                    key={index}
                    date={moment(date).format("dddd, MMMM DD")}
                  >
                    {getQuartersStamp(date).map((q, i) => (
                      <HourQuarters
                        key={i}
                        quarterStamps={q}
                        handler={clickHandler}
                        selectedSlots={selectedSlots}
                        selectedDate={date}
                      />
                    ))}
                  </MonthCollapse>
                </>
              );
              return result;
            })
          : null}
      </Box>
    </div>
  );
}
export default withReducer("Availability", reducers)(withRouter(Availability));
