export const HIDE_LOADING = "[LOADING] CLOSE";
export const SHOW_LOADING = "[LOADING] SHOW";

export function hideLoading() {
  return {
    type: HIDE_LOADING,
  };
}

//  {LOADING : "what is the LOADING"}

export function showLoading(data) {
  return {
    type: SHOW_LOADING,
    data:data
  };
}
