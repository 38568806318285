
// firebase push notifications handler 

import firebase from "@firebase";
import * as Sentry from '@sentry/react';

import * as BaseActions from "store/actions";
import * as Actions from 'components/signup/screens/signupForm/store/actions'


const initFirebase = (dispatch) => {
  // firebase
  if (!firebase.messaging.isSupported()) {
    // alert('notifications not supported')
    dispatch(Actions.updateProfile());
    return
  }

  const messaging = firebase.messaging();
  messaging
    .requestPermission()
    .then(() => messaging.getToken())
    .then((token) => {
      console.log({ called: "initFirebase" })
      console.log({ token })
      if (token) {
        dispatch(
          Actions.updateProfile({
            fcm_tokens: { token: token, deviceType: "web" },
          })
        );
      } else {
        Sentry.captureMessage("Token not generated!")
      }
    })
    .catch((error) => {
      console.log("FCM error", error);
      dispatch(Actions.updateProfile());
    });
  // firebase


  // when we receive message from firebase this handler will be fired
  messaging.onMessage((payload) => {
    console.log({ payload })
    console.log("Message received. ", payload);
    // we are dispatching our redux action here to displaying notification
    dispatch(BaseActions.showNotification(payload.data))
  });
};
export default initFirebase