import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import "./deskAnimation.css";
import "./mobileAnimation.css";
// import DummyImage from "@assets/dummyImage.png"
// redux
import { useDispatch, useSelector } from "react-redux";
import * as BaseActions from "store/actions";
import useDeepCompareEffect from "use-deep-compare-effect";
import { withRouter } from 'react-router-dom'
import { isMobile } from "react-device-detect";
const useStyles = makeStyles((theme) => ({
  positionCon: {
    position: "fixed",
    top: ({ onMobile }) => (onMobile ? "20px" : "unset"),
    bottom: ({ onMobile }) => (onMobile ? "unset" : "40px"),
    right: ({ onMobile }) => (onMobile ? "calc( 50% - 150px)" : isMobile ? 0 : "40px"),
    width: ({ onMobile }) => (onMobile ? "300px" : isMobile ? '100%' : "400px"),
    zIndex: ({ isOpen }) => (isOpen ? 400 : -10),
    transition: "all .5s ease-in-out",
  },
  root: {
    background: "#161616",
    width: "100%",
    borderRadius: isMobile ? 0 : "12px",
    padding: "16px",
    display: "flex",
  },
  img: {
    alignSelf: "center",
    borderRadius: "8px",
    height: ({ onMobile }) => (onMobile ? "50px" : "80px"),
    width: ({ onMobile }) => (onMobile ? "50px" : "80px"),
  },
  content: {
    margin: "0px 20px",
    flexGrow: 1,
  },
  cross: {},
}));
const Notification = ({ onMobile, history }) => {
  const dispatch = useDispatch();
  const payload = useSelector(
    ({ BaseReducer }) => BaseReducer.NotificationReducer
  );
  useDeepCompareEffect(() => {
    let timeout = null;
    if (payload.state === true) {
      //   !isFirst && setIsFirst(false);
      timeout = setTimeout(() => {
        handleClose();
      }, 7000);
    }
    return () => timeout && clearTimeout(timeout);
  }, [payload]);
  const handleClose = () => {
    dispatch(BaseActions.hideNotification());
  };
  const classes = useStyles({ onMobile, isOpen: payload.state });
  return (
    !payload.data?.initial &&
    <div
      className={clsx(
        classes.positionCon,
        "disable-selection",
        !onMobile
          ? payload.state
            ? "slide-in-right"
            : "slide-out-right"
          : payload.state
            ? "slide-in-top"
            : "slide-out-top"
      )}
    >
      <div className={classes.root}>
        <img
          src={
            payload.data?.icon ||
            `${process.env.REACT_APP_ENV === "prod" ? process.env.REACT_APP_BASE_URL_PROD : process.env.REACT_APP_BASE_URL_TESTING}uploads/default_pic.png?1610711811682`
            // { DummyImage }
          }
          alt="pic"
          className={classes.img}
        />
        <div className={clsx("disable-selection", classes.content)} onClick={() => { history.push(payload.data.url); handleClose() }}>
          <Typography style={{ fontSize: !onMobile ? "18px" : '12px' }} variant="body1">{payload.data?.title || ""}</Typography>
          <Typography style={{ fontSize: onMobile ? "10px" : '14px' }} variant="subtitle2">{payload.data?.body || ""}</Typography>
        </div>
        <div className={clsx("disable-selection", classes.cross)}>
          <CloseIcon
            style={{ stroke: "#CB3A3A", strokeWidth: 2, cursor: "pointer" }}
            color="secondary"
            onClick={() => handleClose()}
          />
        </div>
      </div>
    </div>
  );
};
export default withRouter(Notification);
