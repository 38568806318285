import React, {memo} from "react";
import {makeStyles} from "@material-ui/styles";
import {useHistory} from 'react-router-dom'
import {useDispatch} from 'react-redux';
import * as BaseActions from 'store/actions';
import DrawerMenu from 'components/drawerMenu';
import MenuIcon from "../../components/home/subComponents/header/icons/MenuIcon";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import BackArrow from "../icons/BackArrow";

const useStyles = makeStyles((theme) => ({
    container: {
        flexDirection: "row",
        alignItems: "center",
    },
    positionFixed: {
        justifyItems: "center",
        alignItems: "center",
        backgroundColor: '#141414',
        display: "grid",
        rowGap: "15px",
        gridTemplateColumns: "1fr 3fr 1fr",
        padding: "36px 20px 15px 15px",
        position: "fixed",
        zIndex: "100",
        width: "100%",
        [theme.breakpoints.up("sm")]: {
            width: 373,
        },
    },
    leftComponent: {
        justifyContent: "flex-start",
        alignItems: "center",
    },
    backArrow: {
        color: "#FFFFFF",
        justifySelf: "flex-start",
        cursor: "pointer",
        height: 22,
        width: 13,
    },
    menu: {
        justifySelf: "flex-start",
        cursor: "pointer",
        height: "18px",
        width: "27px",
    },
    headerText: {
        minWidth: '87px',
        height: '19px',
        fontFamily: "-apple-system, BlinkMacSystemFont, cerebri-regular",
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '19px',
        textAlign: 'center',
        color: '#FFFFFF',
        justifyContent: 'center',
    },
    rightComponent: {
        width: "fit-content",
        height: '28px',
        justifySelf: 'flex-end',
        alignItems: 'center',
        cursor: 'pointer',
    },
}))

const Header = ({heading, RightComponent, backArrow, onBack}) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const history = useHistory()

    return (
        <Box className={classes.container}>
            <Box className={classes.positionFixed}>
                {
                    backArrow
                        ? <span className={classes.backArrow} onClick={onBack || history.goBack}>
                            <BackArrow />
                        </span>
                        : <span className={classes.menu} onClick={() => dispatch(BaseActions.showMessage(<DrawerMenu/>))}>
                            <MenuIcon />
                        </span>
                }

                <Typography className={classes.headerText}>{heading}</Typography>

                {!!RightComponent && <span className={classes.rightComponent}><RightComponent /></span>}
            </Box>
        </Box>
    );
};
export default memo(Header)
