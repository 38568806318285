import React, { useState, useEffect } from "react";
import { Box, Button, Typography } from "@material-ui/core";
// import pics from "@assets";
// import { Link } from "react-router-dom";
// import { InputField } from "@base";
import { withRouter } from "react-router-dom";
import { Spinner } from "@base";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import Referal from "components/signup/screens/referal";
//// functions need to use react with our component
import { useDispatch, useSelector } from "react-redux";
import reducer from "../signupForm/store/reducers";
import withReducer from "store/withReducer";
import * as Actions from "../signupForm/store/actions";
import * as BaseActions from "store/actions";
import { isInteger } from "formik";
// const path = `/${parseInt(Math.random() * 100000000000000000000, 10)}`;
import queryString from 'query-string'
import { isIOS, isMobile } from "react-device-detect";
const Rate = (props) => {
	const dispatch = useDispatch();
	const [rate, setRate] = React.useState("$5");
	const [openReferal, setOpenReferal] = React.useState(false);
	const [isFirst, setIsFirst] = React.useState(true);
	const [isDisable, setIsDisable] = React.useState(true);
	const [refferalCode, setRefferalCode] = React.useState([]);
	const updateProfileResponse = useSelector(
		({ Rate }) => Rate.UpdateProfileReducer
	);
	const updateProfileResponseData = updateProfileResponse.data;
	const isLoading = updateProfileResponse.isLoading;
	const { layout } = useSelector(({ BaseReducer }) => ({
		layout: BaseReducer.LayoutReducer,
	}));
	React.useEffect(() => {
		if (layout.bottomNav) dispatch(BaseActions.updateLayout({ bottomNav: false }));
	}, [layout.bottomNav])
	React.useEffect(() => {
		if (updateProfileResponseData) {
			if (updateProfileResponseData.status === "Success") {
				// route here
				// props.history.push('/dynamic');
				setOpenReferal(true);
			}
		}
	}, [updateProfileResponseData]);
	const handleSetRate = (val) => {
		let newVal = String(val).substring(1);
		if (!newVal) {
			// dispatch(
			// 	BaseActions.showAlert({
			// 		variant: "error",
			// 		message: "enter your rate first, it can be 0 to 999$",
			// 	})
			// );
			return;
		}
		if (Number(newVal) > 999 || Number(newVal) < 5) {
			// dispatch(
			// 	BaseActions.showAlert({
			// 		variant: "error",
			// 		message: "Your rate must be between $5 and $999.",
			// 	})
			// );
			return;
		}
		if (isInteger("" + parseInt(newVal))) {
			if (code) {
				let updateRefferalCode = []
				for (let i = 0; i < code.length; i++) {
					const element = code[i];
					updateRefferalCode.push(`${element}`)
				}
				setRefferalCode(updateRefferalCode)
				dispatch(
					Actions.updateProfile({
						rate: Number("" + parseInt(newVal)),
					})
				);
			} else {
				dispatch(
					Actions.updateProfile({
						rate: Number("" + parseInt(newVal)),
					})
				);
			}
		} else {
			// dispatch(
			// 	BaseActions.showAlert({
			// 		variant: "error",
			// 		message: "Rate should be an integer",
			// 	})
			// );
			return;
		}
	};
	const handleChange = (name) => (e) => {
		// setIsFirst(false);
		if (e.target.value.length === 1) {
			setRate("$");
			setIsDisable(true);
		}
		if (/^-?\d+\.?\d*$/.test(e.target.value.substring(1))) {
			setIsDisable(false);
			setRate(e.target.value);
			return;
		}
	};
	const handlePadClick = (value) => {
		if (rate[0] !== "$") {
			setRate(`$${value}`);
			setIsDisable(false);
			setIsFirst(false);
		}
		else {
			if (rate.length < 4) {
				setIsDisable(false);
				let newRate = rate + value
				setRate(newRate);
				setIsFirst(false);
				return;
			}
		}
	};
	const handleFocus = () => {
		if (rate === "") {
			setRate("");
			setIsFirst(false);
			setTimeout(() => {
				setRate("$");
			}, 1);
		}
		return "blur();"
	};
	const handleDelete = () => {
		let newRate = rate.slice(0, -1)
		if (newRate.length > 0) {
			setRate(newRate)
		}
	}
	const id = queryString.parse(props.location.search).id
	const code = queryString.parse(props.location.search).code
	function getWindowDimensions() {
		const { innerWidth: width, innerHeight: height } = window;
		return {
			width,
			height
		};
	}
	const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
	console.log(windowDimensions.height)
	const [margin, setMargin] = useState('0px');
	useEffect(() => {
		if (windowDimensions.height > 900 && windowDimensions.height < 1000) {
			setMargin("-50px")
		} else if (windowDimensions.height > 700 && windowDimensions.height < 800) {
			setMargin("-20px")
		}
	}, [])
	const useStyles = makeStyles((theme) => ({
		rateValidation: {
			color: 'red',
			position: 'absolute',
			marginTop: margin,
			fontWeight: 'normal',
			fontFamily: "Cerebri-regular",
			// [theme.breakpoints.only('lg')]: {
			// 	marginTop: "-70px",
			// },
			// [theme.breakpoints.only('xs')]: {
			// 	marginTop: "-20px",
			// },
			// [theme.breakpoints.only('sm')]: {
			// 	marginTop: "-10px",
			// },
		}
	}))
	const classes = useStyles();
	React.useEffect(() => {
		if (rate !== "") {
			setIsDisable(false)
		}
	}, [])
	return (
		<>
			{!openReferal ? (
				<Box
					display="flex"
					flexDirection="column"
					height="100%"
					position="absolute"
				>
					<Box padding={isMobile ? "15px 30px 0px 30px" : "32px 30px 0px 30px"} overflowY="scroll" height={isMobile ? "75vh" : "auto"}>

						<Box margin={isMobile ? "0px" : "0 0 20px 0"}>
							<Typography style={{ fontFamily: "Cerebri-Bold", paddingBottom: isMobile ? '4%' : '0%' }} variant="h1">What's Your Rate?</Typography>
							<Typography style={{ fontFamily: "Cerebri-regular" }} margin="20px 0 0 0" variant="caption">
								(How much do you want to charge people to talk to you for 15
								minutes?)
							</Typography>
						</Box>
						<Box margin={isMobile ? "5x 0px 5x 0px" : "auto 0 0"}>
							{/* <InputField
							inputHeight="70px"
							fontSize="65px"
							textAlign="center"
							type="text"
							name="rate"
							showBorder="No"
							placeHolder="Between $5 to $999"
							value={`${rate}`}
							color={isFirst && "#989898"}
							onFocus="blur()"
							// onfocus="blur()"
							readonly={true}
							// value={`$${Number(rate.substring(1)).toFixed(2)}`}
							onChange={handleChange}
						/> */}
							{/* {rate.replace('$', '') > 999 || rate.replace('$', '') < 5 ? (<span className={classes.rateValidation}>*Your rate must be between $5 and $999.</span>) : null} */}
							<Box
								inputHeight="70px"
								fontSize="65px"
								width="100%"
								showBorder="No"
								display="flex"
								justifyContent="center"
								padding="0 0 0 0"
								fontFamily="Cerebri-Bold"
							>
								<Typography style={{ fontSize: "60px", fontFamily: "Cerebri-Bold" }}>{rate}</Typography>
							</Box>
							<Box
								inputHeight="70px"
								fontSize="65px"
								width="100%"
								showBorder="No"
								display="flex"
								justifyContent="center"
								padding="0 0 0 0"
								fontFamily="Cerebri-Bold"
							>
								<Typography style={{
									color: rate.replace('$', '') > 999 || rate.replace('$', '') < 5 ? "rgb(203, 58, 58)" : "#808080",
									fontFamily: "Cerebri-Regular",
									letterSpacing: '-0.45px'
								}}
									textAlign="center"
								>
									Your rate must be between $5 and $999.
								</Typography>
							</Box>
						</Box>
						<Box style={{
							flexWrap: "wrap",
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							color: "#FFFFFF",
							fontSize: "20px",
							fontFamily: "Cerebri-Bold",
							paddingBottom: "60px"
						}}
						// margin="auto 0 0"
						>
							<div onClick={() => handlePadClick("1")} style={{ width: "33.33%", display: "flex", justifyContent: "center", cursor: "pointer", fontFamily: "Cerebri-Bold" }}>
								<p>1</p>
							</div>
							<div onClick={() => handlePadClick("2")} style={{ width: "33.33%", display: "flex", justifyContent: "center", cursor: "pointer", fontFamily: "Cerebri-Bold" }}>
								<p>2</p>
							</div>
							<div onClick={() => handlePadClick("3")} style={{ width: "33.33%", display: "flex", justifyContent: "center", cursor: "pointer", fontFamily: "Cerebri-Bold" }}>
								<p>3</p>
							</div>
							<div onClick={() => handlePadClick("4")} style={{ width: "33.33%", display: "flex", justifyContent: "center", cursor: "pointer", fontFamily: "Cerebri-Bold" }}>
								<p>4</p>
							</div>
							<div onClick={() => handlePadClick("5")} style={{ width: "33.33%", display: "flex", justifyContent: "center", cursor: "pointer", fontFamily: "Cerebri-Bold" }}>
								<p>5</p>
							</div>
							<div onClick={() => handlePadClick("6")} style={{ width: "33.33%", display: "flex", justifyContent: "center", cursor: "pointer", fontFamily: "Cerebri-Bold" }}>
								<p>6</p>
							</div>
							<div onClick={() => handlePadClick("7")} style={{ width: "33.33%", display: "flex", justifyContent: "center", cursor: "pointer", fontFamily: "Cerebri-Bold" }}>
								<p>7</p>
							</div>
							<div onClick={() => handlePadClick("8")} style={{ width: "33.33%", display: "flex", justifyContent: "center", cursor: "pointer", fontFamily: "Cerebri-Bold" }}>
								<p>8</p>
							</div>
							<div onClick={() => handlePadClick("9")} style={{ width: "33.33%", display: "flex", justifyContent: "center", cursor: "pointer", fontFamily: "Cerebri-Bold" }}>
								<p>9</p>
							</div>
							<div style={{ width: "33.33%", display: "flex", justifyContent: "center", cursor: "pointer", fontFamily: "Cerebri-Bold" }}>
							</div>
							<div onClick={() => handlePadClick("0")} style={{ width: "33.33%", display: "flex", justifyContent: "center", cursor: "pointer", fontFamily: "Cerebri-Bold" }}>
								<p>0</p>
							</div>
							<div style={{ width: "33.33%", display: "flex", justifyContent: "center", cursor: "pointer" }}>
								<CloseIcon
									style={{ color: "white", cursor: "pointer", color: '#CB3A3A' }}
									onClick={() => handleDelete()}
								/>
							</div>
						</Box>
					</Box>
					<Box position={isMobile && "fixed"} width={isMobile ? "100%" : "auto"} bottom={isMobile && 20}
						padding="auto 20px 10px 20px"
						padding="30px"
						paddingBottom="50px"
						paddingBottom={isMobile ? "10px" : "32px"}
					>
						<Button
							disabled={isDisable || isLoading || rate.replace('$', '') < 5}
							fullWidth
							onClick={() => handleSetRate(rate)}
							color="primary"
						>
							{isLoading ? <Spinner /> : "Done"}
						</Button>
					</Box>
				</Box>
			) : (
				id ?
					<Referal id={id} code={refferalCode} />
					:
					<Referal />
			)}
		</>
	);
};
export default withReducer("Rate", reducer)(withRouter(Rate));
