import React, { useRef } from "react";
import { Box, Button, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import pics from "@assets";
import { Link, withRouter } from "react-router-dom";
import { ReferalInput } from "@base";
import Alert from '@material-ui/lab/Alert'
import { Spinner } from "@base";
import { useDispatch, useSelector } from "react-redux";
import reducer from "../signupForm/store/reducers";
import withReducer from "store/withReducer";
import * as Actions from "../signupForm/store/actions";
import * as BaseActions from "store/actions";
import { ref } from "yup";
import useDeepCompareEffect from "use-deep-compare-effect";
import { isIOS, isMobile } from "react-device-detect";
import DummyImage from "@assets/dummyImage.png"
const referrerDefaultData = {
	img: pics.placeHolder,
	fullName: "",
};
const useStyles = makeStyles((theme) => ({
	image: {
		width: 100,
		height: 100,
		borderRadius: 6,
	},
	bottomBtn: {
		marginBottom: 30,
		[theme.breakpoints.up("sm")]: {
			marginBottom: 60,
		},
		[theme.breakpoints.between("md", "xl")]: {
			marginBottom: 30,
		},
		[theme.breakpoints.down("sm")]: {
			marginBottom: 35,
		},
	},
	ref: {
		margin: "10px 0 0 0",
	}
}))
const Referal = (props) => {
	const dispatch = useDispatch();
	const classes = useStyles();
	const [state, setState] = React.useState([]);
	const [isDisable, setIsDisable] = React.useState(true);
	const [referredData, setReferredData] = React.useState(referrerDefaultData);
	const [error, setError] = React.useState(false);
	const [refError, setRefError] = React.useState(false);
	const [usernameEnabled, setUsernameEnabled] = React.useState(null)
	const [username, setUsername] = React.useState("")
	const [initialScreenSize, setInitialScreenSize] = React.useState(window.innerHeight);
	const [keyboardIsShown, setKeyboardIsShown] = React.useState(false);

	const activeRef = useRef(0);
	const refs = useRef([]);
	const updateProfileResponse = useSelector(
		({ Referal }) => Referal.UpdateProfileReducer
	);
	const updateProfileResponseData = updateProfileResponse.data;
	const isLoading = updateProfileResponse.isLoading;
	const userByReferralCodeResponse = useSelector(
		({ Referal }) => Referal.GetUserByReferralCodeReducer
	);

	const { layout } = useSelector(({ BaseReducer }) => ({
		layout: BaseReducer.LayoutReducer,
	}));
	React.useEffect(() => {
		setInterval(() => {
			if (initialScreenSize > window.innerHeight) {
				!keyboardIsShown && setKeyboardIsShown(true);
			} else {
				keyboardIsShown && setKeyboardIsShown(false)
			}
		}, 1)
	}, [keyboardIsShown, initialScreenSize])
	React.useEffect(() => {
		if (layout.bottomNav) dispatch(BaseActions.updateLayout({ bottomNav: false }));
	}, [layout.bottomNav])
	React.useEffect(() => {
		if (props.code) {
			setState(props.code)
		}
	}, [])
	useDeepCompareEffect(() => {
		if (userByReferralCodeResponse.errMsg) {
			// dispatch(
			// 	BaseActions.showAlert({
			// 		variant: "error",
			// 		message: userByReferralCodeResponse.errMsg,
			// 	})
			// );
			setRefError(true);
			setIsDisable(true);
			setReferredData(referrerDefaultData);
		}
		if (
			userByReferralCodeResponse.data &&
			userByReferralCodeResponse.data.data
		) {
			setReferredData({
				img: userByReferralCodeResponse.data.data.profile_media_urls[0],
				fullName: String(userByReferralCodeResponse.data.data.full_name).toUpperCase(),
				username: String(userByReferralCodeResponse.data.data.username).toUpperCase(),
				enabledUsername: userByReferralCodeResponse.data.data.enabledUsername,
			});
			setRefError(false)
			setIsDisable(false);
			setUsernameEnabled(userByReferralCodeResponse.data.data.enabledUsername)
			setUsername(userByReferralCodeResponse.data.data.username)
		}
	}, [userByReferralCodeResponse]);
	React.useEffect(() => {
		let str = "";
		state.forEach((el) => {
			str = str + el;
		});
		if (str.trim().length < 6) {
			setIsDisable(true)
		}
		if (str.trim().length === 6) {
			setIsDisable(false)
			dispatch(Actions.getUserByReferralCode(str));
		} else {
			setIsDisable(true)
			return;
		}
	}, [state]);
	React.useEffect(() => {
		if (updateProfileResponseData) {
			if (updateProfileResponseData.status === "Success") {
				props.history.push(props.id ? `/profile-image?id=${props.id}` : "/profile-image")
			} else {
				setError(true)
			}
		}
	}, [updateProfileResponseData]);
	React.useEffect(() => {
		if (refs.current[activeRef.current]) {
			refs.current[activeRef.current].focus();
		}
	});
	const handleUpdate = (val) => {
		let str = "";
		state.forEach((el) => {
			str = str + el;
		});
		if (str.trim().length === 6) {
			console.log({ handleUpdate: "Yes" })
			dispatch(
				Actions.updateProfile({
					set_referrer: 1,
					referrer: state.join(""),
				})
			);
			props.history.push(props.id ? `/profile-image?id=${props.id}` : "/profile-image")
		} else {
			// dispatch(
			// 	BaseActions.showAlert({
			// 		variant: "error",
			// 		message: "code is missing",
			// 	})
			// );
		}
	};
	const changeHandler = (e) => {
		const { name, value } = e.target;
		if (value.trim() !== "") {
			if (value.match(/^[0-9a-zA-Z]+$/)) {
				const copy = [...state];
				copy[name] = value;
				setState(copy);
				activeRef.current = parseInt(name) + 1;
				return;
			} else {
				// dispatch(
				// 	BaseActions.showAlert({
				// 		variant: "error",
				// 		message: "only alphanumeric are allowed",
				// 	})
				// );
			}
		} else {
			const copy = [...state];
			copy[name] = value;
			setState(copy);
			activeRef.current = parseInt(name);
		}
	};

	// console.log("referal image: ", referredData.img)

	return (
		<Box
			display="flex"
			flexDirection="column"
			height="100%"
			position="absolute"
		>
			<Box padding={isMobile ? "15px 30px 0px 30px" : "32px 30px 0px 30px"} overflowY="scroll" height={isMobile ? "75vh" : "auto"}>

				<Box margin="0 0 20px 0">
					<Typography variant="h1">Who Referred You?</Typography>
					<Typography margin="20px 0 0 0" variant="caption" paddinBottom="4%">
						(Enter the 6 character's code of the person that referred you.)
					</Typography>
				</Box>
				<Box margin={isMobile && isIOS ? "60px 0" : "100px 0"}>
					<Box display="flex" margin="0 0 20px 0" justifyContent="center">
						<img
							alt="Profile Picturse"
							src={referredData.img === "https://narsunprojects.com:3001/uploads/default_pic.png" ? DummyImage : referredData.img}
							className={classes.image}
						/>
					</Box>
					<Typography variant="body1" align="center">
						{referredData && referredData.enabledUsername ? referredData.username : referredData.fullName}
					</Typography>
					<Box margin="20px 0 0 0" display="flex" justifyContent="space-between">
						{new Array(6).fill("").map((arr, idx) => {
							return (
								<ReferalInput
									key={idx}
									ref={(el) => {
										refs.current[idx] = el;
										return ref;
									}}
									disabled={props.code || state.length < idx}
									value={props.code ? String(props.code[idx]).toUpperCase() : state[idx] ? String(state[idx]).toUpperCase() : ""}
									// value={state[idx] ? String(state[idx]).toUpperCase() : ""}
									name={idx}
									onChange={changeHandler}
									blur={error}
									onFocus={error && "blur()"}
								/>
							);
						})}
					</Box>
					<Box className={classes.ref}>
						{
							refError && <Alert severity="error" variant="filled">The code you entered is invalid & valid referal code is 6 character in length</Alert>
						}
					</Box>
				</Box>
			</Box>
			<Box hidden={(isMobile && keyboardIsShown) ? true : false}>
				<Box position={isMobile && "fixed"} width={isMobile ? "100%" : "auto"} bottom={isMobile && 15}
					padding="auto 20px 10px 20px"
					padding="30px"
					paddingBottom="50px"
					paddingBottom={isMobile ? "10px" : "32px"}
					display="flex"
					justifyContent=" space-between"
				>
					<Button
						to={props.id ? `/profile-image?id=${props.id}` : "/profile-image"}
						component={Link}
						style={{ width: "45%", backgroundColor: "#080A0A" }}
						variant="outlined"
					>
						Skip
					</Button>
					<Button
						style={{ width: "45%", backgroundColor: "#31A7DB" }}
						disabled={isDisable || isLoading || refError}
						onClick={() => handleUpdate()}
						variant="contained"
					>
						{isLoading ? <Spinner /> : "OK"}
					</Button>
				</Box>
			</Box>
		</Box>
	);
};
export default withReducer("Referal", reducer)(withRouter(Referal));
