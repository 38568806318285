import React from 'react';
import AddToCal from 'react-add-to-calendar'
import "./index.css"
const AddToCalendar = ({ event, buttonLabel }) => {
    let items = [
        { google: 'Google Calendar' },
        { apple: 'iCalendar' }
    ];
    return (
        <AddToCal buttonLabel={"Select Calendar"} event={event} listItems={items}></AddToCal>
    )
}
export default AddToCalendar