import React from "react";
import Drawer from "@material-ui/core/Drawer";
import { Box, Button, Typography } from "@material-ui/core";
import { Link, withRouter } from "react-router-dom";
import { useHistory } from 'react-router-dom'

import { useDispatch, useSelector } from "react-redux";
import * as BaseActions from "store/actions";
import { isAndroid, isIOS } from "react-device-detect";
function TemporaryDrawer({ open, set, handleClose, ...props }) {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleNavigation = () => {
    dispatch(BaseActions.hideMessage())
    history.push(props.id ? `/legalStuff?id=${props.id}&code=${props.code}` : `/legalStuff`)
  }

  return (
    <React.Fragment>
      {/* <Drawer anchor="bottom" open={open}> */}
      <div
        style={{ backgroundColor: "black", color: "white", padding: 30, paddingBottom: isAndroid ? "70px" : "30px" }}
      // onClick={() => set(false)}
      >
        <Typography variant="body2">Signup Successful</Typography>
        <Typography style={{ marginTop: 36 }} variant="subtitle2">
          A confirmation email has been sent to your email address.
        </Typography>
        <Box margin="48px 0 0 0">
          <Button
            fullWidth
            // to={}
            // component={Link}
            onClick={handleNavigation}
          >
            Ok
          </Button>
        </Box>
      </div>
      {/* </Drawer> */}
    </React.Fragment >
  );
}

export default withRouter(TemporaryDrawer);
