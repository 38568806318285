import React, { useEffect } from "react";
import { withRouter } from "react-router";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Button, Modal, Typography } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import reducers from "./store/reducers";
import useDeepCompareEffect from "use-deep-compare-effect";
import withReducer from "store/withReducer";
import { useSelector, useDispatch } from "react-redux";
import * as Actions from "./store/actions";
import * as BaseActions from "store/actions";
import Header from '@base/header';
import { isIOS, isMobile } from "react-device-detect";
import Pics from "@assets";
const useStyles = makeStyles((theme) => ({
	container: {
		padding: '0 20px',
	},
	backButton: {
		color: "white",
		cursor: "pointer",
		marginRight: 5,
	},
	cards: {
		paddingBottom: '30px'
	},
	card: {
		// marginTop: 30,
		width: "100%",
		display: "flex",
		// alignItems: "center",
		margin: "20px 0",
	},
	delete: {
		color: "#CB3A3A",
		cursor: "pointer",
	},
	defaultText: {
		backgroundColor: "#09161C",
		color: "#31A7DB",
		borderRadius: 15,
		padding: "3px 8px",
		fontSize: 10,
		marginTop: 5,
	},
	gradient: {
		height: 100,
		backgroundImage: "linear-gradient(#00000000, #0A0A0A)",
		opacity: 1,
		zIndex: 9,
		position: "fixed",
		borderRadius: 6,
		width: "100%",
		bottom: 80,
		[theme.breakpoints.up("sm")]: {
			width: 370,
			bottom: 130,
		},
	},
	cardImage: {
		height: 32,
		width: 46,
		marginRight: 15,
		marginTop: 10,
	},
	modal: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		border: "none",
	},
	modalContainer: {
		outline: "none",
		position: "fixed",
		bottom: 0,
		// padding: "10px",
		borderRadius: 0,
		color: "#FFFFFF",
		backgroundColor: "#111111",
		display: "flex",
		justifyContent: "center",
		// alignItems: "center",
		width: "100%",
		marginBottom: "0",
		[theme.breakpoints.up("sm")]: {
			marginBottom: "33px",
			width: "370px",
		},
	},
	modalActionContainer: {
		marginTop: "20px",
		marginBottom: "10px",
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
	},
	bottomBtn: {
		width: '82%',
		marginLeft: '2.5rem',
		[theme.breakpoints.down('xs')]: {
			marginLeft: '2.6rem',
		},
	},
	goMiddle: {
		width: '82%',
		left: '2rem',
		position: 'absolute',
		[theme.breakpoints.down('xs')]: {
			left: '2.7rem',
		},
		bottom: "30vh"
	}
}));
function Payment({ history }) {
	const cardsData = useSelector(({ Payment }) => Payment.PaymentReducer);
	const { deleteRespone, isDrawerOpen } = useSelector(({ Payment, BaseReducer }) => ({
		deleteRespone: Payment.PaymentDeleteReducer,
		isDrawerOpen: BaseReducer.MessageReducer.state,
	}));
	const [cards, setCards] = React.useState([]);
	const [index, setIdx] = React.useState();
	const [open, setOpen] = React.useState(false);
	const dispatch = useDispatch();
	const classes = useStyles();
	useDeepCompareEffect(() => {
		if (cardsData?.data && cardsData.data.status === "Success") {
			setCards(cardsData.data.data);
			return;
		}
	}, [cardsData]);
	useDeepCompareEffect(() => {
		index && setCards(cards.filter((c) => c._id !== index));
	}, [deleteRespone]);
	React.useEffect(() => {
		dispatch(BaseActions.hideMessage());
	}, []);
	useEffect(() => {
		dispatch(Actions.fetchPayments());
	}, [dispatch]);
	useEffect(() => {
		dispatch(BaseActions.updateLayout({ bottomNav: true }))
	}, [])

	const handleDelete = () => {
		dispatch(BaseActions.updateLayout({ bottomNav: true }))
		dispatch(Actions.deletePayment(index));
		setIdx(index);
		setOpen(false);
	};
	const handleClickOpen = (c) => {
		setIdx(c._id);
		dispatch(BaseActions.updateLayout({ bottomNav: false }))
		setOpen(true);
	};
	const handleModalClose = () => {
		dispatch(BaseActions.updateLayout({ bottomNav: true }))
		setOpen(false);
	};
	return (
		<div>
			<Header heading='Payment Method' />

			<div style={{ overflowY: 'scroll', height: isMobile ? '92vh' : '70vh', paddingTop: "84px" }}>
				<Box className={classes.container}>
					<Box className={classes.cards}>
						{cards?.map((c, idx) => (
							<Box className={classes.card} key={idx}>
								<img
									alt="icon"
									className={classes.cardImage}
									src={
										c.card_type === "Visa"
											? Pics.visa
											: c.card_type === "MasterCard"
												? Pics.master
												: c.card_type === "American Express"
													? Pics.amex
													: c.card_type === "Diners Club"
														? Pics.discover
														: c.card_type === "Discover"
															? Pics.discover
															: c.card_type === "UnionPay"
																? Pics.unionPay
																: c.card_type === "JCB"
																	? Pics.JCB
																	: Pics.cardIll
									}
								/>
								<Box style={{ flexGrow: 1 }}>
									<Typography variant="body1">xxxx xxxx xxxx {c.card}</Typography>
									<Typography variant="subtitle2">
										Expires {c.exp_month}/{c.exp_year.toString().slice(2)}
									</Typography>
									{c.default_method && (
										<Typography component="span" className={classes.defaultText}>
											Default
										</Typography>
									)}
								</Box>
								<ClearIcon
									className={classes.delete}
									onClick={() => handleClickOpen(c)}
								/>
								<Modal
									open={open}
									onClose={handleModalClose}
									className={classes.modal}
									aria-labelledby="simple-modal-title"
									aria-describedby="simple-modal-description"
								>
									<Box className={classes.modalContainer}>
										<Box padding={isIOS ? "30px 30px 30px 40px" : "30px"} width="100%"> {/**370 */}
											<Typography variant="body2">Delete Payment Method</Typography>
											<Box marginTop="20px">
												<Typography variant="caption">
													Are you sure you want to delete?
												</Typography>
												<Box marginTop="auto" className={classes.modalActionContainer}>
													<Button
														onClick={handleModalClose}
														variant="outlined"
														color="default"
														style={{ width: "46%" }}
													>
														{"Cancel"}
													</Button>
													<Button
														onClick={() => handleDelete(c._id)}
														color="primary"
														style={{ width: "46%" }}
													>
														{"Yes"}
													</Button>
												</Box>
											</Box>
										</Box>
									</Box>
								</Modal>
							</Box>
						))}
					</Box>
				</Box>
				<Box>
					<Button
						fullWidth
						// className={classes.bottomBtn, cards.length ? classes.goMiddle : ""}
						className={cards.length ? classes.bottomBtn : classes.goMiddle}
						disabled={cards.length >= 10}
						onClick={() => history.push("/addPayment", { enabled: true })}
					>
						{cards.length >= 10
							? "Card Limit reached"
							: "+ Add payment method"}
					</Button>
				</Box>
			</div>
		</div>
	);
}
export default withReducer("Payment", reducers)(withRouter(Payment));
