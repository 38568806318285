import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Header from '@base/header';
import { useDispatch } from 'react-redux'
import * as BaseActions from 'store/actions'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { Box, Typography } from "@material-ui/core";
import { withRouter } from "react-router";
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    color: 'white'
  },
  flexCon: {
    display: "flex",
    flexDirection: "column",
    paddingTop: "84px",
  },
  buttonCon: {},
  questionArea: {
    display: "flex",
    cursor: "pointer",
    justifyContent: "space-between",
    padding: "20px",
  },
  optionArea: {
    display: "flex",
    cursor: "pointer",
    color: 'white',
    justifyContent: "space-between",
    padding: "10px 20px",
  },
}));
const Help = (props) => {
  const dispatch = useDispatch()
  React.useEffect(() => {
    dispatch(BaseActions.updateLayout({ bottomNav: true }))
    dispatch(BaseActions.hideMessage())
  }, [])
  const [Questions, setQuestions] = React.useState([]);
  const [noData] = React.useState(false)
  React.useEffect(() => {
    dispatch(BaseActions.showLoading(true))
    let results;
    axios.get(`${process.env.REACT_APP_ENV === "prod" ? process.env.REACT_APP_BASE_URL_PROD : process.env.REACT_APP_BASE_URL_TESTING}faqs/list-faqtype`)
      .then((response) => {

        results = response.data.data
        setQuestions(results)
      })
  }, []);
  React.useEffect(() => {
    if (Questions.length > 0) {
      dispatch(BaseActions.hideLoading());
    }
  }, [Questions])
  const classes = useStyles();
  return (
    <>
      <Box className={classes.root}>
        <Header heading='Help' />

        <div className={classes.flexCon}>
          <Box className={classes.buttonCon}>
            <QuestionList title="Hosting Questions" Questions={Questions} noData={noData} handleClick={(e) => props.history.push(`/help/questions?title=${e}`)} />
          </Box>
        </div>
        <Box style={{ position: 'absolute', bottom: '10%', width: '100%' }}>
          <OptionArea title="Any other questions ? " value='Click HERE' handleClick={() => props.history.push('/contactus')} />
        </Box>
      </Box>
    </>
  );
};
export default withRouter(Help);
const QuestionList = ({ handleClick, title, Questions, noData }) => {
  const passTitle = (e) => {
    handleClick(e)
  }

  const classes = useStyles();
  return (
    <div>
      {Questions.map((question) => (
        <Box className={classes.questionArea} onClick={() => passTitle(question.type)}>
          <Typography variant="subtitle1" style={{ fontSize: "16px", textTransform: "capitalize", fontFamily: "-apple-system, BlinkMacSystemFont, cerebri-regular", }}>{question.type} Questions</Typography>
          <ArrowForwardIosIcon />
        </Box>
      ))}
    </div>
  )
}
const OptionArea = ({ handleClick, value, title }) => {
  const classes = useStyles();
  return (
    <Box className={classes.optionArea} onClick={handleClick}>
      <Typography variant="subtitle2" style={{ fontSize: "14px" }}>{title}</Typography>
      <Typography variant="subtitle1" style={{ fontSize: "14px" }}>{value}</Typography>
    </Box>
  )
}
