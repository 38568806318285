import axios from "axios";
import * as BaseActions from "store/actions";
//SUBMIT LOGIN ACTION NAMES
export const SEARCH = "SEARCH";
export const SEARCH_IS_LOADING = "SEARCH_IS_LOADING";
export const SEARCH_FAILED = "SEARCH_FAILED";

export const SEARCH_BY_TAG = "SEARCH_BY_TAG";
export const TAG_IS_LOADING = "TAG_IS_LOADING";
export const TAG_FAILED = "TAG_FAILED";

// Helper ACTIONS:
export const tagLoading = (ms) => ({
  type: TAG_IS_LOADING,
  payload: ms,
});

export const tagFailed = (ms) => ({
  type: TAG_FAILED,
  payload: ms,
});
// Helper ACTIONS:
export const searchLoading = (ms) => ({
  type: SEARCH_IS_LOADING,
  payload: ms,
});

export const searchFailed = (ms) => ({
  type: SEARCH_FAILED,
  payload: ms,
});

export function searchProfiles(searchTerm, filter) {
  var cancel = "";
  const token = localStorage.getItem("JWTtoken");

  const { page } = filter;

  const headers = {
    Authorization: `JWT ${token}`,
    // "Content-Type": "application/json",
  };
  // const request = axios.post(
  //   `${process.env.REACT_APP_ENV === "prod" ? process.env.REACT_APP_BASE_URL_PROD :process.env.REACT_APP_BASE_URL_TESTING}search?find=${searchTerm}&page=${page}`,
  //   filter,
  //   config,
  // );
  const request = axios({
    method: "post",
    url: `${process.env.REACT_APP_ENV === "prod" ? process.env.REACT_APP_BASE_URL_PROD : process.env.REACT_APP_BASE_URL_TESTING}search`,
    params: { find: searchTerm, page: page },
    cancelToken: new axios.CancelToken((c) => {
      cancel = c;
    }),
    data: { ...filter },
    headers: headers,
  });
  return (dispatch) => {
    dispatch(searchLoading(true));
    dispatch(BaseActions.showLoading(true));

    request
      .then((response) => {
        if (response.data && response.data.status === "Success") {
          dispatch({
            type: SEARCH,
            payload: response.data,
          });
          dispatch(BaseActions.hideLoading());
        } else {
          let err = Error();
          err.message = response.data.message;
          throw err;
        }
      })
      .catch((error) => {
        // dispatch(
        //   BaseActions.showAlert({ variant: "error", message: error.message })
        // );
        if (axios.isCancel(error)) {
          dispatch(searchFailed(error));
          return;
        }
        if (error.response) {
          if (error.response.status === "404") {
            dispatch(searchFailed(`${error.response.status} : api not found`));
          } else {
            dispatch(searchFailed(error.response.status));
          }
          console.log(error.response);
        } else if (error.request) {
          dispatch(searchFailed(error.request));

          console.log(error.request);
        } else {
          console.log("Error", error.message);
          dispatch(searchFailed(error.message));
        }
        console.log(error);
      });
  };
}
export function searchByTag(tag) {
  const token = localStorage.getItem("JWTtoken");

  const config = {
    headers: {
      Authorization: `JWT ${token}`,
      // "Content-Type": "application/json",
    },
  };
  const request = axios.get(
    `${process.env.REACT_APP_ENV === "prod" ? process.env.REACT_APP_BASE_URL_PROD : process.env.REACT_APP_BASE_URL_TESTING}search/users-by-tag/${tag}?page=${1}`,

    config
  );
  return (dispatch) => {
    dispatch(tagLoading(true));
    dispatch(BaseActions.showLoading(true));

    request
      .then((response) => {
        if (response.data && response.data.status === "Success") {
          dispatch({
            type: SEARCH_BY_TAG,
            payload: response.data,
          });
          dispatch(BaseActions.hideLoading());
        } else {
          let err = Error();
          err.message = response.data.message;
          throw err;
        }
      })
      .catch((error) => {
        // dispatch(
        //   BaseActions.showAlert({ variant: "error", message: error.message })
        // );
        if (error.response) {
          if (error.response.status === "404") {
            dispatch(tagFailed(`${error.response.status} : api not found`));
          } else {
            dispatch(tagFailed(error.response.status));
          }
          console.log(error.response);
        } else if (error.request) {
          dispatch(tagFailed(error.request));

          console.log(error.request);
        } else {
          console.log("Error", error.message);
          dispatch(tagFailed(error.message));
        }
        console.log(error);
      });
  };
}
