import React, {
  // useState
} from "react";
import { withRouter } from "react-router";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import StarRateIcon from "@material-ui/icons/StarRate";
import DummyImage from "@assets/dummyImage.png"
import "./index.css";
// import Pics from "../../../../@assets";
const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px 20px",
    margin: "10px 0",
    [theme.breakpoints.down("sm")]: {
      padding: "0px 20px",
    },
    "&:hover": {
      background: "#111111",
      cursor: "pointer",
    },
  },
  dp: {
    height: 50,
    width: 50,
    borderRadius: 6,
  },
}));
function UserCard({ history, user }) {
  // console.log({ user })
  const [tags, setTags] = React.useState("");
  const classes = useStyles();
  React.useEffect(() => {
    let str = "";
    user &&
      user.tags &&
      user.tags.forEach((tag, i) => {
        let ss = `${tag.expertise}-${tag.years}yrs`;
        str = `${str} ${ss}`;
      });
    setTags(str);
  }, []);
  return (
    <Box
      className={classes.container}
      // onClick={() => history.push("/userProfile")}
      onClick={() => history.push("/search/top")}
    >
      {console.log({ profile_media_urls: user.profile_media_urls })}
      {/* <img alt="dp" src={user.profile_media_urls ? user.profile_media_urls[0] : Pics.defaultDp} className={classes.dp} /> */}
      {/* <img alt="dp" src={user.profile_media_urls ? user.profile_media_urls[0] : `${process.env.REACT_APP_ENV === "prod" ? process.env.REACT_APP_BASE_URL_PROD : process.env.REACT_APP_BASE_URL_TESTING}uploads/default_pic.png?1610711811682`} className={classes.dp} /> */}
      <img alt="dp" src={user.profile_media_urls[0] === "https://narsunprojects.com:3001/uploads/default_pic.png" ? DummyImage : user.profile_media_urls[0]} className={classes.dp} />
      <Box style={{ flexGrow: 1, marginLeft: 15 }}>
        <Typography>{user.enabledUsername ? user.username : user.full_name}</Typography>
        <Box display="flex" flexDirection="row" alignItems="center">
          <StarRateIcon style={{ color: "#FCBD3F", fontSize: 14 }} />
          <Typography variant="subtitle2">
            {user.rating} ({user.reviews_count} reviews)
          </Typography>
        </Box>
        <Box display="flex" flexDirection="row" alignItems="center">
          {tags.length <= 40 ? (
            <div className="marquee">
              <div className="content"></div>
              {tags}
            </div>
          ) : (
            <div className="marquee">
              <div className="overly" />
              <div className="track">
                <div className="content">{tags}</div>
              </div>
            </div>
          )}
        </Box>
      </Box>
      <Box
        style={{
          borderLeft: ".5px solid #80808042",
          paddingLeft: "15px",
          height: "40px",
          display: "flex",
          alignItems: "center",
          minWidth: "65px",
        }}
      >
        <Typography variant="body2">
          {user.rate <= 0 ? "Free" : `$${Number(user.rate)}`}
        </Typography>
      </Box>
    </Box>
  );
}
export default withRouter(UserCard);
