import React from "react";
import { withRouter } from "react-router";
import { Redirect } from "react-router-dom";
import Login from "components/login";
const AuthCheck = (props) => {
  const [auth, setAuth] = React.useState(false);
  React.useEffect(() => {
    const user = JSON.parse(localStorage.getItem("quarterlyUser"));
    const token = localStorage.getItem("JWTtoken");
    // setTimeout(() => {

    if (user && token) {
      setAuth(true);
    } else {
      // props.history.push("/");
      setAuth(false);
    }
    // }, 1000);
  }, [props.location, props.history]);

  return <>{auth ? <>{props.children}</> : <>{props.public}</>}</>;
};

export default withRouter(AuthCheck);
