import React from "react";
import { useStyles } from './styles';

function TopButtons({ options, pageSize, handlePageSizeChange }) {
    const classes = useStyles();

    return (
        <div className={classes.buttonsGrid}>
            {options.map((option) => (
                <button
                    key={option}
                    type="button"
                    onClick={() => handlePageSizeChange(option)}
                    value={option}
                    className={pageSize === option ? classes.topButtonActive : classes.topButton}
                >
                    Top {option}
                </button>
            ))}
        </div>
    );
}

export default TopButtons;