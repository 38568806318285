import { makeStyles } from "@material-ui/core/styles";
import { commonStyles } from "../../styles";

export const useStyles = makeStyles((theme) => ({
    tableTextHeadingUser: {
        ...commonStyles.heading,
        ...commonStyles.mediumText,
        textAlign: 'left',
        gap: '2rem',
        [theme.breakpoints.down('sm')]: {
            ...commonStyles.smallText,
        },
        [theme.breakpoints.down('xs')]: {
            ...commonStyles.smallerText,
            gap: '1rem',
            paddingLeft: '1rem',
        },
    },
    tableTextHeading: {
        ...commonStyles.heading,
        ...commonStyles.mediumText,
        ...commonStyles.centeredText,
        [theme.breakpoints.down('sm')]: {
            ...commonStyles.smallText,
        },
        [theme.breakpoints.down('xs')]: {
            ...commonStyles.smallerText,
        },
    },
}));