import React from "react";
import { Box, Typography, Button } from "@material-ui/core";
import InputGroup from "./subComponents/inputGroup";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Spinner } from "@base";
import { useDispatch, useSelector } from "react-redux";
import reducer from "../signupForm/store/reducers";
import withReducer from "store/withReducer";
import * as Actions from "../signupForm/store/actions";
import * as BaseActions from "store/actions";
import { isIOS, isMobile } from "react-device-detect";
import queryString from 'query-string'
import { Formik } from "formik";
import * as Yup from "yup";
import { initFirebase } from "@utils";
import Footer from "../../../../@base/footer";
import Header from "../../../../@base/header";

const useStyles = makeStyles((theme) => ({
	container: {
		marginBottom: "20px",
		[theme.breakpoints.down('sm')]: {
			paddingBottom: isMobile && isIOS ? "100px" : "20px",
		},
		[theme.breakpoints.between('md', 'xl')]: {
			marginBottom: "-8px",
		},
		height: isMobile ? "10vh" : "auto",
		paddingTop: "15px",
		paddingBottom: "15px",
		backgroundColor: "#080A0A"
	},
	saveButtonTop: {
		background: '#434343',
		borderRadius: 16,
		textAlign: 'center',
		color: 'white',
		padding: '8px 15px',
		display: 'block',
		height: "fit-content",
		width: "fit-content",
	},
	saveButtonLabel: {
		fontFamily: "-apple-system, BlinkMacSystemFont, cerebri-regular",
		fontWeight: 700,
		fontSize: "12px !important",
		lineHeight: '14.32px',
		letterSpacing: '0%',
	},
	overflowY: {
		overflowY: "auto",
		[theme.breakpoints.up("sm")]: {
			height: "calc(100vh - 390px)",
		},
		height: "calc(100vh - 317px)",
	},
	errorText: {
		position: 'absolute',
		fontFamily: "-apple-system, BlinkMacSystemFont, cerebri-regular",
		color: 'rgb(203, 58, 58)',
		fontSize: '14px',
	},
	errorText1: {
		marginTop: '-20px',
		marginLeft: '39px'
	},
	errorText2: {
		marginTop: '-20px',
		marginRight: '50px',
		right: '0',
	},
	backButton: {
		borderColor: "#6B6B6B !important",
		fontWeight: 600,
		fontSize: 18,
		lineHeight: "21.48px",
		letterSpacing: "0%",
		textAlign: "center",
		borderRadius: 10,
	},
	saveButton: {
		fontWeight: 600,
		fontSize: 18,
		lineHeight: "21.48px",
		letterSpacing: "0%",
		textAlign: "center",
		borderRadius: 10,
	},
	marginBottom: {
		marginBottom: 25,
	},
}));

const Expertise = (props) => {
	const dispatch = useDispatch();
	const updateProfileResponse = useSelector(
		({ Expertise }) => Expertise.UpdateProfileReducer
	);
	const updateProfileResponseData = updateProfileResponse.data;
	const isLoading = updateProfileResponse.isLoading;
	const [fromExpertise, setFromExpertise] = React.useState(false);
	const id = queryString.parse(props.location.search).id
	const { layout } = useSelector(({ BaseReducer }) => ({
		layout: BaseReducer.LayoutReducer,
	}));
	const [initialScreenSize,] = React.useState(window.innerHeight);
	const [keyboardIsShown, setKeyboardIsShown] = React.useState(false);

	React.useEffect(() => {
		setInterval(() => {
			if (initialScreenSize > window.innerHeight) {
				!keyboardIsShown && setKeyboardIsShown(true);
			} else {
				keyboardIsShown && setKeyboardIsShown(false)
			}
		}, 1)
	}, [keyboardIsShown, initialScreenSize])

	React.useEffect(() => {
		if (layout.bottomNav) dispatch(BaseActions.updateLayout({ bottomNav: false }));
	}, [layout.bottomNav])

	React.useEffect(() => {
		if (updateProfileResponseData && fromExpertise) {
			if (updateProfileResponseData.status === "Success") {
				// route here
				if (props.tags) {
					props.handleBackToEdit();
					return;
				} else if (id) {
					props.history.push(`/userprofile/${id}`);
				}
				else {
					props.history.push("/home");
				}
			}
		}
	}, [updateProfileResponseData]);

	const submitExpertise = (valuesObj) => {
		initFirebase(dispatch);
		setFromExpertise(true)
		let bluePrint = ["expertise", "years"];
		let arr = [];
		for (let i = 1; i <= 5; i++) {
			let a = valuesObj[`${bluePrint[0]}${i}`];
			let b = valuesObj[`${bluePrint[1]}${i}`];
			if (a) {
				arr.push({ expertise: a, years: b });
			}
		}
		let expOfArray = [];
		arr.forEach((obj) => {
			expOfArray.push(obj["expertise"]);
		});
		let filteredArray = [...new Set(expOfArray)];
		if (filteredArray.length === arr.length) {
			if (props.tags) {
				props.handleTagsChange(arr)
				props.backToEdit()
			} else {
				dispatch(Actions.updateProfile({ tags: arr }));
			}
		}
	};

	const classes = useStyles();

	return (
		<Formik
			initialValues={{
				expertise1:
					props.tags && props.tags[0] ? props.tags[0].expertise.trim() : "",
				years1: props.tags && props.tags[0] ? Number(props.tags[0].years) : 1,
				expertise2:
					props.tags && props.tags[1] ? props.tags[1].expertise.trim() : "",
				years2: props.tags && props.tags[1] ? Number(props.tags[1].years) : 1,
				expertise3:
					props.tags && props.tags[2] ? props.tags[2].expertise.trim() : "",
				years3: props.tags && props.tags[2] ? Number(props.tags[2].years) : 1,
				expertise4:
					props.tags && props.tags[3] ? props.tags[3].expertise.trim() : "",
				years4: props.tags && props.tags[3] ? Number(props.tags[3].years) : 1,
				expertise5:
					props.tags && props.tags[4] ? props.tags[4].expertise.trim() : "",
				years5: props.tags && props.tags[4] ? Number(props.tags[4].years) : 1,
			}}
			onSubmit={(values) => {
				console.table(values);
				submitExpertise(values);
			}}
			validationSchema={Yup.object().shape({
				expertise1: Yup.string()
					.trim()
					.min(2, "*Must be at least 2 characters")
					.max(45, "*Must be less than 45 characters"),
				years1: Yup.string()
					.trim()
					.test(
						"*years",
						"*1 - 45",
						(value) => Number(value) >= 1 && Number(value) <= 45
					)
					.min(1, "*min 1")
					.max(2, "*max 2"),
				expertise2: Yup.string()
					.trim()
					.min(2, "*Must be at least 2 characters")
					.max(45, "*Must be less than 45 characters"),
				years2: Yup.string()
					.trim()
					.test(
						"*years",
						"*1 - 45",
						(value) => Number(value) >= 1 && Number(value) <= 45
					)
					.min(1, "*min 1")
					.max(2, "*max 2"),
				expertise3: Yup.string()
					.trim()
					.min(2, "*Must be at least 2 characters")
					.max(45, "*Must be less than 45 characters"),
				years3: Yup.string()
					.trim()
					.test(
						"*years",
						"*1 - 45",
						(value) => Number(value) >= 1 && Number(value) <= 45
					)
					.min(1, "*min 1")
					.max(2, "*max 2"),
				expertise4: Yup.string()
					.trim()
					.min(2, "*Must be at least 2 characters")
					.max(45, "*Must be less than 45 characters"),
				years4: Yup.string()
					.trim()
					.test(
						"*years",
						"*1 - 45",
						(value) => Number(value) >= 1 && Number(value) <= 45
					)
					.min(1, "*min 1")
					.max(2, "*max 2"),
				expertise5: Yup.string()
					.trim()
					.min(2, "*Must be at least 2 characters")
					.max(45, "*Must be less than 45 characters"),
				years5: Yup.string()
					.trim()
					.test(
						"*years",
						"*1 - 45",
						(value) => Number(value) >= 1 && Number(value) <= 45
					)
					.min(1, "*min 1")
					.max(2, "*max 2"),
			})}
		>
			{(formikProps) => {
				const {
					values,
					dirty,
					isValid,
					setFieldValue,
					handleBlur,
					handleSubmit,
				} = formikProps;

				const hasDuplicates = (key, val) => {
					let isDuplicate = false;
					["expertise1", "expertise2", "expertise3", "expertise4", "expertise5"].forEach((k) => {
						isDuplicate = k === key ? isDuplicate : (isDuplicate || (values[k].trim() === val.trim()))
					})

					return isDuplicate;
				}

				const expertiseFields = [
					values.expertise1,
					values.expertise2,
					values.expertise3,
					values.expertise4,
					values.expertise5,
				];

				const expertValues = [
					{ len: values.expertise1.length, val: values.expertise1 },
					{ len: values.expertise2.length, val: values.expertise2 },
					{ len: values.expertise3.length, val: values.expertise3 },
					{ len: values.expertise4.length, val: values.expertise4 },
					{ len: values.expertise5.length, val: values.expertise5 },
				];

				// Check if all expertise fields have at least 2 characters
				const hasMinExpertise = expertiseFields.some(exp => exp.length >= 2);

				// Check if there are duplicate values with length > 2
				const duplicateExpertise = expertValues.some(({ len, val }, i) =>
					len > 2 && expertValues.some(({ val: otherVal }, j) => i !== j && val === otherVal)
				);

				const isSubmitDisabled = !dirty || !isValid || isLoading || !hasMinExpertise || duplicateExpertise;

				return (
					<>
						<Header
							backArrow
							onBack={props.backToEdit}
							heading={'Expertise'}
						/>

						<form id="expertise-form" onSubmit={handleSubmit} style={{ height: "100%" }}>
							<Box
								display="flex"
								flexDirection="column"
								height="100%"
							>
								<Box padding="90px 20px 40px">
									<Typography style={{
										fontFamily: "-apple-system, BlinkMacSystemFont, cerebri-regular",
										fontWeight: 600,
										fontSize: 33,
										lineHeight: "40px",
										letterSpacing: "0%",
										color: "#31A7DB",
										textAlign: "left",
									}}>
										What are your areas of Expertise?
									</Typography>
								</Box>
								<Box
									padding="0 20px 30px"
									flexDirection="column"
									className={classes.overflowY}
								>
									{
										[
											["expertise1", "years1", "1st"],
											["expertise2", "years2", "2nd"],
											["expertise3", "years3", "3rd"],
											["expertise4", "years4", "4th"],
											["expertise5", "years5", "5th"]
										].map((val, index) => {
											return (
												<>
													<InputGroup
														classes={{ root: classes.marginBottom }}
														key={index}
														name={val[0]}
														number={val[2]}
														expertiseProps={{
															value: values[val[0]],
															setFieldValue,
															handleBlur,
															name: val[0],
															label: "",
														}}
														yearsProps={{
															value: parseInt(values[val[1]], 10),
															setFieldValue,
															handleBlur,
															name: val[1],
															label: "",
														}}
														errors={[
															val[0].length < 2 ? "*Must be at least 2 characters" : null,
															val[0].length > 45 ? "*Must be less than 45 characters" : null,
															values[val[1]] > 45 ? "*1 - 45" : null,
															values[val[0]] && hasDuplicates(val[0], values[val[0]])
																? "*Duplicate entry" : null
														]}
													/>
												</>
											)
										})
									}
								</Box>
							</Box>
						</form>

						<Footer>
							<Box display="flex" gridColumnGap={12} padding="16px 20px 34px">
								{
									props.tags &&
									<Button
										fullWidth
										variant="outlined"
										className={classes.backButton}
										onClick={props.backToEdit}
									>
										Back
									</Button>
								}

								<Button
									type="submit"
									form="expertise-form"
									fullWidth
									className={classes.backButton}
									disabled={isSubmitDisabled}
								>
									{isLoading ? <Spinner /> : "Done"}
								</Button>
							</Box>
						</Footer>
					</>
				);
			}}
		</Formik >
	);
};
export default withReducer("Expertise", reducer)(withRouter(Expertise));
