import React from "react";
import { Box, Typography, Button } from "@material-ui/core";
import { BasicSwitch } from "@base";
import { isIOS } from "react-device-detect";

const DefaultAvailabilityConfirmation = ({ handleClose, id }) => {
  const [checked, setChecked] = React.useState(false);
  const handleChange = () => {
    setChecked(!checked);
  };
  const setRemember = () => {
    localStorage.setItem(id, JSON.stringify(true));
  };
  const unSetRemember = () => {
    localStorage.removeItem(id);
  };


  React.useEffect(() => {
    checked ? setRemember() : unSetRemember();
  }, [checked]);
  return (
    <Box padding="30px" marginBottom={isIOS ? "60px" : "40px"}>
      <Typography variant="body2">Set my availability</Typography>
      <Box marginTop="20px">
        <Typography variant="caption">
          By default, the app will make you available<br />
          9AM to 5PM. To adjust this, click on the <br />
          Down arrow to the right of the date and <br />
          define which Quartrs you are available for <br />
          that particular day.
        </Typography>
      </Box>

      <Box marginTop="30px" display="flex" alignItems="center">
        <BasicSwitch isDark checked={checked} onChange={handleChange} />
        <Box marginLeft="15px">
          <Typography variant="subtitle1" style={{ fontSize: "16px" }}>
            Do not show this again
          </Typography>
        </Box>
      </Box>
      <Box marginTop="30px">
        <Button
          onClick={() => {
            handleClose && handleClose();
            return;
          }}
          fullWidth
        >
          Ok
        </Button>
      </Box>
    </Box>
  );
};

export default DefaultAvailabilityConfirmation;
