import React from 'react';

function CalendarIcon({ ...props }) {
    return (
        <svg width={props.width ?? '26'} height={props.height ?? '26'} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M25.6667 16.3333V14C25.6667 13.0212 25.6667 12.1512 25.6516 11.375H2.34839C2.33334 12.1512 2.33334 13.0212 2.33334 14V16.3333C2.33334 20.7331 2.33334 22.933 3.70018 24.2998C5.06702 25.6667 7.2669 25.6667 11.6667 25.6667H16.3333C20.7331 25.6667 22.9331 25.6667 24.2998 24.2998C25.6667 22.933 25.6667 20.7331 25.6667 16.3333Z"
                fill={props.fill} />
            <path
                d="M9.04168 2.91669C9.04168 2.43344 8.64992 2.04169 8.16668 2.04169C7.68343 2.04169 7.29168 2.43344 7.29168 2.91669V4.75916C5.61246 4.89362 4.51007 5.22362 3.70017 6.03352C2.89027 6.84342 2.56027 7.9458 2.42581 9.62502H25.5742C25.4398 7.9458 25.1097 6.84342 24.2998 6.03352C23.4899 5.22362 22.3875 4.89362 20.7083 4.75916V2.91669C20.7083 2.43344 20.3166 2.04169 19.8333 2.04169C19.3501 2.04169 18.9583 2.43344 18.9583 2.91669V4.68174C18.1822 4.66669 17.3122 4.66669 16.3333 4.66669H11.6667C10.6878 4.66669 9.81782 4.66669 9.04168 4.68174V2.91669Z"
                fill={props.fill} />
        </svg>
    )
}

export default CalendarIcon;