import axios from "axios";
import * as BaseActions from "store/actions";
//SUBMIT LOGIN ACTION NAMES
export const FETCH_PROFILE = "FETCH_PROFILE";
export const PROFILE_IS_LOADING = "PROFILE_IS_LOADING";
export const PROFILE_FAILED = "PROFILE_FAILED";

// Helper ACTIONS:
export const profileLoading = (ms) => ({
    type: PROFILE_IS_LOADING,
    payload: ms,
});

export const profileFailed = (ms) => ({
    type: PROFILE_FAILED,
    payload: ms,
});




export function fetchProfile(id) {
    // let data = { user_id: id };
    const token = localStorage.getItem("JWTtoken");

    // console.log(data, "==>params", token, "<== token");

    const config = {
        headers: {
            Authorization: `JWT ${token}`,
            // "Content-Type": "application/json",
        },
    };
    const request = axios.get(
        `${process.env.REACT_APP_ENV === "prod" ? process.env.REACT_APP_BASE_URL_PROD : process.env.REACT_APP_BASE_URL_TESTING}users/getprofilefromid?uid=${id}`,
        config
    );
    return (dispatch) => {
        dispatch(profileLoading(true));
        dispatch(BaseActions.showLoading(true));

        request
            .then((response) => {
                if (response.data && response.data.status === "Success") {
                    console.log({ response: response.data })
                    console.log({
                        obj: {
                            type: FETCH_PROFILE,
                            payload: response.data,
                        }
                    })
                    dispatch({
                        type: FETCH_PROFILE,
                        payload: response.data,
                    });
                    dispatch(BaseActions.hideLoading());
                } else {
                    let err = Error();
                    err.message = response.data.message;
                    throw err;
                }
            })
            .catch((error) => {
                // dispatch(
                //     BaseActions.showAlert({ variant: "error", message: error.message })
                // );
                if (error.response) {
                    if (error.response.status === "404") {
                        dispatch(profileFailed(`${error.response.status} : api not found`));
                    } else {
                        dispatch(profileFailed(error.response.status));
                    }
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else if (error.request) {
                    dispatch(profileFailed(error.request));

                    console.log(error.request);
                } else {
                    console.log("Error", error.message);
                    dispatch(profileFailed(error.message));
                }
                console.log(error);
            });
    };
}
