import { makeStyles, Switch } from "@material-ui/core";
import React from "react";

import { useDispatch } from "react-redux";
import * as BaseActions from "store/actions";
import AvailabilityDrawer from "components/availabilityDrawer";
const useStyles = makeStyles((theme) => ({
  root: {
    width: 56,
    height: 34,
    padding: 0,
    margin: theme.spacing(1),
    marginRight: 0,
  },
  switchBase: {
    padding: 5,
    color: "#808080",
    "&$checked": {
      transform: "translateX(22px)",
      color: "#808080",
      "& + $track": {
        backgroundColor: "#353535",
        opacity: 1,
        border: "none",
      },
    },
  },
  switchBaseDark: {
    padding: 5,
    "&$checked": {
      transform: "translateX(22px)",
      color: "#31A7DB",
      "& + $trackDark": {
        backgroundColor: "#09161C",
        opacity: 1,
        border: "none",
      },
      "& > .thumbDark": {
        color: "#31A7DB !important",
      },
    },
    "&$focusVisible $thumbDark": {
      color: "#31A7DB",
    },
  },
  thumb: {
    width: 24,
    height: 24,
    // color:'#fff'
  },
  thumbDark: {
    width: 24,
    height: 24,
    // color: "#fff",
  },
  track: {
    borderRadius: 34 / 2,
    backgroundColor: "#353535",
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  trackDark: {
    borderRadius: 34 / 2,
    backgroundColor: "#808080",
    color: "#31A7DB",
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}));

function BasicSwitch({ isDark, checked, onChange, ...props }) {
  //   const dispatch = useDispatch();
  const classes = useStyles();
  const [defaultChecked, setDefaultChecked] = React.useState(false);

  const handleClose = () => {
    setDefaultChecked(!defaultChecked);
  };

  //   React.useEffect(() => {
  //     checked
  //       ? dispatch(BaseActions.showMessage(<AvailabilityDrawer handleClose={handleClose} />))
  //       : dispatch(BaseActions.hideMessage());
  //   }, [checked, dispatch]);
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      checked={checked ? checked : defaultChecked}
      onChange={() => {
        onChange ? onChange() : handleClose();
      }}
      classes={{
        root: classes.root,
        switchBase: isDark ? classes.switchBaseDark : classes.switchBase,
        thumb: isDark ? classes.thumbDark : classes.thumb,
        track: isDark ? classes.trackDark : classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
}

export default React.memo(BasicSwitch);
