import React from "react";
import { withRouter } from "react-router";
import moment from "moment";
import { Box, Typography, Button } from "@material-ui/core";
import pics from "@assets";
import {useDispatch} from 'react-redux'
import * as BaseActions from 'store/actions'

const QuartrEnded = ({ quarter, ...props }) => {
const dispatch = useDispatch()

React.useEffect(() => {
  dispatch(BaseActions.updateLayout({bottomNav:false}))
  return ()=>{
   dispatch(BaseActions.updateLayout({bottomNav:true}))


 }
}, [])
  return (
    <>
      <Box padding="30px"
      backgroundColor="#111111"
      //  paddingBottom="60px"
       >
        <Box textAlign="center">
          <div
            style={{
              backgroundColor: "#31A7DB",
              borderRadius: "6px",
              width: "56px",
              height: "56px",
              padding: "10px",
              position: "relative",
              left: "calc(50% - 33px)",
            }}
          >
            <img
              src={pics.Qlogo}
              alt={pics.Qlogo}
              style={{
                width: "24px",
                height: "25px",
                position: "absolute",
                top: "calc(50% - 14.5px)",
                left: "calc(50% - 9px)",
              }}
            />
          </div>
        </Box>
        <Box marginTop="30px">
          <Typography variant="body2" style={{ fontSize: "18px" }}>
          Quartr ended
          </Typography>
        </Box>
        <Box marginTop="22px">
          <Typography variant="caption" style={{ fontSize: "16px" }}>
          Thanks for hosting a Quartr!
            <span
             
              style={{ textTransform:"capitalize" }}
            > 
              {" "}
            {props.name}
             </span> {" "}
            will give you a review shortly.
          </Typography>
        </Box>

        <Box marginTop="40px" paddingBottom="10px">
          <Button fullWidth onClick={() => props.handleClose()}>
            Ok
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default withRouter(QuartrEnded);
QuartrEnded.defaultProps = {
    name:  "Travis Scott "
}