import React from "react";
import { makeStyles } from "@material-ui/styles";
import { withRouter } from "react-router-dom";
import { TimerManager, initFirebase } from "@utils";
import pics from "@assets";
import SocketClass from "@utils/socketService";
import * as Actions from "./store/actions";
import * as BaseActions from "store/actions";
import reducer from "./store/reducers";
import withReducer from "store/withReducer";
import { useDispatch, useSelector } from "react-redux";
import useDeepCompareEffect from "use-deep-compare-effect";
import Spinner from "@base/spinner/testSpinner";
// import moment from "moment";
import moment from 'moment-timezone'

// import firebase from "@firebase";
let currentReducer1 = {
  isLoading: false,
  errMsg: null,
  data: {
    status: "Success",
    message: "Successfully Fetched Appointment",
    data: {
      current_appointment: {
        _id: "5fe84234f5e76c64a90394c9",
        createdAt: "2020-12-27T08:13:40.444Z",
        updatedAt: "2020-12-27T08:13:40.847Z",
        attendee: {
          _id: "5fb76efb6268a2452965f402",
          full_name: "rizwan rizwan",
          profile_media_urls: [
            `${process.env.REACT_APP_ENV === "prod" ? process.env.REACT_APP_BASE_URL_PROD : process.env.REACT_APP_BASE_URL_TESTING}uploads/default_pic.png`,
            `${process.env.REACT_APP_ENV === "prod" ? process.env.REACT_APP_BASE_URL_PROD : process.env.REACT_APP_BASE_URL_TESTING}uploads/default_pic.png`,
            `${process.env.REACT_APP_ENV === "prod" ? process.env.REACT_APP_BASE_URL_PROD : process.env.REACT_APP_BASE_URL_TESTING}uploads/default_pic.png`,
          ],
        },
        host: {
          _id: "5f8fe3099f1b2a2ad078e33c",
          full_name: "hammad ali",
          profile_media_urls: [
            `https://${process.env.REACT_APP_ENV === "prod" ? process.env.REACT_APP_PROD_S_THREE_BUCKET_NAME : process.env.REACT_APP_TESTING_S_THREE_BUCKET_NAME}.s3.amazonaws.com/uploads/5f8fe3099f1b2a2ad078e33c/pic0`,
            "https://firebasestorage.googleapis.com/v0/b/quartrly-23168.appspot.com/o/assets%2F5f8fe3099f1b2a2ad078e33c%2Fpic1?alt=media&token=93b82030-e813-4102-a45b-c6dc6d9844f6",
            `https://${process.env.REACT_APP_ENV === "prod" ? process.env.REACT_APP_PROD_S_THREE_BUCKET_NAME : process.env.REACT_APP_TESTING_S_THREE_BUCKET_NAME}.s3.amazonaws.com/uploads/5f8fe3099f1b2a2ad078e33c/pic2`,
          ],
        },
        call_at: "2020-12-27T08:15:00.000Z",
        receipt_id: "5fe84233f5e76c64a90394c7",
        break_time_span_for_host: [],
        total_break_time_in_sec: 0,
        call_ended_at: null,
        last_break_start_time: null,
        oncall: false,
        host_token:
          "T1==cGFydG5lcl9pZD00NzA1NjY1NCZzaWc9YzAxMzE1YTA5ODliZGFlYmE0Y2FlNmQ2Mzg0ODhiMzZhOWNiN2VhNjpzZXNzaW9uX2lkPTJfTVg0ME56QTFOalkxTkg1LU1UWXdPVEExTmpneU1EZ3hPWDVLVWtKTWNXaGxSeTlqVEd0S0syOTNZVWh0TW04MlIxWi1VSDQmY3JlYXRlX3RpbWU9MTYwOTA1NjgyMSZub25jZT0wLjEzMjU4NDA4NzAwMzYzNjU1JnJvbGU9bW9kZXJhdG9yJmV4cGlyZV90aW1lPTE2MDkwNTc4NDEmY29ubmVjdGlvbl9kYXRhPW5hbWUlM0RyaXp3YW4lMjByaXp3YW4maW5pdGlhbF9sYXlvdXRfY2xhc3NfbGlzdD1mb2N1cw==",
        attendee_token:
          "T1==cGFydG5lcl9pZD00NzA1NjY1NCZzaWc9OTAxMDcxYTI1YWUzMGJjMmRhYzhlZTlkYjdmNmQ1NTA3ODkzZjQxYjpzZXNzaW9uX2lkPTJfTVg0ME56QTFOalkxTkg1LU1UWXdPVEExTmpneU1EZ3hPWDVLVWtKTWNXaGxSeTlqVEd0S0syOTNZVWh0TW04MlIxWi1VSDQmY3JlYXRlX3RpbWU9MTYwOTA1NjgyMSZub25jZT0wLjk2OTkyNDkzNzIzMDMwNzQmcm9sZT1tb2RlcmF0b3ImZXhwaXJlX3RpbWU9MTYwOTA1Nzg0MSZjb25uZWN0aW9uX2RhdGE9bmFtZSUzRHJpendhbiUyMHJpendhbiZpbml0aWFsX2xheW91dF9jbGFzc19saXN0PWZvY3Vz",
        room_id:
          "2_MX40NzA1NjY1NH5-MTYwOTA1NjgyMDgxOX5KUkJMcWhlRy9jTGtKK293YUhtMm82R1Z-UH4",
        rejected_by: {
          at: null,
          user: null,
        },
        second_accepted_by: {
          at: null,
          user: null,
        },
        first_accepted_by: {
          at: null,
          user: null,
        },
        cancelled_by: {
          deduction_percent: 0,
          at: null,
          user: null,
        },
        type: "booking",
        payout_status: "unpaid",
        status: "scheduled",
        host_accepted: false,
        number_of_no_answer_by_host: 0,
        attendee_accepted: false,
        number_of_no_answer_by_attendee: 0,
        __v: 0,
        remaining_seconds: 563,
        is_host: true,
        before_call_started: true,
      },
      waiting_appointment: null,
    },
  },
};
const useStyles = makeStyles((theme) => ({
  splash: {
    background: `url('${process.env.REACT_APP_ENV === "prod" ? process.env.REACT_APP_BASE_URL_PROD : process.env.REACT_APP_BASE_URL_TESTING}uploads/web-background.png')`,
    // background: `url('assets/images/web-background.png')`,
    height: '100vh',
    backgroundSize: 'cover',
    backgroundAttachment: 'fixed',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '100% 100%',
    display: 'flex',
    justifyContent: 'space-around',
    overflow: 'hidden',
    alignItems: 'center',
    '& > h1': {
      color: 'white',
    },
  },
}))
let checkDispatch = (dispatch, data) => {
  dispatch(BaseActions.showTimer(data));
};
const SplashScreen = (props) => {
  const dispatch = useDispatch();
  const [userUpdated, setUserUpdated] = React.useState(false);
  const [isError, setIsError] = React.useState(false);
  const [go, setGo] = React.useState(false);
  const [current, setCurrent] = React.useState(false);
  const currentReducer = useSelector(
    ({ Splash }) => Splash.CurrentQuartrStateReducer
  );
  const profileReducer = useSelector(
    ({ Splash }) => Splash.UpdateProfileReducer
  );
  useDeepCompareEffect(() => {
    if (profileReducer.data && profileReducer.data.status === "Success") {
      // setTimeout(() => {
      setUserUpdated(true);
      dispatch(Actions.checkQuartrState());
      // }, 500)
      return;
    }
    if (profileReducer.errMsg) {
      setIsError("oops! Something went wrong. </br> Please refresh your page.");
    }
  }, [profileReducer]);
  useDeepCompareEffect(() => {
    // alert('hitted')
    // dispatch(
    //   BaseActions.showTimer(fakeDate.data)
    // );
    if (currentReducer.data && currentReducer.data.status === "Success") {
      // if (!currentReducer.data.data.current_appointment) {
      //   if (profileReducer.data.data.rate_your_call.length !== 0) {
      //     setGo(true);
      //     props.history.push({
      //       pathname: "/rating",
      //       data: profileReducer.data.data.rate_your_call,
      //     });
      //   }
      // }
      if (currentReducer.data.data.current_appointment) {
        // alert("in a quartr");
        setCurrent(true);
        console.log(currentReducer.data.data, 'data')
        if (currentReducer.data.data.current_appointment.remaining_seconds) {
          console.log(currentReducer.data.data.current_appointment.remaining_seconds)
          localStorage.setItem(
            "remainingTimer",
            String(
              currentReducer.data.data.current_appointment.remaining_seconds
            )
          );
        }
        if (currentReducer.data.data.waiting_appointment) {
          // dispatch(
          //   BaseActions.showTimer(currentReducer.data.data.waiting_appointment)
          // );
          TimerManager(
            dispatch,
            currentReducer.data.data.waiting_appointment,
            currentReducer.data.data.waiting_appointment.remaining_seconds
          );
          // checkDispatch(dispatch,currentReducer.data.data.waiting_appointment)
        }
        setGo(true);
        // it will redirect user when his/her quartr will started
        props.history.push(
          `/quartr/${currentReducer.data.data.current_appointment._id}`
        );
        return;
      }
      if (currentReducer.data.data.waiting_appointment) {
        TimerManager(
          dispatch,
          currentReducer.data.data.waiting_appointment,
          currentReducer.data.data.waiting_appointment.remaining_seconds
        );
        // checkDispatch(dispatch,currentReducer.data.data.waiting_appointment)
        // dispatch(
        //   BaseActions.showTimer(currentReducer.data.data.waiting_appointment)
        // );
        setGo(true);
        return;
      }
      setGo(true);
    }
    if (currentReducer.errMsg) {
      setIsError("oops! Something went wrong. </br> Please refresh your page.");
    }
  }, [currentReducer]);

  React.useEffect(() => {
    console.log('splash mount');
    console.log({ version: "1.5" });
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const momentTZ = moment().tz(timeZone)
    console.log({ momentTZ })
    console.log({ gte: moment(momentTZ).day(moment(momentTZ).day() >= 4 ? 4 : -3) })

    localStorage.removeItem("remainingTimer");
    if (localStorage.getItem("JWTtoken")) {
      SocketClass.init(`${localStorage.getItem("JWTtoken")}`, dispatch);
      initFirebase(dispatch);
    } else {
      setGo(true);
    }
  }, []);

  React.useEffect(() => {
    return () => {
      let datas = {
        rate_your_call: []
      };
      dispatch(Actions.updateProfile(datas));
    }
  }, [])
  useDeepCompareEffect(() => {
    const condition = profileReducer.data &&
      profileReducer.data.data &&
      profileReducer.data.data.rate_your_call.length !== 0
    const pathname = window.location.pathname;
    if (condition) {
      !current &&
        props.history.push({
          pathname: "/rating",
          data: profileReducer.data.data.rate_your_call,
        });
    } else if (!condition && pathname === "rating") {
      props.history.back()
    }
  }, [profileReducer, current]);
  // React.useEffect(() => {
  // }, [current]);
  const classes = useStyles();
  return go ? (
    props.children
  ) : (
    <div className={classes.splash}>
      {!isError ? (
        // <img src={pics.splash_gif} alt="splash" />
        <Spinner />
      ) : (
        <div style={{ textAlign: "center" }}>
          {" "}
          <h1 style={{ color: "#808080" }}>
            oops! Something went wrong. <br /> Please refresh your page.
          </h1>
          <button
            onClick={() => {
              window.location.reload();
            }}
          >
            Refresh
          </button>
        </div>
      )}
      {/* <h1>Loading...</h1> */}
    </div>
  );
};
export default withReducer("Splash", reducer)(withRouter(SplashScreen));
