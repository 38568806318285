import React from "react";
// import Header from "components/home/subComponents/header";
import { withRouter } from "react-router";
// import { makeStyles } from "@material-ui/core/styles";
// import { Box, Typography } from "@material-ui/core";
import UserCard from "components/search/subComponents/userCard";
import { useDispatch, useSelector } from "react-redux";
import * as BaseActions from "store/actions";
import * as Actions from "components/search/store/actions";
import reducer from "components/search/store/reducers";
import withReducer from "store/withReducer";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import useDeepCompareEffect from "use-deep-compare-effect";
function AllSearchProfiles({ history, ...props }) {
  const dispatch = useDispatch();
  const [users, serUsers] = React.useState([]);
  const fetchedProfiles = useSelector(({ Search }) => Search.TagReducer);
  React.useEffect(() => {
    dispatch(BaseActions.updateLayout({ bottomNav: false }));
    dispatch(Actions.searchByTag(props.location.state));
  }, []);
  useDeepCompareEffect(() => {
    console.log(fetchedProfiles);
  }, [fetchedProfiles]);
  useDeepCompareEffect(() => {
    if (fetchedProfiles.data) {
      serUsers(fetchedProfiles.data.data.users);
    }
  }, [fetchedProfiles]);
  const handleNavigate = (path, data) => {
    history.push({ pathname: path, state: { ...data, tag: props.location.state } });
  };

  const handleGoBack = () => {
    console.log('window.location ===========',window.location)
    const tag = history.location.state?.tag
   if (window.location.pathname.includes('profilesintag')) {
      // history.push({ pathname: `/profilesintag`, state: tag })
      history.push({ pathname: `/search/tags`})
  
  
    } 
    else {
    
      history.goBack()
    }
  }
  return (
    <div >
      <ArrowBackIosIcon color="secondary" style={{ marginTop: '20px', marginLeft: '20px', cursor: 'pointer' }}
      //  onClick={() => history.goBack()}
      onClick={handleGoBack}
        />
      {users?.map((u, i) => (
        <div onClick={() => handleNavigate(`/userprofile/${u._id}?from=expertise`, u)}>
          <UserCard key={i} id={i} user={u} />
        </div>
      ))}
    </div>
  );
}
export default withReducer("Search", reducer)(withRouter(AllSearchProfiles));
