import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import HomeIcon from "./icons/HomeIcon";
import useDeepCompareEffect from "use-deep-compare-effect";
import SetYourAvailabilityMessage from "components/setYourAvailabilityMessage";
import {useDispatch, useSelector} from "react-redux";
import * as BaseActions from "store/actions";
import {useHistory, useLocation, withRouter} from "react-router-dom";
import clsx from 'clsx'
import Axios from "axios";
import './bottomNav.css'
import CalendarIcon from "./icons/CalendarIcon";
import RingingBellIcon from "./icons/RingingBellIcon";
import UserIcon from "./icons/UserIcon";
import BottomNavItemLabel from "./BottomNavItemLabel";

const useStyles = makeStyles({
	root: {
		width: "100%",
		background: "#262626",
		height: "80px",
	},
	hidden: {
		visibility: "hidden"
	},
	navigationActionButton: {
		padding: "0 !important",
	},
	infobubble: {
		borderRadius: "9999px",
		color: "white",
		backgroundColor: "#D42222",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		position: "absolute",
		left: 19,
		top: -1,
		height: "17px",
		aspectRatio: '1 / 1',
		border: "1.5px solid white",
	},
	infoBubbleText: {
		alignSelf: "center",
		fontSize: '13px',
		fontWeight: 700,
		lineHeight: '15.51px',
		textAlign: 'center',
		textUnderlinePosition: 'from-font',
		textDecorationSkipInk: 'none',
	}
});

function BottomNav(props) {
	const dispatch = useDispatch();
	const classes = useStyles();
	const [value, setValue] = React.useState("home");
	const [isEmpty, setIsEmpty] = React.useState(false);
	const [isDefault, setIsDefault] = React.useState(true);
	const [unreadCount, setUnreadCount] = React.useState(0);

	const loc = props.location.prevLocation

	const userData = useSelector(({ BaseReducer }) => BaseReducer.UserReducer);

	useDeepCompareEffect(() => {
		if (!userData.availablility) {
			setIsEmpty(true);
		} else {
			setIsEmpty(false);
		}
	}, [userData, userData.availablility]);

	const handleClose = () => {
		dispatch(BaseActions.hideMessage());
		props.history.push("/quarters");
	};
	const handleChange = (event, newValue) => {
		isDefault && setIsDefault(false);
		setValue(newValue);
	};

	const getNotification = async () => {
		const authToken = localStorage.getItem("JWTtoken");

		const notifications = await Axios({
		  method: "get",
		  headers:{
			Authorization:`JWT ${authToken}`
		  },
		  url: `${
			process.env.REACT_APP_ENV === "prod"
			  ? process.env.REACT_APP_BASE_URL_PROD
			  : process.env.REACT_APP_BASE_URL_TESTING
		  }notification/unread-count`,
		});

		setUnreadCount(notifications?.data?.unreadCount)
	}

	React.useEffect(() => {
		getNotification()
	}, [userData,props.newNotification]);

	React.useEffect(() => {
		switch (props.location.pathname) {
			case '/home':
				setValue("home");
				break;
			case '/notifications':
				setUnreadCount(0);
				break;
			case "/quarters":
				setValue("calender");
				break;
			case '/profile':
				setValue("menu");
				break;
			default:
				setValue(props.location.pathname.substring(1));
				break;
		}
	}, [props.location]);

	React.useEffect(() => {
		switch (value) {
			case "menu":
				props.history.push("/profile");
				break;
			case "home":
				dispatch(BaseActions.hideMessage());
				if (props.location.pathname === "/home") {
					return;
				}
				!isDefault && props.history.push("/home");

				break;
			case "notifications":
				dispatch(BaseActions.hideMessage());
				if (props.location.pathname === "/notifications") {
					return;
				}
				!isDefault && props.history.push("/notifications");

				break;
			case "calender":
				if (isEmpty) {
					if (loc && loc.prevLocationPath === "/availability") {
						dispatch(BaseActions.hideMessage());
					}
					else {
						dispatch(
							BaseActions.showMessage(
								<SetYourAvailabilityMessage handleClose={handleClose} />
							)
						);
					}
					return;
				}
				dispatch(BaseActions.hideMessage());
				if (props.location.pathname === "/quarters") {
					return;
				}

				props.history.push("/quarters");

				break;
			default:
				dispatch(BaseActions.hideMessage());
				break;
		}
	}, [value]);

	const showNavBar = !(
		window.location.pathname === "/signup" || window.location.pathname === "/legalStuff" ||
		window.location.pathname === "/profile-image" || window.location.pathname === "/rate-refer" ||
		window.location.pathname === "/username" || window.location.pathname === "/expertise" ||
		window.location.pathname === "/personType"
	)

	const location = useLocation();
	const history = useHistory();

	React.useEffect(() => {
		if (value === "home") {
			if (location.pathname === "/notifications" && value === "home") {
				setValue("notifications")
				history.push("/notifications")
			} else if (location.pathname === "/home" && value === "home") {
				setValue("home")
			}
		}
	}, [])

	return (
		showNavBar &&
		<BottomNavigation
			{...props}
			color="secondary"
			value={value}
			onChange={handleChange}
			className={clsx(showNavBar ? `${classes.root} ${props.className}` : classes.hidden)}
		>
			<BottomNavigationAction
				key={1}
				className={classes.navigationActionButton}
				value="home"
				label={
					<BottomNavItemLabel selected={value === "home" && location.pathname === "/home"}>
						Home
					</BottomNavItemLabel>
				}
				showLabel={true}
				icon={<HomeIcon fill={value === "home" && location.pathname === "/home" ? '#31A7DB' : '#5B5B5B'} />}
			/>
			<BottomNavigationAction
				key={2}
				value="calender"
				label={
					<BottomNavItemLabel selected={value === "calender" && location.pathname === "/quarters"}>
						Availability
					</BottomNavItemLabel>
				}
				showLabel={true}
				icon={
					<div style={{ position: "relative" }}>
						{isEmpty && (
							<div className={classes.infobubble}>
								<div className={classes.infoBubbleText}>!</div>
							</div>
						)}
						<CalendarIcon
							fill={value === "calender" && location.pathname === "/quarters" ? '#31A7DB' : '#5B5B5B'}
						/>
					</div>
				}
			/>
			<BottomNavigationAction
				key={3}
				classKey={classes.navigationActionLabel}
				value="notifications"
				label={
					<BottomNavItemLabel selected={value === "notifications" && location.pathname === "/notifications"}>
						Notifications
					</BottomNavItemLabel>
				}
				showLabel={true}
				icon={
					<div style={{position: 'relative'}}>
						{
							unreadCount > 0 &&
							<div className={classes.infobubble}>
								<div className={classes.infoBubbleText}>{unreadCount > 9 ? "+9" : unreadCount}</div>
							</div>
						}
						<RingingBellIcon
							fill={value === "notifications" && location.pathname === "/notifications" ? '#31A7DB' : '#5B5B5B'}
						/>
					</div>
				}
			/>
			<BottomNavigationAction
				key={4}
				value="menu"
				label={
					<BottomNavItemLabel selected={value === "menu" && location.pathname === "/profile"}>
						Profile
					</BottomNavItemLabel>
				}
				showLabel={true}
				selected={true}
				icon={<UserIcon fill={value === "menu" && location.pathname === "/profile" ? '#31A7DB' : '#5B5B5B'} />}
			/>
		</BottomNavigation>
	);
}

export default withRouter(BottomNav);
