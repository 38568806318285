import React from "react";
import { makeStyles } from "@material-ui/core/styles";
// import ListSubheader from "@material-ui/core/ListSubheader";
import Typography from "@material-ui/core/Typography";
// import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// import ListItemIcon from "@material-ui/core/ListItemIcon";
// import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
// import InboxIcon from "@material-ui/icons/MoveToInbox";
// import DraftsIcon from "@material-ui/icons/Drafts";
// import SendIcon from "@material-ui/icons/Send";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
// import StarBorder from "@material-ui/icons/StarBorder";
const useStyles = makeStyles((theme) => ({
  con: {
    backgroundColor: "#080A0A",
    borderRadius: "6px",
    margin: "10px 0",
    // boxShadow: "0 0 20px 5px rgba(255,0,0,0.2) inset",
    // backgroundImage: "linear-gradient(#080A0A, red)",
  },
  root: {
    width: "100%",
    // maxWidth: 360,
    backgroundColor: "#111111",
    height: "56px",
    borderRadius: "6px",
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));
export default function MonthCollapse({ date, children }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [currentDate, setCurrentDate] = React.useState(null);
  const handleClick = () => {
    setOpen(!open);
  };
  React.useEffect(() => {
    setCurrentDate(date);
  }, [date]);
  React.useEffect(() => {
    setOpen(false);
  }, [currentDate]);
  return (
    <>
      <div className={classes.con}>
        <ListItem
          button
          onClick={() => handleClick()}
          style={{ padding: "8px 0px" }}
        >
          <div style={{ width: "100%" }}>
            <Typography variant="body2">{currentDate}</Typography>
          </div>
          {/* <ListItemText  primary="" /> */}
          {open ? (
            <ExpandLess style={{ fontSize: "36px", color: 'white' }} />
          ) : (
            <ExpandMore style={{ fontSize: "36px", color: 'white' }} />
          )}
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
          {children}
        </Collapse>
      </div>
    </>
  );
}
// const usePillStyle = makeStyles((theme) => ({
//   root: {
//     border: "1px solid white",
//     width: "calc(48% - 15px)",
//     margin: "10px",
//   },
// }));
