import React from "react";
import Box from "@material-ui/core/Box";
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    container: {
        backgroundColor: "#272727",
        display: 'flex',
        height: '100%',
        width: '100%',
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        rowGap: '17px',
    },

    title: {
        fontFamily: "-apple-system, BlinkMacSystemFont, cerebri-regular",
        fontWeight: '400',
        fontSize: 12,
        lineHeight: '14.32px',
        letterSpacing: '0%',
        textAlign: 'center',
        color: "white",
    }
}))

function PlaceholderImage({svgSize = 32, title = null, ...props}) {
    const classes = useStyles();

    return (
        <Box {...props} style={{overflow: "hidden"}}>
            <Box className={classes.container}>
                <svg width={svgSize} height={svgSize} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_336_11)">
                        <path fillRule="evenodd" clipRule="evenodd"
                              d="M26.5001 28.8H5.50108C4.37085 28.8 3.53624 27.6848 3.96253 26.6592C5.94042 21.9168 10.5871 19.2 15.9998 19.2C21.4141 19.2 26.0607 21.9168 28.0386 26.6592C28.4649 27.6848 27.6303 28.8 26.5001 28.8ZM9.46667 9.59998C9.46667 6.07038 12.3984 3.19998 15.9998 3.19998C19.6028 3.19998 22.5329 6.07038 22.5329 9.59998C22.5329 13.1296 19.6028 16 15.9998 16C12.3984 16 9.46667 13.1296 9.46667 9.59998ZM31.9291 28.2176C30.7417 22.8432 27.0276 18.8767 22.1392 17.0767C24.7296 15.0335 26.2404 11.7295 25.6851 8.11189C25.0416 3.91509 21.4778 0.556763 17.1757 0.067163C11.2371 -0.609637 6.20011 3.91838 6.20011 9.59998C6.20011 12.624 7.63087 15.3183 9.86192 17.0767C4.9719 18.8767 1.25946 22.8432 0.0704384 28.2176C-0.360746 30.1712 1.2464 32 3.28636 32H28.7132C30.7548 32 32.3619 30.1712 31.9291 28.2176Z"
                              fill="#4C4C4C"/>
                    </g>
                    <defs>
                        <clipPath id="clip0_336_11">
                            <rect width="32" height="32" fill="white"/>
                        </clipPath>
                    </defs>
                </svg>

                {title && <div className={classes.title}>{title}</div>}
            </Box>
        </Box>
    )
}

export default PlaceholderImage;
