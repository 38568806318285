import * as Actions from "../../actions";
const initialState = {
  state: false,
  data: ""
};

export const MessageReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.SHOW_MESSAGE: {
      return {
        state: true,
        data: action.data,
        isDrawer: action.isDrawer,
      };
    }
    case Actions.HIDE_MESSAGE: {
      return {
        state: false,
        data: null,
      };
    }
    default: {
      return state;
    }
  }
};
