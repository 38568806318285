import React, {useEffect} from "react";
import clsx from "clsx";
import {
    Box,
    Button,
    Typography,
    RadioGroup,
    FormControlLabel,
} from "@material-ui/core";
import {Radio as RadioButton} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {InputField} from "@base";
import {withRouter} from "react-router-dom";
import {Spinner} from "@base";
import Referal from "components/signup/screens/referal";
import {useDispatch, useSelector} from "react-redux";
import reducer from "../signupForm/store/reducers";
import withReducer from "store/withReducer";
import * as Actions from "../signupForm/store/actions";
import * as BaseActions from "store/actions";
import Check from "@material-ui/icons/Check";
import queryString from 'query-string'
import {isMobile} from "react-device-detect";

const useStyles = makeStyles((theme) => ({
    inline: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        fontSize: "16px",
    },
    checkedIcon: {
        borderRadius: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "3px",
        border: "2px solid #FFFFFF",
    },
    blueDot: {
        borderRadius: "100%",
        display: "block",
        width: 14,
        height: 14,
        backgroundColor: "#31A7DB",
        content: '""',
    },
    root: {
        color: (props) => (props.isAvailable ? "#fff" : "#3b3b3b"),
        "&$checked": {
            color: "#31A7DB",
        },
    },
    formControlLabel: {
        fontSize: "16px",
        fontWeight: "normal"
    },
    disabled: {
        color: "#3b3b3b",
    },
    checked: {
        fontSize: "16px",
        fontStyle: "none"
    },
    bottomBtn: {
        marginBottom: 0,
        [theme.breakpoints.up("sm")]: {
            marginBottom: 40,
        },
    },
    fontSize: {
        fontSize: "16px"
    }
}));
const Username = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [username, setUsername] = React.useState("");
    const [showUsername, setShowUsername] = React.useState('fullname');
    const [enabledUsername, setEnabledUsername] = React.useState(false);
    const [isAvailable, setIsAvailable] = React.useState(false);
    const [openReferal, setOpenReferal] = React.useState(false);
    const [isFirst] = React.useState(true);
    const [hideErrorMsg, sethideErrorMsg] = React.useState(true)
    console.log(hideErrorMsg)
    const [initialScreenSize] = React.useState(window.innerHeight);
    const [keyboardIsShown, setKeyboardIsShown] = React.useState(false);
    const userData = useSelector(({BaseReducer}) => BaseReducer.UserReducer);

    React.useEffect(() => {
        setInterval(() => {
            if (initialScreenSize > window.innerHeight) {
                !keyboardIsShown && setKeyboardIsShown(true);
            } else {
                keyboardIsShown && setKeyboardIsShown(false)
            }
        }, 1)
    }, [keyboardIsShown, initialScreenSize])

    useEffect(() => {
        dispatch(BaseActions.getUserData());
        setUsername(userData.username?.substring(0, 20));
    }, [dispatch, userData.username]);

    useEffect(() => {
        sethideErrorMsg(false)
        username.length > 1 && username.length < 21 ? setIsAvailable(true) : setIsAvailable(false);
    }, [username])

    const id = queryString.parse(props.location.search).id
    const code = queryString.parse(props.location.search).code
    const updateProfileResponse = useSelector(
        ({Username}) => Username.UpdateProfileReducer
    );
    const updateProfileResponseData = updateProfileResponse.data;
    const isLoading = updateProfileResponse.isLoading;
    const {layout} = useSelector(({BaseReducer}) => ({
        layout: BaseReducer.LayoutReducer,
    }));
    const errMsg = updateProfileResponse.errMsg
    React.useEffect(() => {
        if (layout.bottomNav) dispatch(BaseActions.updateLayout({bottomNav: false}));
    }, [layout.bottomNav])
    React.useEffect(() => {
        if (updateProfileResponseData) {
            if (updateProfileResponseData.status === "Success") {
                if (id) {
                    props.history.push(`/rate-refer?id=${id}&code=${code}`);
                } else {
                    props.history.push("/rate-refer");
                }
                setOpenReferal(true);
            }
        }
    }, [updateProfileResponseData]);
    const handleSetUsername = (val) => {
        sethideErrorMsg(true)
        let newVal = String(val)
        if (!newVal) {
            dispatch(
                BaseActions.showAlert({
                    variant: "error",
                    message: "Enter your username first",
                })
            );
            return;
        }
        dispatch(
            Actions.updateProfile({
                username: String(newVal),
                enabledUsername: Boolean(enabledUsername)
            })
        );
    };
    const handleChange = () => (e) => {
        setUsername(e.target.value);
    };
    const handleRadioChange = (e) => {
        const {value} = e.target;
        setShowUsername(value)
        value === 'username' ? setEnabledUsername(true) : setEnabledUsername(false)
    };

    return (
        <>
            {!openReferal ? (
                <Box
                    display="flex"
                    flexDirection="column"
                    height="100%"
                    position="absolute"
                >
                    <Box padding={isMobile ? "15px 30px 0px 30px" : "32px 30px 0px 30px"} margin="0 0 20px 0">
                        <Box
                            display="flex"
                            flexDirection="column"
                            paddingBottom={isMobile ? "4%" : "0%"}
                        >
                            <Typography variant="h1">
                                Add username
                            </Typography>
                        </Box>
                        <Typography margin="20px 0 0 0" variant="caption">
                            (If you don't enter a Username, your Full Name will be seen by other users.)
                        </Typography>
                    </Box>
                    <Box padding={isMobile ? "15px 30px 0px 30px" : "32px 30px 0px 30px"}
                         margin={isMobile ? "85px 0 0" : "auto 0 0"}>
                        <Box display="flex" flexDirection="row" justifyContent="space-between">
                            <Typography variant="caption">
                                Username
                            </Typography>
                            {
                                isAvailable ?
                                    <Check style={{padding: 5, color: "#4CDE5F", fontSize: "30px"}}/>
                                    : null
                            }
                        </Box>
                        <InputField
                            inputHeight="20px"
                            fontSize="16px"
                            style={{color: "pink"}}
                            type="text"
                            name="username"
                            placeHolder="Example: SuperMn123"
                            error={
                                hideErrorMsg && errMsg === "Username already exists"
                                    ? "*This Username is already in use. Please choose a different one."
                                    : false
                            }
                            value={`${username}`}
                            color={isFirst && "#FFFFFF"}
                            onChange={handleChange}
                        />
                        {(username?.length === 0 || username?.length < 2 || username?.length > 20) ?
                            (<span
                                style={{
                                    fontFamily: "-apple-system, BlinkMacSystemFont, cerebri-regular",
                                    color: 'rgb(203, 58, 58)',
                                    fontSize: '14px',
                                    position: 'absolute',
                                    left: "30px",
                                    marginTop: '-8px',
                                    display: username.length === 0 ? "none" : ""
                                }}>
                                *Username length should be 2 - 20
                            </span>) : null}
                        {username?.indexOf(' ') >= 0 ?
                            (<span
                                style={{
                                    fontFamily: "-apple-system, BlinkMacSystemFont, cerebri-regular",
                                    color: 'rgb(203, 58, 58)',
                                    fontSize: '14px',
                                    position: 'absolute',
                                    left: "30px",
                                    marginTop: '-8px',
                                    display: username?.length === 0 ? "none" : ""
                                }}>
                                *There should be no space in username
                            </span>) : null}
                        {/* {/\d/.test(username) ? (<span style={{ fontFamily: "-apple-system, BlinkMacSystemFont, cerebri-regular", color: 'rgb(203, 58, 58)', fontSize: '14px', position: 'absolute', left: "30px", marginTop: '-20px' }}>*Username should not contain numbers</span>) : null} */}
                    </Box>
                    <Box padding={isMobile ? "15px 30px 0px 30px" : "32px 30px 0px 30px"} marginTop="30px"
                         display="flex" justifyContent="space-between" alignItems="center">
                        <Typography className={classes.fontSize} variant="caption">Display:</Typography>
                        <Box className={classes.inline}>
                            <RadioGroup name="showUsername" value={showUsername} onChange={handleRadioChange}>
                                <Box display="flex">
                                    <FormControlLabel value="fullname" control={
                                        <RadioButton
                                            classes={{
                                                root: classes.root,
                                                checked: classes.checked,
                                                disabled: classes.disabled,
                                            }}
                                            color="secondary"
                                            size="large"
                                            checkedIcon={
                                                <div className={clsx(classes.icon, classes.checkedIcon)}>
                                                    <span className={clsx(classes.blueDot)}/>
                                                </div>
                                            }
                                        />
                                    } label={<Typography className={classes.formControlLabel}>Full name</Typography>}/>
                                    <FormControlLabel value="username" control={
                                        <RadioButton
                                            classes={{
                                                root: classes.root,
                                                checked: classes.checked,
                                                disabled: classes.disabled,
                                            }}
                                            color="secondary"
                                            size="large"
                                            checkedIcon={
                                                <div className={clsx(classes.icon, classes.checkedIcon)}>
                                                    <span className={clsx(classes.blueDot)}/>
                                                </div>
                                            }
                                        />
                                    } label={<Typography className={classes.formControlLabel}>Username</Typography>}/>
                                </Box>
                            </RadioGroup>
                        </Box>
                    </Box>
                    <Box hidden={(isMobile && keyboardIsShown)}>
                        <Box position={isMobile && "fixed"}
                             width={isMobile ? "100%" : "auto"}
                             bottom={isMobile && 15}
                             padding="30px"
                             paddingBottom={isMobile ? "10px" : "32px"}
                             display="flex"
                             justifyContent="space-between"
                        >
                            <Button
                                disabled={!isAvailable}
                                onClick={() => handleSetUsername(username)}
                                color="primary"
                                style={{width: "100%"}}
                            >
                                {isLoading ? <Spinner/> : "Ok"}
                            </Button>
                        </Box>
                    </Box>
                </Box>
            ) : (
                <Referal/>
            )
            }
        </>
    );
};
export default withReducer("Username", reducer)(withRouter(Username));
