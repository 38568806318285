import * as Actions from "../actions";

// initial state
const initialState = {
  isLoading: false,
  errMsg: null,
  data: null,
};

// Reducer for LOGIN handling
export const InviteReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.FETCH_INVITES:
      // we will mould our payload here accordingly  if required  in case of success
      return { ...state, isLoading: false, errMsg: null, data: action.payload };
    case Actions.INVITE_IS_LOADING:
      // ... in case of loading
      return { ...state, isLoading: true, errMsg: null, data: null };
    case Actions.INVITE_FAILED:
      // ... in case of error
      return { ...state, isLoading: false, errMsg: action.payload, data: null };
    default:
      return state;
  }
};