import React, {useCallback, useEffect, useRef, useState} from "react";
import clsx from "clsx";
import {withRouter} from "react-router";
import {makeStyles} from "@material-ui/core/styles";
import {
	Box,
	Button,
	Checkbox,
	FormControlLabel,
	Modal,
	Paper,
	RadioGroup,
	Typography
} from "@material-ui/core";
import Pics from "@assets";
import {MySwitch} from "@base";
import Expertise from "components/signup/screens/expertise";
import 'react-image-crop/dist/ReactCrop.css'
import {useDispatch, useSelector} from "react-redux";
import * as BaseActions from "store/actions";
import withReducer from "store/withReducer";
import * as Actions from "../signup/screens/signupForm/store/actions";
import reducer from "../signup/screens/signupForm/store/reducers";
import Cropper from 'react-easy-crop'
import Slider from '@material-ui/core/Slider';
import {RotateLeft, RotateRight} from "@material-ui/icons";
import getCroppedImg from './cropImage'
import imageCompression from 'browser-image-compression';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import ReactPlayer from 'react-player'
import "./index.css";
import {isAndroid, isIOS, isMobile} from "react-device-detect";
import {uploadWithPresignedURL} from "../../@utils/uploadWithPresignedURL";
import Header from "../../@base/header";
import PlaceholderImage from "../home/subComponents/userCard/icons/PlaceholderImage";
import {EditButton} from "./subComponent/EditButton";
import {allPersonTypes} from "../signup/screens/personType";
import PlaceholderVideo from "../home/subComponents/userCard/icons/PlaceholderVideo";
import {FormSection, FormGroup, InputField, ErrorBox, TextArea, RadioButtonField} from "../../@base/form";

const useStyles = makeStyles((theme) => ({
	saveButton: {
		background: '#434343',
		borderRadius: 16,
		fontFamily: "-apple-system, BlinkMacSystemFont, cerebri-regular",
		fontWeight: 700,
		fontSize: 12,
		lineHeight: '14.32px',
		letterSpacing: '0%',
		textAlign: 'center',
		color: 'white',
		padding: '8px 15px',
		display: 'block',
	},
	container: {
		backgroundSize: "cover",
		position: "relative",
		borderRadius: 6,
		backgroundColor: "black",
	},
	carousel: {
		paddingTop: '79px',
		paddingBottom: '35px',
		backgroundColor: '#141414',
	},
	imgContainer: {
		display: "block",
		height: "172px",
		width: "172px",
		margin: '0 auto',
		padding: 6,
		backgroundColor: "#FFFFFF33",
		borderRadius: 30,
	},
	profileImage: {
		background: '#1B1B1B',
		height: '100%',
		boxShadow: "0px 12px 24px rgba(68, 68, 68, 0.1)",
		borderRadius: 16,
	},
	profileImageMain: {
		background: '#1B1B1B',
		height: '100%',
		boxShadow: "0px 12px 24px rgba(68, 68, 68, 0.1)",
		borderRadius: 24,
	},
	assetContainer: {
		position: 'relative',
		height: '100%',
		aspectRatio: 1,
		background: "#272727",
		borderRadius: 16,
	},
	profileAsset: {
		borderRadius: 16,
		overflow: "hidden",
		height: '100%',
		cursor: 'pointer',
	},
	profileAssetMain: {
		overflow: "hidden",
		height: '100%',
		cursor: 'pointer',
		borderRadius: 24,
	},
	cardContainer: {
		background: "rgba(20, 20, 20, 1)",
		position: "relative",
		padding: "79px 20px 20px",
	},
	body: {
		padding: 20,
	},
	formSectionLabel: {
		fontFamily: "-apple-system, BlinkMacSystemFont, cerebri-regular",
		fontStyle: "normal",
		fontWeight: 700,
		fontSize: 18,
		lineHeight: "21px",
		padding: "0 5px",
		color: "#31A7DB",
	},
	formGroup: {
		boxSizing: "border-box",
		background: "rgba(20, 20, 20, 1)",
		transition: "all 0.25s ease-in-out",
		border: "1px solid #373737",
		borderRadius: 15,
		padding: "8px 15px 12px",
		position: "relative",
	},
	formGroupHover: {
		"&:focus-within": {
			background: "#262626",
		},
	},
	formGroupLabel: {
		fontStyle: "normal",
		fontWeight: 400,
		fontSize: 16,
		lineHeight: "19px",
		display: "flex",
		alignItems: "center",
		color: "#9F9F9F",
		marginBottom: 10,
	},
	transparentInput: {
		fontFamily: "-apple-system, BlinkMacSystemFont, cerebri-regular",
		backgroundColor: "transparent",
		border: "0px",
		outline: "none",
		boxShadow: "none",
		width: "100%",
		fontStyle: "normal",
		fontWeight: 600,
		fontSize: 18,
		lineHeight: "21px",
		display: "flex",
		alignItems: "center",
		color: "#FFFFFF",
		padding: 0,
	},
	textArea: {
		fontWeight: 400,
		fontSize: 14,
		lineHeight: "16.71px",
		letterSpacing: "0%",
	},
	inline: {
		display: "flex",
		justifyContent: "space-between",
	},
	formGroupListItem: {
		padding: "10px 15px",
		"&:first-child": {
			paddingTop: 0,
		},
		"&:last-child": {
			paddingBottom: 0,
		},
		"&:not(&:first-child)": {
			borderTop: "1px solid #262626",
		}
	},
	formGroupListItemLabel: {
		fontFamily: "-apple-system, BlinkMacSystemFont, cerebri-regular",
		fontWeight: 400,
		fontSize: 14,
		lineHeight: "22px",
		letterSpacing: "0%",
	},
	formGroupListItemValue: {
		fontFamily: "-apple-system, BlinkMacSystemFont, cerebri-regular",
		fontWeight: 400,
		fontSize: 14,
		lineHeight: "16.71px",
		letterSpacing: "0%",
		color: "#9F9F9F",
	},
	formControlLabelRoot: {
		margin: 0,
	},
	formGroupItemLabel: {
		fontFamily: "-apple-system, BlinkMacSystemFont, cerebri-regular",
		fontWeight: 400,
		fontSize: 14,
		marginBottom: 0,
		marginLeft: 10,
		textTransform: "uppercase"
	},
	checkboxRoot: {
		margin: 0,
		padding: "15px 0",
		height: 22,
		width: 22,
		borderRadius: 0,
	},
	checkboxInput: {
		border: "1px solid green",
	},
	backButton: {
		// backgroundColor: "rgba(0,0,0,0.57)",
		width: "30px",
		height: "30px",
		borderRadius: 6,
		paddingLeft: 6,
		color: "white",
		cursor: "pointer",
		zIndex: 10
	},
	editButton: {
		cursor: "pointer",
		zIndex: 10,
		marginRight: 15,
		fontFamily: "-apple-system, BlinkMacSystemFont, cerebri-regular",
		fontStyle: "normal",
		fontWeight: "600",
		fontSize: "14px",
		lineHeight: "17px",
		textDecorationLine: "underline",
		color: "#FFF",
	},
	changeButton: {
		cursor: "pointer",
		position: "absolute",
		bottom: 20,
		left: 20,
		backgroundColor: "rgba(0,0,0,0.7)",
		borderRadius: 6,
		width: "90%",
		height: 36,
		textAlign: "center",
		paddingTop: 6,
		fontSize: 16,
		zIndex: "50"
	},
	otherChangeButton: {
		cursor: "pointer",
		position: "absolute",
		bottom: 20,
		left: 20,
		backgroundColor: "#31A7DB",
		borderRadius: 6,
		width: "90%",
		height: 36,
		textAlign: "center",
		paddingTop: 6,
		fontSize: 16,
		zIndex: "50"
	},
	main: {
		display: "flex",
		flexDirection: "row",
	},
	pic: {
		marginRight: 25,
		borderRadius: 6,
		height: 87,
		width: 87,
		backgroundSize: "cover",
		backgroundColor: "#111111",
	},
	picContainer: {
		height: 87,
		width: 87,
		backgroundColor: "rgba(0,0,0,0.2)",
		position: "relative",
		padding: "40px 20px",
	},
	changePicButton: {
		cursor: "pointer",
		position: "absolute",
		bottom: 5,
		left: 5,
		backgroundColor: "rgba(0,0,0,0.7)",
		borderRadius: 6,
		width: "90%",
		textAlign: "center",
		fontSize: 11,
		padding: 5,
	},
	otherChangePicButton: {
		cursor: "pointer",
		position: "absolute",
		bottom: 5,
		left: 5,
		backgroundColor: "#31A7DB",
		borderRadius: 6,
		width: "90%",
		textAlign: "center",
		fontSize: 11,
		padding: 5,
	},
	title: {
		margin: "15px 0",
	},
	expertiseButton: {
		color: "white",
		cursor: "pointer",
	},
	tags: {
		fontSize: 12,
		margin: "13px 16px 0 0",
	},
	rateInput: {
		fontFamily: "cerebri-semibold",
		fontSize: "24px",
	},
	bioInput: {
		fontFamily: "-apple-system, BlinkMacSystemFont, cerebri-regular",
		fontSize: "16px",
		height: "100%",
		resize: "none",
	},
	cropContainer: {
		position: "absolute",
		top: 30,
		zIndex: "100",
		width: "370px",
		height: "300px"
	},
	imgOuterContainer: {
		[theme.breakpoints.up("sm")]: {
			height: `${height}px`,
			width: 373,
		},
		position: "fixed",
		width: "100%",
		height: `100vh`,
		zIndex: "100",
		backgroundColor: "#080A0A",
	},
	imageContainer: {
		width: "100%",
		height: "350px",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		[theme.breakpoints.up("sm")]: {
			width: 373,
		},
	},
	root: {
		color: (props) => (props.isAvailable ? "#fff" : "#3b3b3b"),
		marginRight: 10,
	},
	rotateBtn: {
		border: "1px solid silver",
		borderRadius: 6,
		padding: "5px 5px 0 5px",
		cursor: "pointer"
	},
	btnContainer: {
		padding: "0 25px 10px 25px",
		position: "fixed",
		[theme.breakpoints.up("sm")]: {
			bottom: 40,
			width: 373,
		},
		marginBottom: "30px",
	},
	gradient: {
		height: 115,
		backgroundImage: "linear-gradient(#0A0A0A,#00000000 )",
		opacity: 1,
		zIndex: 9,
		position: "fixed",
		borderRadius: 6,
		width: "100%",
		top: 0,
		[theme.breakpoints.up("sm")]: {
			width: 370,
			bottom: 42,
		},
	},
	modal: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		border: "none",
	},
	modalContainer: {
		outline: "none",
		position: "fixed",
		bottom: 0,
		marginBottom: "0",
		[theme.breakpoints.up("sm")]: {
			marginBottom: "33px",
		},
		[theme.breakpoints.down("sm")]: {
			width: "415px",
		},
		width: "100%",
		borderRadius: 0,
		color: "#FFFFFF",
		backgroundColor: "#111111",
		display: "flex",
		justifyContent: "center",
	},
	modalActionContainer: {
		width: "100%",
		marginTop: "20px",
		marginBottom: "10px",
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
	},
}));

function viewportToPixels(value) {
	var parts = value.match(/([0-9.]+)(vh|vw)/)
	var q = Number(parts[1])
	var side = window[['innerHeight', 'innerWidth'][['vh', 'vw'].indexOf(parts[2])]]
	return side * (q / 100)
}

const height = viewportToPixels("100vh") - 80

function Alert(props) {
	return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function EditProfile({history}) {
	const dispatch = useDispatch();
	const classes = useStyles();
	const [open, setOpen] = useState(false);
	const isFirstRenderRef = useRef(true)
	const [isOnExpertise, setIsOnExpertise] = React.useState(false);
	const [test, setTest] = useState(false);
	const [showCropedImage, setShowCropedImage] = useState(false)
	const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
	const [imgIndex, setImgIndex] = useState(0)
	const [videoUrl, setVideoUrl] = useState("")
	const [finalImageOne, setFinalImageOne] = useState(null)
	const [finalImageTwo, setFinalImageTwo] = useState(null)
	const [finalImageThree, setFinalImageThree] = useState(null)
	const [openAlert, setOpenAlert] = React.useState(false);
	const [, setIsChanged] = React.useState(false);
	const [proceedWithUpdate, setProceedWithUpdate] = React.useState(false);
	const [state, setState] = useState({
		bio: "",
		rate: "",
		tags: [],
		person_type: [],
		profile_media_urls: [],
		enable_review_notification: false,
		enable_invite_notification: false,
		video_url: "",
	});
	const [originalState, setOriginalState] = React.useState(state);
	const userData = useSelector(({BaseReducer}) => BaseReducer.UserReducer);
	const updateProfileResponse = useSelector(
		({EditProfile}) => EditProfile.UpdateProfileReducer
	);
	let updateProfileResponseData = updateProfileResponse.data;
	const errMsg = updateProfileResponse.errMsg;

	React.useEffect(() => {
		if (isFirstRenderRef.current) {
			isFirstRenderRef.current = false
			return
		}
		if (updateProfileResponseData) {
			if (updateProfileResponseData.status === "Success") {
				dispatch(Actions.resetProfileReducer());
				if (proceedWithUpdate) {
					history.push("/profile", "profile-updated");
				}
			}
		}
	}, [updateProfileResponseData]);

	React.useEffect(() => {
		dispatch(BaseActions.updateLayout({bottomNav: false}));
		dispatch(BaseActions.getUserData());
		const {
			bio,
			rate,
			tags,
			person_type,
			username,
			full_name,
			enabledUsername,
			profile_media_urls,
			enable_review_notification,
			enable_invite_notification,
			video_url,
		} = userData;
		setOriginalState({
			fetched: true,
			bio,
			rate: rate,
			tags,
			person_type: person_type,
			username,
			full_name,
			enabledUsername,
			showUsername: enabledUsername ? "username" : "fullname",
			profile_media_urls,
			enable_review_notification,
			enable_invite_notification,
			video_url,
		});
		setState({
			bio,
			rate: rate,
			tags,
			person_type: person_type,
			username,
			full_name,
			enabledUsername,
			showUsername: enabledUsername ? "username" : "fullname",
			profile_media_urls,
			enable_review_notification,
			enable_invite_notification,
			video_url,
		});
		setFinalImageOne(profile_media_urls[0])
		setFinalImageTwo(profile_media_urls[1])
		setFinalImageThree(profile_media_urls[2])
		setVideoUrl(video_url)
	}, [dispatch]);

	const {
		bio,
		rate,
		username,
		full_name,
		enabledUsername,
		showUsername,
		profile_media_urls,
		enable_review_notification,
		enable_invite_notification,
		video_url,
	} = state;

	useEffect(() => {
		if (originalState.fetched) {
			setState(prev => {
				return showUsername === "username" ? {
					...prev,
					full_name: originalState.full_name
				} : {
					...prev,
					username: originalState.username
				};
			});
		}
	}, [ showUsername ]);

	const handleChange = (e) => {
		setIsChanged(true)
		const {name, checked, value, type} = e.target;

		type === "checkbox"
			? setState({...state, [name]: checked})
			: setState({...state, [name]: value});
	};

	const handleTagsChange = (value) => {
		setIsChanged(true)
		setState({...state, tags: value})
	};

	const handleRateChange = (e) => {
		const {name, value} = e.target;
		let rate = value.substring(1)
		if (!isNaN(rate)) {
			setState({...state, [name]: rate});
		}
	};

	const handlePersonTypeChange = (e) => {
		const {value} = e.target;

		let person_type = state.person_type
		person_type.includes(value)
			? person_type.splice(person_type.indexOf(value), 1)
			: person_type.push(value);

		setState({...state, person_type: person_type});
	};

	const handleNameChange = (e) => {
		setCheck(false)
		setIsChanged(true)
		const { value } = e.target;
		enabledUsername ?
			setState({...state, username: value}) :
			setState({...state, full_name: value})
	};

	const [showImage, setShowImage] = React.useState(null)

	const handleRadioChange = (e) => {
		setIsChanged(true)
		const {name, value} = e.target;
		value === 'username' ? setState({...state, [name]: value, enabledUsername: true}) : setState({
			...state,
			[name]: value,
			enabledUsername: false
		})
	};

	const [imageSizeValidation, setImageSizeValidation] = useState(true)
	const [videoSizeValidation, setVideoSizeValidation] = useState(true)

	const fileHandler = async (e, i) => {
		setIsChanged(true)
		setImgIndex(i)
		const file = e.target.files[0];
		e.target.value = ''
		if (file.size < (10 * 1024 * 1024)) {
			const options = {
				maxSizeMB: 10,
				maxWidthOrHeight: 1920,
				useWebWorker: true
			}
			const compressedFile = await imageCompression(file, options);
			setShowImage(URL.createObjectURL(compressedFile))
			setTest(!test);
			setShowCropedImage(true)
			setImageSizeValidation(true)
		} else {
			setImageSizeValidation(false)
			setVideoSizeValidation(true)
		}
	};

	const videoHandler = async (e) => {
		setIsChanged(true)
		const file = e.target.files[0];
		e.target.value = ""
		if (file.type.includes("video")) {
			if (file.size <= (100 * 1024 * 1024)) {
				const url = await uploadingFile(file);

				setOpenAlert(true);
				setVideoUrl(url);
				setVideoSizeValidation(true);
			} else {
				setVideoSizeValidation(false);
				setImageSizeValidation(true)
			}
		} else {
			dispatch();
		}
	};

	const uploadingFile = async (file) => {
		dispatch(BaseActions.showLoading(true));
		try {
			const objectUrl = await uploadWithPresignedURL(file);
			dispatch(BaseActions.hideLoading());
			return objectUrl;
		} catch (error) {
			console.log(error);
			dispatch(BaseActions.hideLoading());
		}
	};

	const handleBackToEdit = () => {
		setIsOnExpertise(true);
	};

	const backToEdit = () => {
		setIsOnExpertise(false);
	};

	const handleSubmit = () => {
		if (rate < 5 || rate > 999) {
			return;
		} else if (enabledUsername && (username?.length < 2 || username?.length > 20)) {
			return;
		} else if (showUsername === "username" && (username?.length === 0 || username?.length < 2 || username?.length > 20)) {
			return;
		} else if (full_name?.length < 2 || full_name?.length > 60) {
			return;
		} else if (enabledUsername && username?.indexOf(' ') >= 0) {
			return;
		} else if (firstName.length < 2 || secondName.length < 2) {
			return;
		} else if (/\d/.test(full_name)) {
			return;
		} else {
			setCheck(true)
			dispatch(Actions.updateProfile({
				...state,
				video_url: videoUrl !== "" ? videoUrl : video_url,
				full_name: showUsername === "username" ? full_name : updatedFullName ? updatedFullName : full_name
			}));

			setOriginalState({...state, fetched: true});
		}
		setProceedWithUpdate(true)
	};

	const [crop, setCrop] = useState({x: 0, y: 0})
	const [zoom, setZoom] = useState(3)
	const [, setCroppedImage] = useState(null)
	const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
		setCroppedAreaPixels(croppedAreaPixels)
	}, [])

	const [rotationValue, setRotationValue] = React.useState(0);
	const showCroppedImage = useCallback(async () => {
		try {
			setShowCropedImage(false)
			const croppedImage = await getCroppedImg(
				showImage,
				croppedAreaPixels,
				rotationValue
			)
			var blob = croppedImage.slice(0, croppedImage.size, "image/*");
			const link = `${userData._id}/pic${imgIndex}/${new Date().getTime()}`;
			const newFile = new File([blob], link, {type: "image/*"});
			setCroppedImage(croppedImage)
			const options = {
				maxSizeMB: 0.4,
				maxWidthOrHeight: 1920,
				useWebWorker: true
			}
			const compressedFile = await imageCompression(newFile, options);
			const url = await uploadingFile(compressedFile);
			profile_media_urls[imgIndex] = url
			if (imgIndex === 0) {
				setFinalImageOne(URL.createObjectURL(compressedFile))
			} else if (imgIndex === 1) {
				setFinalImageTwo(url)
			} else if (imgIndex === 2) {
				setFinalImageThree(url)
			}
		} catch (e) {
		}
	}, [croppedAreaPixels, rotationValue])

	const handleModalClose = () => {
		setOpen(false);
		history.push("/profile")
	};

	useEffect(() => {
		if (full_name) {
			const words = full_name.split(' ');
			if (words.length === 3) {
				if (words[1] === "") {
					words[1] = words[2]
				}
			}
			if (words.length === 4) {
				if (words[2] === "") {
					words[2] = words[3]
				}
				if (words[1] === "") {
					words[1] = words[2]
				}
			}
			const first_name = words[0];
			const second_name = words[1];
			setFirstName(first_name)
			setSecondName(second_name)
			setFullName(full_name)
		}
	}, [full_name])

	const [firstName, setFirstName] = useState("");
	const [secondName, setSecondName] = useState("");
	const [fullName, setFullName] = useState("");
	const [updatedFullName, setUpdatedFullName] = useState("")
	const [check, setCheck] = useState(false)
	const handleFullName = (e) => {
		setIsChanged(true)

		if (e.target.name === "firstname") {
			setFirstName(e.target.value)
			const fName = e.target.value.concat(" ".concat(secondName))
			setUpdatedFullName(fName)
			setState({...state, full_name: fName})
		} else if (e.target.name === "secondname") {
			setSecondName(e.target.value)
			const fName = firstName.concat(" ".concat(e.target.value))
			setUpdatedFullName(fName)
			setState({...state, full_name: fName})
		}
	}

	const handleCloseAlert = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setOpenAlert(false);
	};

	const profileImageTwo = React.useMemo(() =>
		<div className={classes.assetContainer}>
			{
				finalImageTwo && !(finalImageTwo.match(/\/uploads\/default_pic\.png$/) || finalImageTwo === "")
					? <>
						<img
							className={classes.profileImage}
							alt="img"
							src={finalImageTwo}
							draggable={false}
							width="100%"
						/>
						<label htmlFor="button-file-two">
							<EditButton size={30} bottom={5} right={5}/>
						</label>
					</>
					: <label htmlFor="button-file-two">
						<PlaceholderImage className={classes.profileAsset} title={"Add Image"}/>
					</label>
			}

			<input
				accept="image/*"
				onChange={(e) => fileHandler(e, 1)}
				style={{display: "none"}}
				id="button-file-two"
				type="file"
			/>
		</div>, [classes.assetContainer, classes.profileAsset, fileHandler, finalImageTwo]);

	const profileImageThree = React.useMemo(() =>
		<div className={classes.assetContainer}>
			{
				finalImageThree && !(finalImageThree.match(/\/uploads\/default_pic\.png$/) || finalImageThree === "")
					? <>
						<img
							className={classes.profileImage}
							alt="img"
							src={finalImageThree}
							draggable={false}
							width="100%"
						/>
						<label htmlFor="button-file-three">
							<EditButton size={30} bottom={5} right={5}/>
						</label>
					</>
					: <label htmlFor="button-file-three">
						<PlaceholderImage className={classes.profileAsset} title={"Add Image"}/>
					</label>
			}

			<input
				accept="image/*"
				onChange={(e) => fileHandler(e, 2)}
				style={{display: "none"}}
				id="button-file-three"
				type="file"
			/>
		</div>, [classes.assetContainer, classes.profileAsset, fileHandler, finalImageThree]);

	return (
		!isOnExpertise ? (
			<>
				<Header
					heading={'Edit Profile'}
					RightComponent={() => {
						return (
							<Typography className={classes.saveButton} onClick={handleSubmit}>
								Save
							</Typography>
						)
					}}
				/>

				<Paper className={classes.container}>
					{
						showCropedImage &&
						<Box className={classes.imgOuterContainer}>
							<Box className={classes.imageContainer}>
								<Box className={classes.cropContainer}>
									<Cropper
										image={showImage}
										crop={crop}
											zoom={zoom}
											rotation={rotationValue}
											aspect={4 / 4}
											onCropChange={setCrop}
											onCropComplete={onCropComplete}
											onZoomChange={setZoom}
										/>
								</Box>
							</Box>

							<Box padding="0 20px 0 20px" width="100%">
								<div
									className={classes.root}
									style={{
										justifyContent: "space-between",
										alignItems: "center",
										width: "100%",
										display: "flex",
									}}
								>
									<div className={classes.rotateBtn}
										 onClick={() => setRotationValue(rotationValue - 90)}><span
										style={{color: "#fff"}}><RotateLeft/></span></div>
									<Box padding="0 20px 0 20px" width="100%">
										<div className={classes.root}>
											<Slider value={zoom} min={1} max={5} step={0.1}
													onChange={(e, newValue) => setZoom(newValue)}
													aria-label="pretto slider"/>
										</div>
									</Box>
									<div className={classes.rotateBtn}
										 onClick={() => setRotationValue(rotationValue + 90)}><span
										style={{color: "#fff"}}><RotateRight/></span></div>
								</div>
							</Box>
							<Box className={classes.btnContainer} position="absolute" display="flex"
								 justifyContent="space-between" bottom={0} padding="0 20px 0 20px" width="100%">
								<div className={classes.root}>
									<Button style={{backgroundColor: "#080A09", border: '1px solid silver'}}
											onClick={() => setShowCropedImage(false)}>Cancel</Button>
								</div>
								<div className={classes.root}>
									<Button onClick={showCroppedImage}>Save</Button>
								</div>
							</Box>
						</Box>
					}

					<Box className={classes.cardContainer} style={{position: 'relative'}}>
						<div className={classes.imgContainer} style={{marginBottom: 20}}>
							<div style={{position: 'relative', height: '100%', width: '100%'}}>
								{
									finalImageOne &&
									!(finalImageOne.match(/\/uploads\/default_pic\.png$/) || finalImageOne === "")
										? <>
											<img
												className={clsx(classes.profileImageMain)}
												alt="img"
												src={finalImageOne}
												draggable={false}
												width="100%"
											/>
											<label htmlFor="button-file">
												<EditButton size={36} bottom={5} right={5}/>
											</label>
										</>
										: <label htmlFor="button-file">
											<PlaceholderImage
												className={clsx(classes.profileAssetMain)}
												title={"Add Image"}
											/>
										</label>
								}

								<input
									accept="image/*"
									onChange={(e) => fileHandler(e, 0)}
									style={{display: "none"}}
									id="button-file"
									type="file"
								/>
							</div>
						</div>

						<Box display="grid"
							 alignItems="center"
							 justifyContent="space-between"
							 gridTemplateColumns={'1fr 1fr 1fr'}
							 gridColumnGap={23}
						>
							{profileImageTwo}

							{profileImageThree}

							<div className={classes.assetContainer}
								 style={{backgroundImage: `url(${videoUrl ? videoUrl : ""})`}}>
								{
									video_url || videoUrl
										? (<>
											{
												isIOS
													? <ReactPlayer
														light={"https://images.unsplash.com/photo-1541363111435-5c1b7d867904?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8ZHVtbXl8ZW58MHx8MHx8&ixlib=rb-1.2.1&w=150&q=80"}
														playsinline
														url={video_url ? video_url : videoUrl ? videoUrl : ""}
														width="100%"
														height="100%"
														className={classes.profileAsset}
													/>
													: <ReactPlayer playsinline url={videoUrl ? videoUrl : ""}
																   width="100%" height="100%"/>
											}

											<label htmlFor="button-video">
												<EditButton size={30} bottom={5} right={5}/>
											</label>
										</>)
										: (
											<label htmlFor="button-video">
												{
													video_url
														? <img
															className={classes.profileAsset}
															alt="img"
															src={Pics.videoThumbnail}
															draggable={false}
															width="100%"
														/>
														: <PlaceholderVideo className={classes.profileAsset}
																			title={"Add Video"}/>}
											</label>
										)
								}

								<input
									accept="video/*"
									onChange={(e) => videoHandler(e, 0)}
									style={{display: "none"}}
									id="button-video"
									type="file"
								/>
							</div>

							<Snackbar open={openAlert} autoHideDuration={6000} onClose={handleCloseAlert}>
								<Alert onClose={handleCloseAlert} severity="success">
									Video uploaded successfully!
								</Alert>
							</Snackbar>
						</Box>
					</Box>

					{
						imageSizeValidation === false
							? <ErrorBox error={<div style={{ textAlign: "center"}}>Image size should be less 10MB</div>}/>
							: null
					}

					{
						videoSizeValidation === false
							? <ErrorBox error={<div style={{ textAlign: "center"}}>Video size should be less 100MB</div>}/>
							: null
					}

					<FormSection label="Personal Details">
						{
							enabledUsername ?
								<InputField
									type="text"
									name="username"
									label="Username"
									value={username ? username : ''}
									onChange={handleNameChange}
									className={classes.transparentInput}
									placeholder="Example: SuperMn123"
									disabled={!enabledUsername}
									errors={[
										errMsg && check && "Username already exists" &&
										"This Username is already in use. Please choose a different one.",
									]}
								/>
								:
								<InputField
									type="text"
									name="username"
									label="Full Name"
									value={fullName ? fullName : ""}
									onChange={handleNameChange}
									className={classes.transparentInput}
									placeholder="Example: SuperMn123"
									disabled={showUsername === "fullname"}
									errors={[]}
								/>
						}

						<Box>
							<Box
								display="flex"
								justifyContent="space-between"
								alignItems="center"
								padding="0 15px"
								gridColumnGap={30}
							>
								<Typography variant="caption">Display:</Typography>

								<Box className={classes.inline}>
									<RadioGroup name="showUsername" value={"" + showUsername}
												onChange={handleRadioChange}>
										<Box display="flex" gridColumnGap={10}>
											<RadioButtonField value="fullname" label="Full Name"/>
											<RadioButtonField value="username" label="Username"/>
										</Box>
									</RadioGroup>
								</Box>
							</Box>

							{
								showUsername === "username" && (username?.length === 0 ||
									username?.length < 2 || username?.length > 20)
									? <ErrorBox error={"Username length should be 2 - 20"}/>
									: null
							}

							{
								showUsername === "username" && username?.indexOf(' ') >= 0
									? <ErrorBox error={"There should be no space in username"}/>
									: null
							}

							{
								showUsername === "fullname" && /\d/.test(full_name)
									? <ErrorBox error={"Full name should not contain numbers"}/>
									: null
							}
						</Box>

						<InputField
							type="text"
							name="firstname"
							label="First Name"
							value={firstName}
							onChange={handleFullName}
							placeholder="John"
							errors={[
								firstName.length < 2 ? "Minimum 2 characters." : null,
								firstName.length > 30 ? "Cannot be more than 30 characters" : null
							]}
						/>

						<InputField
							type="text"
							name="secondname"
							label="Last Name"
							value={secondName}
							onChange={handleFullName}
							placeholder="Smith"
							errors={[
								secondName.length < 2 ? "Minimum 2 characters." : null,
								secondName.length > 30 ? "Cannot be more than 30 characters" : null
							]}
						/>

						<TextArea
							label="Bio"
							maxLength={200}
							rows={5}
							name="bio"
							value={bio}
							onChange={handleChange}
						/>
					</FormSection>

					<FormSection label="Rate">
						<FormGroup
							display="flex"
							justifyContent="space-between"
							flexDirection="row"
							alignContent="center"
							style={{padding: "22px 15px"}}
							gridColumnGap={10}
							errors={[rate > 999 || rate < 5 ? "Your rate must be between $5 and $999" : null]}
						>
							<Typography
								style={{
									fontFamily: '-apple-system, BlinkMacSystemFont, cerebri-semibold',
									fontWeight: 400,
									fontSize: 22,
									lineHeight: "26.25px",
									letterSpacing: "0%",
									color: '#9F9F9F',
								}}
							>
								$
							</Typography>

							<input
								name="rate"
								value={Math.round(rate) === 0 ? " " : " " + Math.round(rate)}
								onChange={handleRateChange}
								className={clsx(classes.transparentInput)}
								placeholder="5.00"
								maxLength="4"
								style={{width: '100%', lineHeight: "26.25px"}}
							/>

							<Typography
								style={{
									fontFamily: '-apple-system, BlinkMacSystemFont, cerebri-semibold',
									fontWeight: 400,
									fontSize: 22,
									lineHeight: "26.25px",
									letterSpacing: "0%",
									minWidth: 'fit-content',
									color: '#9F9F9F',
								}}
							>
								/ Quartr
							</Typography>
						</FormGroup>
					</FormSection>

					<FormSection label="Person Type">
						<FormGroup
							justifyContent={'space-between'}
							alignContent={'center'}
							flexDirection={'column'}
							style={{border: 0, padding: "15px 0"}}
							groupEffect={false}
						>
							{allPersonTypes.map((t, i) => (
								<Box
									key={i}
									display={'flex'}
									flexDirection={'row'}
									width={'100%'}
									justifyContent={'space-between'}
									alignItems={'center'}
									className={clsx(classes.formGroupListItem)}
								>
									<FormControlLabel
										classes={{ root: classes.formControlLabelRoot }}
										onChange={handlePersonTypeChange}
										control={
											<Checkbox
												classes={{ root: classes.checkboxRoot }}
												value={t.title}
												checked={state.person_type.includes(t.title)}
												icon={
													<span style={{
														boxSizing: "border-box",
														width: 22,
														height: 22,
														background: "#141414",
														border: "1px solid #373737",
														borderRadius: 5,
													}} />
												}
												checkedIcon={
													<span style={{
														boxSizing: "border-box",
														width: 22,
														height: 22,
														background: "#31A7DB",
														borderRadius: 5,
														display: "flex",
														placeContent: "center",
														alignItems: "center",
													}}>
														<svg width="16"
															 height="12"
															 viewBox="0 0 16 12"
															 fill="none"
															 xmlns="http://www.w3.org/2000/svg"
														>
															<path
																d="M15.2656 2.14453L13.4609 0.339844L5.75 8.04785L2.53906 4.83984L0.734375 6.64453L5.75 11.6602L15.2656 2.14453Z"
																fill="white"
															/>
														</svg>
													</span>
												}
											/>
										}
										label={
											<Typography className={classes.formGroupItemLabel}>
												{t.title}
											</Typography>
										}
									/>
								</Box>
							))}
						</FormGroup>
					</FormSection>

					<FormSection label={
						<Box display="flex" flexDirection="row" justifyContent="space-between">
							<Typography className={classes.formSectionLabel}>Expertise</Typography>

							<div className={classes.expertiseButton} onClick={() => setIsOnExpertise(state.tags)}>
								<span className={classes.editButton}>
									Edit
								</span>
								<svg width="7" height="12" viewBox="0 0 7 12" fill="none"
									 xmlns="http://www.w3.org/2000/svg">
									<path d="M0 1.215L1.1776 0L7 6L1.1776 12L0 10.785L4.64115 6L0 1.215Z" fill="white"/>
								</svg>
							</div>
						</Box>
					}>

						{state.tags?.length > 0 && (
							<FormGroup
								justifyContent={'space-between'}
								alignContent={'center'}
								flexDirection={'column'}
								style={{border: 0, padding: "15px 0"}}
								groupEffect={false}
							>
								{state.tags
									?.sort(function (a, b) {
										return b.years - a.years;
									})
									.map((t, i) => (
										<Box
											key={i}
											display={'flex'}
											flexDirection={'row'}
											width={'100%'}
											justifyContent={'space-between'}
											alignItems={'center'}
											className={clsx(classes.formGroupListItem)}
										>
											<Typography
												className={classes.formGroupListItemLabel}>
												{t.expertise}
											</Typography>
											<Typography
												className={classes.formGroupListItemValue}>
												{t.years} years
											</Typography>
										</Box>
									))}
							</FormGroup>
						)}
					</FormSection>

					<FormSection label="Notifications">
						{state.tags?.length > 0 && (
							<FormGroup
								justifyContent={'space-between'}
								alignContent={'center'}
								flexDirection={'column'}
								style={{border: 0, padding: "15px 0"}}
								groupEffect={false}
							>
								<Box
									display={'flex'}
									flexDirection={'row'}
									width={'100%'}
									justifyContent={'space-between'}
									alignItems={'center'}
									className={clsx(classes.formGroupListItem)}
								>
									<Typography className={classes.formGroupListItemLabel}>
										Reviews
									</Typography>

									<MySwitch
										isDark
										checked={enable_review_notification}
										onChange={handleChange}
										name="enable_review_notification"
									/>
								</Box>

								<Box
									display={'flex'}
									flexDirection={'row'}
									width={'100%'}
									justifyContent={'space-between'}
									alignItems={'center'}
									className={clsx(classes.formGroupListItem)}
								>
									<Typography className={classes.formGroupListItemLabel}>
										Referrals
									</Typography>

									<MySwitch
										isDark
										checked={enable_invite_notification}
										onChange={handleChange}
										name="enable_invite_notification"
									/>
								</Box>
							</FormGroup>
						)}
					</FormSection>

					<Box className={classes.body}>
						<Modal
							open={open}
							onClose={handleModalClose}
							className={classes.modal}
							aria-labelledby="simple-modal-title"
							aria-describedby="simple-modal-description"
						>
							<Box className={classes.modalContainer}>
								<Box padding={isAndroid ? "50px" : "40px"}
									 width={isMobile ? "97%" : "100"}> {/**370 */}
									<Typography variant="body2">Save Changes</Typography>
									<Box marginTop="20px">
										<Typography variant="caption">
											Do you want to save changes?
										</Typography>
										<Box marginTop="auto" className={classes.modalActionContainer}>
											<Button
												onClick={handleModalClose}
												variant="outlined"
												color="default"
												style={{width: "46%"}}
											>
												{"No"}
											</Button>
											<Button
												onClick={handleSubmit}
												color="primary"
												style={{width: "46%"}}
											>
												{"Yes"}
											</Button>
										</Box>
									</Box>
								</Box>
							</Box>
						</Modal>
					</Box>
				</Paper>
			</>
		) : (
			<Expertise
				handleTagsChange={handleTagsChange}
				handleBackToEdit={handleBackToEdit}
				backToEdit={backToEdit}
				tags={state.tags}
			/>
		)
	);
}

export default withReducer("EditProfile", reducer)(withRouter(EditProfile));
