import React, {useState} from "react";
import {withRouter} from "react-router";
import {Box, Paper, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import StarRateIcon from "./icons/StarRateIcon";
import PlaceholderImage from "./icons/PlaceholderImage";
import {Image} from "semantic-ui-react";
import PlaceholderErrorImage from "./icons/PlaceholderErrorImage";

const useStyles = makeStyles((theme) => ({
	container: {
		height: "160px",
		width: "160px",
		borderRadius: "16px",
		backgroundSize: '100% 100%',
		backgroundRepeat: 'no-repeat',
		boxShadow: '0px 12px 24px 0px #4444441A'
	},
	absolute: {
		position: "absolute",
	},
	tags: {
		marginRight: "10px",
		fontSize: 10,
	},
	title: {
		position: "absolute",
		width: "100%",
		bottom: "0",
		padding: "5px"
	},
	tagsContainer: {
		marginTop: '10px',
		width: '156px',
		padding: '0 2px',
		display: "grid",
		gap: '4px',
		gridTemplateColumns: '1fr 1fr',
	},
	cardContainer: {
		height: "160px",
		width: "160px",
		position: "relative",
	},
	textContainer: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		width: "100%",
	},
	ratingPill: {
		boxSizing: "border-box",
		position: "absolute",
		display: "flex",
		bottom: '5px',
		right: '5px',
		gap: '5px',
		placeItems: "center",
		padding: '0 8px',
		backgroundColor: '#1B1B1B',
		borderRadius: '9px',
	},
	ratePill: {
		boxSizing: "border-box",
		position: "absolute",
		display: "flex",
		top: '5px',
		right: '5px',
		gap: '5px',
		placeItems: "center",
		padding: '0 8px',
		backgroundColor: '#1B1B1B',
		borderRadius: '9px',
	},
	userName: {
		fontSize: '14px',
		fontWeight: '400',
		lineHeight: '16.71px',
		letterSpacing: '0.5px',
		textAlign: 'left',
		textUnderlinePosition: 'from-font',
		textDecorationSkipInk: 'none',
	},
	userProfession: {
		fontFamily: "-apple-system, BlinkMacSystemFont, cerebri-regular",
		fontSize: '12px',
		fontWeight: '300',
		lineHeight: '14.32px',
		letterSpacing: '0.5px',
		textAlign: 'left',
		textUnderlinePosition: 'from-font',
		textDecorationSkipInk: 'none',
		marginTop: '4px',
		color: '#A8A8A8',
	},
	userRate: {
		fontFamily: "-apple-system, BlinkMacSystemFont, cerebri-regular",
		fontSize: '16px',
		fontWeight: 600,
		lineHeight: '19.09px',
		textAlign: 'left',
		textUnderlinePosition: 'from-font',
		textDecorationSkipInk: 'none',
		color: '#FFFFFF',
		justifySelf: 'end',
		alignSelf: 'center',
	}
}));

function UserCard({ user, ...props }) {
	const classes = useStyles();
	const [imageError, setImageError] = useState(false);

	console.log(user?.profile_media_urls[0] && !(
		user.profile_media_urls[0] === "" || user.profile_media_urls[0].match(/\/uploads\/default_pic\.png$/)
	))

	return (
		<Box className="userCard disable-selection" style={{ cursor: 'pointer' }}>
			<Paper className={classes.container}>
				{user?.profile_media_urls[0] && !(
					user.profile_media_urls[0] === "" || user.profile_media_urls[0].match(/\/uploads\/default_pic\.png$/)
				)
					? (imageError
							? <PlaceholderErrorImage
								className={`${classes.container} ${classes.absolute}`}
								title={"Error Loading Media"}
							/>
							: <Image
								src={user.profile_media_urls[0]}
								alt={user.enabledUsername ? user.username : user.full_name}
								className={`${classes.container} ${classes.absolute}`}
								onError={() => setImageError(true)}
							/>
					)
					: <PlaceholderImage className={`${classes.container} ${classes.absolute}`} />
				}

				<Box className={classes.cardContainer}>
					<Box className={classes.textContainer}>
						<Box className={classes.ratePill}>
							<Typography variant="body1" className={classes.userRate}>
								{user.rate <= 0 ? "Free" : `$${user.rate}`}
							</Typography>
						</Box>

						<Box className={classes.ratingPill}>
							<StarRateIcon fill="#FFB800" />

							<Typography variant="body1" style={{
								fontWeight: 600,
								fontSize: 12,
								lineHeight: "19.09px",
								letterSpacing: "0%",
							}}>
								{user.rating === 0 ? 'No Reviews' : user.rating.toFixed(1)}
							</Typography>
						</Box>
					</Box>
				</Box>
			</Paper>

			<Box className={classes.tagsContainer}>
				<Box style={{ gridColumn: 'span 2'}}>
					<Typography variant="body1" className={classes.userName} noWrap={true}>
						<span title={user.enabledUsername ? user.username : user.full_name}>
							{user.enabledUsername ? user.username : user.full_name}
						</span>
					</Typography>

					{
						user.tags.length ?
							<Typography variant="body1" className={classes.userProfession} noWrap={true}>
							<span title={`${user.tags[0]?.expertise} - ${user.tags[0]?.years} ${user.tags[0]?.years === 1 ? 'yr' : 'yrs'}`}>
								{user.tags[0]?.expertise} - {user.tags[0]?.years} {user.tags[0]?.years === 1 ? 'yr' : 'yrs'}
							</span>
						</Typography>
						: null
					}
				</Box>
			</Box>
		</Box>
	);
}

export default React.memo(withRouter(UserCard));
