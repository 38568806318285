import React from "react";

function StarRateIcon({ ...props }) {
    return (
            <svg width={props.width ?? '10'} height={props.height ?? '10'} viewBox="0 0 10 10" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
                <path
                    d = "M5.45678 0.30596L6.60353 2.80104C6.65339 2.95075 6.80297 3.05055 6.95255 3.10045L9.54521 3.49967C9.99394 3.49967 10.1435 3.99868 9.84436 4.29809L7.94972 6.24426C7.85001 6.34406 7.80015 6.54367 7.80015 6.69337L8.24888 9.38806C8.29874 9.78728 7.89987 10.1366 7.55085 9.93698L5.20748 8.63954C5.05791 8.53973 4.90833 8.53973 4.75875 8.63954L2.41539 9.93698C2.06637 10.1366 1.61764 9.83718 1.71736 9.38806L2.16609 6.69337C2.21595 6.54367 2.11623 6.34406 2.01652 6.24426L0.121877 4.29809C-0.127417 3.99868 0.0221595 3.49967 0.421031 3.44976L3.01369 3.05055C3.16327 3.05055 3.31285 2.90085 3.36271 2.75114L4.50946 0.256058C4.75875 -0.0932535 5.25734 -0.0932536 5.45678 0.30596Z"
                    fill={props.fill}
                />
            </svg>
        )
}

export default StarRateIcon;